import React from 'react';
import { computeIsRequired, getInputData, getInputError } from "./util";
import * as classnames from "classnames";
import { arrayContextWrapper } from "../ArrayContext";
import { formContextWrapper } from "../FormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import GoogleMapComponent from "google-map-react";
import "./googleMap.module.css";
import GoogleSearch from "../util/GoogleSearch";
import Button from '../../UI/Button/Button'
import { getGeocodeLatlng } from '../util/geoCode'

export const Marker = ({ icon }) => <FontAwesomeIcon styleName="marker" icon={icon}></FontAwesomeIcon>;


class GoogleMap extends React.Component {

    state = {
        required: false,
        $ref: "",
        uploading: false,
        imageUrl: undefined,
        pointedLocation: null,
        realLocation: null,
        placeId: undefined
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scope.$ref !== prevState.$ref) {
            return {
                required: computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref),
                $ref: nextProps.scope.$ref
            }
        }
        return prevState;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const context = this.props.context;
        const nextContext = nextProps.context;
        const $ref = this.props.scope.$ref;
        const next$ref = nextProps.scope.$ref;
        const array = this.props.array;
        const nextArray = nextProps.array;
        return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) ||
            getInputError(context, array, $ref) !== getInputError(nextContext, nextArray, next$ref) ||
            $ref !== next$ref ||
            context !== nextContext
    }

    componentDidMount() {
        setTimeout(() => {
            this.loadData()
        }, 0)
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(this.state.pointedLocation) return;
        this.loadData()
    }

    loadData = () => {
        const { context, array, scope: { $latRef, $lngRef } } = this.props;
        const lat = getInputData(context, array, $latRef);
        const lng = getInputData(context, array, $lngRef);
        const value = lat && lng ? [parseFloat(lat), parseFloat(lng)] : [0, 0];

        this.setState({
            required: false,
            $ref: '',
            uploading: false,
            imageUrl: undefined,
            pointedLocation: value ? value : [0, 0],
            realLocation: value,
            placeId: undefined
        })
    }


    setRealMarker = async (inputHandler, $ref, $latRef, $lngRef) => {
        const location = this.state.pointedLocation
        this.setState({
            realLocation: location
        }, () => {
            inputHandler($ref, this.state.realLocation);
            setTimeout(() => {
                inputHandler($latRef, location[0].toFixed(6));
                setTimeout(() => {
                    inputHandler($lngRef, location[1].toFixed(6));
                }, 0)
            }, 0)
        });
    };

    setPlaceId = (placeId, inputHandler, $placeIdRef) => {
        this.setState({
            placeId: placeId
        }, async () => {
            await inputHandler($placeIdRef, this.state.placeId);
        })
    };

    setPointedMarker = (location) => {
        this.setState({
            pointedLocation: [location.lat, location.lng]
        })
    };

    setLocation = (e) => {
        e.preventDefault();
        const { context, scope: { $ref, $latRef, $lngRef, $placeIdRef }, array } = this.props;
        const inputHandler = array ? array.onInputChange : context.onInputChange;

        this.setRealMarker(inputHandler, $ref, $latRef, $lngRef).then(() => {
            getGeocodeLatlng({ lat: this.state.pointedLocation[0], lng: this.state.pointedLocation[1] })
                .then(result => {
                    this.setPlaceId(result[0].place_id, inputHandler, $placeIdRef);
                }).catch((error) => {
                console.error('getGeocodeLatlng error: ', error.message)
            })
        }).catch((error) => {
            console.error('real marker error: ', error.message)
        })
    }

    renderInput() {
        const { context, scope: { $ref, $latRef, $lngRef, $placeIdRef }, array } = this.props;
        const inputHandler = array ? array.onInputChange : context.onInputChange;

        const changeLocation = async (result) => {
            await this.setPointedMarker(result.location);
            await this.setRealMarker(inputHandler, $ref, $latRef, $lngRef);
            this.setPlaceId(result.placeId, inputHandler, $placeIdRef);
        }

        const handleChangeOnMap = async (map) => {
            await this.setPointedMarker(map.center);
        }

        return <div className="position-relative">
            <div styleName="geolocation">
                <div styleName="search">
                    <GoogleSearch handleChange={
                        (result) => changeLocation(result)
                    }
                    />
                    {this.state.realLocation &&
                        <GoogleMapComponent
                            bootstrapURLKeys={{
                                key: "AIzaSyC5R25oL5BvcgVGaHBBs3Fei_UWypeXO-E",
                            }}
                            onChange={(map) => handleChangeOnMap(map)}
                            center={this.state.pointedLocation ? this.state.pointedLocation : [0, 0]}
                            zoom={this.state.realLocation ? 10 : 2}>
                            <Marker
                                lat={this.state.pointedLocation[0]}
                                lng={this.state.pointedLocation[1]}
                                icon={faMapMarker}/>
                        </GoogleMapComponent>
                    }
                </div>
                <Button disabled={this.props.disabled} color="primary" styleName="pick" onClick={this.setLocation} >
                    Pick location
                </Button>
                <div styleName="marker-overlay">
                    <FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon>
                </div>
            </div>
        </div>
    }

    render() {

        const { outterhtmlClass } = this.props;
        return (
            <div style={this.props.style} className={classnames(outterhtmlClass, "pl-5")}>
                {this.renderInput()}
            </div>
        );
    };

}

export default arrayContextWrapper(formContextWrapper(GoogleMap));
