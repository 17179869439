import React from 'react'
import { Form } from 'reactstrap'

import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'

import './styles.module.css'
import {PermissionGuard} from "../../role-helpers";
import qs from "qs";
import Select from "../../../UI/Select/Select";

const AllUsersList = props => {

    const query = qs.parse(props.query, { ignoreQueryPrefix: true })

    const setFilter = (key, value) => {
        const newQuery = { ...query }

        if (value == null || value === "") {
            delete newQuery[key]
        } else {
            newQuery[key] = value
        }

        props.push(`?${qs.stringify(newQuery)}`)
    }

    return (
        <div>
            <div styleName="header" className="d-flex align-items-center">
                <h4>{props.title}</h4>
                <PermissionGuard code={props.permission}>
                    {!props.noBtn && <Button className="ml-auto" color="primary" onClick={props.actionHandler}>{props.actionLabel}</Button>}
                </PermissionGuard>

                {props.additionalComponent && props.additionalComponent()}
            </div>
            {!props.noSearch && <Form onSubmit={props.onSubmit} styleName="search onboardingSearch">
                <Input
                    style={{width: "256px"}}
                    value={props.value}
                    onChange={props.onChange}
                    name="search"
                    placeholder={props.searchTitle}/>
                    {/*<Select*/}
                    {/*    placeholder="All Countries"*/}
                    {/*    options={[{value: 1, label: "admin"}]}*/}
                    {/*    isSearchable={true}*/}
                    {/*    isClearable={true}*/}
                    {/*    onChange={c => setFilter("admin", c ? c.value : null)}*/}
                    {/*    className="mr-3 ml-3" />*/}
            </Form>}
        </div>
    )
}

export default AllUsersList
