import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Form} from 'reactstrap';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import {createMessage} from '../Util/notification';
import DynamicForm from '../DynamicForm/DynamicForm';
import user from '../../api/user';
import {ERR_VALIDATION} from '../../api/errorCodes';
import {getSafeDeep} from '../Util/state';
import {mapBackendErrors} from '../DynamicForm/controls/util';
import {parseErrors} from '../Util/form';
import brand from '../../api/brand';
import categories from '../../redux/category';

class DeleteBrandDialog extends React.Component {
  state = {
    errors: undefined
  };

  componentDidMount() {}

  componentDidUpdate() {}

  onSubmit = async (e) => {
    const data = this.props.data;
    e.preventDefault();
    try {
      await brand.delete(data.id);
      createMessage('Successfully deleted brand');
      this.props.reloadData();
      this.props.toggle();
    } catch (e) {
      console.error(e);
      this.setState({errors: parseErrors(e)});
    }
  };

  render() {
    const {toggle, isOpen, categories, data} = this.props;
    const {errors} = this.state;
    if (!isOpen) return '';

    return (
      <Modal
        toggle={toggle}
        isOpen={isOpen}
        errors={errors}
        centered
        title="Delete Brand"
        cancelButton={() => (
          <Button color="secondary" className="mr-3" onClick={toggle}>
            Cancel
          </Button>
        )}
        submitButton={() => (
          <Button color="danger" onClick={(e) => this.onSubmit(e)}>
            Delete
          </Button>
        )}
        size="xs"
      >
        <div className="text-center mb-5 mt-4">
          Are you sure you want to delete brand{' '}
          <strong>{getSafeDeep(data, 'name')}</strong> ?
        </div>
      </Modal>
    );
  }
}

function mapState(state) {
  return {
    categories: state.categories.data.filter(
      (it) => it.system_setting == false && it.name != 'online' && it.visible
    )
  };
}

function mapActions(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(DeleteBrandDialog));
