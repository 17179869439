import React, {Fragment} from 'react';
import user from '../../api/user';
import * as immutable from 'immutable';
import * as qs from 'qs';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {getSafeDeep} from '../Util/state';
import InfoForm from '../InfoForm/InfoForm';
import {notifyError} from '../Util/notification';
import {
  getAdminConfirmed,
  getAdminDeclined,
  getDocumentType,
  getMeetingType,
  getPermanentAddressCountry,
  getTemporaryAddressCountry,
  infoField,
  infoFieldCategory,
  getBTCWalletAddress,
  getBCHWalletAddress,
  getEURWalletAddress,
  getETHWalletAddress,
  getELIWalletAddress
} from '../Util/infoForm';
import WithLoading from '../Util/HOC/WithLoading';
import EditUserDialog from './EditUserKYCDialog';
import ResetPasswordDialog from './ResetPasswordDialog';
import SuspendUserDialog from './SuspendUserDialog';
import KYCEditDialog from '../KYC/KYCEditDialog';
import UnblockLoginDialog from './UnblockLoginDialog';
import Page from '../UI/Page/Page';
import Button from '../UI/Button/Button';
import {Card} from '@material-ui/core';

import './userInfo.module.css';
import {Helmet} from 'react-helmet';
import {routes} from '../Util/routing';
import ActivateUserDialog from './ActivateUserDialog';

const userInfoFields = [
  infoFieldCategory('Personal Information', [
    infoField('profile.first_name', 'First name'),
    infoField('profile.last_name', 'Last name'),
    infoField('email', 'E-mail'),
    infoField('id', 'ID'),
    infoField('profile.citizenship', 'Nationality'),
    infoField('profile.country_phone_prefix', 'Country phone prefix'),
    infoField('profile.national_number', 'National number'),
    infoField('profile.birth_date', 'Date of birth', 'date'),
    infoField('profile.birth_place.city', 'Place of Birth')
  ]),
  infoFieldCategory('Address Information', [
    infoField('profile.permanent_address.address1', 'Address'),
    infoField('profile.permanent_address.zipcode', 'Postal code'),
    infoField('profile.permanent_address.city', 'City'),
    infoField(getPermanentAddressCountry, 'Country'),
    infoField('profile.temporary_address.address1', 'Address 2'),
    infoField('profile.temporary_address.zipcode', 'Postal code 2'),
    infoField('profile.temporary_address.city', 'City 2'),
    infoField(getTemporaryAddressCountry, 'Country 2')
  ]),
  infoFieldCategory('Document Information', [
    infoField(getDocumentType, 'ID document type'),
    infoField('profile.document.0.serial_number', 'ID document number'),
    infoField(
      'profile.document.0.date_of_issue',
      'ID document issue date',
      'date'
    ),
    infoField(
      'profile.document.0.date_of_expiry',
      'ID document expiration date',
      'date'
    )
  ]),
  infoFieldCategory('Wallet Information', [
    infoField(getBTCWalletAddress, 'BTC wallet address'),
    infoField(getBCHWalletAddress, 'BCH wallet address'),
    infoField(getETHWalletAddress, 'ETH wallet address'),
    infoField(getEURWalletAddress, 'EUR wallet address'),
    infoField(getELIWalletAddress, 'ELI wallet address')
  ]),
  infoFieldCategory('Verification call', [
    infoField(getMeetingType, 'Meeting type'),
    infoField('profile.date_and_time', 'Date and time', 'datetime'),
    infoField('profile.education', 'Education'),
    infoField('profile.summer_goods', 'Yearly earnings'),
    infoField('profile.skype', 'Skype username'),
    infoField('profile.pep_list', 'PEP list', 'bool'),
    infoField('profile.sanction_list', 'Sanction list', 'bool'),
    infoField('profile.funds_source', 'Source of funds')
  ]),
  infoFieldCategory('User Document Uploads', [
    infoField(
      'profile.document.0.document_front_image',
      'Front side ID',
      'kycImage'
    ),
    infoField(
      'profile.document.0.document_back_image',
      'Back side ID',
      'kycImage'
    ),
    infoField('profile.photo', 'Selfie with ID and handwritten note', 'image')
  ]),

  infoFieldCategory('Registration & KYC', [
    infoField('access_history.kyc_created.created', 'KYC Created', 'date'),
    infoField('date_joined', 'Registered', 'date')
  ]),

  infoFieldCategory('KYC Confirmed/Declined', [
    infoField('access_history.kyc_confirmed.created', 'KYC Confirmed', 'date'),
    infoField(getAdminConfirmed, 'KYC Confirmed by'),
    infoField('access_history.kyc_declined.created', 'KYC Declined', 'date'),
    infoField(getAdminDeclined, 'KYC Declined by')
  ]),

  infoFieldCategory('KYC Updated', [
    infoField('access_history.kycU.created', 'KYC Updated', 'date')
  ]),

  infoFieldCategory('COMMENT', [infoField('profile.comment', 'Comment')])
];

const InfoFormWithLoading = WithLoading(InfoForm);

class UserInfo extends React.Component {
  state = {
    user: undefined,
    isLoading: true,
    resetPasswordDialogOpen: false,
    editUserDialogOpen: false,
    editKycDialogOpen: false,
    deleteUserDialogOpen: false,
    suspendUserDialogOpen: false,
    unblockUserDialogOpen: false,
    activateUserDialogOpen: false,
    additionalComponents: <div></div>
  };

  toggleDialog = (key) => {
    const dialogName = `${key}DialogOpen`;
    this.setState({[dialogName]: !this.state[dialogName]});
  };

  loadData = async () => {
    try {
      const {
        match: {
          params: {id}
        }
      } = this.props;
      this.setState({isLoading: true});
      const {data} = await user.get(id);
      this.setState({
        user: data,
        isLoading: false,
        additionalComponents: this.renderAdditionalComponents()
      });
    } catch (e) {
      this.setState({user: null, isLoading: false});
      notifyError(e.message);
      this.props.history.push(routes.kycList);
    }
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {}

  renderAdditionalComponents = () => {
    if (!this.state.user) {
      this.loadData();
    } else {
      const userLabel = !this.state.user
        ? undefined
        : (this.state.user && this.state.user.is_active
            ? 'Deactivate'
            : 'Activate') + ' User';

      const isSuperAdmin = false;
      const kycDisabled =
        getSafeDeep(this.state.user, 'profile', false) === null &&
        getSafeDeep(this.state.user, 'tier') === 1;
      return (
        <Fragment>
          <Button
            color="outline-primary"
            className="mr-3"
            onClick={() => this.toggleDialog('resetPassword')}
          >
            Reset Password
          </Button>
          <Button
            color="outline-primary"
            className="mr-3"
            disabled={!userLabel}
            onClick={() => this.toggleDialog('activateUser')}
          >
            {userLabel || 'X'}
          </Button>
          <Button
            color="outline-primary"
            className="mr-3"
            onClick={() => this.toggleDialog('suspendUser')}
          >
            Suspend User
          </Button>
          <Button
            color="outline-primary"
            className="mr-3"
            onClick={() => this.toggleDialog('editUser')}
          >
            Edit User
          </Button>
          {!kycDisabled && (
            <Button
              color="outline-primary"
              className="mr-3"
              onClick={() => this.toggleDialog('editKyc')}
            >
              Edit KYC
            </Button>
          )}
          {isSuperAdmin && (
            <Button
              color="outline-danger"
              onClick={() => this.toggleDialog('deleteUser')}
            >
              Delete User
            </Button>
          )}
          {getSafeDeep(this.state.user, 'is_login_blocked') && (
            <Button
              color="outline-danger"
              onClick={() => this.toggleDialog('unblockUser')}
            >
              Unblock Login
            </Button>
          )}
        </Fragment>
      );
    }
  };

  render() {
    const {user, isLoading} = this.state;
    const {documentTypes} = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>{`${getSafeDeep(user, 'first_name')} ${getSafeDeep(
            user,
            'last_name'
          )} | Elicon`}</title>
        </Helmet>
        <Page>
          <Card>
            <div styleName="header" className="d-flex align-items-center">
              <h4>
                User - {getSafeDeep(user, 'first_name')}{' '}
                {getSafeDeep(user, 'last_name')}
              </h4>
            </div>
            <div styleName="header" className="d-flex">
              {this.state.additionalComponents}
            </div>
            <InfoFormWithLoading
              isLoading={isLoading}
              fields={userInfoFields}
              data={user}
              requiredProps={{documentTypes: documentTypes}}
            />
          </Card>
        </Page>
        <EditUserDialog
          reloadData={this.loadData}
          data={user}
          isOpen={this.state['editUserDialogOpen']}
          toggle={() => this.toggleDialog('editUser')}
        />
        <ResetPasswordDialog
          reloadData={this.loadData}
          data={user}
          isOpen={this.state.resetPasswordDialogOpen}
          toggle={() => this.toggleDialog('resetPassword')}
        />
        <ActivateUserDialog
          reloadData={this.loadData}
          data={user}
          toggleUser={() =>
            this.setState({
              user: {...this.state.user, is_active: !this.state.user.is_active}
            })
          }
          isOpen={this.state.activateUserDialogOpen}
          toggle={() => this.toggleDialog('activateUser')}
        />
        <SuspendUserDialog
          reloadData={this.loadData}
          data={user}
          isOpen={this.state.suspendUserDialogOpen}
          toggle={() => this.toggleDialog('suspendUser')}
        />
        {!isLoading && (
          <KYCEditDialog
            reloadData={this.loadData}
            data={user}
            isOpen={this.state.editKycDialogOpen}
            toggle={() => this.toggleDialog('editKyc')}
          />
        )}
        <UnblockLoginDialog
          reloadData={this.loadData}
          data={user}
          isOpen={this.state.unblockUserDialogOpen}
          toggle={() => this.toggleDialog('unblockUser')}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    auth: state.auth,
    query: immutable.Map(
      qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
    ),
    documentTypes: state.documentTypes.data
  };
}

export default connect(mapState)(withRouter(UserInfo));
