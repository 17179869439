import {apiClientV3} from '../util';

export default {
  getTenants: () => apiClientV3.get('/admin/send-pos/tenants'),
  getTenant: (id) => apiClientV3.get(`/admin/send-pos/${id}`),
  connect: (pos, tenant) =>
    apiClientV3.post('/admin/send-pos', {
      point_of_sale_id: pos,
      tenant: tenant
    })
};
