import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ListBody from '../Util/ListView/ListBody/ListBody';
import WithLoading from '../Util/HOC/WithLoading';
import qs from 'query-string';
import {push} from 'connected-react-router';
import {listHeader, renderCreatedAt, renderLink} from '../Util/listView';
import {getSafeDeep} from '../Util/state';
import ListHeader from '../Util/ListView/ListHeader/ListHeader';
import immutable from 'immutable';
import AddReferralDialog from './AddReferralDialog';
import RemoveReferralDialog from './RemoveReferralDialog';
import Page from '../UI/Page/Page';
import {Card} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Edit, Delete, PlaylistAddCheck} from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import More from '../UI/More/More';
import referral from '../../api/V3/referral';
import EditReferralDialog from './EditReferralDialog';
import ActivitiesModal from './ActivitiesModal';
import {hasPermission} from '../Util/role-helpers';
import {notifyError} from '../Util/notification';
import {Helmet} from 'react-helmet';

const ListBodyWithLoading = WithLoading(ListBody);

class Referral extends React.Component {
  state = {
    isLoading: true,
    rowsPerPage: 10,
    count: 0,
    data: [],
    delete: null,
    addReferralDialogOpen: false,
    editReferralDialogOpen: false,
    activitiesModalDialogOpen: false,
    activityReferral: '',
    activityReferralEdit: '',
    search: ''
  };

  ReferralsListHeaders = [
    listHeader('Referral ID', 'id', true),
    listHeader('Owner', 'owner', true, undefined, undefined, undefined, 'left'),
    listHeader(
      'Short URL',
      'short_url',
      false,
      (row) => renderLink(row.short_url, row.short_url, true),
      undefined,
      undefined,
      'center'
    ),
    listHeader('Note', 'note', false, undefined, undefined, undefined, 'right'),
    listHeader(
      'Created At',
      'created_at',
      true,
      (row) => renderCreatedAt(row),
      undefined,
      undefined,
      'right'
    ),
    listHeader(
      'Actions',
      'actions',
      false,
      (data) => <More {...this.getActions(data)} />,
      undefined,
      undefined,
      'right'
    )
  ];
  getActions = (data) => {
    const StyledListItemText = withStyles({
      root: {
        fontSize: '16px!important'
      }
    })(ListItemText);

    const StyledIcon = (Icon) => {
      return withStyles({
        root: {
          fontSize: '24px'
        }
      })(Icon);
    };

    const StyledListItemIcon = withStyles({
      root: {
        minWidth: 24,
        marginRight: '16px'
      }
    })(ListItemIcon);

    const StyledSearch = StyledIcon(PlaylistAddCheck);
    const StyledEdit = StyledIcon(Edit);
    const StyledDelete = StyledIcon(Delete);
    const items = [];

    if (hasPermission(this.props.user, 'viewAll_company_referral_activities')) {
      items.push({
        handler: async () => {
          await this.setState({activityReferral: data.id});
          this.toggleDialog('activitiesModal');
        },
        render: () => (
          <Fragment>
            <StyledListItemIcon>
              <StyledSearch />
            </StyledListItemIcon>
            <StyledListItemText disableTypography primary="Activities" />
          </Fragment>
        )
      });
    }

    if (hasPermission(this.props.user, 'change_company_referral')) {
      items.push({
        handler: async () => {
          await this.setState({activityReferralEdit: data.id});
          this.toggleDialog('editReferral');
        },
        render: () => (
          <Fragment>
            <StyledListItemIcon>
              <StyledEdit />
            </StyledListItemIcon>
            <StyledListItemText disableTypography primary="Edit" />
          </Fragment>
        )
      });
    }

    if (hasPermission(this.props.user, 'delete_company_referral')) {
      items.push({
        handler: () => {
          this.setState({delete: data});
        },
        render: () => (
          <Fragment>
            <StyledListItemIcon>
              <StyledDelete />
            </StyledListItemIcon>
            <StyledListItemText disableTypography primary="Delete" />
          </Fragment>
        )
      });
    }

    return {
      items
    };
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(oldProps, prevState) {
    const {query, auth} = this.props;
    const {query: oldQuery, auth: oldAuth} = oldProps;
    if (
      !immutable.is(query, oldQuery) ||
      this.state.rowsPerPage != prevState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  toggleDialog = (dialogName) => {
    const fieldName = `${dialogName}DialogOpen`;
    this.setState({[fieldName]: !this.state[fieldName]});
  };

  loadData = async () => {
    try {
      const query = this.props.query;
      const page = query.page || 0;
      const sort = query.sort;
      const search = query.search;

      this.setState({isLoading: true, sort});
      const {
        data: {count, results: data}
      } = await referral.get(
        search,
        page * this.state.rowsPerPage,
        this.state.rowsPerPage,
        sort
      );
      this.setState({
        data,
        count,
        activityReferral: getSafeDeep(data, 'data.0.id'),
        isLoading: false
      });
    } catch (err) {
      this.setState({isLoading: false});
      notifyError(err.message);
    }
  };

  requestSort = (sort) => {
    this.setState({sort});
    const query = {...this.props.query};
    query.page = 0;
    query.sort = sort;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    let currentItem =
      qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).page *
        this.state.rowsPerPage || 0 + 1;
    let newPage = Math.floor(currentItem / newRowsPerPage);
    this.setState({rowsPerPage: newRowsPerPage});
    const query = {...this.props.query};
    query.page = newPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  render() {
    const {isLoading, rowsPerPage, count, data} = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Referrals | Elicon</title>
        </Helmet>
        <Page permission={'viewAll_company_referrals'}>
          <Card>
            <ListHeader
              type="Referral"
              title="Referrals"
              value={this.state.search}
              onChange={(e) => this.setState({search: e.target.value})}
              searchTitle="Filter"
              // onSubmit={(e) => this.onSearch(e)}
              actionHandler={(dialog) => this.toggleDialog(dialog)}
              toggle={() => this.toggleDialog('addReferral')}
              actionLabel="Add Wallet"
            />
            <ListBodyWithLoading
              sortField={this.state.sort}
              requestSort={this.requestSort}
              headers={this.ReferralsListHeaders}
              isLoading={isLoading}
              data={data}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
          </Card>
        </Page>
        <RemoveReferralDialog
          onSubmit={async (data) => {
            await referral.delete(data.id);
            this.loadData();
          }}
          referral={this.state.delete}
          toggle={() => this.setState({delete: null})}
        />
        <AddReferralDialog
          toggle={() => this.toggleDialog('addReferral')}
          isOpen={this.state.addReferralDialogOpen}
          reloadData={() => this.loadData()}
        />
        <EditReferralDialog
          toggle={() => this.toggleDialog('editReferral')}
          isOpen={this.state.editReferralDialogOpen}
          reloadData={() => this.loadData()}
          selectedReferral={this.state.data.find(
            (referral) => referral.id == this.state.activityReferralEdit
          )}
          id={this.state.activityReferralEdit}
        />
        <ActivitiesModal
          isOpen={this.state.activitiesModalDialogOpen}
          toggle={() => this.toggleDialog('activitiesModal')}
          id={this.state.activityReferral}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true}),
    user: state.user.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(Referral));
