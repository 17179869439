import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ListBody from '../../../../../Util/ListView/ListBody/ListBody';
import WithLoading from '../../../../../Util/HOC/WithLoading';
import qs from 'query-string';
import {push} from 'connected-react-router';
import {listHeader, renderCreated} from '../../../../../Util/listView';
import {getSafeDeep} from '../../../../../Util/state';
import ListHeader from '../../../../../Util/ListView/ListHeader/ListHeader';
import immutable from 'immutable';
import {Card} from '@material-ui/core';
import {notifyError} from '../../../../../Util/notification';
import withdrawal from '../../../../../../api/V3/withdrawal';

const ListBodyWithLoading = WithLoading(ListBody);

class Withdrawals extends React.Component {
  state = {
    isLoading: true,
    rowsPerPage: 10,
    sort: '',
    count: 0,
    approveDialogOpen: false,
    declineDialogOpen: false,
    withdrawal: undefined
  };

  getWithdrawalListHeaders = (data) => {
    return [
      listHeader('Date', 'created', false, (data) => renderCreated(data)),
      listHeader('Amount', 'amount', false, (data) => {
        return (
          data.amount +
          ' ' +
          this.props.currencies.find(
            (c) => c.id == getSafeDeep(data, 'currency')
          ).display_name
        );
      }),
      listHeader('Fee', 'extra.company_withdraw_fee', false, (data) => {
        return (
          getSafeDeep(data, 'extra.company_withdraw_fee') +
          ' ' +
          this.props.currencies.find(
            (c) => c.id == getSafeDeep(data, 'currency')
          ).display_name
        );
      }),
      listHeader('Local Fee', 'extra.local_currency', false, (data) => {
        return `${data.extra.company_local_withdraw_fee} ${
          this.props.currencies.find(
            (c) => c.id == getSafeDeep(data, 'extra.local_currency')
          ).display_name
        }`;
      }),
      listHeader(
        'Address',
        'address',
        false,
        undefined,
        undefined,
        undefined,
        'right'
      )
    ];
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(oldProps, prevState) {
    const {query, auth} = this.props;
    const {query: oldQuery, auth: oldAuth} = oldProps;
    if (
      !immutable.is(query, oldQuery) ||
      this.state.rowsPerPage != prevState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  loadData = async () => {
    // let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    try {
      const query = this.props.query;
      const search = query.search;
      let sort = query.sort ? query.sort : '';
      const page = query.page || 0;
      query.sort = sort;
      this.setState({isLoading: true});
      const {data: data} = await withdrawal.getCompanyWithdraw(
        this.props.organization.id
      );
      let count = data.length;
      this.setState({count, data, isLoading: false});
      if (query.page > Math.ceil(count / this.state.rowsPerPage))
        query.page = 0;
    } catch (err) {
      this.setState({isLoading: false});
      notifyError(err.message);
    }
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    let currentItem =
      qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).page *
        this.state.rowsPerPage || 0 + 1;
    let newPage = Math.floor(currentItem / newRowsPerPage);
    this.setState({rowsPerPage: newRowsPerPage});
    const query = {...this.props.query};
    query.page = newPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  requestSort = (sort) => {
    this.setState({sort});
    const query = {...this.props.query};
    query.page = 0;
    query.sort = sort;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  render() {
    const {isLoading, data, rowsPerPage, count} = this.state;

    return (
      <Fragment>
        <Card>
          <ListHeader
            type="OnlySearch"
            title="Withdrawal List"
            searchTitle="Search"
            noBtn={true}
            noSearch={true}
          />
          <ListBodyWithLoading
            sortField={this.state.sort}
            requestSort={this.requestSort}
            headers={this.getWithdrawalListHeaders(data)}
            isLoading={isLoading}
            data={data}
            count={count}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
          />
        </Card>
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true}),
    user: state.user.data,
    currencies: state.currencies.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(Withdrawals));
