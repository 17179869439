import React from 'react';
import moment from 'moment';
import {FormGroup, Label, Form} from 'reactstrap';
import Button from '../UI/Button/Button';
import './style.module.css';
import auth from '../../api/auth';
import {bindActionCreators} from 'redux';
import {replace} from 'connected-react-router';
import {connect} from 'react-redux';
import {clearUser, getUser, getUserList} from '../../redux/user';
import type {User} from '../../api/model/user';
import {loadApiResources} from '../Util/reduxUtil';
import {getCurrencyList} from '../../redux/currency';
import {getHwPosOptionsList} from '../../redux/hwPosOptions';
import {getDocumentTypeList} from '../../redux/documentType';
import {getBrandList} from '../../redux/brand';
import {getTransactionFeeList} from '../../redux/transactionFee';
import {getCategoryList} from '../../redux/category';
import {getPosProviderList} from '../../redux/posProvider';
import {getPlatformList} from '../../redux/platform';
import {changeStateFromInput} from '../Util/controlledComponent';
import {getTierList} from '../../redux/tier';
import {ELIPAY_LOCAL_STORAGE} from '../../config';
import {getExchangeFeeList} from '../../redux/exchangeFee';
import {getGlobalLanguageList} from '../../redux/sourceLang';

import MatCard from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {styled} from '@material-ui/core/styles';
import CheckBox from '../UI/CheckBox/CheckBox';

import logo from '../Header/logo_default_dark.svg';
import Input from '../UI/Input/Input';
import {getCountryList} from '../../redux/countries';
import {getPosTypeList} from '../../redux/posType';
import {notifyError, createMessage} from '../Util/notification';
import {clearAllCookies, deleteCookie} from '../../utils/helperFunctions';

type Props = {
  user: User
};

const Card = styled(MatCard)({
  width: 400,
  padding: 32
});

class Login extends React.Component<Props> {
  state = {
    username: '',
    password: '',
    error: '',
    otp: '',
    email: '',
    rememberMe: {checked: false},
    loading: false,
    forgotPassword: false
  };

  loadData = async () => {
    await loadApiResources(this.props);
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {username, password, otp} = this.state;
    if (username.length === 0 || password.length === 0) {
      return this.setState({error: 'Username or password blank'});
    }
    this.setState({loading: true});
    try {
      const response = await auth.login(
        username,
        password,
        otp === '' ? undefined : otp
      );
      if (response.data.status === 'otp_required') {
        this.props.replace(
          `/register-otp?token=${response.data.registration_token}`
        );
      } else {
        const {value} = await this.props.getUser();
        if (value[0].data.restricted != true) {
          await this.props.clearUser();
          clearAllCookies();
          await auth.logout();
          this.setState({error: 'Access denied'});
          return;
        }
        await this.loadData();
        localStorage.setItem(`${ELIPAY_LOCAL_STORAGE}/LoggingIn`, false);
      }
    } catch (e) {
      if (e.response) {
        if (e.response.data.code === 'validationError') {
          this.setState({error: JSON.parse(e.response.data.message)[0].reason});
        } else {
          this.setState({error: e.response.data.message});
        }
      } else {
        this.setState({error: e.message});
      }
    } finally {
      this.setState({loading: false});
    }
  };

  onChange = (e) => {
    this.setState(changeStateFromInput(e));
  };

  componentDidMount() {
    this.props.clearUser();
    clearAllCookies();
  }

  toggleRemember = (e) => {
    const rememberMe = this.state.rememberMe;
    rememberMe.checked = e;
    this.setState({rememberMe});
  };

  resetPassword = async (e) => {
    e.preventDefault();

    if (this.state.email) {
      try {
        this.setState({loading: true});
        await auth.forgotPassword(this.state.email);
        this.setState({loading: false, forgotPassword: false});
        createMessage('Check your email');
      } catch (err) {
        this.setState({loading: false});
        notifyError(err.message);
        this.setState({error: err.message});
      }
    } else {
      this.setState({error: 'Email is required'});
    }
  };

  ForgotPassword = () => {
    return (
      <Card>
        <CardContent>
          <div className="text-center mb-5">
            <h3>Forgot password</h3>
          </div>
          <p className="text-danger mb-5">{this.state.error}</p>
          <Form onSubmit={this.resetPassword}>
            <FormGroup>
              <Label for="username">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={this.state.email}
                onChange={this.onChange}
              />
            </FormGroup>
            <div className="d-flex mt-5">
              <Button
                isLoading={this.state.loding}
                color="secondary"
                onClick={() =>
                  this.setState({forgotPassword: false, error: ''})
                }
                isLoading={this.state.isLoading}
              >
                Back
              </Button>
              <Button
                color="primary"
                className="ml-auto"
                type="submit"
                isLoading={this.state.loading}
              >
                Reset password
              </Button>
            </div>
          </Form>
        </CardContent>
      </Card>
    );
  };

  Login = () => {
    const {username, password, otp, rememberMe, error} = this.state;

    return (
      <Card>
        <CardContent>
          <div className="text-center mb-5">
            <h3>Login</h3>
          </div>
          <p className="text-danger mb-5">{error}</p>
          <Form onSubmit={this.onSubmit}>
            <FormGroup>
              <Label for="username">Username</Label>
              <Input
                type="text"
                name="username"
                id="username"
                placeholder="Type here"
                value={username}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Type here"
                value={password}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="otp">One Time Password</Label>
              <Input
                type="text"
                name="otp"
                id="otp"
                placeholder="Enter your OTP"
                value={otp}
                onChange={this.onChange}
              />
            </FormGroup>
            <CheckBox
              label={'Remember me'}
              name="repeatable"
              checked={this.state.rememberMe.checked}
              onClick={(e) =>
                this.toggleRemember(!this.state.rememberMe.checked)
              }
            />
            <div className="d-flex mt-5">
              <button
                type="button"
                onClick={
                  this.state.loading
                    ? () => {}
                    : () => this.setState({forgotPassword: true, error: ''})
                }
                styleName="forgot"
              >
                Forgot password
              </button>
              <Button
                color="primary"
                className=" ml-auto"
                type="submit"
                isLoading={this.state.loading}
              >
                Sign In
              </Button>
            </div>
          </Form>
        </CardContent>
      </Card>
    );
  };

  render() {
    return (
      <div className="d-flex">
        <div styleName="left" className="d-flex">
          <div className="p-5">
            <img src={logo} width="120px" />
          </div>
          <div styleName="copyright" className="p-5">
            <a href="#">© {moment().format('YYYY')} Eligma</a>
          </div>
        </div>
        <div
          styleName="right"
          className="d-flex justify-content-center align-items-center"
        >
          {this.state.forgotPassword ? <this.ForgotPassword /> : <this.Login />}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return {
    user: state.user.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators(
    {
      getUser,
      clearUser,
      replace,
      getCurrencyList,
      getHwPosOptionsList,
      getDocumentTypeList,
      getUserList,
      getBrandList,
      getTransactionFeeList,
      getCategoryList,
      getPosProviderList,
      getPlatformList,
      getPosTypeList,
      getTierList,
      getExchangeFeeList,
      getGlobalLanguageList,
      getCountryList
    },
    dispatch
  );
}

export default connect(mapState, mapActions)(Login);
