import {fulfilled, fulfilledState, initState} from "./middleware";
import transactionFee from "../api/transactionFee";

export const GET_TRANSACTION_FEE_LIST = "GET_TRANSACTION_FEE_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_TRANSACTION_FEE_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getTransactionFeeList = () => ({
    type: GET_TRANSACTION_FEE_LIST,
    payload: transactionFee.list()
});
