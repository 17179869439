import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createMessage, notifyError} from "../Util/notification";
import {Form, Text} from 'informed';
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import {ERR_VALIDATION} from "../../api/errorCodes";
import {getSafeDeep} from "../Util/state";
import {mapBackendErrors} from "../DynamicForm/controls/util";
import {parseErrors} from "../Util/form";
import suspendUserDataSchema from "./schema/suspendUserData";
import suspendUserUiSchema from "./schema/suspendUserUi";
import Modal from '../UI/Modal/Modal'
import Button from '../UI/Button/Button'

class SuspendUserDialog extends React.Component {

    state = {
        errors: undefined,
        isLoading: false
    };

    componentDidMount() {

         this.loadSchema();
    }

    componentDidUpdate() {
    }



    onSubmit = async(data) => {
        try{
            const userData = this.props.data;
            this.setState({ isLoading: true })
            await user.suspend(userData.id, data.form.suspended_until);
            createMessage(`User suspended successfully`);
            this.setState({ isLoading: false })
            this.props.reloadData();
            this.props.toggle();
        }catch (e) {
            notifyError(e.message)
            this.setState({ isLoading: false, errors: parseErrors(e)});
        }
    };

    loadSchema = async() => {
        const uiSchema = suspendUserUiSchema;
        const dataSchema = suspendUserDataSchema;
        this.setState({ uiSchema, dataSchema });
    };

    render() {
        const {toggle, isOpen, tiers} = this.props;
        const {errors, uiSchema, dataSchema, isLoading} = this.state;
        if(!isOpen) return "";
        return <Fragment>
            <DynamicForm 
                modal={{
                    title: "Suspend User",
                    confirmLabel: "Suspend", 
                    toggle, 
                    isOpen,
                    size: "xs",
                }}
                uiSchema={uiSchema} 
                dataSchema={dataSchema} 
                errors={errors} 
                onSubmit={(data) => this.onSubmit(data)}/>
        </Fragment>
    };

}

function mapState(state) {
    return {
        tiers: state.tiers.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(SuspendUserDialog));
