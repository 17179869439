import {apiClientV3} from "../util";

export default {

     // Get a paginated list of hashes
     listPaginated: (offset: number = 0, limit: number = 10, unassigned: boolean = false, q: string = undefined) =>{
        let params = {
            offset: offset,
            limit: limit,
            unassigned: unassigned,
            q: q
        };
        return apiClientV3.get(`/admin/qr-codes/`, {params});
    },

    // List bundles of hashes(Zipped bundles, paginated)
    listBundlesPaginated: (offset: number = 0, limit: number = 10) => {
        return apiClientV3.get(`/admin/qr-codes/bundles/`, {params:{offset, limit}});
    },

    listCodes: (offset: number = 0, limit: number = 10, user_id, search, unassigned) => {
        return apiClientV3.get(`/admin/qr-codes/`, {params:{offset, limit, user_id, unassigned, search}});
    },

    // regenerate
    regenerate: (token:string) => {
        return apiClientV3.get(`/admin/qr-codes/bundles/${token}`)
    },

    //generate
    generate: (quantity) => {
        return apiClientV3.post(`admin/qr-codes/bundles/`, { quantity })
    }
}
