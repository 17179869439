import * as qs from 'qs';
import '../../components/MainMenu/mainMenu.module.css';

const relevantParams = ['tier'];

export function checkRoutesEqual(firstUrl, secondUrl) {
  let dashes = firstUrl.split('?');
  dashes = dashes.filter((item) => Number.isInteger(item) == false);
  let firstQuery =
    dashes.length > 1 ? qs.parse(dashes.pop(), {ignoreQueryPrefix: true}) : {};
  let firstRoute = dashes.join();

  dashes = secondUrl.split('?');
  dashes = dashes.filter((item) => Number.isInteger(item) == false);
  let secondQuery =
    dashes.length > 1 ? qs.parse(dashes.pop(), {ignoreQueryPrefix: true}) : {};
  let secondRoute = dashes.join();

  if (firstRoute != secondRoute) return false;
  for (let relevantParam of relevantParams) {
    if (firstQuery[relevantParam] != secondQuery[relevantParam]) return false;
  }

  return true;
}

export function getActiveClass(currentUrl, destinationUrl, activeClass) {
  let matching = checkRoutesEqual(currentUrl, destinationUrl);
  return matching ? activeClass : '';
}

export const routeNames = {
  '/user-management/users': 'User List',
  '/goCrypto/merchant-admin/organizations': 'Company List',
  '/goCrypto/merchant-admin/organizations/new': 'New Company',
  '/sales/kyc': 'Kyc List',
  '/goCrypto/merchant-admin/brands': 'Brand List',
  '/goCrypto/merchant-admin/organizations/:id/new-offline': 'New Local Store',
  '/goCrypto/merchant-admin/organizations/:id/new-online': 'New Online Store',
  '/goCrypto/merchant-admin/pos/:id': 'Store Information',
  '/goCrypto/merchant-admin/pos': 'Store List',
  '/goCrypto/merchant-admin/qr-bundles': 'QR Bundle List',
  '/goCrypto/merchant-admin/qr-codes': 'QR Code List',
  '/sales/loyalty': 'Loyalty Question List',
  '/admins': 'Admin List',
  '/admins/:id': 'Admin Information',
  '/profile': 'User Profile',
  '/user-management/roles': 'Role List',
  '/user-management/users/:id': 'User Information',
  '/goCrypto/merchant-admin/organizations/:id': 'Company Information',
  '/sales/loyalty/:id': 'Loyalty Question Information',
  '/sales/broadcast': 'Broadcast Notifications',
  '/goCrypto/balances': 'Balance List',
  '/accounting/wallets': 'Wallet List',
  '/goCrypto/transactions': 'Transaction List',
  '/goCrypto/referrals': 'Referral List',
  '/sales/company-kyb/:id': 'Company KYB Information',
  '/sales/company-kyb': 'Company KYB List',
  '/sales/company-kyb/new': 'Submit Company KYB',
  '/goCrypto/billing': 'Billing List',
  'goCrypto/pos-providers-billing': 'POS Providers Billing',
  '/goCrypto/referrals/activities': 'Referral Activity List',
  '/user-management/elly-users': 'Elly Users',
  '/exchange/trades': 'Trades',
  '/holistic-pos/companies': 'Holistic Companies',
  '/holistic-pos/companies/:id': 'Holistic POS Company Details',
  //TODO: remove this after lab4pay is implemented as separate tenant for pulsar
  '/holistic-pos/companies/:id/lab4pay/:id': 'Company Details',
  '/holistic-pos/lab4pay/stores': 'Holistic POS Lab4pay Stores',
  '/holistic-pos/lab4pay/stores/:id': 'Holistic POS Lab4pay Store Details'
  //TODO: end-remove
};

export const routes = {
  userList: '/user-management/users',
  organizationList: '/goCrypto/merchant-admin/organizations',
  newOrganization: '/goCrypto/merchant-admin/organizations/new',
  kycList: '/sales/kyc',
  brandList: '/goCrypto/merchant-admin/brands',
  posNewOffline: '/goCrypto/merchant-admin/organizations/:id/new-offline',
  posNewOnline: '/goCrypto/merchant-admin/organizations/:id/new-online',
  posInfoPattern: '/goCrypto/merchant-admin/pos/:id',
  posList: '/goCrypto/merchant-admin/pos',
  QRBundleList: '/goCrypto/merchant-admin/qr-bundles',
  QRCodeList: '/goCrypto/merchant-admin/qr-codes',
  loyaltyQuestionList: '/sales/loyalty',
  adminList: '/admins',
  adminInfoPattern: `/admins/:id`,
  profile: '/profile',
  roles: '/user-management/roles',
  userInfoPattern: '/user-management/users/:id',
  organizationInfoPattern: '/goCrypto/merchant-admin/organizations/:id',
  loyaltyQuestionInfoPattern: '/sales/loyalty/:id',
  broadcastNotifications: '/sales/broadcast',
  balanceList: `/goCrypto/balances`,
  walletsList: `/accounting/wallets`,
  transactions: '/goCrypto/transactions',
  referral: '/goCrypto/referrals',
  viewCompanyKYB: '/sales/company-kyb/:id',
  listCompanyKYB: '/sales/company-kyb',
  createCompanyKYB: '/sales/company-kyb/new',
  billing: '/goCrypto/billing',
  externalPOSProviders: '/goCrypto/external-pos-providers',
  activities: '/goCrypto/referrals/activities',
  elipayUserList: '/user-management/elly-users',
  transactionsExperimental: '/transactions-refactor',
  refactorReferralList: '/referral-refactor',
  merchantWithdrawal: '/goCrypto/merchant-admin/withdrawal',
  onboarding: '/goCrypto/merchant-admin/onboarding',
  exchangeTradesKraken: '/exchange/trades/kraken',
  exchangeLedgersKraken: '/exchange/ledgers/kraken',
  exchangeBalancesKraken: '/exchange/balances/kraken',
  exchangeTradesBitstamp: '/exchange/trades/bitstamp',
  exchangeLedgersBitstamp: '/exchange/ledgers/bitstamp',
  exchangeBalancesBitstamp: '/exchange/balances/bitstamp',
  exchangeTradesTokensnet: '/exchange/trades/tokensnet',
  exchangeLedgersTokensnet: '/exchange/ledgers/tokensnet',
  exchangeBalancesTokensnet: '/exchange/balances/tokensnet',
  exchangeTradesBitcoincom: '/exchange/trades/bitcoincom',
  exchangeLedgersBitcoincom: '/exchange/ledgers/bitcoincom',
  exchangeBalancesBitcoincom: '/exchange/balances/bitcoincom',
  exchangeTradesBlockchaincom: '/exchange/trades/blockchaincom',
  exchangeLedgersBlockchaincom: '/exchange/ledgers/blockchaincom',
  exchangeBalancesBlockchaincom: '/exchange/balances/blockchaincom',
  exchangeTradesBinance: '/exchange/trades/binance',
  exchangeLedgersBinance: '/exchange/ledgers/binance',
  exchangeBalancesBinance: '/exchange/balances/binance',
  exchangeTradesOkex: '/exchange/trades/okex',
  exchangeLedgersOkex: '/exchange/ledgers/okex',
  exchangeBalancesOkex: '/exchange/balances/okex',
  exchangeTradesBitmart: '/exchange/trades/bitmart',
  exchangeLedgersBitmart: '/exchange/ledgers/bitmart',
  exchangeBalancesBitmart: '/exchange/balances/bitmart',
  holisticPOSCompanies: '/holistic-pos/companies',
  holisticPOSCompanyDetails: '/holistic-pos/companies/:id',

  //TODO: remove this after lab4pay is implemented as separate tenant for pulsar
  holisticPOSLab4payCompanyDetails: '/holistic-pos/companies/:id/lab4pay/:id',
  holisticPOSLab4payStores: '/holistic-pos/lab4pay/stores',
  holisticPOSLab4payStoreDetails: '/holistic-pos/lab4pay/stores/:id'
  //TODO: end-remove
};

function getRouteWithId(route, id, params = null) {
  return `${route}/${id}`;
}

export function getLoyaltyInfoRoute(id) {
  return getRouteWithId(routes.loyaltyQuestionList, id);
}

// export function getUserInfoRoute(id){
//     return getRouteWithId(routes.userList,id);
// }

export function getAdminInfoRoute(id) {
  return getRouteWithId(routes.adminList, id);
}

export function getOrganizationInfoRoute(id) {
  return getRouteWithId(routes.organizationList, id);
}

export function getKybInfoRoute(id) {
  return getRouteWithId(routes.listCompanyKYB, id);
}

export function getPosInfoRoute(id) {
  return getRouteWithId(routes.posList, id);
}

//DEPRICATED
export const mainMenu = [
  {
    label: 'User Admin',
    link: routes.userList,
    subLinkMapping: 'tiers',
    subLinks: [
      {
        label: 'All users',
        link: routes.userList
      }
    ]
  },
  {
    label: 'GoCrypto',
    link: undefined,
    subLinkMapping: undefined,
    subLinks: [
      {
        label: 'Balances',
        link: routes.balanceList
      },
      {
        label: 'Transactions',
        link: routes.transactions
      },
      {
        label: 'Billing',
        link: routes.billing
      }
    ]
  },
  {
    label: 'Exchanges',
    link: undefined,
    subLinkMapping: undefined,
    subLinks: [
      {
        label: 'Trades',
        link: routes.exchangeTrades
      },
      {
        label: 'Ledgers',
        link: routes.exchangeLedgers
      },
      {
        label: 'Balances',
        link: routes.exchangeBalances
      }
    ]
  },
  {
    label: 'KYC',
    link: undefined,
    subLinkMapping: undefined,
    subLinks: [
      {
        label: 'KYC List',
        link: routes.kycList
      }
    ]
  },
  {
    label: 'Company KYB',
    link: routes.listCompanyKYB
  },
  {
    label: 'Sales',
    link: undefined,
    subLinkMapping: undefined,
    subLinks: [
      {
        label: 'Organizations',
        link: routes.organizationList
      },
      {
        label: 'Brands',
        link: routes.brandList
      },
      {
        label: 'QR Bundles',
        link: routes.QRBundleList
      },
      {
        label: 'QR Codes',
        link: routes.QRCodeList
      },
      {
        label: 'Wallets',
        link: routes.walletsList
      },
      {
        label: 'Referral',
        link: routes.referral
      },
      {
        label: 'Withdrawal',
        link: routes.merchantWithdrawal
      },
      {
        label: 'Onboarding',
        link: routes.onboarding
      }
    ]
  },
  {
    label: 'Marketing',
    link: undefined,
    subLinkMapping: undefined,
    subLinks: [
      {
        label: 'In-App Questions',
        link: routes.loyaltyQuestionList
      }
    ]
  },
  {
    label: 'Administration',
    link: undefined,
    subLinkMapping: undefined,
    subLinks: [
      {
        label: 'Admin Rights',
        link: routes.adminList
      }
    ]
  },
  {
    label: 'Notifications',
    link: undefined,
    subLinkMapping: undefined,
    subLinks: [
      {
        label: 'Broadcast Notifications',
        link: routes.broadcastNotifications
      }
    ]
  }
];

export function redirect(url) {
  window.open(url, '_blank');
}
