import React from 'react';
import {renderElement, setProp} from './controls/util';
import ajv from 'ajv';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';

import FormContext from './FormContext';
import {deepCopy, getSafeDeep} from '../Util/state';
import deepmerge from 'deepmerge';
import './controls/styles.css';
import {checkIfPhoneIsValid, shouldProceed} from './util/checkers';

const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

type DynamicFormProps = {
  uiSchema: {
    id: string,
    type: string,
    elements: Array
  },
  dataSchema: Object,
  initData?: Object,
  errors: Object,
  onSubmit: (Object) => void,
  getValues: (Object) => Object
};

export default class DynamicForm extends React.Component<DynamicFormProps> {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.initData || {},
      errors: {},
      customErrors: {},
      backendErrors: {},
      disabled: []
    };

    this.validator = null;
    this.alert = React.createRef();
  }

  onInputChange = (path, value) => {
    const requiresPos = document.getElementsByName(
      '#/properties/form/properties/merchant_requires_pos_device'
    );
    const orgType = document.getElementsByName(
      '#/properties/form/properties/organization_type'
    );

    let newData = {...this.state.data};

    this.setState({data: {...newData}});

    let data = JSON.parse(JSON.stringify(this.state.data));
    Object.keys(this.state.data).map((key) => {
      if (typeof this.state.data[key] === 'function') {
        data[key] = this.state.data[key];
      }
    });

    setProp(data, value, path);

    data = this.props.inputChangeListener
      ? this.props.inputChangeListener(data)
      : data;

    if (requiresPos.length > 0) {
      if (orgType[0].value === '1' || orgType[0].value === '2') {
        requiresPos[0].parentElement.parentElement.style.display = 'block';
      } else {
        requiresPos[0].parentElement.parentElement.style.display = 'none';
      }
    }

    return new Promise((resolve) => this.setState({data}, resolve));
  };

  setDisabled = (disabled) => {
    this.setState({disabled});
  };

  refreshForm = () => {
    this.setState({});
  };

  componentDidUpdate(oldProps) {
    if (
      (this.props.errors !== oldProps.errors &&
        this.state.errors &&
        !Object.keys(this.state.errors).length) ||
      (this.state.errors !== this.props.errors &&
        this.props.errors &&
        Object.keys(this.props.errors).length)
    ) {
      this.setState({errors: this.props.errors});
    }

    if (this.props.errors !== oldProps.errors) {
      this.setState({backendErrors: this.props.errors});
    }

    if (
      JSON.stringify(this.props.dataSchema) !==
      JSON.stringify(oldProps.dataSchema)
    ) {
      const ajvInstance = new ajv({
        coerceTypes: true,
        useDefaults: true,
        allErrors: true,
        schemaId: 'auto',
        $data: true
      });
      this.setState(
        {
          dataSchema: this.props.dataSchema,
          data: this.props.clearOnChange
            ? this.props.initData
            : deepmerge(this.state.data, this.props.initData, {
                arrayMerge: overwriteMerge
              })
        },
        () => {
          const stateData = deepCopy(this.state.data);
          this.validator = ajvInstance.compile(this.props.dataSchema);
          this.validator(stateData);
          this.setState({data: stateData, errors: this.props.errors});
        }
      );
      return;
    }

    if (
      JSON.stringify(this.props.initData) !== JSON.stringify(oldProps.initData)
    ) {
      const ajvInstance = new ajv({
        coerceTypes: true,
        useDefaults: true,
        allErrors: true,
        schemaId: 'auto',
        $data: true
      });
      this.setState(
        {
          data: this.props.clearOnChange
            ? this.props.initData
            : deepmerge(this.state.data, this.props.initData, {
                arrayMerge: overwriteMerge
              })
        },
        () => {
          const stateData = deepCopy(this.state.data);
          this.validator = ajvInstance.compile(this.props.dataSchema);
          this.validator(stateData);
          this.setState({data: stateData, errors: this.props.errors});
        }
      );
    }
  }

  componentDidMount() {
    const ajvInstance = new ajv({
      coerceTypes: true,
      useDefaults: true,
      allErrors: true,
      schemaId: 'auto',
      $data: true
    });
    const stateData = deepCopy(this.state.data);
    this.validator = ajvInstance.compile(this.props.dataSchema);
    this.validator(stateData);

    this.setState({data: stateData});
  }

  getErrors = (data = this.state.data) => {
    const valid = this.validator(data);
    if (!valid) {
      return this.validator.errors.reduce((errors, error) => {
        let path = error.schemaPath.split('/');
        path.pop();
        path = path.join('/');
        if (error.keyword === 'required') {
          path += `/properties/${error.params.missingProperty}`;
          errors[path] = 'This field is required';
        } else if (error.keyword == 'const') {
          errors[path] = 'Repeated password must be the same as a new password';
        } else {
          errors[path] = error.message;
        }
        return errors;
      }, {});
    } else {
      return {};
    }
  };

  shouldProceed = () => {
    return shouldProceed(
      this.state.errors,
      this.state.backendErrors,
      this.props.dataSchema
    );
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {data} = this.state;
    const valid = this.validator(data);

    if (!valid) {
      const errors = this.validator.errors.reduce((errors, error) => {
        let path = error.schemaPath.split('/');
        path.pop();
        path = path.join('/');

        if (error.keyword === 'required') {
          path += `/properties/${error.params.missingProperty}`;
          errors[path] = 'This field is required';
        } else if (error.keyword === 'const') {
          if (error.dataPath === '.form.repeated_new_password') {
            errors[path] =
              'Repeated password must be the same as a new password';
          } else {
            errors[path] = error.message;
          }
        } else if (error.keyword === 'minLength') {
          errors[path] = 'This field is required';
        } else if (
          error.keyword === 'format' &&
          error.params.format === 'email'
        ) {
          errors[path] = 'Invalid Email';
        } else {
          errors[path] = error.message;
        }
        return errors;
      }, {});

      await this.setState({errors}, () => {
        if (this.alert.current)
          this.alert.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
      });
    } else {
      await this.setState({errors: {}});
    }
    if (this.shouldProceed()) {
      this.props.onSubmit(data, document.activeElement);
    }
  };

  setError = (key, value) => {
    const {customErrors} = this.state;
    customErrors[key] = value;
    this.setState({customErrors});
  };

  setData = (data) => {
    this.setState({data});
  };

  render() {
    if (!this.props.dataSchema || !this.props.uiSchema) return <div />;

    if (this.props.wrappers) {
      return (
        <FormContext.Provider
          value={{
            readOnly: this.props.readOnly,
            disabled: this.state.disabled,
            setDisabled: this.setDisabled,
            isLoading: this.props.isLoading,
            onInputChange: this.onInputChange,
            data: deepCopy(this.state.data),
            dataSchema: this.props.dataSchema,
            errors: {...this.state.errors, ...this.state.customErrors},
            setError: this.setError,
            refreshForm: this.refreshForm
          }}
        >
          <form onSubmit={this.onSubmit}>
            <FormContext.Consumer>
              {(ctx) => {
                return this.props.wrappers(
                  renderElement(this.props.uiSchema),
                  this.setData,
                  ctx
                );
              }}
            </FormContext.Consumer>
          </form>
        </FormContext.Provider>
      );
    }

    return (
      <FormContext.Provider
        value={{
          getErrors: this.getErrors,
          disabled: this.state.disabled,
          setDisabled: this.setDisabled,
          isLoading: this.props.isLoading,
          onInputChange: this.onInputChange,
          data: this.state.data,
          dataSchema: this.props.dataSchema,
          errors: {...this.state.errors, ...this.state.customErrors},
          setError: this.setError,
          refreshForm: this.refreshForm
        }}
      >
        <div>
          {this.props.modal ? (
            <Modal
              onSubmit={this.onSubmit}
              isLoading={this.props.isLoading}
              {...this.props.modal}
            >
              {this.props.renderBefore ? this.props.renderBefore() : null}
              <form onSubmit={this.onSubmit}>
                {renderElement(this.props.uiSchema)}
                {this.props.submit && !this.props.readOnly ? (
                  <div className="dynamic-form-footer mt-3">
                    <Button
                      isLoading={this.props.isLoading}
                      color="primary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                ) : null}
              </form>
            </Modal>
          ) : (
            <form onSubmit={this.onSubmit}>
              {renderElement(this.props.uiSchema)}
              {this.props.submit && !this.props.readOnly ? (
                <div className="dynamic-form-footer mt-3">
                  <Button
                    isLoading={this.props.isLoading}
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              ) : null}
            </form>
          )}
        </div>
      </FormContext.Provider>
    );
  }
}
