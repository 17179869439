import {apiClient} from "./util";
import type {PaginatedList} from "./model/list";
import {AxiosPromise} from 'axios';
import {ALPHABET_LOWER} from "../components/Loyalty/util";

export default {
    // Get a paginated list of questions, queryable
    listPaginated: (filter: string, q: string = "", offset: number, limit: number = 10): AxiosPromise<PaginatedList<Question>> =>
        apiClient.get("/payment/answer", {params: {filter, offset, limit, q}}),

    // Get a question
    get: (id: number): AxiosPromise<Question> =>
        apiClient.get(`/admin/payment/answer/${id}`),//.then(transformUserData),

    // Create a question
    create: (question: Question): AxiosPromise => {
        let questionTransformed = {
            value:question.value,
            predefined_answers: question.answers.map((questionItem, index) =>{
                return {
                    "value": questionItem,
                    "enum": ALPHABET_LOWER[index],
                    "translations":[]
                };
            }),
            repeatable:question.repeatable,
            translations:question.translations
        };
        return apiClient.post('/admin/loyalty/question', questionTransformed);
    },

    // Translate a question
    translate: (translation) => {
        return apiClient.post("admin/payment/question/translation", translation);
    }
};
