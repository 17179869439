import React from 'react'
import {renderElement} from "./util";
import {Collapse} from "reactstrap";
import cx from 'classnames'

type GroupProps = {
    labelType?: string,
    label?: string,
    componentId?: string,
    elements?: Array,
    isCollapsed?: boolean
}

export default class Group extends React.Component<GroupProps> {
    state = {
        collapsed: false,
        propCollapsed: false
    };

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.collapsed !== nextState.collapsed ||
            this.props.elements !== nextProps.elements;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isCollapsed !== prevState.propCollapsed) {
            return {collapsed: nextProps.isCollapsed, propCollapsed: nextProps.isCollapsed}
        }
        return prevState;
    }


    render() {
        const {label, labelType, componentId, elements, outterhtmlClass} = this.props;
        const {collapsed} = this.state;
        /*onClick={() => this.setState({collapsed: !collapsed})}>*/
        const LabelElement = (labelType || 'h5').toLowerCase();
        return (
            <div id={componentId} className={cx("mb-4 group", outterhtmlClass)}>
                {label &&
                <LabelElement style={{ color: "#575962" }} className="d-flex align-items-center">
                    <label className="flex-grow-1">{label}</label>
                </LabelElement>
                }
                {!collapsed && elements && elements.map(renderElement)}
            </div>
        );
    }
}
