import React, {useEffect, useState} from 'react';

import Button from '../../../../UI/Button/Button';
import {createMessage} from '../../../../Util/notification';
import {getSafeDeep} from '../../../../Util/state';
import company from '../../../../../api/V3/company';
import {PermissionGuard} from '../../../../Util/role-helpers';
import {openNewTab} from '../../../../../utils/helperFunctions';

const Contract = (props) => {
  const {organization, loadData} = props;

  const [isLoading, setLoading] = useState(false);

  const regenerateContract = async () => {
    setLoading(true);
    try {
      await company.regenerateContract(organization.id);
      await loadData();
    } catch (e) {
      createMessage(
        getSafeDeep(e, 'response.data.message', e.message),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const downloadContracts = () => {
    if (organization.merchant_contract_file) {
      openNewTab(organization.merchant_contract_file);
    }
    if (organization.merchant_pos_contract_file) {
      openNewTab(organization.merchant_pos_contract_file);
    }
  };

  return (
    <div>
      <h5>Download contract</h5>
      <p>
        Download signed contract between Eligma and {organization.full_name}
      </p>
      <div className="d-flex">
        {organization.merchant_contract_file && (
          <PermissionGuard code={'download_company_contract'}>
            <Button
              onClick={downloadContracts}
              color="primary"
              className="mr-3"
            >
              Download
            </Button>
          </PermissionGuard>
        )}
        <PermissionGuard code={'regenerate_company_contract'}>
          <Button
            isLoading={isLoading}
            color="primary"
            onClick={regenerateContract}
          >
            Regenerate
          </Button>
        </PermissionGuard>
      </div>
    </div>
  );
};

export default Contract;
