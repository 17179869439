import stores from "../api/V3/stores";
import {fulfilled, fulfilledState, initState} from "./middleware";

export const GET_POS_TYPE_LIST = "GET_POS_TYPE_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_POS_TYPE_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getPosTypeList = () => ({
    type: GET_POS_TYPE_LIST,
    payload: stores.posTypeList()
});
