// eslint-disable-next-line no-unused-vars
import React, {Fragment} from 'react';
import Modal from '../../UI/Modal/Modal'
import Button from '../../UI/Button/Button'

class DeleteDocumentDialog extends React.Component {

    render() {
        const {toggle, isOpen, onDelete} = this.props;
        if(!isOpen) return "";

        return (
            <Modal  toggle={toggle}
                    isOpen={isOpen}
                    centered
                    title="Delete Company Document"
                    cancelButton={() => <Button color="secondary" className="mr-3" onClick={toggle}>Cancel</Button>}
                    submitButton={() => <Button color="danger" onClick={onDelete}>Delete</Button>}
                    size="xs">

                    <div className="text-center mb-5 mt-4">Are you sure you want to delete this document?</div>
            
            </Modal>
        )
    };

}

export default DeleteDocumentDialog;
