import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col, Row} from "reactstrap";
import Modal from '../UI/Modal/Modal'
import Select from '../UI/Select/Select'
import {createMessage} from "../Util/notification";
import DynamicForm from "../DynamicForm/DynamicForm";
import {deepCopy, getSafeDeep, stripByRule, stripUndefined} from "../Util/state";
import {objectToArray, parseErrors} from "../Util/form";
import exportCSVData from "./schema/exportCSVData";
import exportCSVUi from "./schema/exportCSVUi";
import wallets from "../../api/V3/wallets";
import moment from "moment";
import {API_URL_V3} from "../../api/util";

class TransactionExportCSVDialog extends React.Component {

    state = {
        errors: undefined,
        documentType: this.props.documentTypes[0],
        isLoading: false,
    };

    componentDidMount() {

        this.loadSchema();
    }

    componentDidUpdate() {
    }


    onSubmit = async (data) => {
        try{
            if(this.props.screen === "wallets")
                window.open(`${API_URL_V3}admin/crypto_accounting/wallets/${this.props.id}/transactions/export?start_date=${data.form.start_date}&end_date=${data.form.end_date}`, '_blank');
            else if(this.props.screen === "transactions")
                window.open(`${API_URL_V3}reports/generate?format_type=0&date_from=${data.form.start_date}&date_to=${data.form.end_date}&report_type=admin_transaction_report&sync=true&save=false`, '_blank');



        }catch (e) {
            console.error(e);
            this.setState({ errors: parseErrors(e)});
        }


    };
    loadSchema = async() => {
        const uiSchema =  exportCSVData;
        const dataSchema = exportCSVUi;
        this.setState({ uiSchema, dataSchema });
    };



    render() {
        const {toggle, isOpen} = this.props;
        const {errors} = this.state;
        if(!isOpen) return "";

        const modal = {
            toggle,
            isOpen,
            errors,
            title: "Export CSV",
            size: "md",
            confirmLabel: "Export"
        }

        return (
            <DynamicForm    uiSchema={exportCSVUi}
                            dataSchema={exportCSVData}
                            modal={modal}
                            initData={{form: {
                                start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                                end_date: moment().format('YYYY-MM-DD')
                            }}}
                            isLoading={this.state.isLoading}
                            errors={errors}
                            onSubmit={(data) => {this.onSubmit(data)}}/>
        )
    };

}

function mapState(state) {
    return {
        documentTypes: objectToArray(state.documentTypes.data, "id", "name")
    };
}

function mapActions(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(TransactionExportCSVDialog));
