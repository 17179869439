import React from 'react'

import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/Create';
import Divider from '@material-ui/core/Divider';

const More = ({ items }) => {

    //don't show when no options
    if (!items || !items.length) return null;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const StyledIcon = withStyles({
        root: {
            fontSize: "24px !important",
            color: "#000 !important"
        }
    })(MoreHorizIcon)

    const StyledDivider = withStyles({
        root: {
            margin: ".5rem 0"
        }
    })(Divider)

    return (
        <div style={{ fontSize: "24px", color: 'black' }}>
            <IconButton
                size="medium"
                aria-controls="long-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpen}
            >
                <StyledIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {items.map((item, i) => (
                    <div key={i}>
                        {item.handler ?
                            <MenuItem onClick={() => {item.handler(); handleClose()}}>
                                {item.render()}
                            </MenuItem> :
                            <MenuItem onClick={() => {handleClose()}}>
                                <a className="d-flex align-items-center" rel="noopener noreferrer"
                                   style={{color: 'black'}} target="_blank" href={item.href(item)}>{item.render()}</a>
                            </MenuItem>
                        }
                        {i < items.length - 1 ? <StyledDivider /> : null}
                    </div>
                ))}
            </Menu>
        </div>
    )
}

export default More
