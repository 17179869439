import React, {useState} from 'react';
import {connect} from 'react-redux';

import {Chip} from '@material-ui/core';
import Button from '../../../../../UI/Button/Button';

import {createMessage} from '../../../../../Util/notification';
import DeleteTagDialog from './DeleteTagDialog';
import AddTagDialog from './AddTagDialog';
import company from '../../../../../../api/V3/company';
import {hasPermission, PermissionGuard} from '../../../../../Util/role-helpers';

const Tags = (props) => {
  const chips = props.organization.tags;

  const [isDelete, setDelete] = useState(null);
  const [isAdd, setAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteTag = async (tag) => {
    try {
      setIsLoading(true);
      await company.removeTag(props.organization.id, tag.name);
      createMessage('Successfully deleted tag from organization');
      props.loadData();
      setDelete(null);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const addTags = async (tags) => {
    try {
      setIsLoading(true);
      await company.addTag(props.organization.id, tags.value);
      setIsLoading(false);
      setAdd(null);
      props.loadData();
      createMessage('Successfully updated organization tags');
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  return (
    <div>
      <PermissionGuard code="company_add_tag">
        <Button color="primary" onClick={() => setAdd(true)}>
          Add Tag
        </Button>
      </PermissionGuard>
      <div className="my-5 p-2 border-bottom">
        {chips.map((chip) => {
          return (
            <Chip
              className="m-1"
              key={chip.name}
              label={chip.display_name}
              onDelete={
                hasPermission(props.user, 'company_delete_tag')
                  ? () => setDelete(chip)
                  : undefined
              }
            />
          );
        })}
      </div>
      <DeleteTagDialog
        toggle={() => setDelete(null)}
        isOpen={!!isDelete}
        data={isDelete}
        isLoading={isLoading}
        onSubmit={(chip) => deleteTag(chip)}
      />
      \
      <AddTagDialog
        options={props.categories.map((c) => ({
          value: c.name,
          label: c.display_name
        }))}
        toggle={() => setAdd(false)}
        isOpen={!!isAdd}
        isLoading={isLoading}
        onSubmit={addTags}
      />
    </div>
  );
};

export default connect((state) => ({
  categories: state.categories.data.filter(
    (it) => it.system_setting == false && it.name != 'online' && it.visible
  ),
  user: state.user.data
}))(Tags);
