import {apiClient} from "./util";

export default {
    // Get a paginated list of hashes
    listPaginated: (offset: number = 0, limit: number = 10, unassigned: boolean = false, q: string = undefined) =>{
        let params = {
            offset: offset,
            limit: limit,
            unassigned: unassigned,
            q: q
        };
        return apiClient.get(`/admin/identifiers/qr`, {params});
    },

    // Autosuggest free hashes according to a string
    suggest: (query: string) => {
        return apiClient.get(`/autocomplete/identifiers?q=${query}`);
    },

    // Get a list of unassigned hashes
    listFree: () =>{
        let params = {
            unassigned: true
        };
        return apiClient.get("/admin/identifiers", {params: params});
    },

    // List bundles of hashes(Zipped bundles, paginated)
    listBundlesPaginated: (offset: number = 0, limit: number = 10) => {
        return apiClient.get(`/admin/identifiers/generator/all`, {params:{offset, limit}});
    },

    // Generate hash bundle(Zipped bundle)
    generate: (number = 1) => {
        if(number > 100) number = 100;
        let params = {
            count: number
        };

        return apiClient.post(`/admin/identifiers/generator/`, params);
    }
    
}
