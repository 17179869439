import React from 'react'

import Input from '../Input/Input'
import './time.module.css'

const Time = props => {
    return(
        <div styleName="time">
            <button onClick={(e) => {e.preventDefault(); props.onChange('+'); props.onBlur()}} styleName="arrow up">
                <span>
                    >
                </span>
            </button>
                <Input onBlur={props.onBlur} {...props} />
            <button onClick={(e) => {e.preventDefault(); props.onChange('-'); props.onBlur()}} styleName="arrow down">
                <span>
                    >
                </span>
            </button>
        </div>
    )
}

export default Time;