import React from "react";

export type ArrayContextProps = {
    item: Object,
    onInputChange: (string, Object) => void,
    $ref: String,
    errors: Array
}

const ArrayContext = React.createContext(undefined);

export function arrayContextWrapper(Component){
    return (props) => (
        <ArrayContext.Consumer>
            {ctx =>
                <Component {...props} array={ctx}/>
            }
        </ArrayContext.Consumer>
    )
}

export default ArrayContext;
