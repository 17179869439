import React from "react";
import transaction from "../../../api/V3/transactions";
import {notifyError} from "../notification";
import {calculateRowsPerPage, pushRowsPerPageToQuery, pushSortToQuery} from "../ListView/listViewHelper";
import qs from "query-string";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getSafeDeep} from "../state";


export function PaginatedTable(WrappedComponent, config) {
    return class PaginatedTable extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                data: [],
                rowsPerPage: 10,
                sort: '',
                count: 0,
                isLoading: false,
                search: '',
            };
            getSafeDeep(config, 'modals', []).forEach(modalKey => {
                this.state[`${modalKey}DialogOpen`] = false;
            });
        }

        toggleDialog = () => (dialogName) => {
            const fieldName = `${dialogName}DialogOpen`;
            this.setState({[fieldName]: !this.state[fieldName]});
        };

        loadData = async () => {
            const {apiCall} = config;
            try {
                this.setState({isLoading: true});
                const query = {...this.props.query};
                const page = query.page || 0;
                const sort = query.sort;
                const search = query.search;

                this.setState({
                    isLoading: true,
                });

                const response = (await apiCall({search, offset: page * this.state.rowsPerPage, limit: this.state.rowsPerPage, sort})).data;
                const {count, results:data} = response;

                // const totalData = (await transaction.getTotal()).data;

                if (query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;

                this.setState({
                    // totalData,
                    data,
                    sort,
                    count,
                    isLoading: false,
                });
            } catch (err) {
                this.setState({isLoading: false});
                notifyError(err.message);
            }
        };

        componentDidMount() {
            this.loadData();
        }

        componentDidUpdate(oldProps, oldState) {
            const {query} = this.props;
            const {query: oldQuery} = oldProps;
            if (JSON.stringify(query) !== JSON.stringify(oldQuery) || this.state.rowsPerPage != oldState.rowsPerPage) {
                this.loadData();
            }
        }

        requestSort = () => (sort) => {
            this.setState({sort});
            const {location, query, history} = this.props;
            pushSortToQuery({location, query, history, sort});
        };

        onChangeRowsPerPage = () => (e) => {
            let newRowsPerPage = e.target.value;
            const {location, query, history} = this.props;
            const {rowsPerPage} = this.state;
            this.setState({rowsPerPage: newRowsPerPage});
            const newPage = calculateRowsPerPage({location, newRowsPerPage, rowsPerPage});
            pushRowsPerPageToQuery({location, query, history, page: newPage});
        };

        render() {
            const {data, rowsPerPage, sort, count, isLoading, search} = this.state;
            const modalProps = {};
            getSafeDeep(config, 'modals', []).forEach(modalKey => {
                modalProps[`${modalKey}DialogOpen`] = this.state[`${modalKey}DialogOpen`];
            });
            return <WrappedComponent {...this.props}
                                     data={data}
                                     rowsPerPage={rowsPerPage}
                                     sort={sort}
                                     requestSort={this.requestSort()}
                                     onChangeRowsPerPage={this.onChangeRowsPerPage()}
                                     count={count}
                                     toggleDialog={this.toggleDialog()}
                                     isLoading={isLoading}
                                     search={search}
                                     {...modalProps}
            />;
        }
    }
}
