import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Modal from '../UI/Modal/Modal'
import {createMessage, notifyError} from "../Util/notification";
import {Form, Text} from 'informed';
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import {ERR_VALIDATION} from "../../api/errorCodes";
import {getSafeDeep} from "../Util/state";
import {mapBackendErrors} from "../DynamicForm/controls/util";
import {parseErrors} from "../Util/form";
import addUserDataSchema from "./schema/elipayAddUserData.json";
import addUserUiSchema from "./schema/elipayAddUserUi.json";
import {getUserList} from "../../redux/user";

import { Footer } from '../UI/Modal/Modal'

class AddUserDialog extends React.Component {

    state = {
        errors: undefined,
        isLoading: false,
    };

    componentDidUpdate() {
    }

    onSubmit = async (data) => {

        try{
            this.setState({ isLoading: true })
            await user.create(data);
            createMessage('Successfully submitted');
            this.props.getUserList();
            this.props.reloadData();
            this.setState({ isLoading: false })
            this.props.toggle();
        }catch (e) {
            notifyError(e.message)
            this.setState({ isLoading: false })
            this.setState({ errors: parseErrors(e)});
        }
    };

    render() {
        const {toggle, isOpen, tiers} = this.props;
        const {errors} = this.state;
        if(!isOpen) return "";

        const modal = {
            isOpen,
            toggle,
            title: "Add User",
            size: 'lg',
            isLoading: this.state.isLoading
        }

        return (
            <DynamicForm    
                isLoading={this.state.isLoading}
                modal={modal}
                uiSchema={addUserUiSchema}
                dataSchema={addUserDataSchema}
                initData={{ form: { }, tiers}}
                onSubmit={(data) => {this.onSubmit(data.form)}}
                errors={errors}
            />
        )
    };

}

function mapActions(dispatch) {
    return bindActionCreators({getUserList}, dispatch);
}

export default withRouter(connect(null, mapActions)(AddUserDialog));
