import React from 'react'

import { Chip } from '@material-ui/core'

import './tags.module.css'

const Tags = (props) => {
    
    const {options, selected} = props
    return(
        <div>
            <div styleName="selected" >
                {selected && selected.map(item => {
                    return <Chip className="m-1" key={item.name} label={item.display_name} onDelete={() => props.removeOption(item)} />
                })}
            </div>
            <div className="py-3">
                {options && options.map(option => {
                    return <Chip className="m-1" key={option.name} label={option.display_name} onClick={() => props.addOption(option)} />
                })}
            </div>
        </div>
    ) 
}

export default Tags;