import React from 'react';
import {isFunction} from './types';
import {getSafe, getSafeDeep} from './state';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {getAdminInfoRoute} from './routing';
import {supportedLanguages} from './language';
import KYCImageDisplay from '../KYC/KYCImageDisplay';
import {meetingTypeOptions} from './meetingTypeOptions';
import {getCountryName} from './countryList';

export function getCountry(data) {
  return data ? getCountryName(data) : undefined;
}

export function getBTCWalletAddress(data) {
  return getSafeDeep(
    data.wallets[
      Object.keys(data.wallets).find(
        (key) => data.wallets[key].crypto_currency === 2
      )
    ],
    'address',
    'No wallet address found'
  );
}

export function getBCHWalletAddress(data) {
  return getSafeDeep(
    data.wallets[
      Object.keys(data.wallets).find(
        (key) => data.wallets[key].crypto_currency === 7
      )
    ],
    'address',
    'No wallet address found'
  );
}

export function getETHWalletAddress(data) {
  return getSafeDeep(
    data.wallets[
      Object.keys(data.wallets).find(
        (key) => data.wallets[key].crypto_currency === 3
      )
    ],
    'address',
    'No wallet address found'
  );
}

export function getEURWalletAddress(data) {
  return getSafeDeep(
    data.wallets[
      Object.keys(data.wallets).find(
        (key) => data.wallets[key].crypto_currency === 10
      )
    ],
    'address',
    'No wallet address found'
  );
}

export function getELIWalletAddress(data) {
  return getSafeDeep(
    data.wallets[
      Object.keys(data.wallets).find(
        (key) => data.wallets[key].crypto_currency === 9
      )
    ],
    'address',
    'No wallet address found'
  );
}

export function getPermanentAddressCountry(data) {
  return getCountry(
    getSafeDeep(data, 'profile.permanent_address.country', undefined)
  );
}

export function getTemporaryAddressCountry(data) {
  return getCountry(
    getSafeDeep(data, 'profile.temporary_address.country', undefined)
  );
}

export function getDocumentType(data) {
  const type = getSafeDeep(data, 'profile.document.0.type');
  if (type === undefined || !data.documentTypes) return 'NA';
  return data.documentTypes[type];
}

export function getMeetingType(data) {
  const meetingType = getSafeDeep(data, 'profile.meeting_type');
  const type = meetingTypeOptions.find((item) => item.value == meetingType);
  return type ? type.label : '';
}

export function getAdminUpdated(data) {
  let admin = getSafeDeep(data, 'access_history.kyc_updated.user', undefined);
  if (!admin) return '';
  return <Link to={getAdminInfoRoute(admin.id)}>{admin.id}</Link>;
}

export function getAdminConfirmed(data) {
  let admin = getSafeDeep(data, 'access_history.kyc_confirmed.user', undefined);
  if (!admin) return '';
  return <Link to={getAdminInfoRoute(admin.id)}>{admin.id}</Link>;
}

export function getAdminDeclined(data) {
  let admin = getSafeDeep(data, 'access_history.kyc_declined.user', undefined);
  if (!admin) return '';
  return <Link to={getAdminInfoRoute(admin.id)}>{admin.id}</Link>;
}

function renderImage(infoField, data) {
  return (
    <img
      alt="document"
      width="200"
      height="200"
      style={{objectFit: 'contain'}}
      src={
        isFunction(infoField.fieldKey)
          ? infoField.fieldKey(data)
          : getSafeDeep(data, infoField.fieldKey, '')
      }
    />
  );
}

function renderKYCImage(infoField, data) {
  return getSafeDeep(data, infoField.fieldKey, undefined) ? (
    <KYCImageDisplay
      imageData={getSafeDeep(data, infoField.fieldKey, undefined)}
    />
  ) : (
    'No image attached.'
  );
}

function renderMultipleImages(infoField, data) {
  getSafeDeep(data, infoField.fieldKey, undefined) &&
    getSafeDeep(data, infoField.fieldKey).map((image, index) => {
      return <img key={index} alt="store" src={image} />;
    });
}

function renderTextField(infoField, data) {
  return (
    <div className="form-value">
      {isFunction(infoField.fieldKey)
        ? infoField.fieldKey(data)
        : getSafeDeep(data, infoField.fieldKey)}
    </div>
  );
}

function renderDateField(infoField, data) {
  return (
    <div className="form-value">
      {isFunction(infoField.fieldKey)
        ? infoField.fieldKey(data)
        : getSafeDeep(data, infoField.fieldKey, undefined)
        ? moment(getSafeDeep(data, infoField.fieldKey)).format('DD.MM.YYYY')
        : 'NA'}
    </div>
  );
}

function renderDateTimeField(infoField, data) {
  return (
    <div className="form-value">
      {isFunction(infoField.fieldKey)
        ? infoField.fieldKey(data)
        : getSafeDeep(data, infoField.fieldKey, undefined)
        ? moment(getSafeDeep(data, infoField.fieldKey))
            .local()
            .utc()
            .format('DD.MM.YYYY HH:mm')
        : 'NA'}
    </div>
  );
}

function renderBoolField(infoField, data) {
  return (
    <div className="form-value">
      {getSafeDeep(data, infoField.fieldKey, undefined) ? 'Yes' : 'No'}
    </div>
  );
}

export function renderCountryField(infoField, data) {
  return (
    <div className="form-value">
      {getSafe(
        getCountry(getSafeDeep(data, infoField.fieldKey, undefined)),
        'NA'
      )}
    </div>
  );
}

function renderLanguageField(infoField, data) {
  return (
    <div className="form-value">
      {getSafe(
        supportedLanguages[getSafeDeep(data, infoField.fieldKey, 'NA')],
        'Undefined'
      )}
    </div>
  );
}

function renderLinkField(infoField, data) {
  return (
    <div className="form-value">
      <a
        target="_blank"
        href={getSafeDeep(data, infoField.fieldKey, undefined)}
      >
        {getSafeDeep(data, infoField.fieldKey, undefined)}
      </a>
    </div>
  );
}

function renderAnswerList(infoField, data) {
  return (
    <ul className="list-unstyled">
      {getSafeDeep(data, infoField.fieldKey, [])
        .sort((a, b) => (a.enum > b.enum ? 1 : -1))
        .map((item) => {
          return (
            <li key={item.value}>
              <strong>{item.enum}</strong> - {item.value}
            </li>
          );
        })}
    </ul>
  );
}

export function renderInfoField(infoField, data, props) {
  const dataWithProps = {...data, ...props};
  if (isFunction(infoField.fieldKey)) {
    return infoField.fieldKey(dataWithProps);
  }
  switch (infoField.fieldType) {
    case 'image':
      return renderImage(infoField, dataWithProps);
    case 'multipleImages':
      return renderMultipleImages(infoField, dataWithProps);
    case 'answerList':
      return renderAnswerList(infoField, dataWithProps);
    case 'date':
      return renderDateField(infoField, dataWithProps);
    case 'datetime':
      return renderDateTimeField(infoField, dataWithProps);
    case 'bool':
      return renderBoolField(infoField, dataWithProps);
    case 'language':
      return renderLanguageField(infoField, dataWithProps);
    case 'country':
      return renderCountryField(infoField, dataWithProps);
    case 'link':
      return renderLinkField(infoField, dataWithProps);
    case 'kycImage':
      return renderKYCImage(infoField, dataWithProps);
    default:
      return renderTextField(infoField, dataWithProps);
  }
}

export function infoFieldCategory(label, items, visible = () => true) {
  return {
    label,
    items,
    visible
  };
}

export function infoField(fieldKey, label, fieldType) {
  return {
    label,
    fieldKey,
    fieldType
  };
}
