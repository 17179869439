import {apiClient, apiClientV3} from './util';
import {transformOrganizationData} from './model/organization';

export default {
  // Create an organization
  create: (organization) => {
    return apiClient.put('/admin/merchant/', organization);
  },

  // Link a source to organization
  link: (org, src) =>
    apiClientV3.post(`/admin/company/${org.id}/store/${src.id}/`),

  // Unlink a source from organization
  unlink: (org, src) =>
    apiClientV3.delete(`/admin/company/${org.id}/store/${src.id}/`),

  // Add a tag to organization
  addTag: (orgId, tagId) =>
    apiClient.post(`/admin/merchant/${orgId}/tag/${tagId}/`),

  // Remove a tag from organization
  removeTag: (orgId, tagId) =>
    apiClient.delete(`/admin/merchant/${orgId}/tag/${tagId}/`),

  // Update an organization
  update: (org) => apiClient.post(`/admin/merchant/${org.id}`, org),

  // Get a paginated list of organization
  listPaginated: (
    search: string = '',
    offset: number,
    limit: number = 10,
    ordering: string = '-created',
    extra = {}
  ) => {
    let params = {
      offset: offset,
      show_deleted: true,
      limit: limit,
      ordering,
      ...extra
    };
    if (search !== '') params.search = search;

    return apiClientV3.get('/admin/companies', {params});
  },

  getGoCryptoCompanies: (
    search = undefined,
    offset = 0,
    limit = 10,
    ordering = ''
  ) => {
    let params = {
      offset: offset,
      limit: limit,
      ordering
    };
    if (search !== '') params.search = search;

    return apiClientV3.get('/admin/companies/gocrypto', {params});
  },

  // Get an organization
  get: (id) =>
    apiClient.get(`/admin/merchant/${id}`).then(transformOrganizationData),

  getFiatCurrencies: (country) =>
    apiClientV3.get(`/admin/company/fiat_currencies`, {
      params: {
        country
      }
    }),

  getSettlementCurrencies: (local_currency) =>
    apiClientV3.get(`/admin/company/currencies`, {
      params: {
        local_currency
      }
    }),

  getExchangeFees: (country) =>
    apiClientV3.get(`/admin/fees/exchange/${country}`),

  restartKyb: (id) => apiClientV3.post(`/admin/remove/company/kyb/${id}`)
};
