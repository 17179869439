import React, {ChangeEvent, useEffect, useState} from 'react';
import Button from '../../../UI/Button/Button';
import cx from 'classnames';
import './org-menu.module.css';
import {approvalStates, renderKybStatus} from '../../OrganizationList';
import {getSafeDeep} from '../../../Util/state';
import {createMessage, notifyError} from '../../../Util/notification';
import company from '../../../../api/V3/company';
import {PermissionGuard} from '../../../Util/role-helpers';
import Modal from '../../../UI/Modal/Modal';
import Input from '../../../UI/Input/Input';
import {routes} from '../../../Util/routing';
import Select from '../../../UI/Select/Select';
import referralApi from '../../../../api/V3/referral';
import moment from 'moment';
import RestartKYBDialog from '../../RestartKYBDialog';
import {connect} from 'react-redux';

const RejectModal = ({isOpen, toggle, company, onRejectClick, isLoading}) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setReason('');
    setError('');
  }, [isOpen]);

  const onSubmit = () => {
    if (reason.trim().length === 0) {
      return setError('This field is required');
    }
    onRejectClick(reason);
  };

  return (
    <Modal
      title="Reject company"
      isOpen={isOpen}
      toggle={toggle}
      isLoading={isLoading}
      submitButton={() => (
        <Button color="danger" isLoading={isLoading} onClick={onSubmit}>
          Reject
        </Button>
      )}
    >
      <div className="mb-5">
        Are you sure you want to reject{' '}
        <strong>{getSafeDeep(company, 'full_name')}</strong>
      </div>
      <div>
        <label htmlFor="reason">Reason</label>
        <Input
          placeholder="Enter reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <small className="text-danger">{error}</small>
      </div>
    </Modal>
  );
};

const ReferralModal = ({isOpen, toggle, company, reload}) => {
  const [referral, setReferral] = useState();
  const [resellerFee, setResellerFee] = useState(0);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setResellerFee('');
  }, [isOpen]);

  const onAddResellerFee = async () => {
    if (referral == null) return;
    setLoading(true);
    try {
      const {data} = await referralApi.createReferralActivity(
        referral.id,
        company.id,
        resellerFee || 0
      );
      toggle();
      reload({referrals: [...company.referrals, data.referral]});
      createMessage('Success');
    } catch (e) {
      console.error(e);
      notifyError(getSafeDeep(e, 'response.data.message', e.message), 'error');
    } finally {
      setLoading(false);
    }
  };

  const loadOptions = async (search) => {
    const {
      data: {results}
    } = await referralApi.get(search, 0, 10);
    return results;
  };

  return (
    <Modal
      title="Assign referral"
      isOpen={isOpen}
      toggle={toggle}
      isLoading={isLoading}
      submitButton={() => (
        <Button
          color="primary"
          isLoading={isLoading}
          onClick={onAddResellerFee}
        >
          Assign
        </Button>
      )}
    >
      <div>
        <label htmlFor="referral">Referral</label>
        <Select
          placeholder="Select referral"
          async
          defaultOptions
          loadOptions={loadOptions}
          name="referral"
          value={referral}
          onChange={setReferral}
          getOptionValue={(r) => r.id}
          getOptionLabel={(r) => `${r.id} (${r.owner})`}
        />
      </div>
      <div className="mt-3">
        <label htmlFor="resellerFee">Reseller fee (%)</label>
        <Input
          id="resellerFee"
          type="number"
          placeholder="Enter reseller fee"
          value={resellerFee}
          onChange={(e) => setResellerFee(e.target.value)}
        />
      </div>
    </Modal>
  );
};

const ResellerFeeModal = ({isOpen, toggle, company, reload}) => {
  const [referralActivity, setReferralActivity] = useState('');
  const [resellerFee, setResellerFee] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const onAddResellerFee = async () => {
    if (referralActivity == null) return;
    setLoading(true);
    try {
      const {data} = await referralApi.editReferralActivity(
        company.id,
        getSafeDeep(referralActivity, 'id'),
        getSafeDeep(referralActivity, 'referral.id'),
        resellerFee || 0
      );
      toggle();
      reload({referrals: [...company.referrals, data.referral]});
      createMessage('Success');
    } catch (e) {
      console.error(e);
      notifyError(getSafeDeep(e, 'response.data.message', e.message), 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (getSafeDeep(company, 'referrals.0.id') && isOpen) {
      referralApi
        .getReferralActivities(company.referrals[0].id)
        .then(({data}) => {
          setResellerFee(getSafeDeep(data, '0.reseller_fee'));
          setReferralActivity(getSafeDeep(data, '0'));
        });
    }
  }, [isOpen]);

  return (
    <Modal
      title="Edit Reseller fee & Referral"
      isOpen={isOpen}
      toggle={toggle}
      isLoading={isLoading}
      submitButton={() => (
        <Button
          color="primary"
          isLoading={isLoading}
          onClick={onAddResellerFee}
        >
          Save
        </Button>
      )}
    >
      <div>
        <div className="mt-3">
          <label htmlFor="resellerFee">Referral owner</label>
          <Input
            readOnly
            type="text"
            id="referralActivity"
            placeholder="Referral owner"
            defaultValue={getSafeDeep(referralActivity, 'referral.owner')}
          />
        </div>
        <div className="mt-3">
          <label htmlFor="resellerFee">Reseller fee (%)</label>
          <Input
            type="number"
            id="resellerFee"
            placeholder="Reseller fee"
            value={resellerFee}
            onChange={(e) => setResellerFee(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

const OrganizationMenu = (props) => {
  /*Props*/
  const {organization, links, handleEmails, checkbox, updateEmailCheckbox} =
    props;

  /*Local state*/
  const [rejectOpen, setRejectOpen] = useState(false);
  const [rejectInProgress, setRejectInProgress] = useState(false);
  const [referralOpen, setReferralOpen] = useState(false);
  const [restartKybOpen, setRestartKybOpen] = useState(false);
  const [restartKybInProgress, setRestartKybInProgress] = useState(false);
  const [resellerFeeOpen, setResellerFeeOpen] = useState(false);
  const [openTermination, setOpenTermination] = useState(false);
  const [documentsResetLoading, setDocumentsResetLoading] = useState(false);
  const [transactionsResetLoading, setTransactionsResetLoading] =
    useState(false);
  const [documentsCheck, setDocumentsCheck] = useState(null);
  const [transactionsCheck, setTransactionsCheck] = useState(null);

  /*Methods*/
  const orgAction = async (action, reason) => {
    if (rejectInProgress) return;

    setRejectInProgress(true);
    try {
      const {data} = await company.companyAction(
        organization.id,
        action,
        reason
      );
      if (action === 'reject') {
        props.history.replace(routes.organizationList);
      }
      props.dataUpdate({...data[0]});
      createMessage('Success');
      setRejectOpen(false);
    } catch (e) {
      notifyError(getSafeDeep(e, 'response.data.message', e.message), 'error');
    } finally {
      setRejectInProgress(false);
    }
  };

  const getDocumentsCheck = async () => {
    try {
      const {data: documents} = await company.getDocumentsCheck(
        organization.id
      );
      setDocumentsCheck(documents.remaining_days);
    } catch (error) {
      console.error('Error fetching documents: ', error.message);
    }
  };

  const getTransactionsCheck = async () => {
    try {
      const {data: transactions} = await company.getTransactionsCheck(
        organization.id
      );
      setTransactionsCheck(transactions.remaining_days);
    } catch (error) {
      console.error('Error fetching documents: ', error.message);
    }
  };

  const resetDocumentsCheck = async () => {
    setDocumentsResetLoading(true);
    try {
      await company.updateDocumentsCheck(organization.id);
      await getDocumentsCheck();
    } catch (error) {
      notifyError(`Error resetting documents: ${error.message}`);
    } finally {
      setDocumentsResetLoading(false);
    }
  };

  const resetTransactionsCheck = async () => {
    setTransactionsResetLoading(true);
    try {
      await company.updateTransactionsCheck(organization.id);
      await getTransactionsCheck();
    } catch (error) {
      notifyError(`Error resetting transactions: ${error.message}`);
    } finally {
      setTransactionsResetLoading(false);
    }
  };

  const toggleTerminationModal = () => {
    setOpenTermination((prevState) => !prevState);
  };

  const submitTermination = async () => {
    setOpenTermination(false);
    await orgAction('terminate');
  };

  const renderButtons = () => {
    const approvalStates = [0, 1];

    if (!approvalStates.includes(organization.approval_state)) {
      return;
    }

    if (organization.approval_state === 0) {
      return (
        <>
          <Button
            isLoading={rejectInProgress}
            color="outline-success"
            className="mr-4"
            onClick={() => orgAction('approve')}
          >
            Approve
          </Button>
          <Button
            isLoading={rejectInProgress}
            color="outline-danger"
            className="mr-4"
            onClick={() => setRejectOpen(true)}
          >
            Reject
          </Button>
        </>
      );
    }

    return (
      <Button
        isLoading={rejectInProgress}
        color="outline-danger"
        className="mr-4"
        onClick={toggleTerminationModal}
      >
        Terminate
      </Button>
    );
  };

  const awaitTimeout = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
  };

  const updateEmailState = async (event: ChangeEvent<HTMLInputElement>) => {
    if (rejectInProgress) return;
    updateEmailCheckbox(event.target.checked);

    awaitTimeout(300).then(() => {
      handleEmails();
    });
  };

  const getPayfac = () => {
    const payfacItem = props.payfacList.find(
      (item) => item.name === organization.payfac
    );

    if (payfacItem) {
      return payfacItem.name;
    }

    return '';
  };

  /*Hooks*/
  useEffect(() => {
    if (organization.id) {
      getDocumentsCheck().catch((error) => console.error(error.message));
      getTransactionsCheck().catch((error) => console.error(error.message));
    }
  }, [organization]);

  /*Template*/
  return (
    <div styleName="menu">
      <Modal
        title="Are you sure"
        toggle={toggleTerminationModal}
        isOpen={openTermination}
        size="xs"
        cancelLabel="Cancel"
        isLoading={rejectInProgress}
        submitButton={() => (
          <Button
            isLoading={rejectInProgress}
            className="ml-3"
            onClick={submitTermination}
            color="danger"
          >
            Terminate
          </Button>
        )}
      >
        <p>Are you sure that you want to terminate the selected company?</p>
      </Modal>
      <div styleName="profile">
        <img alt="" styleName="picture" src={organization.logo_url} />
        <span styleName="title">{organization.full_name}</span>
        <span styleName="status">
          {approvalStates[organization.approval_state]}
        </span>
        <div className="mt-2">KYB Status: {renderKybStatus(organization)}</div>
        <div className="mt-2">
          Referral:{' '}
          {getSafeDeep(organization, 'referrals.0.owner') ? (
            <a href="#" onClick={() => setResellerFeeOpen(true)}>
              {getSafeDeep(organization, 'referrals.0.owner')}
            </a>
          ) : (
            'None'
          )}
        </div>
        <div className="mt-2">
          Settlement Currency :{' '}
          {props.currentSettlement ? props.currentSettlement.active : ''}
        </div>
        <div className="mt-2">
          Settlement Currency ({moment().add(1, 'months').format('MMMM')}):{' '}
          {props.currentSettlement ? props.currentSettlement.pending : ''}
        </div>
        {organization.payfac && (
          <div className="mt-2">
            <div className="payfac font-weight-bold">
              Payfac Provider: {getPayfac()}
            </div>
          </div>
        )}
      </div>
      <ResellerFeeModal
        isOpen={resellerFeeOpen}
        toggle={() => setResellerFeeOpen(false)}
        reload={props.dataUpdate}
        company={organization}
      />
      <div styleName="divider" />
      <div>
        {links.map(
          (link, index) =>
            link && (
              <PermissionGuard key={link.icon} code={link.permission}>
                <div
                  styleName={cx('link', {
                    active: link.icon == props.active.icon
                  })}
                  onClick={() => props.navigateTab(index)}
                >
                  <i className={`flaticon-${link.icon}`} />
                  <span>{link.name}</span>
                </div>
              </PermissionGuard>
            )
        )}
      </div>
      <div styleName="divider" />
      <PermissionGuard code={'company_update_state'}>
        <div className="px-4">
          <div styleName="actions" className="mb-4">
            actions
          </div>
          <div className="d-flex pb-3">
            {renderButtons()}

            {!getSafeDeep(organization, 'referrals.0.owner') && (
              <Button
                isLoading={rejectInProgress}
                color="outline-primary"
                onClick={() => setReferralOpen(true)}
              >
                Add referral
              </Button>
            )}
          </div>
          <div styleName="reset-main">
            <div
              className="d-flex pb-3 align-items-center"
              styleName="reset-wrap"
            >
              <span>
                Documents check:{' '}
                <span
                  styleName={cx({
                    positive: documentsCheck > 0,
                    negative: documentsCheck <= 0 && documentsCheck != null
                  })}
                >
                  {documentsCheck != null ? parseInt(documentsCheck) : '/'}{' '}
                  {documentsCheck != null && (
                    <span>
                      {documentsCheck >= 0
                        ? `${documentsCheck === 1 ? 'day' : 'days'} remaining`
                        : `${documentsCheck === -1 ? 'day' : 'days'} ago`}
                    </span>
                  )}
                </span>
              </span>
              {documentsCheck != null && organization.kyb_status >= 7 && (
                <Button
                  isLoading={documentsResetLoading}
                  color="outline-primary"
                  onClick={resetDocumentsCheck}
                >
                  Reset
                </Button>
              )}
            </div>
            <div
              className="d-flex pb-3 align-items-center"
              styleName="reset-wrap"
            >
              <span>
                Transactions check:{' '}
                <span
                  styleName={cx({
                    positive: transactionsCheck > 0,
                    negative:
                      transactionsCheck <= 0 && transactionsCheck != null
                  })}
                >
                  {transactionsCheck != null
                    ? parseInt(transactionsCheck)
                    : '/'}{' '}
                  {transactionsCheck != null && (
                    <span>
                      {transactionsCheck >= 0
                        ? `${
                            transactionsCheck === 1 ? 'day' : 'days'
                          } remaining`
                        : `${transactionsCheck === -1 ? 'day' : 'days'} ago`}
                    </span>
                  )}
                </span>
              </span>
              {transactionsCheck != null && organization.kyb_status >= 7 && (
                <Button
                  isLoading={transactionsResetLoading}
                  color="outline-primary"
                  onClick={resetTransactionsCheck}
                >
                  Reset
                </Button>
              )}
            </div>
          </div>
          <div className="pb-5">
            {getSafeDeep(organization, 'kyb_status') === 7 && (
              <Button
                isLoading={restartKybInProgress}
                color="outline-danger"
                onClick={() => setRestartKybOpen(true)}
              >
                Restart KYB
              </Button>
            )}
          </div>
          <div className="pb-5">
            <label className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={checkbox}
                onChange={updateEmailState}
              />
              <span className="ml-3">Send Emails</span>
            </label>
          </div>
        </div>
      </PermissionGuard>
      <ReferralModal
        isOpen={referralOpen}
        toggle={() => setReferralOpen(false)}
        reload={props.dataUpdate}
        company={organization}
      />
      <RejectModal
        isOpen={rejectOpen}
        onRejectClick={(reason) => orgAction('reject', reason)}
        isLoading={rejectInProgress}
        toggle={() => setRejectOpen(false)}
        company={organization}
      />
      <RestartKYBDialog
        isOpen={restartKybOpen}
        data={organization}
        reloadData={props.reloadData}
        toggle={() => setRestartKybOpen(false)}
      />
    </div>
  );
};

function mapState(state) {
  return {
    payfacList: state.payfacList.data
  };
}

export default connect(mapState)(OrganizationMenu);
