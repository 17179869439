/* eslint-disable no-caller */
import React from 'react';
import Group from './Group';
import HorizontalLayout from './HorizontalLayout';
import VerticalLayout from './VerticalLayout';
import InputControl from './InputControl';
import Select from './Select';
import FormContext from '../FormContext';
import Base64Image from './Base64Image';
import DatetimePicker from './DatetimePicker';
import ArrayComponent from './Array';
import type {ArrayContextProps} from '../ArrayContext';
import type {FormContextProps} from '../FormContext';
import SectionLayout from './SectionLayout';
import ReadOnly from './ReadOnly';
import Copy from './Copy';
import MultiSelect from './MultiSelect';
import Boolean from './Boolean';
import Image from './Image';
import ImageArray from './ImageArray';
import GoogleMap from './GoogleMap';
import WorkingHours from './WorkingHours';
import AutosuggestSelect from './AutosuggestSelect';
import Base64KYC from './Base64KYC';
import Phone from './Phone';
import TheSame from './TheSame';
import Empty from './Empty';
import Tags from './Tags';
import Keywords from './Keywords';
import TransferList from './TransferList';
import SeparatorDashed from './SeparatorDashed';
import * as _ from 'lodash';

const components = {
  Group,
  HorizontalLayout,
  VerticalLayout,
  Copy: Copy,
  Base64KYC: Base64KYC,
  GoogleMap: GoogleMap,
  Boolean: Boolean,
  Control: InputControl,
  MultiSelect: MultiSelect,
  Select: Select,
  Image: Image,
  WorkingHours: WorkingHours,
  ImageArray: ImageArray,
  DatetimePicker: DatetimePicker,
  Autocomplete: AutosuggestSelect,
  Base64Image: Base64Image,
  Array: ArrayComponent,
  'section-layout-component': SectionLayout,
  ReadOnly,
  Phone,
  TheSame,
  Empty,
  Tags,
  Keywords,
  TransferList,
  SeparatorDashed
};

export function renderElement(element, index) {
  const ElementComponent = components[element.type];
  if (!ElementComponent) {
    if (element.elements) return element.elements.map(renderElement);
    else return null;
  }
  return (
    <FormContext.Consumer
      key={element.componentId || getDataProp(element, 'scope.$ref') || index}
    >
      {(ctx) => {
        if (element.rule) {
          if (element.rule.condition) {
            const targetRef = element.rule.condition.scope.$ref;
            let targetValue = getDataProp(ctx.data, targetRef);
            if (targetValue === undefined) targetValue = null;
            if (
              element.rule.effect === 'SHOW' &&
              targetValue != element.rule.condition.expectedValue
            ) {
              return null;
            }
            if (
              element.rule.effect === 'HIDE' &&
              targetValue == element.rule.condition.expectedValue
            ) {
              return null;
            }
            return (
              <ElementComponent
                {...element}
                targetValue={targetValue}
                disabled={ctx.readOnly}
              />
            );
          }
          if (element.rule.conditions) {
            // for(let condition of element.rule.conditions)
            const isFulfilled = element.rule.conditions.some((condition) => {
              {
                const targetRef = condition.scope.$ref;
                let targetValue = getDataProp(ctx.data, targetRef);
                if (targetValue === undefined) targetValue = null;
                if (
                  element.rule.effect === 'SHOW' &&
                  targetValue != condition.expectedValue
                ) {
                  return false;
                }
                return !(
                  element.rule.effect === 'HIDE' &&
                  targetValue == condition.expectedValue
                );
              }
            });
            return (
              isFulfilled && (
                <ElementComponent {...element} disabled={ctx.readOnly} />
              )
            );
          }
        }
        return (
          <ElementComponent
            key={
              element.componentId || getDataProp(element, 'scope.$ref') || index
            }
            {...element}
            disabled={ctx.readOnly}
          />
        );
      }}
    </FormContext.Consumer>
  );
}

export const getSafe = (obj, def = '') =>
  obj !== null && obj !== undefined ? obj : def;

function getPropRecurse(data, props, dataSchema = false) {
  if (props.length === 1) {
    return data[props[0]];
  }
  let prop = props.shift();
  if (prop === 'properties' && !dataSchema) prop = props.shift();
  if (props.length === 0) {
    return data[prop];
  }
  return getPropRecurse(getSafe(data[prop], {}), props, dataSchema);
}

export function getDataProp(data, path, separator = '/') {
  const props = path.split(separator);
  if (props[0] === '#') {
    props.shift();
  }
  if (props[0] === 'properties') {
    props.shift();
  }

  return getPropRecurse(data, props);
}

export function getProp(data, path, separator = '/') {
  const props = path.split(separator);
  if (props[0] === '#') {
    props.shift();
  }
  return getPropRecurse(data, props, true);
}

function setPropRecurse(obj, value, props) {
  if (props.length === 1) {
    obj[props[0]] = value;
    return;
  }
  let prop = props.shift();
  if (prop === 'properties') prop = props.shift();
  if (props.length === 0) {
    obj[prop] = value;
    return;
  }
  if (!obj[prop]) {
    if (parseInt(prop, 10) >= 0) {
      obj[prop] = [];
    } else {
      obj[prop] = {};
    }
  }
  return setPropRecurse(obj[prop], value, props);
}

export const setProp = (obj, value, path, separator = '/') => {
  const props = path.split(separator);
  if (props[0] === '#') {
    props.shift();
  }
  return setPropRecurse(obj, value, props);
};

export function computeIsRequired(
  context: FormContextProps,
  array: ArrayContextProps,
  $ref
) {
  const refParts = $ref.split('/');
  let basePath = '';
  if (array) {
    refParts.shift();
    basePath = array.$ref + '/items/';
  }
  const originalRef = basePath + refParts.join('/');
  const propName = refParts.pop();
  refParts.pop();
  const reqPropName = basePath + refParts.join('/') + '/required';
  const requiredArray = getSafe(getProp(context.dataSchema, reqPropName), []);
  return (
    requiredArray.findIndex((prop) => prop === propName) !== -1 ||
    computeRequiredFromError(context, array, originalRef)
  );
}

export const computeRequiredFromError = (
  context: FormContextProps,
  array,
  prop
) => {
  try {
    const errors = context.getErrors({...context.data, form: {}});
    return Object.entries(errors).find(([key, value]) => {
      const validationKey = key.replace(/allOf\/.\/then\//, '');
      if (
        value == 'This field is required' &&
        getProp(context.dataSchema, validationKey).type == 'object'
      ) {
        const errorObj = {...context.data, form: {}};
        setProp(errorObj, {}, validationKey);
        return Object.entries(context.getErrors(errorObj)).find(
          ([key, value]) => {
            return (
              key.replace(/allOf\/.\/then\//, '') == prop &&
              value == 'This field is required'
            );
          }
        );
      }
      return validationKey == prop && value == 'This field is required';
    });
  } catch (e) {
    return false;
  }
};

export const getInputData = (
  context: FormContextProps,
  array: ArrayContextProps,
  $ref
) => {
  return getDataProp(array ? array.item : context.data, $ref);
};

export const getInputConfig = (
  context: FormContextProps,
  array: ArrayContextProps,
  $ref
) => {
  let basePath = '';
  let $refPath = $ref;
  if (array) {
    $refPath = $ref.substr(1);
    basePath = array.$ref + '/items';
  }
  let path = basePath + $refPath;
  return getProp(context.dataSchema, path);
};

export const getInputError = (
  context: FormContextProps,
  array: ArrayContextProps,
  $ref
) => {
  let errors = array ? array.errors : context.errors;
  return errors[$ref];
};

export const mapBackendErrors = (errorList) => {
  let errorMap = {};
  errorList.forEach((item) => {
    item.fields.forEach((subItem) => {
      let key = subItem;
      if (subItem.indexOf('working_hours') != -1) {
        key = subItem.replace('working_hours', 'working_hours_transformed');
        key = key.split('.');
        key.pop();
        key = key.join('.');
      }
      key = key.replace('.', '/properties/');
      const fullKey = `#/properties/form/properties/${key}`;
      if (!errorMap[fullKey]) errorMap[fullKey] = item.reason;
    });
  });
  return errorMap;
};

export function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}
