import React from 'react'

import Button from '../../../UI/Button/Button'

import './styles.module.css'
import {PermissionGuard} from "../../role-helpers";

const QRCodeBundlesHeader = props => {
    return (
        <div styleName="header" className="d-flex align-items-center">
            <h4>{props.title}</h4>
            <PermissionGuard code="create_qr_bundle">
                <Button className="ml-auto" color="primary" onClick={props.actionHandler}>{props.actionLabel}</Button>
            </PermissionGuard>
        </div>
    )
}

export default QRCodeBundlesHeader
