import {applyMiddleware, compose, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import {routerMiddleware} from 'connected-react-router';
import reducers from './redux';
import {createBrowserHistory} from 'history';

const logger = createLogger({
  collapsed: true,
  colors: {
    title: () => 'green'
  }
});

export const history = createBrowserHistory();

const composeWithDevTools =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middleware = applyMiddleware(
  routerMiddleware(history),
  promise,
  thunk,
  logger
);

export default function configureStore(preloadedState) {
  const store = createStore(
    reducers(history),
    preloadedState,
    composeWithDevTools(middleware)
  );

  return store;
}
