import {fulfilled, fulfilledState, initState} from "./middleware";
import brand from "../api/brand";

export const GET_BRAND_LIST = 'GET_BRAND_LIST';

export default (state = initState, action) => {
    switch (action.type) {
        case fulfilled(GET_BRAND_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getBrandList = () => ({
    type: GET_BRAND_LIST,
    payload: brand.list()
});
