import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createMessage} from "../Util/notification";
import Modal from '../UI/Modal/Modal'
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import {ERR_VALIDATION} from "../../api/errorCodes";
import {getSafeDeep} from "../Util/state";
import {mapBackendErrors} from "../DynamicForm/controls/util";
import {parseErrors} from "../Util/form";
import brand from "../../api/brand";
import editNoteDataSchema from "./schema/editNoteData";
import editNoteUiSchema from "./schema/editNoteUI";
import {getBrandList} from "../../redux/brand";
import currencies from "../../api/V3/currency"
import {apiClientV3} from "../../api/util";
import wallets from "../../api/V3/wallets";


class EditNoteDialog extends React.Component {

    state = {
        errors: undefined,
        isLoading: false
    };

    componentDidMount() {
        this.loadSchema();
    }
    
    componentDidUpdate() {
    }

    onSubmit = async (data) => {
        try{
            this.setState({ isLoading: true })
            await wallets.editNote(this.props.transaction.id, { note: data.note });
            createMessage('Successfully edited note');
            this.setState({ isLoading: false })
            this.props.toggle();
            this.props.reloadData();
        }catch (e) {
            this.setState({ isLoading: false, errors: parseErrors(e)});
        }
    };

    loadSchema = async() => {
        const uiSchema = editNoteUiSchema;
        const dataSchema = editNoteDataSchema;
        this.setState({ uiSchema, dataSchema });
    };

    render() {
        const {toggle, isOpen, } = this.props;
        const {errors, isLoading} = this.state;
        if(!isOpen) return "";



        const modal = {
            toggle,
            isOpen,
            isLoading,
            title: "Edit Note",
            errors,
            size: "md"
        }

        return(
            <DynamicForm    uiSchema={this.state.uiSchema}
                            dataSchema={this.state.dataSchema}
                            modal={modal}
                            initData={{ form: { ...this.props.transaction }}}
                            errors={errors}
                            onSubmit={(data) => this.onSubmit(data.form)}/>
        )
    };
}

function mapState(state) {
    return {
    };
}

function mapActions(dispatch) {
    return bindActionCreators({getBrandList}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(EditNoteDialog));
