import { apiClientV3 } from "../util";

export default {

    //get roles for user
    getUserRoles: (userId) => {
        return apiClientV3.get(`admin/rbac/user/${userId}/roles/`)
    },

    getUserRegion: (userId) => {
        return apiClientV3.get(`admin/rbac/user/${userId}/region/`)
    },

    //get current possible roles
    getRoles: (search = '', offset, limit = 10, ordering = '') => {
        let params = {
            offset: offset,
            limit: limit,
            ordering
        };
        if (search != "") params.search = search;
        return apiClientV3.get('admin/rbac/role', { params })
    },

    getAllRoles: () => {
        return apiClientV3.get('admin/rbac/role')
    },

    //get all permissions
    getPermissions: () => {
        return apiClientV3.get('admin/rbac/permission')
    },

    //create new
    createNew: data => {
        return apiClientV3.post('admin/rbac/role', data)
    },

    //delete role
    deleteRole: id => {
        return apiClientV3.delete('admin/rbac/role/' + id)
    },

    //edit role
    editRole: (id, data) => {
        return apiClientV3.put('admin/rbac/role/' + id, data)
    },

    assign: (userId, roles) => {
        return apiClientV3.put(`admin/rbac/user/${userId}/roles/assign`, roles)
    },

    getRegions: () => {
        return apiClientV3.get(`admin/rbac/region`)
    }

}
