import React from 'react'

import Modal from '../../../../../UI/Modal/Modal'
import Button from '../../../../../UI/Button/Button'

const DeleteTagModal = props => {
    
    return (
        <Modal
            submitButton={() => <Button isLoading={props.isLoading} className="ml-3" onClick={() => props.onSubmit(props.data)} color="danger">Delete</Button> }
            title="Delete Tag"
            isLoading={props.isLoading}
            toggle={props.toggle}
            isOpen={props.isOpen}>
            <p className="text-center">Are you sure you want to delete tag { props.data && <b>{props.data.display_name}</b>} ?</p>
        </Modal>
    )
}

export default DeleteTagModal