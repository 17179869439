export const meetingTypeOptions = [
    {
        label: "Video call",
        value: "video-call"
    },
    {
        label: "Face-to-face",
        value: "face-to-face"
    }
];
