import {apiClientV3} from "../util";

export default {
    get: (search, offset=0, limit=10, ordering) => {
        let params = {
            limit,
            offset,
        }

        if(ordering){
            params.ordering = ordering
        }
        if(search){
            params.search = search;
        }
        return apiClientV3.get(`/admin/crypto_accounting/wallets`, { params })
    },

    editWallet: (id, data) => {
        return apiClientV3.put(`/admin/crypto_accounting/wallets/${id}`, data)
    },

    editNote: (id, data) => {
        return apiClientV3.put(`admin/crypto_accounting/transactions/${id}`, data)
    },

    deleteWallet: id => {
        return apiClientV3.delete(`/admin/crypto_accounting/wallets/${id}`)
    },

    getWalletTransaction: (id, search, offset=0, limit=10, ordering) => {
        let params = {
            offset,
            limit
        }
        if(search){
            params.search = search
        }
        if(ordering){
            params.ordering = ordering
        }
        return apiClientV3.get(`/admin/crypto_accounting/wallets/${id}/transactions`, { params })
    },

    add: (data) =>  apiClientV3.post(`/admin/crypto_accounting/wallets`, data),

    exportTransactionCSV: (start_date, end_date) => apiClientV3.get(`/admin/crypto_accounting/wallets/2/transactions/export?start_date=${start_date}&end_date=${end_date}`),
}



