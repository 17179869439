import React from 'react'

import Page from '../UI/Page/Page'
import {Helmet} from "react-helmet";

const Dashboard = props => {

    return (
        <>
            <Page title="Dashboard" />
            <Helmet>
                <title>Dashboard | Elicon</title>
            </Helmet>
        </>
    )
}

export default Dashboard;
