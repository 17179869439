import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import {parseErrors} from "../../Util/form";

import user from '../../../api/user'
import roles from '../../../api/V3/roles'

import Page from '../../UI/Page/Page'

import Menu from './Menu/Menu'
import Panel from './Panel/Panel'
import { notifyError } from '../../Util/notification'
import {Helmet} from "react-helmet";

class Profile extends React.Component{

    state = {
        isLoading: false,
        tab: 0,
        user: null,
        errors: {},
        links: []
    }

    componentDidMount(){
        this.loadData()
    }

    updateBasicInfo = async ({ form }) => {
        try{
            this.setState({ isLoading: true })
            await user.update(this.props.user.id, form)
            this.setState({ isLoading: false })  
            this.loadData() 
        }catch(err){
            this.setState({ isLoading: false, errors: parseErrors(err) })   
            notifyError(err.message)
        }
    }

    changePassword = async ({ form }) => {
        try{
            this.setState({ isLoading: true })
            await user.change_password(form)
            this.setState({ isLoading: false })
        }catch(err){
            this.setState({ isLoading: false, errors: parseErrors(err) })   
            notifyError(err.message)
        }
    }

    loadData = async () => {
        try{    
            this.setState({ isLoading: true })
            const { data } = await user.get(this.props.user.id)
            const rolesData = await roles.getUserRoles(this.props.user.id)

            this.setState({
                user: data,
                isLoading: false,
                links: [
                    { icon: "profile-1", name: 'Basic Info', schema: { 
                            ui: require('./schema/basicInfoUi.json'),
                            data: require('./schema/basicInfoData.json'),
                            initData: {form: {
                                first_name: this.props.user.first_name,
                                last_name: this.props.user.last_name,
                                email: this.props.user.email
                            }},
                        },
                        onSubmit: this.updateBasicInfo
                    },
                    { icon: "share", name: 'Change Password', schema: {
                            ui: require('./schema/changePasswordUi.json'),
                            data: require('./schema/changePasswordData.json'),
                            initData: { form: {} },
                        },
                        onSubmit: this.changePassword
                    },
                    { icon: "chat-1", name: 'Permissions', roles: rolesData.data },
                    { icon: "graphic-2", name: 'Other', schema: {
                        ui: require('./schema/otherUi.json'),
                        data: require('./schema/otherData.json'),
                        initData: { form: {
                            language: 'eng',
                            timezone: 'UTC',
                            date_joined: moment(data.date_joined).format('DD.MM.YYYY hh:mm'),
                            last_login: "Today"
                        }}
                    }},
                ]
            })
        }catch(err){
            notifyError(err.message)
            this.setState({ isLoading: false })
        }
    }

    navigateTab = (tab) => {
        this.setState({ tab })
    }

    render(){
        return(
            <Page title="User Profile">
                <Helmet>
                    <title>Profile | Elicon</title>
                </Helmet>
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <Menu
                            user={this.state.user} 
                            active={this.state.links[this.state.tab]}
                            navigateTab={this.navigateTab}
                            links={this.state.links}
                        />
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <Panel 
                            isLoading={this.state.isLoading}
                            errors={this.state.errors}
                            navigateTab={this.navigateTab}
                            activeTab={this.state.tab}
                            tabsCount={this.state.links.length}
                            active={this.state.links[this.state.tab]}
                        />
                    </div>
                </div>
            </Page>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data
    }
}

export default connect(mapStateToProps)(Profile);
