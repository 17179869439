import {getSafeDeep} from "./state";
import {ERR_VALIDATION, ERR_INTEGRITY} from "../../api/errorCodes";
import {mapBackendErrors} from "../DynamicForm/controls/util";
import {createMessage, notifyError} from "./notification";


export function parseErrors(errorResponse){
    const error = JSON.parse(JSON.stringify(errorResponse));
    console.error(errorResponse)
    if (getSafeDeep(error,"response.data.code", "NA") == ERR_VALIDATION){
        return mapBackendErrors(JSON.parse(error.response.data.message));
    } else if(getSafeDeep(error,"response.data.code", "NA") == ERR_INTEGRITY){
        try{
            notifyError(getSafeDeep(error, 'response.data.message', 'undefined error'))
            return {}
        }catch (e) {
            notifyError('Something unexpected happened, please check network log.')
            return {}
        }
    } else {
        notifyError('Something unexpected happened, please check network log.');
        return {};
    }
}

export function parseProfileNested(errorResponse){
    const error = JSON.parse(JSON.stringify(errorResponse));
    if (getSafeDeep(error,"response.data.code", "NA") == ERR_VALIDATION){
        let errorMap = {};
        JSON.parse(error.response.data.message).forEach(item => {
            item.fields.forEach(subItem => {
                let key = subItem;
                key = key.replace(".","/properties/");

                let fullKey = `#/properties/form/properties/profile/properties/${key}`;
                if(key == 'email'){
                    fullKey = `#/properties/form/properties/${key}`;
                }
                if(!errorMap[fullKey]) errorMap[fullKey] = item.reason;
            });
        });
        return errorMap;
    } else {
        notifyError('Something unexpected happened, please check network log.');
        return {};
    }
}

export function objectToArray(object, idLabel, nameLabel){
    return Object.keys(object).map((key, index) => {
        return {
            [idLabel]:key,
            [nameLabel]: object[key]
        };
    });
}
