import {apiClientV3} from "./util";
import {AxiosPromise} from 'axios';

export default {
    // List all currencies
    list: (hw_pos_buy_and_sell_currencies):AxiosPromise => apiClientV3.get(`/currencies`, {
        params: {
            hw_pos_buy_and_sell_currencies
        }
    })
}
