import qs from "query-string";
import React, { Fragment } from "react";
import { push } from "connected-react-router";
import { dictionaryToArrayOfObjects } from "../../../utils/helperFunctions";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Card from "../../UI/Card/Card";
import Page from "../../UI/Page/Page";
import Button from "../../UI/Button/Button";
import WithLoading from "../../Util/HOC/WithLoading";
import {listHeader, renderFullDate, renderLink} from "../../Util/listView";
import ListBody from "../../Util/ListView/ListBody/ListBody";
import ListHeader from "../../Util/ListView/ListHeader/ListHeader";
import { createMessage } from "../../Util/notification";
import { getSafeDeep } from "../../Util/state";
import holistic from "../../../api/V3/holistic";
import HolisticPOSAddNew from "../HolisticPOSAddNew";
import HolisticPOSCreateFromGoCrypto from "../HolisticPOSCreateFromGoCrypto";
import HolisticPOSDeleteModal from "../HolisticPOSDeleteModal";
import newCompanyUiSchema from "../schemas/newCompanyUI.json"
import newCompanyDataSchema from "../schemas/newCompanyData.json"
import organization from "../../../api/organization";

const ListBodyWithLoading = WithLoading(ListBody);

class HolisticPOSCompanies extends React.Component {
  state = {
    isLoading: true,
    count: 0,
    rowsPerPage: 10,
    sort: "",
    addNew: false,
    create: false,
    delete: null,
    data: [],
    paymentMethods: [],
    operatedBy: [],
    createFromCoCryptoLabel: 'Create From GoCrypto Classic'
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(oldProps, prevState) {
    const { query, auth } = this.props;
    const { query: oldQuery, auth: oldAuth } = oldProps;
    if (
        JSON.stringify(query) !== JSON.stringify(oldQuery) ||
        this.state.rowsPerPage != prevState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  getGoCryptoCompanies = async () => {
    const response = await organization.getGoCryptoCompanies()
    if(!response.data.results.length) return;

    this.setState({
      createFromCoCryptoLabel: 'Create From GoCrypto Classic'
    })
  }

  loadData = async () => {
    {
      let { page, search, sort, pageSize, ...filter } = { ...this.props.query };
      this.setState({ isLoading: true });
      try {
        const response = await holistic.companiesListPaginated(
            search,
            (page || 0) * (pageSize || 10),
            pageSize || 10,
            sort,
            filter
        );
        const paymentMethodsRes = await holistic.getPaymentMethods();
        const operatedByRes = await holistic.getOperatedBy();

        if (this.props.query.search != search) {
          this.setState({ isLoading: false });
        } else {
          this.setState({
            count: response.data.count,
            data: response.data.results,
            paymentMethods: paymentMethodsRes.data,
            operatedBy: dictionaryToArrayOfObjects(operatedByRes.data),
            isLoading: false,
          });
        }

      } catch (e) {
        createMessage(
            getSafeDeep(
                e,
                "response.data.message",
                getSafeDeep(e, "response.data", e.message)
            ),
            "error"
        );
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    const query = { ...this.props.query };
    let currentItem = query.page * query.pageSize || 0;
    query.page = Math.floor(currentItem / newRowsPerPage);
    query.pageSize = newRowsPerPage;
    this.props.history.push(
        `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  requestSort = (sort) => {
    this.setState({ sort });
    const query = { ...this.props.query };
    query.page = 0;
    query.sort = sort;
    this.props.history.push(
        `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  getCompaniesListHeaders = () => {
    return [
      listHeader("Company Name", "full_name", true, (item) => {
        return renderLink(item.full_name, `/holistic-pos/companies/${item.id}`);
      }),
      listHeader("Created", "created", true, (item) => {
        return renderFullDate(item.created)
      }),
      listHeader("Delete", "", false, (item) => {
        return (
            <Button
                color="danger"
                isLoading={this.state.isLoading}
                onClick={() => {
                  this.setState({ delete: item });
                }}
            >
              Delete
            </Button>
        );
      }),
    ];
  };

  render() {
    const { isLoading, data, count } = this.state;
    return (
        <Fragment>
          <Helmet>
            <title>Holistic Companies | Elicon</title>
          </Helmet>
          <Page permission={"view_all_holistic_companies"}>
            <Card>
              <ListHeader
                  type="OnlySearch"
                  title="Holistic Companies"
                  searchTitle="Search"
                  actionLabel="Add New Company"
                  actionHandler={() => this.setState({ addNew: true })}
                  createLabel={this.state.createFromCoCryptoLabel}
                  createHandler={() => this.setState({ create: true })}
              />
              <ListBodyWithLoading
                  sortField={this.state.sort}
                  requestSort={this.requestSort}
                  padding="16px"
                  headers={this.getCompaniesListHeaders()}
                  isLoading={isLoading}
                  data={data}
                  count={count}
                  rowsPerPage={this.props.query.pageSize || 10}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
              />
            </Card>
          </Page>
          <HolisticPOSAddNew
              uiSchema={newCompanyUiSchema}
              dataSchema={newCompanyDataSchema}
              isOpen={this.state.addNew}
              reloadData={() => this.loadData()}
              toggle={() => this.setState({ addNew: false })}
              label={"Company"}
              paymentMethods={this.state.paymentMethods}
              operatedBy={this.state.operatedBy}
              onSubmit={(data) => {
                return holistic.addNewCompany(data.form);
              }}
          />
          <HolisticPOSCreateFromGoCrypto
              isOpen={this.state.create}
              reloadData={() => this.loadData()}
              toggle={() => this.setState({ create: false })}
              onSubmit={(id, data) => {
                return holistic.createFromGoCrypto(id, data);
              }}
          />
          <HolisticPOSDeleteModal
              isOpen={!!this.state.delete}
              reloadData={() => {
                this.loadData();
              }}
              toggle={() => this.setState({ delete: null })}
              label={getSafeDeep(this.state.delete, "full_name")}
              onSubmit={() => {
                return holistic.deleteCompany(this.state.delete.id);
              }}
          />
        </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
  };
}

function mapActions(dispatch) {
  return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(HolisticPOSCompanies));
