import React, {useState} from 'react';

import {Form} from 'reactstrap';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import Select from '../../../UI/Select/Select';

import {getCountryData} from '../../countryList';

import './styles.module.css';
import {withRouter} from 'react-router';
import qs from 'qs';
import {PermissionGuard} from '../../role-helpers';
import DatePicker from '../../../UI/DatePicker/DatePicker';
import moment from 'moment';

/*Constants*/
const countries = getCountryData();

const approvalStates = [
  {value: 0, label: 'First Lead'},
  {value: 1, label: 'Second Lead'},
  {value: 2, label: 'Pending'},
  {value: 3, label: 'Approved'},
  {value: 4, label: 'Rejected'},
  {value: 5, label: 'Terminated'}
];

const dataFormat = 'YYYY-MM-DD';

const OrgList = (props) => {
  /*Local state*/
  const query = qs.parse(props.query, {ignoreQueryPrefix: true});

  /*Methods*/
  const getOptions = () =>
    countries.map((c) => ({value: c.code, label: c.name}));

  const setFilter = (key, value) => {
    const newQuery = {...query};

    if (value == null || value === '') {
      delete newQuery[key];
    } else {
      newQuery[key] = value;
    }

    props.push(`?${qs.stringify(newQuery)}`);
  };

  const getFilter = (key) => query[key];

  /*Template*/
  return (
    <div>
      <div
        styleName="header"
        className="d-flex align-items-center justify-content-end"
      >
        <h4 className="mr-auto">{props.title}</h4>
        <PermissionGuard code={'create_company'}>
          <Button
            onClick={props.actionHandler}
            className="mr-3"
            color="outline-primary"
          >
            Add
          </Button>
        </PermissionGuard>
        <PermissionGuard code={'company_update_state'}>
          <Button onClick={props.rejectHandler} className="mr-3" color="danger">
            Reject
          </Button>
        </PermissionGuard>
        <PermissionGuard code={'view_company_report'}>
          <Button
            isLoading={props.exportLoading}
            color="primary"
            onClick={props.onExportClicked}
          >
            Export
          </Button>
        </PermissionGuard>
      </div>
      <Form onSubmit={props.onSubmit} className="d-flex" styleName="search">
        <div>
          <label>&nbsp;</label>
          <Input
            className="mr-3"
            style={{width: '256px'}}
            onChange={props.onChange}
            name="search"
            placeholder={props.searchTitle}
          />
        </div>
        <div style={{width: '256px'}}>
          <label>Country</label>
          <Select
            value={getOptions().find((c) => c.value === getFilter('country'))}
            placeholder="All Countries"
            options={getOptions()}
            isSearchable={true}
            isClearable={true}
            onChange={(c) => setFilter('country', c ? c.value : null)}
            className="mr-3"
          />
        </div>
        <div style={{width: '256px'}}>
          <label>Approval state</label>
          <Select
            value={approvalStates.find(
              (a) => a.value === getFilter('approval_state')
            )}
            options={approvalStates}
            isClearable={true}
            onChange={(c) => setFilter('approval_state', c ? c.value : null)}
            className="mr-3"
          />
        </div>
        <div style={{width: '256px'}} className="mr-3">
          <label>Date from</label>
          <DatePicker
            format="DD/MM/YYYY"
            clearable={true}
            variant="dialog"
            maxDate={getFilter('created_to')}
            value={getFilter('created_from') || null}
            onChange={(date) =>
              setFilter(
                'created_from',
                date ? moment(date).format(dataFormat) : null
              )
            }
          />
        </div>
        <div style={{width: '256px'}}>
          <label>Date to</label>
          <DatePicker
            format="DD/MM/YYYY"
            value={getFilter('created_to') || null}
            minDate={getFilter('created_from')}
            clearable={true}
            variant="dialog"
            onChange={(date) =>
              setFilter(
                'created_to',
                date ? moment(date).format(dataFormat) : null
              )
            }
          />
        </div>
      </Form>
    </div>
  );
};

export default withRouter(OrgList);
