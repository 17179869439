import {apiClient, apiClientV3, mapiClient} from './util';
import type {User} from './model/user';
import {AxiosPromise} from 'axios';

export default {
  getUser: (): AxiosPromise<User> => apiClient.get('/auth'),

  getRole: () => apiClientV3.get('/rbac/user/current/roles/'),

  forgotPassword: (email) => apiClientV3.post('/password/reset/', {email}),

  getOtpConfig: (registration_token): AxiosPromise =>
    apiClientV3.get('/auth/register-otp', {params: {registration_token}}),

  registerOtpDevice: (device_id, registration_token, token): AxiosPromise =>
    apiClientV3.post('/auth/register-otp', {
      device_id,
      registration_token,
      token
    }),

  // Login method
  login: (username: string, password: string, otp_token): AxiosPromise =>
    apiClient.post('/auth', {username, password, otp_token}),

  // Logout method
  logout: (): AxiosPromise => apiClient.delete('/auth'),

  // Authorization reason setting
  setReason: (reason: string): AxiosPromise =>
    apiClient.post('/admin/reason', {reason}),

  activateUser: (otpToken, password, passwordConfirm): AxiosPromise =>
    apiClient.post(`/user/activate/${otpToken}`, {
      new_password: password,
      confirm_password: passwordConfirm
    }),

  setPassword: (otpToken, password, passwordConfirm): AxiosPromise =>
    apiClientV3.post(`/password/new/${otpToken}`, {
      new_password: password,
      confirm_password: passwordConfirm
    }),

  loginUserToManager: (email: string): AxiosPromise =>
    mapiClient.post(`/api/login`, {
      email: email
    }),

  validateManagerUser: (userId: number, token: string): AxiosPromise =>
    apiClientV3.post(`/admin/login-as-user`, {
      user: userId,
      token: token
    })
};
