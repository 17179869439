import React, {useState, useEffect} from 'react';
import Modal from '../UI/Modal/Modal';
import Input from '../UI/Input/Input';
import {createMessage, notifyError} from '../Util/notification';
import DynamicForm from '../DynamicForm/DynamicForm';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

const HolisticPOSAddNew = ({
  uiSchema,
  dataSchema,
  isOpen,
  toggle,
  onSubmit,
  reloadData,
  label,
  fullLabel,
  countries,
  paymentMethods,
  operatedBy,
  sourceLang,
  history,
  location
}) => {
  /*Framework hooks*/

  /*Local state*/
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  /*Constants*/
  const languages = sourceLang.map((item) => {
    item.code = item.code.toLowerCase();
    return item;
  });

  /*Methods*/
  const dynamicSubmitHandler = async (data) => {
    try {
      setIsLoading(true);
      const response = await onSubmit(data);
      setIsLoading(false);
      createMessage(`New ${label} created`);
      toggle();
      history.push(`${location.pathname}/${response.data.id}`);
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      notifyError(err.message);
    }
  };

  if (uiSchema && dataSchema) {
    return (
      <DynamicForm
        uiSchema={uiSchema}
        dataSchema={dataSchema}
        initData={{form: {}, countries, paymentMethods, operatedBy, languages}}
        onSubmit={dynamicSubmitHandler}
        modal={{
          title: `Add new ${label}`,
          isOpen,
          toggle,
          isLoading,
          size: 'sm'
        }}
      />
    );
  }

  const [name, setName] = useState('');

  useEffect(() => {
    setName('');
    setError('');
  }, [isOpen]);

  const submitHandler = async () => {
    if (name.trim().length === 0) {
      return setError('This field is required');
    }

    try {
      setIsLoading(true);
      await onSubmit(name);
      setIsLoading(false);
      createMessage(`New ${label} created`);
      toggle();
      reloadData();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      notifyError(err.message);
    }
  };

  return (
    <Modal
      title={`Add new ${label}`}
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={submitHandler}
      isLoading={isLoading}
    >
      <div>
        <label htmlFor="reason">
          {fullLabel ? fullLabel : `${label} Name`}
        </label>
        <Input
          placeholder={fullLabel ? `Enter ${fullLabel}` : `Enter ${label} Name`}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <small className="text-danger">{error}</small>
      </div>
    </Modal>
  );
};

function mapState(state) {
  return {
    countries: state.countries.data || [],
    sourceLang: state.sourceLang.data
  };
}

export default withRouter(connect(mapState)(HolisticPOSAddNew));
