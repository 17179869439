import React from 'react'
import {getProp, getDataProp, getSafe, computeIsRequired, getInputConfig, getInputData, getInputError} from "./util";
// import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {FormFeedback, FormGroup, Label} from "reactstrap";
import Input from '../../UI/Input/Input'
import ReactSelect from 'react-select';
import 'react-datepicker/dist/react-datepicker.min.css';
import * as classnames from "classnames";
import {formContextWrapper} from "../FormContext";
import {arrayContextWrapper} from "../ArrayContext";
import type {FormContextProps} from "../FormContext";
import type {ArrayContextProps} from "../ArrayContext";

type InputControlProps = {
    label: string,
    outterhtmlClass: string,
    readOnly: boolean,
    allowedClear?: boolean,
    scope: {
        $ref: string
    },
    context: FormContextProps,
    array?: ArrayContextProps
}

const InputControl = ({ outterhtmlClass }) => {
    return (
        <div className={classnames(outterhtmlClass, "mb-3")}>
        </div>
    );
}

export default arrayContextWrapper(formContextWrapper(InputControl))
