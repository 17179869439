import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ListBody from '../Util/ListView/ListBody/ListBody';
import WithLoading from '../Util/HOC/WithLoading';
import qs from 'query-string';
import {push} from 'connected-react-router';
import {listHeader, renderCreatedAt, renderLink} from '../Util/listView';
import ListHeader from '../Util/ListView/ListHeader/ListHeader';

import AddReferralDialog from './AddReferralDialog';
import RemoveReferralDialog from './RemoveReferralDialog';
import Page from '../UI/Page/Page';
import {Card} from '@material-ui/core';
import {Edit, Delete, PlaylistAddCheck} from '@material-ui/icons';
import More from '../UI/More/More';
import referral from '../../api/V3/referral';
import EditReferralDialog from './EditReferralDialog';
import ActivitiesModal from './ActivitiesModal';
import {hasPermission} from '../Util/role-helpers';
import {Helmet} from 'react-helmet';
import {PaginatedTable} from '../Util/HOC/PaginatedTable';
import {
  StyledIcon,
  StyledListItemIcon,
  StyledListItemText
} from '../UI/TableStyling/TableStyling';

const ListBodyWithLoading = WithLoading(ListBody);

class ReferralList extends React.Component {
  state = {
    delete: null,
    activityReferral: '',
    activityReferralEdit: ''
  };

  ReferralsListHeaders = [
    listHeader('Referral ID', 'id', true),
    listHeader('Owner', 'owner', true, undefined, undefined, undefined, 'left'),
    listHeader(
      'Short URL',
      'short_url',
      false,
      (row) => renderLink(row.short_url, row.short_url, true),
      undefined,
      undefined,
      'center'
    ),
    listHeader('Note', 'note', false, undefined, undefined, undefined, 'right'),
    listHeader(
      'Created At',
      'created_at',
      true,
      (row) => renderCreatedAt(row),
      undefined,
      undefined,
      'right'
    ),
    listHeader(
      'Actions',
      'actions',
      false,
      (data) => <More {...this.getActions(data)} />,
      undefined,
      undefined,
      'right'
    )
  ];
  getActions = (data) => {
    const {toggleDialog} = this.props;
    const StyledSearch = StyledIcon(PlaylistAddCheck);
    const StyledEdit = StyledIcon(Edit);
    const StyledDelete = StyledIcon(Delete);
    const items = [];

    if (hasPermission(this.props.user, 'viewAll_company_referral_activities')) {
      items.push({
        handler: async () => {
          await this.setState({activityReferral: data.id});
          toggleDialog('activitiesModal');
        },
        render: () => (
          <Fragment>
            <StyledListItemIcon>
              <StyledSearch />
            </StyledListItemIcon>
            <StyledListItemText disableTypography primary="Activities" />
          </Fragment>
        )
      });
    }

    if (hasPermission(this.props.user, 'change_company_referral')) {
      items.push({
        handler: async () => {
          await this.setState({activityReferralEdit: data.id});
          toggleDialog('editReferral');
        },
        render: () => (
          <Fragment>
            <StyledListItemIcon>
              <StyledEdit />
            </StyledListItemIcon>
            <StyledListItemText disableTypography primary="Edit" />
          </Fragment>
        )
      });
    }

    if (hasPermission(this.props.user, 'delete_company_referral')) {
      items.push({
        handler: () => {
          this.setState({delete: data});
        },
        render: () => (
          <Fragment>
            <StyledListItemIcon>
              <StyledDelete />
            </StyledListItemIcon>
            <StyledListItemText disableTypography primary="Delete" />
          </Fragment>
        )
      });
    }

    return {items};
  };

  render() {
    const {
      isLoading,
      rowsPerPage,
      count,
      data,
      search,
      toggleDialog,
      sort,
      requestSort,
      onChangeRowsPerPage
    } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Referrals | Elicon</title>
        </Helmet>
        <Page permission={'viewAll_company_referrals'}>
          <Card>
            <ListHeader
              type="Referral"
              title="Referrals"
              value={search}
              searchTitle="Filter"
              actionHandler={(dialog) => toggleDialog(dialog)}
              toggle={() => toggleDialog('addReferral')}
              actionLabel="Add Wallet"
            />
            <ListBodyWithLoading
              sortField={sort}
              requestSort={requestSort}
              headers={this.ReferralsListHeaders}
              isLoading={isLoading}
              data={data}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Card>
        </Page>
        <RemoveReferralDialog
          onSubmit={async (data) => {
            await referral.delete(data.id);
            this.loadData();
          }}
          referral={this.state.delete}
          toggle={() => this.setState({delete: null})}
        />
        <AddReferralDialog
          toggle={() => toggleDialog('addReferral')}
          isOpen={this.props.addReferralDialogOpen}
          reloadData={() => this.loadData()}
        />
        <EditReferralDialog
          toggle={() => toggleDialog('editReferral')}
          isOpen={this.props.editReferralDialogOpen}
          reloadData={() => this.loadData()}
          selectedReferral={data.find(
            (referral) => referral.id === this.state.activityReferralEdit
          )}
          id={this.state.activityReferralEdit}
        />
        <ActivitiesModal
          isOpen={this.props.activitiesModalDialogOpen}
          toggle={() => toggleDialog('activitiesModal')}
          id={this.state.activityReferral}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true}),
    user: state.user.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

const ReferralPaginatedTable = PaginatedTable(ReferralList, {
  apiCall: referral.getT,
  modals: ['addReferral', 'editReferral', 'activitiesModal']
});

export default withRouter(
  connect(mapState, mapActions)(ReferralPaginatedTable)
);
