import React from 'react';
import Button from '../Button/Button'

import { makeStyles } from '@material-ui/core/styles';
import { Modal } from 'reactstrap'
import { clearProps } from '../../Util/types'
import CircularProgress from '@material-ui/core/CircularProgress';

import './modal.module.css'

const useStyles = makeStyles(theme => ({
    root: {
        padding: "1rem",
        color: "inherit !important"
    },
}));


export default (passedProps) => {

    const notToSpread = ['noFooter', 'cancelButton', 'submitButton', 'footer', 'isLoading', 'cancelLabel', 'confirmLabel']
    const props = clearProps(passedProps, notToSpread)
    const classes = useStyles();

    if(passedProps.isLoading){
        props.toggle = () => {}
    }
    
    return  (
        <Modal {...props} contentClassName="p-0" className="mt-4"  zIndex={2000} >
            <div styleName="header">
                <h5 styleName="title">{passedProps.title}</h5>
                <button styleName="close"  disabled={passedProps.isLoading} onClick={passedProps.toggle}>
                    {passedProps.isLoading ? <CircularProgress className={classes.root} /> :  "×" }
                </button>
            </div>
            <div styleName="body">
                {passedProps.children}
            </div> 
            {passedProps.footer ? 
                <div styleName="footer">
                    {passedProps.footer()}
                </div> : 
                <div styleName="footer">
                    {passedProps.cancelButton ? passedProps.cancelButton() : (
                        <Button color="secondary" isLoading={passedProps.isLoading} onClick={props.toggle}>
                            {passedProps.cancelLabel ? passedProps.cancelLabel : "Cancel"}
                        </Button>)}
                    {passedProps.submitButton ? passedProps.submitButton() : (
                        <Button color="primary" isLoading={passedProps.isLoading} onClick={props.onSubmit ? props.onSubmit : ()=>{}} className="ml-3" type="submit">
                            {passedProps.confirmLabel ? passedProps.confirmLabel : "Save"}
                        </Button>)}   
                </div>
            }
        </Modal>
    )
}