import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import organization from '../../../api/organization';
import WithLoading from '../../Util/HOC/WithLoading';
import {getUserList} from '../../../redux/user';
import Page from '../../UI/Page/Page';
import Menu from './Menu/Menu';
import Panel from './Panel/Panel';
import {parseErrors} from '../../Util/form';
import {createMessage, notifyError} from '../../Util/notification';
import company from '../../../api/V3/company';
import * as _ from 'lodash';
import {deepCopy, getSafeDeep} from '../../Util/state';
import {Helmet} from 'react-helmet';
import ReasonModal from './ReasonModal/ReasonModal';
import {hasPermission} from '../../Util/role-helpers';

const PageWithLoading = WithLoading(Page);

class OrganizationMenu extends React.Component {
  state = {
    isLoading: false,
    arePropsLoading: false,
    tab: 0,
    errors: {},
    organization: {},
    links: [],
    fiatCurrencies: {},
    settlementCurrencies: {},
    exchangeFees: [],
    reasonDialog: false,
    submit: null,
    checkbox: false,
    payfacList: []
  };

  modifySettlementCurrency = async (
    data,
    currentSettlementCurrencies,
    currentFiatCurrencies
  ) => {
    if (data.settlement_currency.length == 0) {
      data.settlement_currency = [{...currentSettlementCurrencies}];
    } else {
      data.settlement_currency = [
        {
          active: getSafeDeep(data, 'settlement_currency.0.active'),
          pending:
            getSafeDeep(data, 'settlement_currency.0.pending', null) != null
              ? getSafeDeep(data, 'settlement_currency.0.pending')
              : getSafeDeep(data, 'settlement_currency.0.active')
        }
      ];
    }

    if (data.fiat_currency.length == 0) {
      data.fiat_currency = [{...currentFiatCurrencies}];
    } else {
      data.fiat_currency = [
        {
          active: getSafeDeep(data, 'fiat_currency.0.active'),
          pending:
            getSafeDeep(data, 'fiat_currency.0.pending', null) != null
              ? getSafeDeep(data, 'fiat_currency.0.pending')
              : getSafeDeep(data, 'fiat_currency.0.active')
        }
      ];
    }

    const active = getSafeDeep(
      this.props.currencies.find(
        (c) =>
          c.id ==
          getSafeDeep(
            data,
            'settlement_currency.0.active',
            getSafeDeep(data, 'fiat_currency.0.active')
          )
      ),
      'name'
    );
    let pending = active;
    if (getSafeDeep(data, 'settlement_currency.0.pending', null) != null) {
      pending = getSafeDeep(
        this.props.currencies.find(
          (c) => c.id == getSafeDeep(data, 'settlement_currency.0.pending')
        ),
        'name'
      );
    }

    this.setState({
      organization: data,
      isLoading: false,
      links: this.generateLinks(data),
      currentFiatCurrencies: {active, pending},
      currentSettlementCurrencies: {active, pending},
      errors: {}
    });
  };

  initialEmailCheckbox = () => {
    this.setState({
      checkbox: !getSafeDeep(this.state.organization, 'disable_company_mails')
    });
  };

  updateEmailCheckbox = (active: boolean) => {
    this.setState({
      checkbox: active
    });
  };

  loadData = async () => {
    const {
      match: {
        params: {id}
      }
    } = this.props;
    this.setState({isLoading: true});
    try {
      const {data} = await company.get(id);

      const fiatCurrency = await organization.getFiatCurrencies(
        getSafeDeep(data, 'primary_address.country', 'SI')
      );

      const settlementCurrency = await company.getSettlementCurrency(data.id);

      const currentSettlementCurrency = (
        await company.getCompanySettlement(data.id)
      ).data;

      const exchangeFees = (
        await organization.getExchangeFees(data.primary_address.country)
      ).data;

      const payfacData = [
        {
          name: 'Naka'
        },
        ...(await company.getPayfac()).data
      ];

      await this.setState({
        fiatCurrencies: fiatCurrency.data,
        settlementCurrencies: settlementCurrency.data,
        exchangeFees: exchangeFees,
        payfacList: payfacData
      });

      await this.modifySettlementCurrency(
        data,
        currentSettlementCurrency,
        fiatCurrency.data
      );
    } catch (e) {
      parseErrors(e);
    } finally {
      this.setState({isLoading: false});
    }
  };

  generateLinks = (data) => {
    const businessInformationUi = require('./schema/businessInformationUi.json');
    const businessInformationData = require('./schema/businessInformationData.json');

    const bankInfoBusinessModelUi = require('./schema/businessModelUi.json');
    const bankInfoBusinessModelData = require('./schema/businessModelData.json');
    if (data.approval_state === 1) {
      businessInformationUi.elements[4].elements[0].elements[1].elements[0].scope.disabled = true; //disable merchant requires pos device
      bankInfoBusinessModelUi.elements[1].scope.disabled = true; //disable settlement currency active
    } else {
      // not approved
      businessInformationUi.elements[1].elements[1].scope.disabled = false; //activate again registration number
      businessInformationUi.elements[2].elements[0].scope.disabled = false; //activate again vat

      bankInfoBusinessModelUi.elements[1].scope.disabled = false; //activate again settlement currency active
    }

    return [
      {
        icon: 'information',
        name: 'Business Information',
        schema: {
          ui: businessInformationUi,
          data: businessInformationData,
          initData: {
            form: {
              full_name: data.full_name,
              email: data.email,
              primary_address: data.primary_address,
              vat: data.vat,
              accountant_xml_id: data.accountant_xml_id || '',
              ceo_first_name: data.ceo_first_name,
              ceo_last_name: data.ceo_last_name,
              registration_number: data.registration_number,
              country_phone_prefix: data.country_phone_prefix || '+386',
              national_number: data.national_number,
              organization_type: data.organization_type,
              merchant_requires_pos_device: data.merchant_requires_pos_device,
              is_demo: data.is_demo != null ? data.is_demo : false,
              locale: data.locale || 'en',
              risk_score: data.risk_score || 'low',
              incorporation_date: data.incorporation_date || null,
              exclude_billings:
                data.exclude_billings != null ? data.exclude_billings : false,
              bitfinex_uid:
                data.bitfinex_uid != null ? data.bitfinex_uid : 'Not set'
            },
            paymentProcessors: [
              {name: 'Bitfinex Slovenia', code: 3},
              {name: 'Bitfinex El Salvador', code: 2},
              {name: 'Bitfinex Switzerland', code: 1},
              {name: 'GoCrypto + Binance', code: 0}
            ]
          }
        }
      },
      {
        icon: 'cart',
        name: 'Organization Fiat Currency',
        schema: {
          data: require('./schema/organizatonFiatCurrencyData.json'),
          ui: require('./schema/organizatonFiatCurrencyUi.json'),
          initData: {
            form: {
              fiat_currency_active: getSafeDeep(
                data,
                'fiat_currency.0.active',
                0
              ),
              fiat_currency_pending: getSafeDeep(
                data,
                'fiat_currency.0.pending',
                0
              )
            },
            fiatCurrencies: this.state.fiatCurrencies
          }
        }
      },
      {icon: 'profile', name: 'Contact Information'},
      {
        icon: 'business',
        name: 'Bank info and Business model',
        schema: {
          data: bankInfoBusinessModelData,
          ui: bankInfoBusinessModelUi,
          initData: {
            form: {
              exchange_fee: data.exchange_fee,
              bank_info: data.bank_info,
              settlement_currency_active:
                getSafeDeep(data, 'settlement_currency.0.pending') ||
                getSafeDeep(data, 'fiat_currency.0.active'),
              settlement_currency_pending:
                getSafeDeep(data, 'settlement_currency.0.pending') ||
                getSafeDeep(data, 'fiat_currency.0.active'),
              commission: data.commission,
              organization_type: data.organization_type,
              merchant_requires_pos_device: data.merchant_requires_pos_device,
              is_demo: data.is_demo != null ? data.is_demo : false,
              bill_immediately:
                data.bill_immediately != null ? data.bill_immediately : false,
              daily_settlement:
                data.daily_settlement != null ? data.daily_settlement : false,
              payfac: data.payfac
            },
            settlementCurrencies: this.state.settlementCurrencies,
            payfacOptions: this.state.payfacList
          }
        }
      },
      {icon: 'file', name: 'Contract'},
      {icon: 'map', name: 'Points of Sale'},
      {icon: 'chat', name: 'Notes', permission: 'view_all_notes'},
      {icon: 'comment', name: 'Edit Reasons', permission: 'list_reasons'},
      {icon: 'clipboard', name: 'Tags'},
      hasPermission(this.props.user, 'company_withdrawal_list')
        ? {icon: 'coins', name: 'Withdrawals'}
        : null,
      hasPermission(this.props.user, 'view_company_balances')
        ? {icon: 'piggy-bank', name: 'Funds'}
        : null
    ];
  };

  async componentDidMount() {
    await this.loadData();
    this.initialEmailCheckbox();
  }

  componentDidUpdate(prevProps: Readonly<P>): void {
    const {
      match: {
        params: {id}
      }
    } = this.props;
    const {
      match: {
        params: {id: oldId}
      }
    } = prevProps;
    if (id !== oldId) {
      this.loadData();
    }
  }

  handleUpdate = (data, calledBy) => {
    if (
      !this.state.reasonDialog &&
      this.state.organization.approval_state == 1
    ) {
      //company approved
      this.setState({
        reasonDialog: true,
        submit: (reason) => {
          this.update(data, calledBy, reason);
        }
      });
    } else {
      this.update(data, calledBy);
    }
  };

  update = async (data, calledBy, reason = '') => {
    try {
      data = deepCopy(data);
      if (data.form.primary_address) {
        data.form.primary_address = _.omitBy(
          data.form.primary_address,
          _.isNil
        );
      }
      if (
        data.form.country_phone_prefix &&
        data.form.country_phone_prefix[0] !== '+'
      ) {
        data.form.country_phone_prefix = `+${data.form.country_phone_prefix}`;
      }
      if (data.form.settlement_currencies_active) {
        await company.setSettlementCurrency(
          this.state.organization.id,
          data.form.settlement_currencies_active,
          data.form.settlement_currencies_pending
        );
        delete data.form['settlement_currencies'];
      }
      if (data.form.organization_type == 0) {
        data.form.merchant_requires_pos_device = false;
      }

      if (data.form.payfac === 'Naka') {
        data.form.payfac = null;
      }

      const response = await company.update(
        this.state.organization.id,
        data.form,
        reason
      );
      const currentSettlementCurrencies = (
        await company.getCompanySettlement(response.data.id)
      ).data;
      const currentFiatCurrencies = (
        await organization.getFiatCurrencies(
          getSafeDeep(response.data, 'primary_address.country')
        )
      ).data;

      await this.modifySettlementCurrency(
        response.data,
        currentSettlementCurrencies,
        currentFiatCurrencies
      );
      createMessage('Successfully updated organization');
      if (calledBy.dataset.name == 'next') {
        this.navigateTab(this.state.tab + 1);
      }
      await this.loadData();
    } catch (e) {
      if (getSafeDeep(e, 'response.data.code') == 'companyEditNotAllowed') {
        notifyError(getSafeDeep(e, 'response.data.message'));
      } else {
        console.dir(e);
        this.setState({errors: parseErrors(e), reasonDialog: false});
      }
    }
  };

  navigateTab = (tab) => {
    this.setState({tab});
  };

  dataUpdated = (newData) => {
    const newOrganization = {...this.state.organization, ...newData};

    this.modifySettlementCurrency(
      newOrganization,
      this.state.currentSettlementCurrencies,
      this.state.currentFiatCurrencies
    );
  };

  handleEmails = async () => {
    try {
      this.setState({isLoading: true});
      await company.sendEmails(
        getSafeDeep(this.state.organization, 'id'),
        this.state.checkbox
      );
    } catch (err) {
      notifyError(err.message);
    } finally {
      this.setState({isLoading: false});
      this.loadData().catch((error) =>
        console.error('loadData error: ', error.message)
      );
    }
  };

  render() {
    const {isLoading, arePropsLoading, organization, tab} = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>{`${organization.full_name} | Elicon`}</title>
        </Helmet>
        <PageWithLoading
          isLoading={isLoading || arePropsLoading}
          noCard={true}
          title={organization.full_name}
          permission={'view_company'}
        >
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <Menu
                user={this.props.user}
                active={this.state.links[this.state.tab]}
                navigateTab={this.navigateTab}
                organization={organization}
                links={this.state.links}
                dataUpdate={this.dataUpdated}
                handleEmails={this.handleEmails}
                reloadData={this.loadData}
                currentSettlement={this.state.currentSettlementCurrencies}
                checkbox={this.state.checkbox}
                updateEmailCheckbox={this.updateEmailCheckbox}
              />
            </div>
            <div className="col-xl-9 col-lg-8">
              <Panel
                dataUpdated={this.dataUpdated}
                loadData={this.loadData}
                errors={this.state.errors}
                update={this.handleUpdate}
                navigateTab={this.navigateTab}
                activeTab={this.state.tab}
                active={this.state.links[this.state.tab] || {}}
                organization={organization}
                fiatCurrencies={this.state.fiatCurrencies}
                settlementCurrencies={this.state.settlementCurrencies}
              />
            </div>
            <ReasonModal
              toggle={() => this.setState({reasonDialog: false})}
              submit={this.state.submit}
              isOpen={this.state.reasonDialog}
            />
          </div>
        </PageWithLoading>
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    user: state.user.data,
    currencies: state.currencies.data,
    payfacList: state.payfacList.data,
    exchangeFees: state.exchangeFees.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({getUserList}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(OrganizationMenu));
