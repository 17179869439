import React from 'react'
import { Form } from 'reactstrap'

import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'

import './styles.module.css';

export default props => {

    return (
        <div>
            <div styleName="header" className="d-flex align-items-center">
                <h4>{props.title}</h4>
                <Button className="ml-auto mr-3" color="primary" onClick={props.actionHandler}>{props.actionLabel}</Button>
            </div>
            <div className="d-flex" styleName="search">
                <Form onSubmit={props.onSubmit} >
                        <Input
                            style={{ width: "256px" }}
                            value={props.value}
                            className="mr-3"
                            onChange={props.onChange}
                            name="search"
                            placeholder={props.searchTitle} />
                </Form>
                {props.renderLanguageSelect()}
            </div>
        </div>
    )
}