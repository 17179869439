import {apiClientV3} from '.././util';
import qs from 'qs';

export default {
  // Get a paginated list of holistic pos companies
  companiesListPaginated: (
    search: string = '',
    offset: number,
    limit: number = 10,
    ordering: string = '-created'
  ) => {
    let params = {
      offset: offset,
      limit: limit
    };
    if (ordering != '') params.ordering = ordering;
    if (search != '') params.search = search;

    return apiClientV3.get('/admin/holistic/companies', {params});
  },

  // create new company
  addNewCompany: (data) => {
    return apiClientV3.post('/admin/holistic/company/', data);
  },

  // get details of a company
  getCompanyDetails: (id) => {
    return apiClientV3.get(`/admin/holistic/company/${id}`);
  },

  //get stores of a company
  getCompanyStoresListPaginated: (
    id,
    search: string = '',
    offset: number,
    limit: number = 10,
    ordering: string = ''
  ) => {
    let params = {
      offset: offset,
      limit: limit
    };
    if (ordering != '') params.ordering = ordering;
    if (search != '') params.search = search;

    return apiClientV3.get(`/admin/holistic/stores/${id}/`, {params});
  },

  //delete company
  deleteCompany: (id) => {
    return apiClientV3.delete(`/admin/holistic/company/${id}`);
  },

  //update company
  updateCompany: (id, data) => {
    return apiClientV3.put(`/admin/holistic/company/${id}`, data);
  },

  createFromGoCrypto: (id, data) => {
    return apiClientV3.post(`/admin/company/${id}/holistic`, data);
  },

  // add merchant to company
  addMerchant: (id, data) => {
    return apiClientV3.post(`admin/holistic/merchant/${id}`, data);
  },

  //get company merchant
  getCompanyMerchant: (
    companyId,
    search,
    offset,
    limit = 10,
    ordering = ''
  ) => {
    let params = {
      offset: offset,
      limit: limit
    };
    if (ordering != '') params.ordering = ordering;
    if (search != '') params.search = search;

    return apiClientV3.get(`admin/holistic/merchant/${companyId}`, {params});
  },

  getHolisticTenants: (
    companyId,
    search,
    offset,
    limit = 10,
    ordering = ''
  ) => {
    let params = {
      offset: offset,
      limit: limit
    };
    if (ordering != '') params.ordering = ordering;
    if (search != '') params.search = search;

    return apiClientV3.get(`admin/holistic/external_service/${companyId}`, {
      params
    });
  },

  // Get a paginated list of holistic pos stores
  storesListPaginated: (
    search: string = '',
    offset: number,
    limit: number = 10,
    ordering: string = ''
  ) => {
    let params = {
      offset: offset,
      limit: limit
    };
    if (ordering != '') params.ordering = ordering;
    if (search != '') params.search = search;

    return apiClientV3.get('/admin/holistic/stores', {params});
  },

  //create new store
  createStore: (full_name) => {
    return apiClientV3.post(`/admin/holistic/store/`, {full_name});
  },

  //delete store
  deleteStore: (id) => {
    return apiClientV3.delete(`/admin/holistic/store/${id}`);
  },

  //update store
  updateStore: (id, full_name) => {
    return apiClientV3.put(`/admin/holistic/store/${id}`, {full_name});
  },

  //organization add store
  organizationAddStore: (companyId, storeId) => {
    return apiClientV3.post(
      `/admin/holistic/company/${companyId}/store/${storeId}`
    );
  },

  //store details
  getStoreDetails: (id) => {
    return apiClientV3.get(`/admin/holistic/store/${id}`);
  },

  //get store terminals
  getStoreTerminals: (id) => {
    return apiClientV3.get(`/admin/holistic/store/terminals/${id}`);
  },

  // create new terminal
  createTerminal: (id) => {
    return apiClientV3.post(`/admin/holistic/terminal`, {id});
  },

  //add terminal to store
  storeAddTerminal: (storeId, terminalId) => {
    return apiClientV3.post(
      `/admin/holistic/terminal/${terminalId}/store/${storeId}`
    );
  },

  // delete terminal
  deleteTerminal: (id) => {
    return apiClientV3.delete(`/admin/holistic/terminal/${id}`);
  },

  //update terminal
  updateTerminal: (id, full_name) => {
    return apiClientV3.put(`/admin/holistic/terminal/${id}`, {full_name});
  },

  //get operated by options
  getOperatedBy: () => apiClientV3.get('/admin/holistic/operated'),

  //get payment methods
  getPaymentMethods: () => apiClientV3.get('/admin/holistic/methods'),

  //assign external
  linkExternalCompany: (companyId, externalCompanyId, tenant, defaultTenant) =>
    apiClientV3.post(
      `/admin/holistic/${companyId}/assign_external`,
      {
        external_id: externalCompanyId,
        tenant
      },
      {
        params: {primary: defaultTenant || undefined},
        paramsSerializer: (p) => qs.stringify(p)
      }
    ),

  getGoCryptoCompanyList: (companyId, search, limit = 10, offset = 0) =>
    apiClientV3.get(`/admin/holistic/${companyId}/available-gocrypto/`, {
      params: {
        search,
        limit,
        offset
      }
    }),

  linkGoCryptoCompany: (companyId, goCryptoCompanyId) =>
    apiClientV3.get(
      `/admin/holistic/${companyId}/coalesce/${goCryptoCompanyId}`
    )
};
