import React from "react";

export type FormContextProps = {
    errors: Object,
    dataSchema: Object,
    data: Object,
    isLoading: Boolean,
    disabled: [String],
    onInputChange: (path, value) => void
}

const FormContext = React.createContext({
    data: {},
    onInputChange: (string, any) => {},
    dataSchema: {},
    errors: {},
    isLoading: false,
    refreshForm: () => {},
    setDisabled: () => {}
});

export function formContextWrapper(Component){
    return (props) => (
        <FormContext.Consumer>
            {ctx =>
                <Component {...props} context={ctx}/>
            }
        </FormContext.Consumer>
    )
}

export default FormContext;
