import {apiClientV3} from "../util";

export default {

    ledgerList: (exchange_id, search= "", offset = 0, limit = 10, sort = "") => {
        let params = {
            offset: offset,
            limit: limit,
            ordering: sort
        };
        if(search != "") params.search = search;
        return apiClientV3.get(`/admin/exchanges/ledgers/${exchange_id}`, {params})
    },

    tradesList: (trader, search= "", offset = 0, limit = 10, sort = "timestamp") =>{
        let params = {
            offset: offset,
            limit: limit,
            ordering: sort
        };
        if(search != "") params.search = search;

        return apiClientV3.get(`/admin/exchanges/trades?trader=${trader}`, {params: params})
    } ,

    balanceList: (exchange_id) => apiClientV3.get(`/admin/exchanges/balances/${exchange_id}`),

    editNote: (trade_id, note) => apiClientV3.post(`/admin/exchanges/trades`, {trade_id, note}),

    editLedgerNote: (referenceId, note) => apiClientV3.post(`/admin/exchanges/ledgers/`, {referenceId, note}),

    exportLedgerCSV: (exchange_id, date_from, date_to) => apiClientV3.get(`/admin/exchanges/ledgers/export/${exchange_id}?date_from=${date_from}&date_to=${date_to}`)

}



