import {fulfilled, fulfilledState, initState} from "./middleware";
import currency from "../api/currency";

export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_CURRENCY_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getCurrencyList = () => ({
    type: GET_CURRENCY_LIST,
    payload: currency.list()
});


