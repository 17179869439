import React from 'react';
import {
  getProp,
  getDataProp,
  getSafe,
  computeIsRequired,
  getInputConfig,
  getInputData,
  getInputError
} from './util';
// import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {FormFeedback, FormGroup, Label} from 'reactstrap';
import Input from '../../UI/Input/Input';
import ReactSelect from 'react-select';
import 'react-datepicker/dist/react-datepicker.min.css';
import * as classnames from 'classnames';
import {formContextWrapper} from '../FormContext';
import {arrayContextWrapper} from '../ArrayContext';
import type {FormContextProps} from '../FormContext';
import type {ArrayContextProps} from '../ArrayContext';
import Link from '../../UI/Link/Link';

type InputControlProps = {
  label: string,
  outterhtmlClass: string,
  readOnly: boolean,
  allowedClear?: boolean,
  scope: {
    $ref: string
  },
  dependsOn?: string,
  context: FormContextProps,
  array?: ArrayContextProps
};

class InputControl extends React.Component<InputControlProps> {
  state = {
    required: false,
    $ref: '',
    depValue: null
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.scope.$ref !== prevState.$ref ||
      (nextProps.dependsOn &&
        prevState.depValue !==
          getInputData(nextProps.context, nextProps.array, nextProps.dependsOn))
    ) {
      return {
        required: computeIsRequired(
          nextProps.context,
          nextProps.array,
          nextProps.scope.$ref
        ),
        $ref: nextProps.scope.$ref,
        depValue: nextProps.dependsOn
          ? getInputData(
              nextProps.context,
              nextProps.array,
              nextProps.dependsOn
            )
          : null
      };
    }
    return prevState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const context = this.props.context;
    const nextContext = nextProps.context;
    const $ref = this.props.scope.$ref;
    const next$ref = nextProps.scope.$ref;
    const array = this.props.array;
    const nextArray = nextProps.array;
    return (
      getInputData(context, array, $ref) !==
        getInputData(nextContext, nextArray, next$ref) ||
      getInputError(context, array, $ref) !==
        getInputError(nextContext, nextArray, next$ref) ||
      (this.props.dependsOn &&
        nextProps.dependsOn &&
        getInputData(context, array, this.props.dependsOn) !==
          getInputData(
            nextProps.context,
            nextProps.array,
            nextProps.dependsOn
          )) ||
      $ref !== next$ref ||
      context !== nextProps.context ||
      context.isLoading !== nextProps.isLoading ||
      context.disabled != nextProps.context.disabled
    );
  }

  renderInput = () => {
    const {
      context,
      scope: {$ref},
      readOnly,
      allowedClear = true,
      array
    } = this.props;
    const config = getInputConfig(context, array, $ref);
    const value = getInputData(context, array, $ref);
    const error = getInputError(context, array, $ref);
    const inputHandler = array ? array.onInputChange : context.onInputChange;
    try {
      //     if (config.enum){
      //         const options = [...config.enum];
      //         if (allowedClear) {
      //             options.splice(0, 0, null);
      //         }
      //         return (
      //             <ReactSelect value={{value: getSafe(value), label: value}}
      //                          options={options.map(o => ({value: o, label: o}))}
      //                          name={$ref}
      //                          components={{
      //                              Control: ({innerProps, children}) => (
      //                                  <div className={classnames("form-control p-0 d-flex", {"is-invalid": !!error})}
      //                                       {...innerProps}>{children}</div>
      //                              )
      //                          }}
      //                          onChange={e => inputHandler($ref, e.value)}/>
      //         )
      //     }
      //     if (config.type === "boolean") {
      //         return (
      //             <div className="form-check">
      //                 <input defaultChecked={getSafe(value, false)}
      //                        type="checkbox"
      //                        name={$ref}
      //                        onClick={e => inputHandler($ref, !value)}/>
      //             </div>
      //         )
      //     }
      //     if (config.type === "integer") {
      //         return (
      //             <Input name={$ref}
      //                    step="1"
      //                    readOnly={readOnly}
      //                    invalid={!!error}
      //                    type="number"
      //                    onChange={e => inputHandler($ref, e.target.value)}
      //                    value={getSafe(value)}/>
      //         )
      //     }
      return (
        <Input
          name={$ref}
          disabled={
            context.isLoading ||
            context.disabled.indexOf($ref) != -1 ||
            !!this.props.scope.disabled
          }
          readOnly={readOnly}
          invalid={!!error}
          type={this.props.format ? this.props.format : 'text'}
          onChange={(e) => this.handleInput(e)}
          placeholder={`Enter ${this.props.label}`}
          value={getSafe(value)}
        />
      );
    } catch (e) {
      console.error($ref, e);
      return null;
    }
  };

  handleInput = async (e) => {
    const value = e.target.value;
    const {
      context,
      array,
      scope: {to$ref, $ref, theSame}
    } = this.props;
    const inputHandler = array ? array.onInputChange : context.onInputChange;
    if (theSame && to$ref && getInputData(this.props.context, null, theSame)) {
      await inputHandler($ref, value);
      await inputHandler(to$ref, value);
    } else {
      await inputHandler($ref, value);
    }
  };

  render() {
    const {
      context,
      scope: {$ref, disabled},
      label,
      outterhtmlClass,
      array
    } = this.props;
    const error = getInputError(context, array, $ref);

    let labelRender = (
      <label htmlFor={$ref}>
        {label}
        {this.state.required || this.props.required ? (
          <sup className="text-danger">*</sup>
        ) : null}
      </label>
    );
    if (
      context.data.copy &&
      Object.keys(context.data.copy).includes($ref.split('/').pop())
    ) {
      labelRender = (
        <div className="d-flex">
          {labelRender}
          <Link
            className="ml-auto"
            onClick={() =>
              context.onInputChange(
                $ref,
                context.data.copy[$ref.split('/').pop()]
              )
            }
          >
            Insert "{context.data.copy[$ref.split('/').pop()]}"
          </Link>
        </div>
      );
    }

    return (
      <div className={classnames(outterhtmlClass, 'mb-3')}>
        {labelRender}
        {this.renderInput()}
        {disabled && <small>{disabled}</small>}
        {error && <small className="text-danger">{error}</small>}
      </div>
    );
  }
}

export default arrayContextWrapper(formContextWrapper(InputControl));
