import posProvider from "../api/posProvider";
import {fulfilled, fulfilledState, initState} from "./middleware";

export const GET_POS_PROVIDER_LIST = "GET_POS_PROVIDER_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_POS_PROVIDER_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getPosProviderList = () => ({
    type: GET_POS_PROVIDER_LIST,
    payload: posProvider.list()
});
