import React from 'react'
import { Form } from 'reactstrap'

import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'

import './styles.module.css'
import {PermissionGuard} from "../../role-helpers";

const BrandListHeader = props => {
    return (
        <div>
            <div styleName="header" className="d-flex align-items-center">
                <h4>{props.title}</h4>
                <PermissionGuard code="create_franchise">
                    <Button className="ml-auto" color="outline-primary" onClick={props.actionHandler}>{props.actionLabel}</Button>
                </PermissionGuard>
            </div>
            <Form onSubmit={props.onSubmit} styleName="search">
                <Input
                    style={{ width: "256px" }}
                    value={props.value}
                    onChange={props.onChange}
                    name="search"
                    placeholder={props.searchTitle} />
            </Form>
        </div>
    )
}

export default BrandListHeader
