import React, { Fragment, useEffect } from 'react'

import DynamicForm from '../../../DynamicForm/DynamicForm'

import Button from '../../../UI/Button/Button'

import "./panel.module.css";

const contained = (item, items, field) => {
    for(let i=0; i<items.length; i++){
        if(item[field] == items[i][field]){
            return i
        }
    }
    return -1
}

const groupPermissions = (data) => {
    let permissions = []
    data.forEach(item => {
        const index  = contained(item, permissions, 'group')
        if(index != -1){
            if(contained(item, permissions[index].actions, 'code') == -1){
                permissions[index].actions.push(item)
            }
        }else{
            permissions.push({ group: item.group, actions: [item] })
        }
    })
    return permissions;
}

class Panel extends React.Component {

    state = {
        roles: [],
        permissions: []
    }

    componentDidUpdate(prevProps){
        if(this.props.active != prevProps.active){
            if(this.props.active.roles){

                let permissions = []
                this.props.active.roles.map(role => {
                    permissions = [...permissions, ...role.permissions]
                })

                this.setState({ 
                    roles: [{
                        group: 'Roles',
                        actions: this.props.active.roles
                    }],
                    permissions: groupPermissions(permissions)
                })
            }
        }
    }

    render(){

        const active = this.props.active

        if(!active) return null;

        if(active.name == 'Permissions'){


            return (
                <div styleName="panel">
                    <div styleName="body">
                        <h3 styleName="header" className="pb-5 pt-3">Permissions</h3>
                        <div className="d-flex justify-content-between">
                            <div styleName="col">
                                <p>Roles</p>
                                <div styleName="data">
                                    {this.state.roles.map(role => (
                                        <Fragment key={role.group}>
                                            <div styleName="level">{role.group}</div>
                                            {role.actions.map((item, i) => {
                                                return <div key={`${item.id}--${i}`} styleName="item">
                                                    {item.name}
                                                </div>
                                            })}
                                        </Fragment>
                                    ))}
                                    {/* {Object.keys(roles).map(role => (
                                        <Fragment key={role}>
                                            <div styleName="level">{role}</div>
                                            {roles[role].map((item, i) => {
                                                return <div key={`${item}--${i}`} styleName="item">
                                                    {item}
                                                </div>
                                            })}
                                        </Fragment>
                                    ))} */}
                                </div>
                            </div>
                            <div className="p-3"></div>
                            <div styleName="col">
                                <p>Permissions</p>
                                <div styleName="data">
                                    {this.state.permissions.map(permission => (
                                        <Fragment key={permission.group}>
                                            <div styleName="level">{permission.group}</div>
                                            {permission.actions.map((item, i) => {
                                                return <div key={`${item.code}--${i}`} styleName="item">
                                                    {item.display_name}
                                                </div>
                                            })}
                                        </Fragment>
                                    ))}
                                    {/* {Object.keys(permissions).map(permission => (
                                        <Fragment key={permission}>
                                            <div styleName="level">{permission}</div>
                                            {permissions[permission].map((item, i) => {
                                                return <div key={`${item}--${i}`} styleName="item">
                                                    {item}
                                                </div>
                                            })}
                                        </Fragment>
                                    ))} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        
        return (
            <Fragment>
                <div styleName="panel">
                    <DynamicForm 
                        isLoading={this.props.isLoading}
                        onSubmit={active.onSubmit}
                        errors={this.props.errors}
                        wrappers={(dynamicForm ) => (
                            <React.Fragment>
                                <div styleName="body">
                                    <h3 styleName="header" className="pb-5 pt-3" >{active.name}</h3>
                                    {dynamicForm}
                                </div>
                                <div styleName="footer" className="d-flex" >
                                    <Button color="primary" isLoading={this.props.isLoading} className="mr-2 ml-auto">Save</Button>
                                </div>
                            </React.Fragment>)
                        }
                        initData={{ 
                            timezones: [
                                { value: 'UTC', label: 'Europe' },
                            ], languages: [
                                { value: 'eng', label: "English" }
                            ], 
                            ...active.schema.initData 
                        }}
                        uiSchema={active.schema.ui}
                        dataSchema={active.schema.data}
                    />
                </div>
            </Fragment>
        )
    }
}

export default (Panel)