import {fulfilled, fulfilledState, initState} from './middleware';
import company from '../api/V3/company';

export const GET_PAYFAC_LIST = 'GET_PAYFAC_LIST';

export default (state = initState, action) => {
  switch (action.type) {
    case fulfilled(GET_PAYFAC_LIST):
      return fulfilledState(state, [
        {
          name: 'Naka'
        },
        ...action.payload.data
      ]);
    default:
      return state;
  }
};

export const getPayfacList = () => ({
  type: GET_PAYFAC_LIST,
  payload: company.getPayfac()
});
