import { apiClientV3 } from "../util";

const exportRequest = (report_type: 'store_report' | 'company_report', format_type = 0) => (search, ordering, filter = {}) => {
    if (filter.approval_state) {
        filter.approval_status = filter.approval_state
        delete filter.approval_state
    }
    filter.timezone_offset = new Date().getTimezoneOffset()
    return apiClientV3.get(`/reports/generate`, {
        params: {
            report_type, search, ordering, ...filter, format_type
        }, responseType: 'arraybuffer'
    })
}

export default {
    exportSourceList: exportRequest('store_report', 0),
    exportCompanyList: exportRequest('company_report', 0)
}
