import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import { push } from 'connected-react-router';
import { listHeader, listHeaders, renderEditUser, renderCreated, renderName } from "../Util/listView";
import { getSafe, getSafeDeep } from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';

import Page from '../UI/Page/Page'

import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Check, Close } from '@material-ui/icons'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import More from '../UI/More/More'
import { notifyError } from '../Util/notification';
import { hasPermission } from "../Util/role-helpers";
import { Helmet } from "react-helmet";

import withdrawal from "../../api/V3/withdrawal";
import WithdrawalDialog from "./WithdrawalDialog";

const ListBodyWithLoading = WithLoading(ListBody);

class WithdrawalList extends React.Component {

    getActions = (data) => {

        const StyledListItemText = withStyles({
            root: {
                fontSize: "16px!important"
            }
        })(ListItemText)

        const StyledIcon = (Icon) => {
            return withStyles({
                root: {
                    fontSize: "24px"
                }
            })(Icon)
        }

        const StyledListItemIcon = withStyles({
            root: {
                minWidth: 24,
                marginRight: "16px"
            }
        })(ListItemIcon)

        const StyledApprove = StyledIcon(Check)
        const StyledDecline = StyledIcon(Close)

        const items = []

        if (hasPermission(this.props.user, '')) {
            items.push({
                handler: () => {
                    this.setState({ withdrawal: data })
                    this.toggleDialog("approve")
                },
                render: () => (<Fragment><StyledListItemIcon><StyledApprove /></StyledListItemIcon><StyledListItemText disableTypography primary="Approve" /></Fragment>)
            })
        }

        if (hasPermission(this.props.user, '')) {
            items.push({
                handler: () => {
                    this.setState({ withdrawal: data })
                    this.toggleDialog('decline')
                },
                render: () => (<Fragment><StyledListItemIcon><StyledDecline /></StyledListItemIcon><StyledListItemText disableTypography primary="Decline" /></Fragment>)
            })
        }

        return {
            items
        }
    }

    withdrawalListHeaders = [
        listHeader('Company', "company", false),
        listHeader('Wallet', "wallet", false),
        listHeader('Amount', "amount", false, (data) => `${data.amount}${this.props.currencies.find(c => c.id == data.currency).display_symbol}`, undefined, undefined, "center"),
        listHeader('Local Amount', "amount", false, (data) => `${getSafeDeep(data, 'extra.local_amount')}${this.props.currencies.find(c => c.id == getSafeDeep(data, 'extra.local_currency')).display_symbol}`, undefined, undefined, "center"),
        listHeader('Date', "created", false, (data) => renderCreated(data), undefined, undefined, "center"),
        listHeader('Actions', 'actions', false, (data) => (<More {...this.getActions(data)} />), undefined, undefined, "right")
    ];

    state = {
        isLoading: true,
        rowsPerPage: 10,
        sort: '',
        count: 0,
        approveDialogOpen: false,
        declineDialogOpen: false,
        withdrawal: undefined,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, prevState) {
        const { query, auth } = this.props;
        const { query: oldQuery, auth: oldAuth } = oldProps;
        if (!immutable.is(query, oldQuery) || this.state.rowsPerPage != prevState.rowsPerPage) {
            this.loadData();
        }
    }

    toggleDialog = (dialogName) => {
        const fieldName = `${dialogName}DialogOpen`;
        this.setState({ [fieldName]: !this.state[fieldName] });
    };

    loadData = async () => {
        // let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        try {
            const query = this.props.query
            const search = query.search;
            let sort = query.sort ? query.sort : "";
            const page = query.page || 0;
            query.sort = sort;
            this.setState({ isLoading: true });
            const { data: data } = await withdrawal.get(search, page * this.state.rowsPerPage, this.state.rowsPerPage, sort);

            let count = data.length
            this.setState({ count, data, isLoading: false });
            if (query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
        } catch (err) {
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = { ...this.props.query };
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    requestSort = (sort) => {
        this.setState({ sort });
        const query = { ...this.props.query };
        query.page = 0;
        query.sort = sort
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    render() {
        const { isLoading, data, rowsPerPage, count } = this.state;

        return <Fragment>
            <Helmet>
                <title>Withdrawals | Elicon</title>
            </Helmet>
            <Page permission={'list_pending_withdraws'}>
                <Card>
                    <ListHeader
                        type="OnlySearch"
                        title="Withdrawal Confirmations (over 1000€)"
                        searchTitle="Search"
                        noBtn={true}
                    />
                    <ListBodyWithLoading
                        sortField={this.state.sort}
                        requestSort={this.requestSort}
                        headers={this.withdrawalListHeaders}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                    <WithdrawalDialog currencies={this.props.currencies} loadData={() => this.loadData()} toggle={() => this.toggleDialog('approve')} reason={'approve'} isOpen={this.state.approveDialogOpen} withdrawal={this.state.withdrawal} />
                    <WithdrawalDialog currencies={this.props.currencies} loadData={() => this.loadData()} toggle={() => this.toggleDialog('decline')} reason={'decline'} isOpen={this.state.declineDialogOpen} withdrawal={this.state.withdrawal} />

                </Card>
            </Page>
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        user: state.user.data,
        currencies: state.currencies.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(WithdrawalList));
