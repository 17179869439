import {apiClientV3} from './util';

export default {
  list: (
    kyb_status: number,
    search: string,
    ordering: Array<
      | 'kyb_status'
      | 'business_type'
      | 'full_name'
      | 'id'
      | 'representatives__last_updated'
      | 'last_updated'
    >,
    offset: number,
    limit: number = 10
  ) =>
    apiClientV3.get('/admin/company-kyb/', {
      params: {
        search,
        ordering,
        kyb_status,
        offset,
        limit
      }
    }),
  get: (id) => apiClientV3.get(`/admin/company-kyb/${id}/`),
  create: (id, data) => apiClientV3.post(`/admin/company-kyb/${id}/`, data),
  update: (id, data) => apiClientV3.put(`/admin/company-kyb/${id}/`, data),
  approve: (id) => apiClientV3.post(`/admin/company-kyb/${id}/approve`),
  reject: (id, reason) =>
    apiClientV3.post(`/admin/company-kyb/${id}/reject`, {reason}),
  setCallMissed: (id) =>
    apiClientV3.post(`/admin/company-kyb/${id}/call_missed`),
  documents: (company_id) => ({
    list: () => apiClientV3.get(`/admin/company-kyb/${company_id}/documents`),
    create: (data) =>
      apiClientV3.post(`/admin/company-kyb/${company_id}/documents`, data),
    get: (id) =>
      apiClientV3.get(`/admin/company-kyb/${company_id}/documents/${id}`),
    delete: (id) =>
      apiClientV3.delete(`/admin/company-kyb/${company_id}/documents/${id}`)
  }),
  owners: (company_id) => ({
    list: () => apiClientV3.get(`/admin/company-kyb/${company_id}/owners`),
    create: (data) =>
      apiClientV3.post(`/admin/company-kyb/${company_id}/owners`, data),
    get: (id) =>
      apiClientV3.get(`/admin/company-kyb/${company_id}/owners/${id}`),
    update: (id, data) =>
      apiClientV3.put(`/admin/company-kyb/${company_id}/owners/${id}`, data),
    delete: (id) =>
      apiClientV3.delete(`/admin/company-kyb/${company_id}/owners/${id}`)
  })
};
