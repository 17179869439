import React from 'react'
import ReactSelect from "react-select";
import {computeIsRequired, getDataProp, getInputConfig, getInputData, getInputError, getProp} from "./util";
import * as classnames from "classnames";
import {formContextWrapper} from "../FormContext";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import type {FormContextProps} from "../FormContext";
import type {ArrayContextProps} from "../ArrayContext";
import {arrayContextWrapper} from "../ArrayContext";

class TheSame extends React.Component<SelectProps> {
    state = {
        required: false,
        $ref: "",
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scope.$ref !== prevState.$ref) {
            return {
                required: computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref),
                $ref: nextProps.scope.$ref
            }
        }
        return prevState;
    }

    getValue = () => {
        const {context, array, scope: {$ref}, arrayOptions} = this.props;
        const config = getInputConfig(context, array, $ref);
        const dataValue = getInputData(context, array, $ref);
        if(dataValue === undefined) return !!config.default

        return !!dataValue;
    };

    shouldComponentUpdate(nextProps, nextState) {
        const context = this.props.context;
        const nextContext = nextProps.context;
        const $ref = this.props.scope.$ref;
        const next$ref = nextProps.scope.$ref;
        const array = this.props.array;
        const nextArray = nextProps.array;
        try{
            return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) ||
                getDataProp(context.data, this.props.arrayOptions.$ref) !== getDataProp(nextContext.data, nextProps.arrayOptions.$ref) ||
                $ref !== next$ref
        } catch (e) {
            return true
        }
    }

    componentDidMount(){
        if(this.getValue()){
            this.props.context.setDisabled(this.props.disabled)
        }else {
            this.props.context.setDisabled([])
        }
    }

    onClick = () => {
        const val = !this.getValue()
        this.props.context.onInputChange(this.props.scope.$ref, val)
        if(val){
            this.props.context.setDisabled(this.props.disabled)
        }else{
            this.props.context.setDisabled([])
        }
    }

    render() {
        const {scope: {$ref}, context, label} = this.props;

        return (
            <label htmlFor="$ref" onClick={this.onClick}>
                <input 
                    className="mr-2"
                    name={this.props.$ref ? this.props.$ref : this.props.name} 
                    type="checkbox" 
                    checked={this.getValue()} onChange={() => {}} ></input> 
                {label}
            </label>
        )
    }
}

export default arrayContextWrapper(formContextWrapper(TheSame))
