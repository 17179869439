import React, { Fragment } from 'react'

import './page.module.css'
import { hasPermission, mapRoleState, hasRole } from "../../Util/role-helpers";
import { connect } from "react-redux";
import Card from "../Card/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "react-router-dom/Link";
import { withRouter } from "react-router-dom";
import { routeNames } from "../../Util/routing";
import { getSafeDeep } from "../../Util/state";
import { HomeOutlined } from '@material-ui/icons'
import qs from "query-string";

const capitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());


const Page = props => {
    const renderForbidden = () => (
        <Card>
            <CardContent>
                <h1 style={{ textAlign: 'center' }}>
                    Access denied
                </h1>
            </CardContent>
        </Card>
    )
    const pathParts = props.match.path.split("/")
    const urlParts = props.match.url.split("/")
    pathParts.shift()
    urlParts.shift()
    if (pathParts[pathParts.length - 1] === "") {
        pathParts.pop()
        urlParts.pop()
    }
    if (props.query.tier) {
        pathParts.push(getSafeDeep(props.tiers.find(t => t.id == props.query.tier), "name", ""))
    }
    return (
        <div styleName="page">
            <div styleName="header">
                {props.title && <h3 styleName="title">{props.title}</h3>}
                {pathParts[0] !== "" && (
                    <p>
                        <Link to="/" styleName="breadcrumb-link"><HomeOutlined /></Link>
                        {pathParts.map((part, i) => {
                            const pathName = getSafeDeep(routeNames, `/${pathParts.slice(0, i + 1).join('/')}`)
                            return (
                                <Fragment key={i}>
                                    <span styleName="breadcrumb-divider">&ndash;</span>
                                    {i < pathParts.length - 1 ? (
                                        pathName ? (
                                            <Link styleName="breadcrumb-link"
                                                to={`/${urlParts.slice(0, i + 1).join('/')}`}>{pathName}</Link>
                                        ) : (
                                                <span>{capitalize(part.replace('-', ' '))}</span>
                                            )
                                    ) : (
                                            <span>{getSafeDeep(routeNames, `/${pathParts.slice(0, i + 1).join('/')}`, capitalize(part.replace('-', ' ')))}</span>
                                        )}
                                </Fragment>
                            )
                        })}
                    </p>
                )}
            </div>
            {props.roles ? (hasRole(props.user, props.roles) ? props.children : renderForbidden()) : (hasPermission(props.user, props.permission) ? props.children : renderForbidden())}
        </div>
    )
}

const mapState = state => {
    return {
        user: state.user.data,
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        tiers: state.tiers.data
    }
}

export default withRouter(connect(mapState)(Page));
