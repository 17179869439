import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createMessage} from '../Util/notification';
import DynamicForm from '../DynamicForm/DynamicForm';
import {parseErrors} from '../Util/form';
import addReferralData from './schema/addReferralData';
import addReferralUi from './schema/addReferralUI';
import referral from '../../api/V3/referral';

class AddReferralDialog extends React.Component {
  state = {
    errors: undefined,
    isLoading: false
  };

  componentDidMount() {
    this.loadSchema();
  }

  componentDidUpdate() {}

  onSubmit = async (data) => {
    try {
      this.setState({isLoading: true});
      await referral.add(data.form);
      createMessage('Successfully created referral');
      this.props.reloadData();
      this.props.toggle();
      this.setState({isLoading: false});
    } catch (e) {
      console.error(e);
      this.setState({isLoading: false, errors: parseErrors(e)});
    }
  };

  loadSchema = async () => {
    const uiSchema = addReferralUi;
    const dataSchema = addReferralData;
    this.setState({uiSchema, dataSchema});
  };

  render() {
    const {toggle, isOpen, currencies} = this.props;
    const {errors, isLoading} = this.state;
    if (!isOpen) return null;

    const modal = {
      toggle,
      isLoading,
      isOpen,
      errors,
      title: 'Add Referral',
      size: 'md'
    };

    return (
      <DynamicForm
        uiSchema={addReferralUi}
        dataSchema={addReferralData}
        initData={{currencies}}
        modal={modal}
        isLoading={this.state.isLoading}
        errors={errors}
        onSubmit={(data) => {
          this.onSubmit(data);
        }}
      />
    );
  }
}

function mapState(state) {
  return {
    currencies: state.sourceLang.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(AddReferralDialog));
