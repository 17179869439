import React, {useEffect, useState} from 'react'

import {IconButton} from '@material-ui/core';
import {Send, Delete} from "@material-ui/icons"
import {withStyles} from '@material-ui/core/styles'

import cx from 'classnames'

import './notes.module.css'
import {renderFullDate} from "../../../../../Util/listView";
import notes from '../../../../../../api/V3/notes'
import {createMessage} from "../../../../../Util/notification";
import {getSafeDeep} from "../../../../../Util/state";
import uuid from 'uuid/v4'
import {connect} from "react-redux";
import type {User} from "../../../../../../api/model/user";
import {PermissionGuard} from "../../../../../Util/role-helpers";

const Input = props => {

    const StyledIconButton = withStyles({
        root: {
            background: "#5867dd",
            color: "#fff"
        }
    })(IconButton)

    const {value, onChange, onSubmit} = props

    return (
        <form className="d-flex" onSubmit={onSubmit}>
            <input placeholder="Type here ..." value={value} onChange={onChange} type="text" className="flex-1 mr-3" styleName="input"/>
            <StyledIconButton type="submit">
                <Send/>
            </StyledIconButton>
        </form>
    )
}

const SingleNote = props => {

    const StyledIconButton = withStyles({
        root: {
            "&:hover": {
                background: "transparent",
            },
            color: "#000",
            fontSize: "24px"
        }
    })(IconButton)

    const StyledDelete = withStyles({
        root: {
            color: "#000",
            fontSize: "24px"
        }
    })(Delete)

    const {note, onDeleteClick} = props

    return (
        <div styleName="singleNote" className={props.index == notes.length - 1 ? "border-0 pb-3" : ""}>
            <div>
                <div className="d-flex align-items-center">
                    <span className="mr-2" styleName="author">{note.user_name}</span>
                    <span styleName="time">{renderFullDate(note.created)}</span>
                </div>
                <p styleName="text">{note.note}</p>
            </div>
            <PermissionGuard code={'delete_note'}>
                <div styleName="del" className="ml-auto">
                    <StyledIconButton onClick={onDeleteClick}>
                        <StyledDelete/>
                    </StyledIconButton>
                </div>
            </PermissionGuard>
        </div>
    )
}

const Notes = ({organization, user}) => {
    const [noteList, setNoteList] = useState([])
    const [newNote, setNewNote] = useState("")
    const loadNotes = async () => {
        const {data} = await notes.list(organization.id)
        setNoteList(data)
    }
    useEffect(() => {
        loadNotes()
    }, [organization.id])

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            await notes.create({
                note: newNote,
                foreign_id: organization.id,
                user_id: uuid(),
                user_name: `${user.first_name} ${user.last_name}`
            })
            await loadNotes()
            setNewNote("")
        } catch (e) {
            createMessage(getSafeDeep(e, "response.data.message", e.message), 'error')
        }
    }

    const onDeleteClick = async (note) => {
        try {
            await notes.delete(note.id)
            await loadNotes()
        } catch (e) {
            createMessage(getSafeDeep(e, "response.data.message", e.message), 'error')
        }
    }

    return (
        <>
            {noteList.map((note, i) => (
                <SingleNote index={i} key={note.id} note={note} onDeleteClick={() => onDeleteClick(note)}/>
            ))}
            {!noteList.length && <p className="text-center">No notes yet.</p>}
            <div styleName="separator"/>
            <PermissionGuard code={'create_note'}>
                <Input value={newNote} onChange={e => setNewNote(e.target.value)} onSubmit={onSubmit}/>
            </PermissionGuard>
        </>
    )
}

const mapState = state => {
    return {
        user: state.user.data
    }
}

export default connect(mapState)(Notes);
