import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames'

import { clearProps } from '../../Util/types'

import './button.module.css'

const useStyles = makeStyles(theme => ({
    root: {
        padding: "1rem",
        color: "inherit !important"
    },
}));

const Button = (passedProps) => {

    const props = clearProps(passedProps, ["isLoading"])

    const classes = useStyles();

    if(props.href){
        return (
            <div className="d-inline-block">
                <a disabled={passedProps.isLoading} {...props} styleName={cx("button", passedProps.color)} >
                    {passedProps.isLoading ? <CircularProgress className={classes.root} /> : passedProps.children}
                </a>
            </div>
        )
    }
    
    return (
        <button disabled={passedProps.isLoading || passedProps.disabled} {...props} styleName={cx("button", passedProps.color)}>
            {passedProps.isLoading ? <CircularProgress className={classes.root} /> : passedProps.children}
        </button>
    );
};

export default Button;
