import React from 'react'

import './styles.module.css'

export default props => {

    const { question } = props

    return (
        <div>
            <div styleName="header" className="d-flex align-items-center">
                <h4>{props.title}</h4>
            </div>
            <div styleName="others">
                <p styleName={"field"}>Question</p>
                <h6 className="mb-5">{question.value}</h6>

                <p styleName={"field"}>Reoccurring</p>
                <p className="mb-5">{question.repeatable ? "Yes" : "No"}</p>
                
                <p styleName={"field"}>Predefined answers</p>
                {question.predefined_answers && question.predefined_answers.map(answer => {
                    return <p key={`${answer.value}-${answer.enum}`} ><b>{answer.enum}) &nbsp; </b>{answer.value}</p>
                })}
            </div>
        </div>
    )
}