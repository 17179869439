import React, { useState } from 'react'

import './keywords.module.css'

import { Chip } from '@material-ui/core'

const Keywords = props => {

    const { list } = props
    const [input, setInput] = useState('')

    const submitHandler = (e) => {
        if(e.key=="Enter"){
            e.preventDefault();
            props.addItem(input)
            setInput('')
        }
    }

    return (
        <div className="d-flex flex-wrap" styleName="keywords">
            {list && list.map((item, i) => {
                return <Chip className="m-2" key={i} label={item} onDelete={!props.disabled ? e => props.removeItem(item) : undefined}/>
            })}
            <input disabled={props.disabled} name={props.$ref} styleName="input" value={input} onKeyPress={submitHandler} onChange={e => setInput(e.target.value)} type="text">
            </input>
        </div>
    )
}

export default Keywords;
