import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SideNav from "../SideNav/SideNav";
import { Col, Row } from "reactstrap";
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import { push } from 'connected-react-router';
import { getSafe, getSafeDeep } from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';
import AddNotificationDialog from "./AddNotificationDialog";

import Page from '../UI/Page/Page'
import { Card } from '@material-ui/core'
import { Helmet } from "react-helmet";


class BroadcastNotifications extends React.Component {

    state = {
        isLoading: true,
        addNotificationDialogOpen: false,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps) {
        const { query, auth } = this.props;
        const { query: oldQuery, auth: oldAuth } = oldProps;
        if (!immutable.is(query, oldQuery)) {
            this.loadData();
        }
    }

    toggleDialog = (dialogName) => {
        const fieldName = `${dialogName}DialogOpen`;
        this.setState({ [fieldName]: !this.state[fieldName] });
    };

    loadData = async () => {

    };

    render() {
        const { isLoading, data, pages } = this.state;

        return <Fragment>
            <Helmet>
                <title>Broadcast Notifications | Elicon</title>
            </Helmet>
            <Page roles={['System Administrator', 'MKTG Questioner']}>
                <Card>
                    <ListHeader
                        title="Broadcast Notifications"
                        actionHandler={() => this.toggleDialog("addNotification")}
                        actionLabel="Broadcast notification"
                    />
                </Card>
            </Page>
            <AddNotificationDialog
                isOpen={this.state.addNotificationDialogOpen}
                toggle={() => this.toggleDialog("addNotification")}
                reloadData={() => this.loadData()}
            />
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(BroadcastNotifications));
