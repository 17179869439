import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListBody from "../../Util/ListView/ListBody/ListBody";
import WithLoading from "../../Util/HOC/WithLoading";
import qs from "query-string";
import { push } from "connected-react-router";
import {
  listHeader,
  renderCurrencyPair,
  renderFullDate,
} from "../../Util/listView";
import ListHeader from "../../Util/ListView/ListHeader/ListHeader";
import immutable from "immutable";

import Page from "../../UI/Page/Page";

import { Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Edit, Delete } from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import More from "../../UI/More/More";
import { notifyError } from "../../Util/notification";
import { Helmet } from "react-helmet";
import exchange from "../../../api/V3/exchange";
import EditNoteDialog from "./EditNoteDialog/EditNoteDialog";

const ListBodyWithLoading = WithLoading(ListBody);

class TradeList extends React.Component {
  getActions = (data) => {
    const StyledListItemText = withStyles({
      root: {
        fontSize: "16px!important",
      },
    })(ListItemText);

    const StyledIcon = (Icon) => {
      return withStyles({
        root: {
          fontSize: "24px",
        },
      })(Icon);
    };

    const StyledListItemIcon = withStyles({
      root: {
        minWidth: 24,
        marginRight: "16px",
      },
    })(ListItemIcon);

    const StyledEdit = StyledIcon(Edit);
    const StyledDelete = StyledIcon(Delete);

    const items = [];

    items.push({
      handler: () => {
        this.setState({ trade_id: data.trade_id, note: data.note });
        this.toggleDialog("edit");
      },
      render: () => {
        return (
          <Fragment>
            <StyledListItemIcon>
              <StyledEdit />
            </StyledListItemIcon>
            <StyledListItemText disableTypography primary="Edit" />
          </Fragment>
        );
      },
    });

    return {
      items,
    };
  };

  tradeListHeaders = [
    listHeader(
      "Date",
      "timestamp",
      false,
      (data) => renderFullDate(data.timestamp),
      undefined,
      undefined,
      "left"
    ),
    listHeader(
      "Pair",
      "symbol",
      false,
      (data) =>
        renderCurrencyPair(data.symbol, this.props.currencies, data.side),
      undefined,
      undefined,
      "left"
    ),
    listHeader(
      "Side",
      "side",
      true,
      (data) => String(data.side).toUpperCase(),
      undefined,
      undefined,
      "left"
    ),
    listHeader(
      "Price",
      "price",
      false,
      (data) => `${data.price} ${String(data.symbol).substring(3)}`,
      undefined,
      undefined,
      "right"
    ),
    listHeader(
      "Sell",
      "cost",
      false,
      (data) => `${data.cost} ${String(data.symbol).substring(3)}`,
      undefined,
      undefined,
      "right"
    ),
    listHeader(
      "Buy",
      "amount",
      false,
      (data) => `${data.amount} ${String(data.symbol).substring(0, 3)}`,
      undefined,
      undefined,
      "right"
    ),
    listHeader(
      "Fee",
      "fee",
      false,
      (data) => `${data.fee} ${String(data.symbol).substring(3)}`,
      undefined,
      undefined,
      "right"
    ),
    listHeader("Note", "note", false, undefined, undefined, undefined, "left"),
    listHeader(
      "Actions",
      "actions",
      false,
      (data) => <More {...this.getActions(data)} />,
      undefined,
      undefined,
      "right"
    ),
  ];

  state = {
    trader: this.props.location.pathname.split("/")[3],
    trade_id: undefined,
    editDialogOpen: false,
    isLoading: false,
    rowsPerPage: 10,
    sort: "",
    count: 0,
    data: [
      {
        date: "10.04.2020 16:36",
        pair: "EUR -> BCH",
        price: "210.80000",
        sell: "5.09156600",
        buy: "0.02415354",
        fee: "0.01222",
        note: "Note",
      },
    ],
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(oldProps, prevState) {
    const { query, auth } = this.props;
    const { query: oldQuery, auth: oldAuth } = oldProps;
    if (
      !immutable.is(query, oldQuery) ||
      this.state.rowsPerPage != prevState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  toggleDialog = (dialogName) => {
    const fieldName = `${dialogName}DialogOpen`;
    this.setState({ [fieldName]: !this.state[fieldName] });
  };

  loadData = async () => {
    let query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    try {
      const query = this.props.query;
      const search = query.search;
      let sort = query.sort ? query.sort : "-timestamp";
      const page = query.page || 0;
      query.sort = sort;
      this.setState({ isLoading: true });
      const {
        data: { count, results: data },
      } = await exchange.tradesList(
        this.state.trader,
        search,
        page * this.state.rowsPerPage,
        this.state.rowsPerPage,
        sort
      );
      this.setState({ count, data, isLoading: false });
      if (query.page > Math.ceil(count / this.state.rowsPerPage))
        query.page = 0;
    } catch (err) {
      this.setState({ isLoading: false });
      notifyError(err.message);
    }
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    let currentItem =
      qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page *
        this.state.rowsPerPage || 0 + 1;
    let newPage = Math.floor(currentItem / newRowsPerPage);
    this.setState({ rowsPerPage: newRowsPerPage });
    const query = { ...this.props.query };
    query.page = newPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  requestSort = (sort) => {
    this.setState({ sort });
    const query = { ...this.props.query };
    query.page = 0;
    query.sort = sort;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  render() {
    const { isLoading, data, rowsPerPage, count } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Trades | Elicon</title>
        </Helmet>
        <Page permission={""}>
          <Card>
            <ListHeader
              type="OnlySearch"
              title="Trades"
              searchTitle="Filter"
              noBtn={true}
            />
            <ListBodyWithLoading
              sortField={this.state.sort}
              requestSort={this.requestSort}
              headers={this.tradeListHeaders}
              isLoading={isLoading}
              data={data}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
          </Card>
        </Page>
        <EditNoteDialog
          trade_id={this.state.trade_id}
          toggle={() => this.toggleDialog("edit")}
          isOpen={this.state.editDialogOpen}
          reloadData={() => this.loadData()}
          note={{ note: this.state.note }}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
    user: state.user.data,
    currencies: state.currencies.data,
  };
}

function mapActions(dispatch) {
  return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(TradeList));
