// @flow
import type {Source} from "./source";
import type {WorkingHours} from "./source";
import {deepCopy} from "../../components/Util/state";

export type Wallet = {
    active: boolean,
    address: string,
    label: string,
    network: string,
    transaction_type: string
}

export type User = {
    id: number,
    email: string,
    first_name: string,
    last_name: string,
    is_staff: boolean,
    is_superuser: boolean,
    source: Array<Source>,
    wallets: Map<String, Wallet>,
    restricted: boolean
}

export const transformUserData = (res: any) => {
    const data = res.data;
    data.wallets = data.wallets.reduce((wallets, wallet: Wallet) => ({...wallets, [wallet.crypto_currency]: wallet}), {});
    data.source.forEach((source) => {
        source.working_hours = source.working_hours.reduce((wh, hour: WorkingHours) => ({...wh, [hour.day_of_the_week]: {...hour}}), {})
    });
    return res;
};


export const filterUserData = (data) => {
    const newData = deepCopy(data);
    if (data.profile.date_and_time == "") delete newData.profile.date_and_time;
    return newData;
};
