import React, { useState, useEffect } from "react";
import Modal from "../UI/Modal/Modal";
import Input from "../UI/Input/Input";
import { createMessage, notifyError } from "../Util/notification";
import Select from "../UI/Select/Select";
import { hasPermission } from "../Util/role-helpers";
import CheckBox from "../UI/CheckBox/CheckBox";

const LinkExternalCompanyModal = ({
  defaultValue,
  isOpen,
  toggle,
  onSubmit,
  reloadData,
  defaultTenant,
  operatedByList
}) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [externalCompanyId, setExternalCompanyId] = useState("");
  const [tenant, setTenant] = useState(defaultTenant);
  const [isDefaultTenant, setIsDefaultTenant] = useState(false);

  useEffect(() => {
    setExternalCompanyId(defaultValue);
    setError("");
  }, [isOpen]);

  const submitHandler = async () => {
    if (typeof externalCompanyId === 'string' && externalCompanyId.trim().length === 0) {
      return setError("This field is required");
    }

    try {
      setIsLoading(true);
      await onSubmit(externalCompanyId, tenant.id, isDefaultTenant);
      setIsLoading(false);
      createMessage(`New link created`);
      toggle();
      reloadData();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      notifyError(err.message);
    }
  };

  const loadTenants = (_, callback) => {
    callback(operatedByList);
  };

  return (
    <Modal
      title={`Link external company`}
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={submitHandler}
      isLoading={isLoading}
    >
      <div>
        <label htmlFor="reason">External company id</label>
        <Input
          placeholder="External company id"
          value={externalCompanyId}
          required={true}
          defaultValue={undefined}
          onChange={(e) => setExternalCompanyId(e.target.value)}
        />

        <p className="mt-4 mb-2">Tenant:</p>
        <Select
          async
          cacheOptions
          defaultOptions
          required={true}
          loadOptions={loadTenants}
          getOptionLabel={(tenant) => tenant.name}
          getOptionValue={(tenant) => tenant.id}
          onChange={(tenant) => setTenant(tenant)}
          value={tenant}
        />
        <CheckBox
          className="mt-3"
          onClick={(e) => {
            e.preventDefault();
            setIsDefaultTenant(!isDefaultTenant);
          }}
          checked={isDefaultTenant}
          label="Default tenant"
        />

        <small className="text-danger">{error}</small>
      </div>
    </Modal>
  );
};

export default LinkExternalCompanyModal;
