import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import connect from "react-redux/es/connect/connect";
import {getActiveClass, mainMenu} from "../Util/routing";
import {Button} from "reactstrap";
import React from "react";
import "./mainMenu.module.css";
import {Link, NavLink} from "react-router-dom";
import {withRouter} from "react-router";
import * as classnames from "classnames";
import qs from "qs";

const MainMenuStructure = ({push, ...props}) => (
    <ul styleName="main-list">
        {mainMenu && mainMenu.map((menuItem, menuIndex) => {
            return <li key={`parentLink-${menuIndex}`} className="mb-3">
                <div className="d-flex w-100 justify-content-center position-relative">
                    <hr styleName="strike-title" className="position-absolute w-100"/>
                    <div styleName="title" className="px-3 font-weight-bold dark-gray-text">{menuItem.label}</div>
                </div>
                <ul styleName="sub-list">
                    {menuItem.subLinkMapping && props[menuItem.subLinkMapping] && props[menuItem.subLinkMapping].map((subLinkItem, subLinkIndex) => {
                            return <li key={`${menuItem.subLinkMapping}-${subLinkIndex}`}>

                                <Link styleName={getActiveClass(props.fullRoute, `${menuItem.link}?tier=${subLinkItem.id}`, 'active-link')} label={subLinkItem.name} to={`${menuItem.link}?tier=${subLinkItem.id}`}>
                                    <Button styleName="link" color="outline-info" className=" px-2 mt-2">
                                        {subLinkItem.name}
                                    </Button>
                                </Link>
                            </li>
                        }
                    )}
                    {menuItem.subLinks && menuItem.subLinks.map((subLinkItem, subLinkIndex) => {
                            return <li key={`subLink-${subLinkIndex}`}>
                                <Link styleName={getActiveClass(props.fullRoute, `${subLinkItem.link}`, 'active-link')} label={subLinkItem.label} to={subLinkItem.link}>
                                    <Button styleName="link" color="outline-info" className=" px-2 mt-2">
                                        {subLinkItem.label}
                                    </Button>
                                </Link>
                            </li>
                        }
                    )}
                </ul>


            </li>
        })}
    </ul>
);

const mapState = state => ({
    tiers: state.tiers.data,
    query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
    fullRoute: `${state.router.location.pathname}${state.router.location.search}`,
});
const mapActions = dispatch => bindActionCreators({push}, dispatch);

export default connect(mapState, mapActions)(MainMenuStructure);
