import React, {useState} from 'react';

import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateUtils from '@date-io/moment';
import {createMuiTheme} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import './styles.picker.css';

const theme = createMuiTheme({
  overrides: {
    MuiPopover: {
      root: {
        zIndex: '3000 !important'
      }
    },
    MuiFormControl: {
      root: {
        display: 'flex !important'
      }
    },
    MuiInput: {
      underline: {
        ['&:after']: {
          display: 'none !important'
        },
        ['&:before']: {
          display: 'none !important'
        }
      }
    }
  }
});

export default (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const variant = props.variant || 'inline';

  return (
    <div className="picker-container">
      <MuiPickersUtilsProvider utils={DateUtils}>
        <ThemeProvider theme={theme}>
          <DatePicker
            disabled={props.disabled}
            clearable={props.clearable}
            disableToolbar={props.disableToolbar}
            open={isOpen}
            emptyLabel=""
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            variant={variant}
            minDate={props.minDate}
            maxDate={props.maxDate}
            format={props.format}
            value={props.value}
            invalidDateMessage=""
            autoOk
            onChange={props.onChange}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};
