import React, {Fragment, useState} from "react";
import Page from "../../UI/Page/Page";
import {Card} from "@material-ui/core";
import ListHeader from "../../Util/ListView/ListHeader/ListHeader";
import qs from "query-string";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import WithLoading from "../../Util/HOC/WithLoading";
import ListBody from "../../Util/ListView/ListBody/ListBody";
import {
    listHeader,
    renderBalances,
    renderCreatedAt,
    renderFullDate,
    renderLedgerAmount,
    renderMultiple, renderMultipleLedgers
} from "../../Util/listView";
import More from "../../UI/More/More";
import {Helmet} from "react-helmet";
import {notifyError, createMessage} from "../../Util/notification";
import {calculateRowsPerPage, pushRowsPerPageToQuery, pushSortToQuery} from "../../Util/ListView/listViewHelper";
import {withStyles} from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Delete, Edit} from "@material-ui/icons";
import ExportCSVDialog from "./ExportCSVDialog/ExportCSVDialog";
import exchange from "../../../api/V3/exchange";
import {API_URL_V3} from "../../../api/util";
import EditNoteDialog from "../ExchangeTrades/EditNoteDialog/EditNoteDialog";


const ListBodyWithLoading = WithLoading(ListBody);

class LedgerList extends React.Component {
    state = {
        exchange_id: this.props.location.pathname.split("/")[3],
        data: [],
        rowsPerPage: 10,
        isLoading: false,
        sort: '',
        count: 0,
        editDialogOpen: false,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, oldState) {
        const {query} = this.props;
        const {query: oldQuery} = oldProps;
        if (JSON.stringify(query) !== JSON.stringify(oldQuery) || this.state.rowsPerPage != oldState.rowsPerPage) {
            this.loadData();
        }
    }

    loadData = async () => {
        try {
            this.setState({isLoading: true});
            const query = {...this.props.query};
            const page = query.page || 0;
            const sort = query.sort;
            const search = query.search;

            this.setState({
                isLoading: true,
                sort
            });

            const {data: {count, results: data}} =  await exchange.ledgerList(this.state.exchange_id, search, page * this.state.rowsPerPage, this.state.rowsPerPage, sort);

            if (query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;

            this.setState({
                data,
                count,
                isLoading: false,
            });
        } catch (err) {
            this.setState({isLoading: false});
            notifyError(err.message);
        }
    };

    getActions = (data) => {

        const StyledListItemText = withStyles({
            root: {
                fontSize: "16px!important"
            }
        })(ListItemText)

        const StyledIcon = (Icon) => {
            return withStyles({
                root: {
                    fontSize: "24px"
                }
            })(Icon)
        }

        const StyledListItemIcon = withStyles({
            root: {
                minWidth: 24,
                marginRight: "16px"
            }
        })(ListItemIcon)

        const StyledEdit = StyledIcon(Edit)
        const StyledDelete = StyledIcon(Delete)

        const items = []

        items.push({
            handler: () => {
                this.setState({referenceId : data.refId , note: data.note})
                this.toggleDialog('edit')
            },
            render: () => {return <Fragment><StyledListItemIcon><StyledEdit/></StyledListItemIcon><StyledListItemText disableTypography primary="Edit" /></Fragment> }
        })

        return {
            items
        }
    }



    ledgerHeaders = [
        listHeader('Date', "time", false, row => renderFullDate(row.date)),
        listHeader('Type', "type", false, undefined, undefined, undefined, "left"),
        listHeader('Valute', "asset", true, (data) => renderMultipleLedgers(data.orders, 'valute'), undefined, undefined, "left"),
        listHeader('Ref ID', "refid", false, (data) => {return data.refId}, undefined, "undefined", "left"),
        listHeader('Amount', "amount", false, data => renderLedgerAmount(data.orders), undefined, undefined, "right"),
        listHeader('Fee', "fee", true, (data) => renderMultipleLedgers(data.orders, 'fee'), undefined, "undefined", "left"),
        listHeader('Balance', "balance", true, (data) => renderMultipleLedgers(data.orders, 'balance'), undefined, "undefined", "right"),
        listHeader('Status', "status", false, undefined,  undefined, "undefined", "right"),
        listHeader('Note', "note", true, undefined, undefined, "undefined", "left"),
        listHeader('Actions', 'actions', false, (data) => (<More {...this.getActions(data)} />), undefined, undefined, "right"),
    ];

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        const {location, query, history} = this.props;
        const {rowsPerPage} = this.state;
        this.setState({rowsPerPage: newRowsPerPage});
        const newPage = calculateRowsPerPage({location, newRowsPerPage, rowsPerPage});
        pushRowsPerPageToQuery({location, query, history, page: newPage});
    };

    toggleDialog = (dialogName) => {
        const fieldName = `${dialogName}DialogOpen`;
        this.setState({[fieldName]: !this.state[fieldName]});
    };

    requestSort = (sort) => {
        this.setState({sort});
        const {location, query, history} = this.props;
        pushSortToQuery({location, query, history, sort});
    };


    render() {

        const {data, totalData, isLoading, rowsPerPage, count} = this.state;

        return <Fragment>
            <Helmet>
                <title>Ledgers | Elicon</title>
            </Helmet>
            <Page>
                <Card>
                    <ListHeader
                        type="Ledger"
                        title={"Ledgers List"}
                        export={() => this.toggleDialog('CSV')}
                    />
                    <ListBodyWithLoading
                        sortField={this.state.sort}
                        requestSort={this.requestSort}
                        headers={this.ledgerHeaders}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                </Card>
            </Page>
            <EditNoteDialog
                referenceId={this.state.referenceId}
                toggle={() => this.toggleDialog('edit')}
                isOpen={this.state.editDialogOpen}
                reloadData={() => this.loadData()}
                note={{note: this.state.note}}
                ledger={true}
            />
            <ExportCSVDialog
                toggle={() => this.toggleDialog('CSV')}
                isOpen={this.state.CSVDialogOpen}
                exchange_id={this.state.exchange_id}
            />
        </Fragment>
    };

}


function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(LedgerList));
