import React from 'react'

import './styles.module.css'

export default props => {
    return (
        <div>
            <div styleName="header" className="d-flex align-items-center">
                <h4>{props.title}</h4>
            </div>
            <div styleName="others">
                {props.additionalComponent()}
            </div>
        </div>
    )
}