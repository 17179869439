import React from 'react';
import ReactDatePicker from 'react-datepicker';
import * as classnames from 'classnames';
import moment from 'moment';
import {
  computeIsRequired,
  getDataProp,
  getInputData,
  getInputError
} from './util';
import {formContextWrapper} from '../FormContext';
import {FormFeedback, FormGroup, Label} from 'reactstrap';
import type {FormContextProps} from '../FormContext';
import type {ArrayContextProps} from '../ArrayContext';
import {arrayContextWrapper} from '../ArrayContext';

import DatePicker from '../../UI/DatePicker/DatePicker';

type DatetimePickerProps = {
  label: string,
  outterhtmlClass: string,
  readOnly: boolean,
  allowedClear?: boolean,
  scope: {
    $ref: string
  },
  context: FormContextProps,
  array?: ArrayContextProps
};

class DatetimePicker extends React.Component<DatetimePickerProps> {
  state = {
    required: false,
    open: false,
    $ref: ''
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.scope.$ref !== prevState.$ref) {
      return {
        required: computeIsRequired(
          nextProps.context,
          nextProps.array,
          nextProps.scope.$ref
        ),
        $ref: nextProps.scope.$ref
      };
    }
    return prevState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const context = this.props.context;
    const nextContext = nextProps.context;
    const $ref = this.props.scope.$ref;
    const next$ref = nextProps.scope.$ref;
    const array = this.props.array;
    const nextArray = nextProps.array;
    return (
      getInputData(context, array, $ref) !==
        getInputData(nextContext, nextArray, next$ref) ||
      getInputError(context, array, $ref) !==
        getInputError(nextContext, nextArray, next$ref) ||
      $ref !== next$ref ||
      context.isLoading !== nextProps.isLoading
    );
  }

  render() {
    const {
      outterhtmlClass,
      label,
      context,
      scope: {$ref},
      array,
      time
    } = this.props;
    const inputHandler = array ? array.onInputChange : context.onInputChange;
    const error = getInputError(context, array, $ref);
    let value = getInputData(context, array, $ref);
    if (value == '') {
      value = null;
    }
    const dataFormat = time ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';

    return (
      <div className={classnames(outterhtmlClass, 'mb-3')}>
        <label className="d-block" htmlFor={$ref}>
          {label}
          {this.state.required || this.props.required ? (
            <sup className="text-danger">*</sup>
          ) : null}
        </label>
        <DatePicker
          disabled={context.isLoading}
          disableToolbar={this.props.disableToolbar}
          format="DD/MM/YYYY"
          value={value}
          error={error}
          onChange={(date) => {
            inputHandler($ref, moment(date).format(dataFormat));
          }}
        />
        <FormFeedback
          valid={!error}
          className={classnames({'d-block': !!error})}
        >
          {error}
        </FormFeedback>
      </div>
    );
  }
}

export default arrayContextWrapper(formContextWrapper(DatetimePicker));
