import {getName, overwrite, getCode, getData} from 'country-list';

export function getCountryName(name) {
    return getName(name);
}

export function getCountryCode(name) {
    return getCode(name);
}

export function getCountryData() {
    const additionalCountries = [{
        code: 'XK',
        name: 'Kosovo'
    }];
    overwrite(additionalCountries);
    return [...getData(), ...additionalCountries];
}
