import React from 'react'
import classnames from "classnames"
import { Input } from 'reactstrap'

import './input.module.css'

export default props => {
    
    return (
        <Input {...props} styleName={classnames("input")}></Input>
    )
}