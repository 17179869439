import {fulfilled, fulfilledState, initState} from "./middleware";
import category from "../api/category";

export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_CATEGORY_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getCategoryList = () => ({
    type: GET_CATEGORY_LIST,
    payload: category.list()
});
