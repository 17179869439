import {apiClientV3} from "../util";

export default {
    get: (search, offset=0, limit=10, ordering, type) => {
        let params = {
            offset,
            limit,
        }
        if (type) {
            params.search = type;
        }
        if(search){
            type ? params.search = `${type} ${search}` : params.search = search
        }
        if(ordering){
            params.ordering = ordering
        }else{
            params.ordering = '-datetime'
        }
        return apiClientV3.get(`/admin/payments`, { params })
    },

    getT: ({search, offset = 0, limit = 10, sort = '-datetime'}) => {
        let params = {
            offset,
            limit
        };
        if(search){
            params.search = search
        }
        if(sort){
            params.ordering = sort
        }
        return apiClientV3.get(`/admin/payments`, { params })
    },

    getTotal: () => apiClientV3.get(`/admin/payments/total`),

    mark: id => apiClientV3.post('/admin/payment/mark', {
        payment: id,
        is_test: true
    })
}



