import React, { useEffect, useState } from "react";
import Modal from "../UI/Modal/Modal";
import { createMessage, notifyError } from "../Util/notification";
import holistic from "../../api/V3/holistic";
import Select from "../UI/Select/Select";

const LinkGoCryptoCompanyModal = ({
  companyId,
  isOpen,
  toggle,
  onSubmit,
  reloadData,
  selectedCompany,
}) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(selectedCompany);

  useEffect(() => {
    setError("");
    setSelected(selectedCompany);
  }, [isOpen]);

  const submitHandler = async () => {
    try {
      setIsLoading(true);
      await onSubmit(selected.id);
      setIsLoading(false);
      createMessage(`New link created`);
      toggle();
      reloadData();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      notifyError(err.message);
    }
  };

  const loadOptions = (input, callback) => {
    if (companyId) {
      holistic.getGoCryptoCompanyList(companyId, input, 100).then((res) => {
        callback(res.data.results);
      });
    }
  };

  return (
    <Modal
      title={`Link GoCrypto company`}
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={submitHandler}
      isLoading={isLoading}
    >
      <div>
        <Select
          async
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          getOptionLabel={(company) => company.full_name}
          getOptionValue={(company) => company.id}
          onChange={(company) => setSelected(company)}
          value={selected}
        />

        <small className="text-danger">{error}</small>
      </div>
    </Modal>
  );
};

export default LinkGoCryptoCompanyModal;
