import React from 'react'
// import ReactSelect from "react-select";
import Select from '../../UI/Select/Select'
import {computeIsRequired, getDataProp, getInputConfig, getInputData, getInputError, getProp} from "./util";
import * as classnames from "classnames";
import {formContextWrapper} from "../FormContext";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import type {FormContextProps} from "../FormContext";
import type {ArrayContextProps} from "../ArrayContext";
import {arrayContextWrapper} from "../ArrayContext";
type SelectProps = {
    label: string,
    outterhtmlClass: string,
    allowedClear: boolean,
    arrayOptions: {
        displayFieldName: string,
        idFieldName: string,
        loadItemsOnOpen: boolean,
        $ref: string
    },
    scope: {
        $ref: string
    },
    context: FormContextProps,
    array?: ArrayContextProps
}

class MultiSelect extends React.Component<SelectProps> {
    state = {
        required: false,
        $ref: ""
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scope.$ref !== prevState.$ref) {
            return {
                required: computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref),
                $ref: nextProps.scope.$ref
            }
        }
        return prevState;
    }

    getValue = () => {
        const {context, array, scope: {$ref}, arrayOptions} = this.props;
        const config = getInputConfig(context, array, $ref);
        const dataValue = getInputData(context, array, $ref);
        
        if(dataValue && dataValue.length == 0) return null;
        return dataValue;
    };

    getOptions = () => {
        const {context, array, scope: {$ref}, arrayOptions, allowedClear, diffRef} = this.props;
        const config = getInputConfig(context, array, $ref);
        if (config.enum) {
            return config.enum.map(o => ({value: o, label: o}))
        } else {
            let  dataItems = getDataProp(context.data, arrayOptions.$ref) || [];
            if (allowedClear) {
                dataItems.splice(0, {[arrayOptions.idFieldName]: null, [arrayOptions.displayFieldName]: ""});
            }

            if(diffRef){
                let diffedDataItems = [];
                let forbiddenItems = getInputData(context, array, diffRef);
                diffedDataItems = dataItems.filter( item => {
                    if( forbiddenItems.find(subItem => item[arrayOptions.idFieldName] == subItem[arrayOptions.idFieldName]) === undefined){
                        return item;
                    }
                });
                dataItems = diffedDataItems;
            }
            return dataItems;//.map(item => ({value: item[arrayOptions.idFieldName], label: item[arrayOptions.displayFieldName]}))
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        const context = this.props.context;
        const nextContext = nextProps.context;
        const $ref = this.props.scope.$ref;
        const next$ref = nextProps.scope.$ref;
        const array = this.props.array;
        const nextArray = nextProps.array;
        try{
            return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) ||
                getDataProp(context.data, this.props.arrayOptions.$ref) !== getDataProp(nextContext.data, nextProps.arrayOptions.$ref) ||
                getInputError(context, array, $ref) !== getInputError(nextContext, nextArray, next$ref) ||
                $ref !== next$ref || 
                context.isLoading !== nextProps.isLoading
        } catch (e) {
            return true
        }
    }




    render() {
        const {outterhtmlClass, scope: {$ref}, context, label, arrayOptions, array} = this.props;
        const config = getProp(context.dataSchema, $ref);
        const error = getInputError(context, array, $ref);
        if (!config.enum && !arrayOptions) {
            return (
                <FormGroup className={outterhtmlClass}>
                    <Label className="mb-0 d-flex align-items-center" htmlFor={$ref}>{label}{this.state.required && <sup className="text-danger">*</sup>}</Label>
                    <Input />
                    <FormFeedback valid={!error} className={classnames({"d-block": !!error})}>{error}</FormFeedback>
                </FormGroup>
            )
        }
        return (
            <div className="mb-3">
                <label htmlFor={$ref}>{label}{this.state.required && <sup className="text-danger">*</sup>}</label>
                <Select     value={this.getValue()}
                            isDisabled={this.props.context.isLoading || this.props.disabled}
                            className={classnames(outterhtmlClass)}
                            options={this.getOptions()}
                            isMulti={true}
                            isSearchable={true}
                            name={$ref}
                            getOptionLabel={(item) => item[arrayOptions.displayFieldName]}
                            getOptionValue={(item) => item[arrayOptions.idFieldName]}
                            // components={{
                            //     Control: ({innerProps, children}) => (
                            //         <div
                            //             className={classnames("form-control height-unset p-0 d-flex", {"is-invalid": !!error})}
                            //             {...innerProps}>{children}</div>
                            //     )
                            // }}
                            onChange={e => context.onInputChange($ref, e)}/>
                <FormFeedback valid={!error} className={classnames({"d-block": !!error},"offset-3 col-9")}>{error}</FormFeedback>
            </div>
        );
    }
}

export default arrayContextWrapper(formContextWrapper(MultiSelect))
