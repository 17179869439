import React, {useEffect, useState, useRef} from 'react'
import { Form } from 'reactstrap'

import Input from '../../../UI/Input/Input'

import "./styles.module.css"
import Select from "../../../UI/Select/Select";
import * as qs from "qs";
import Button from "../../../UI/Button/Button";
import cx from 'classnames';
import {PermissionGuard} from "../../role-helpers";

export function useOutsideAlerter(ref, isOpen, setIsOpen) {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && isOpen) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}

const WalletTransaction = props => {
    const wrapperRef = useRef(null);
    const [toggle, setToggle] = useState(false);
    useOutsideAlerter(wrapperRef, toggle, setToggle);

    const toggleDropdown = () => {
        setToggle(!toggle)
    };

    return (
        <div>
            <div styleName="header" className="d-flex align-items-center">
                <h4>{props.title}</h4>
                <PermissionGuard code="export_wallet_transactions">
                    <div className="ml-auto"  ref={wrapperRef}>
                        <Button styleName="dropdown-button" onClick={toggleDropdown}>Export <div styleName="chevron"> &#10095; </div></Button>
                        <div styleName={cx("dropdown-content", {"show" : toggle})}>
                            <a onClick={() => props.toggle("CSV")}>CSV</a>
                        </div>
                    </div>
                </PermissionGuard>
            </div>
                <Form onSubmit={props.onSubmit} styleName="search">
                    <Input
                        style={{ width: "256px" }}
                        onChange={props.onChange}
                        value={props.value}
                        name="Search"
                        placeholder="Filter" />
                </Form>
            </div>
    )
}

export default WalletTransaction
