import React from 'react';
import DynamicForm from '../DynamicForm/DynamicForm'

const AddRoleDialog = props => {

    return (
            <DynamicForm 
                onSubmit={props.onSubmit}
                uiSchema={require('./roleUi.json')}
                dataSchema={require('./roleData.json')}
                initData={{ form: { permissions: [] },
                  permissions: props.permissions}}
                modal={{
                    title: "Create Role",
                    isOpen: props.isOpen,
                    isLoading: props.isLoading,
                    size: 'lg',
                    cancelLabel: 'Close',
                    toggle: props.toggle
                }}
            />
    );
}

export default AddRoleDialog;