import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SideNav from "../SideNav/SideNav";
import { Col, Row } from "reactstrap";
import Button from '../UI/Button/Button'
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import { push } from 'connected-react-router';
import user from "../../api/user";
import { listHeader, listHeaders, renderEditUser, renderName } from "../Util/listView";
import { getSafe, getSafeDeep } from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';

import Page from '../UI/Page/Page'

import { Card, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { OpenInNew, Search, Edit, Delete } from '@material-ui/icons'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import More from '../UI/More/More'
import AddWalletDialog from "./AddWalletDialog";
import wallets from "../../api/V3/wallets";
import WalletDetailsDialog from "./WalletDetailsDialog";
import { hasPermission } from "../Util/role-helpers";
import { notifyError } from '../Util/notification';
import { Helmet } from "react-helmet";
import EditWalletDialog from './EditWalletDialog';
import DeleteWalletDialog from './DeleteWalletDialog'

const ListBodyWithLoading = WithLoading(ListBody);

class WalletsList extends React.Component {
    getActions = (data) => {

        const StyledListItemText = withStyles({
            root: {
                fontSize: "16px!important"
            }
        })(ListItemText)

        const StyledIcon = (Icon) => {
            return withStyles({
                root: {
                    fontSize: "24px"
                }
            })(Icon)
        }

        const StyledListItemIcon = withStyles({
            root: {
                minWidth: 24,
                marginRight: "16px"
            }
        })(ListItemIcon)

        const StyledSearch = StyledIcon(Search)
        const StyledOpenInNew = StyledIcon(OpenInNew)
        const StyledDelete = StyledIcon(Delete)
        const StyledEdit = StyledIcon(Edit)

        let items = [
            {
                handler: () => { window.open(`https://etherscan.io/address/${data.address}`, "_blank") },
                render: () => (<Fragment><StyledListItemIcon><StyledOpenInNew /></StyledListItemIcon><StyledListItemText disableTypography primary="Open in Etherscan" /></Fragment>)
            }
        ]

        if (hasPermission(this.props.user, 'list_wallet_transactions')) {
            items.splice(0, 0, {
                handler: () => { this.toggleDialog("details"); this.setState({ selectedWallet: data }) },
                render: () => (<Fragment><StyledListItemIcon><StyledSearch /></StyledListItemIcon><StyledListItemText disableTypography primary="Details" /></Fragment>)
            }, {
                handler: () => { this.toggleDialog("edit"); this.setState({ editWallet: data }) },
                render: () => (<Fragment><StyledListItemIcon><StyledEdit /></StyledListItemIcon><StyledListItemText disableTypography primary="Edit" /></Fragment>)
            }, {
                handler: () => { this.toggleDialog("delete"); this.setState({ deleteWallet: data }) },
                render: () => (<Fragment><StyledListItemIcon><StyledDelete /></StyledListItemIcon><StyledListItemText disableTypography primary="Delete" /></Fragment>)
            })
        }

        return {
            items
        }
    }

    WalletsListHeaders = [
        listHeader('Title', "title", true),
        listHeader('Address', "address", false),
        listHeader('Currency', "currency", true, undefined, undefined, undefined, "center"),
        listHeader('Actions', 'actions', false, (data) => (<More {...this.getActions(data)} />), undefined, undefined, "right"),
    ];


    state = {
        isLoading: true,
        addWalletDialogOpen: false,
        editDialogOpen: false,
        detailsDialogOpen: false,
        deleteDialogOpen: false,
        rowsPerPage: 10,
        count: 0,
        selectedWallet: null,
        editWallet: null,
        deleteWallet: null,
        sort: ''
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, prevState) {
        const { query, auth } = this.props;
        const { query: oldQuery, auth: oldAuth } = oldProps;
        if (!immutable.is(query, oldQuery) || this.state.rowsPerPage != prevState.rowsPerPage) {
            this.loadData();
        }
    }

    toggleDialog = (dialogName) => {
        const fieldName = `${dialogName}DialogOpen`;
        this.setState({ [fieldName]: !this.state[fieldName] });
    };

    loadData = async () => {
        // let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        try {
            const query = this.props.query
            const sort = query.sort ? query.sort : "id";
            const page = query.page || 0;
            const search = query.search
            this.setState({ isLoading: true, sort });
            const { data: { count, results: data } } = await wallets.get(search, page * this.state.rowsPerPage, this.state.rowsPerPage, sort);
            this.setState({ count, data, isLoading: false });
            if (query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
        } catch (err) {
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = { ...this.props.query };
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    requestSort = (sort) => {
        this.setState({ sort });
        const query = { ...this.props.query };
        query.page = 0;
        query.sort = sort
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    render() {
        const { isLoading, data, rowsPerPage, count } = this.state;

        return <Fragment>
            <Helmet>
                <title>Crypto Wallets | Elicon</title>
            </Helmet>
            <Page permission={'list_wallets'}>
                <Card>
                    <ListHeader
                        type="OnlySearch"
                        title="Wallets List"
                        searchTitle="Filter"
                        actionHandler={() => this.toggleDialog("addWallet")}
                        actionLabel="Add Wallet"
                    />
                    <ListBodyWithLoading
                        sortField={this.state.sort}
                        requestSort={this.requestSort}
                        headers={this.WalletsListHeaders}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                </Card>
            </Page>
            <AddWalletDialog
                toggle={() => this.toggleDialog("addWallet")}
                isOpen={this.state.addWalletDialogOpen}
                reloadData={() => this.loadData()}
            />
            <WalletDetailsDialog
                toggle={() => this.toggleDialog("details")}
                isOpen={this.state.detailsDialogOpen}
                selectedWallet={this.state.selectedWallet}
            />
            <EditWalletDialog
                toggle={() => this.toggleDialog('edit')}
                isOpen={this.state.editDialogOpen}
                reloadData={() => this.loadData()}
                wallet={this.state.editWallet}
            />
            <DeleteWalletDialog
                toggle={() => this.toggleDialog('delete')}
                isOpen={this.state.deleteDialogOpen}
                reloadData={() => this.loadData()}
                wallet={this.state.deleteWallet}
            />
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        user: state.user.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(WalletsList));
