import React, {Fragment, useState} from 'react';
import Page from '../UI/Page/Page';
import {Card} from '@material-ui/core';
import ListHeader from '../Util/ListView/ListHeader/ListHeader';
import qs from 'query-string';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import WithLoading from '../Util/HOC/WithLoading';
import ListBody from '../Util/ListView/ListBody/ListBody';
import {listHeader, renderFullDate} from '../Util/listView';
import {getSafeDeep} from '../Util/state';
import CardDisplay from '../Util/AmountCardDisplay/CardDisplay';
import './transactions.module.css';
import TransactionExportCSVDialog from './TransactionExportCSVDialog';
import transaction from '../../api/V3/transactions';
import {Helmet} from 'react-helmet';
import {notifyError, createMessage} from '../Util/notification';
import {
  calculateRowsPerPage,
  pushRowsPerPageToQuery,
  pushSortToQuery
} from '../Util/ListView/listViewHelper';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import {hasPermission} from '../Util/role-helpers';

const ListBodyWithLoading = WithLoading(ListBody);

const IsTestModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async () => {
    try {
      setIsLoading(true);
      await transaction.mark(props.transaction.payment_id);
      setIsLoading(false);
      createMessage('Transaction marked successfully');
      props.reloadData();
      props.toggle();
    } catch (err) {
      setIsLoading(false);
      notifyError(err.message);
    }
  };

  return (
    <Modal
      toggle={props.toggle}
      title="Mark as Test"
      isOpen={!!props.transaction}
      isLoading={isLoading}
      onSubmit={submitHandler}
      confirmLabel="Mark"
      size="sm"
    >
      Do you really want to mark the transaction?
    </Modal>
  );
};

const transactionTypes = {
  Payment: 'Payment',
  HwPosPayment: 'HWPos Payment',
  HwPosBuy: 'HWPos Exchange Buy',
  HwPosSell: 'HWPos Exchange Sell'
};

class Transactions extends React.Component {
  state = {
    totalData: {},
    data: [],
    rowsPerPage: 10,
    isLoading: false,
    sort: '',
    CSVDialogOpen: false,
    count: 0,
    isTestTransaction: null
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(oldProps, oldState) {
    const {query} = this.props;
    const {query: oldQuery} = oldProps;
    if (
      JSON.stringify(query) !== JSON.stringify(oldQuery) ||
      this.state.rowsPerPage != oldState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  loadData = async () => {
    try {
      this.setState({isLoading: true});
      const query = {...this.props.query};
      const page = query.page || 0;
      const sort = query.sort;
      const search = query.search;
      const type = query.type || 'Payment';

      this.setState({
        isLoading: true,
        sort
      });

      const response = (
        await transaction.get(
          search,
          page * this.state.rowsPerPage,
          this.state.rowsPerPage,
          sort,
          type
        )
      ).data;
      const {count, results: data} = response;

      const totalData = (await transaction.getTotal()).data;

      if (query.page > Math.ceil(count / this.state.rowsPerPage))
        query.page = 0;

      this.setState({
        totalData,
        data,
        count,
        isLoading: false
      });
    } catch (err) {
      this.setState({isLoading: false});
      notifyError(err.message);
    }
  };

  renderGOCGButton = (data) => {
    return (
      <Button
        style={{margin: '0 auto'}}
        onClick={() => this.setState({isTestTransaction: data})}
        disabled={data.is_test}
        color="primary"
      >
        Test
      </Button>
    );
  };

  transactionsHeaders = [
    listHeader('Date', 'datetime', true, (row) => renderFullDate(row.datetime)),
    listHeader(
      'Type',
      'type',
      false,
      (data) => transactionTypes[data.type] || data.type
    ),
    listHeader(
      'Store',
      'store',
      false,
      undefined,
      undefined,
      undefined,
      'left'
    ),
    listHeader(
      'Price',
      'crypto_amount',
      true,
      undefined,
      undefined,
      undefined,
      'right'
    ),
    listHeader(
      'Crypto Currency',
      'crypto_currency',
      false,
      (row) =>
        this.props.currencies.find(({id}) => id == row.crypto_currency)
          .display_symbol,
      undefined,
      'undefined',
      'left'
    ),
    hasPermission(this.props.user, 'mark_test_payments')
      ? listHeader(
          'Mark as Test',
          '',
          false,
          this.renderGOCGButton,
          undefined,
          undefined,
          'center'
        )
      : null,
    listHeader(
      'EUR',
      'local_amount',
      true,
      undefined,
      undefined,
      'undefined',
      'right'
    )
  ];

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    const {location, query, history} = this.props;
    const {rowsPerPage} = this.state;
    this.setState({rowsPerPage: newRowsPerPage});
    const newPage = calculateRowsPerPage({
      location,
      newRowsPerPage,
      rowsPerPage
    });
    pushRowsPerPageToQuery({location, query, history, page: newPage});
  };

  toggleDialog = (dialogName) => {
    const fieldName = `${dialogName}DialogOpen`;
    this.setState({[fieldName]: !this.state[fieldName]});
  };

  requestSort = (sort) => {
    this.setState({sort});
    const {location, query, history} = this.props;
    pushSortToQuery({location, query, history, sort});
  };

  render() {
    const {data, totalData, isLoading, rowsPerPage, count} = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Transactions | Elicon</title>
        </Helmet>
        <Page permission={['viewAll_payments', 'view_payments_total']}>
          <div styleName="card-display">
            <CardDisplay
              topLabel="All purchases"
              topValue={getSafeDeep(totalData, 'all.count')}
              bottomLabel="Amount EUR"
              bottomValue={getSafeDeep(totalData, 'all.amount_eur')}
            />
            <CardDisplay
              topLabel="Today's purchases"
              topValue={getSafeDeep(totalData, 'last_24h.count')}
              bottomLabel="Amount EUR"
              bottomValue={getSafeDeep(totalData, 'last_24h.amount_eur')}
            />
            <CardDisplay
              topLabel="All exchanges"
              topValue={getSafeDeep(totalData, 'all_exchange.count')}
              bottomLabel="Amount EUR"
              bottomValue={getSafeDeep(totalData, 'all_exchange.amount_eur')}
            />
            <CardDisplay
              topLabel="Today's exchanges"
              topValue={getSafeDeep(totalData, 'last_24h_exchange.count')}
              bottomLabel="Amount EUR"
              bottomValue={getSafeDeep(
                totalData,
                'last_24h_exchange.amount_eur'
              )}
            />
          </div>
          <Card>
            <ListHeader
              type="Transaction"
              typeFilters={Object.entries(transactionTypes).map(
                ([value, label]) => ({
                  value,
                  label
                })
              )}
              toggle={(dialog) => this.toggleDialog(dialog)}
            />
            <ListBodyWithLoading
              sortField={this.state.sort}
              requestSort={this.requestSort}
              headers={this.transactionsHeaders}
              isLoading={isLoading}
              data={data}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
          </Card>
        </Page>
        <TransactionExportCSVDialog
          toggle={() => this.toggleDialog('CSV')}
          isOpen={this.state.CSVDialogOpen}
          screen="transactions"
        />
        <IsTestModal
          transaction={this.state.isTestTransaction}
          reloadData={this.loadData}
          toggle={() => this.setState({isTestTransaction: null})}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true}),
    user: state.user.data,
    currencies: state.currencies.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(Transactions));
