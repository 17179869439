import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import Button from '../../../UI/Button/Button';
import Dropdown from '../../../UI/Dropdown/Dropdown';
import DynamicForm from '../../../DynamicForm/DynamicForm';

import './panel.module.css';

import {getCountryData} from '../../../Util/countryList';

import Contract from './Others/Contract';
import PointsOfSale from './Others/PointOfSales/PointsOfSale';
import Notes from './Others/Notes/Notes';
import Tags from './Others/Tags/Tags';
import LinkPointOfSaleDialog from '../../../PoS/Others/LinkPointOfSaleDialog';
import CompanyContacts from './Others/Contacts';
import {hasPermission, PermissionGuard} from '../../../Util/role-helpers';
import {routes} from '../../../Util/routing';
import transactionFees from '../../../../redux/transactionFee';
import Withdrawals from './Others/Withdrawals/Withdrawals';
import Funds from './Others/Funds/Funds';
import Reasons from './Others/Reasons/Reasons';

const countries = getCountryData();

const Panel = (props) => {
  const {active, organization, loadData} = props;

  const [linkSource, setLinkSource] = useState(false);

  const riskScores = [
    {value: 'low', name: 'Low (0 - 1.49)'},
    {value: 'regular', name: 'Regular (1.5 - 1.99)'},
    {value: 'high', name: 'High (2.0 and over)'}
  ];

  const others = {
    Contract: (organization) => (
      <Contract organization={organization} loadData={props.loadData} />
    ),
    'Points of Sale': (organization) => (
      <PointsOfSale loadData={loadData} organization={organization} />
    ),
    Notes: (organization) => (
      <Notes loadData={loadData} organization={organization} />
    ),
    'Edit Reasons': (organization) => (
      <Reasons loadData={loadData} organization={organization} />
    ),
    Tags: (organization) => (
      <Tags loadData={loadData} organization={organization} />
    ),
    'Contact Information': (organization) => (
      <CompanyContacts
        organization={organization}
        dataUpdated={props.dataUpdated}
      />
    ),
    Withdrawals: (organization) => <Withdrawals organization={organization} />,
    Funds: (organization) => <Funds organization={organization} />
  };

  useEffect(() => {
    const orgElement = document.getElementsByName(
      '#/properties/form/properties/organization_type'
    );
    const requiresPos = document.getElementsByName(
      '#/properties/form/properties/merchant_requires_pos_device'
    );

    if (orgElement.length) {
      if (orgElement[0].value == 0) {
        requiresPos[0].parentElement.parentElement.style.display = 'none';
      } else if (orgElement[0].value == 1) {
        requiresPos[0].parentElement.parentElement.style.display = 'block';
      }
    }
  }, []);

  return (
    <Fragment>
      <div styleName="panel">
        {active.schema ? (
          <DynamicForm
            errors={props.errors}
            readOnly={!hasPermission(props.user, 'change_company')}
            wrappers={(dynamicForm, reset) => (
              <React.Fragment>
                <div styleName="body">
                  <h3 styleName="header" className="pb-5 pt-3">
                    {active.name}
                  </h3>
                  {dynamicForm}
                </div>
                {active.schema &&
                  hasPermission(props.user, 'change_company') && (
                    <div styleName="footer" className="d-flex">
                      <Button color="primary" className="mr-2 ml-auto">
                        Save
                      </Button>
                      <Button color="primary" type="submit" data-name="next">
                        Save & Next
                      </Button>
                    </div>
                  )}
              </React.Fragment>
            )}
            clearOnChange
            initData={{
              countries,
              exchangeFees: props.exchangeFees,
              fiatCurrencies: props.fiatCurrencies,
              settlementCurrencies: props.settlementCurrencies,
              payfacOptions: props.payfacOptions,
              transactionFees: props.transactionFees,
              languages: props.sourceLang.map((item) => {
                item.code = item.code.toLowerCase();
                return item;
              }),
              risk_scores: riskScores,
              ...active.schema.initData
            }}
            uiSchema={active.schema.ui}
            onSubmit={props.update}
            dataSchema={active.schema.data}
          />
        ) : active.name == 'Points of Sale' ? (
          <React.Fragment>
            <div styleName="body" className="d-flex pb-0">
              <h3 styleName="header" className="pb-5 pt-3">
                Points of Sale
              </h3>
              <PermissionGuard code="create_source_v3">
                <Dropdown
                  links={[
                    {
                      label: 'Local store',
                      to: routes.posNewOffline.replace(':id', organization.id)
                    },
                    {
                      label: 'Online store',
                      to: routes.posNewOnline.replace(':id', organization.id)
                    }
                  ]}
                  btnText="Add Store"
                  className="ml-auto mr-3"
                />
              </PermissionGuard>
              <PermissionGuard code={['company_add_source']}>
                <Button
                  color="outline-primary"
                  onClick={() => setLinkSource(true)}
                >
                  Link store
                </Button>
              </PermissionGuard>
            </div>
            {others['Points of Sale'](organization)}
          </React.Fragment>
        ) : others[active.name] ? (
          <div styleName="body">
            <h3 styleName="header" className="pb-5 pt-3">
              {active.name}
            </h3>
            {others[active.name](organization)}
          </div>
        ) : null}
      </div>
      {active.name == 'Points of Sale' ? (
        <LinkPointOfSaleDialog
          organization={organization}
          isOpen={linkSource}
          toggle={() => setLinkSource(false)}
        />
      ) : null}
    </Fragment>
  );
};

const mapState = (state) => {
  return {
    exchangeFees: state.exchangeFees.data,
    transactionFees: state.transactionFees.data,
    user: state.user.data,
    sourceLang: state.sourceLang.data
  };
};

export default connect(mapState)(Panel);
