import React from 'react';
import './loader.module.css';
import PropTypes from 'prop-types';
import {Card, CardBody} from "reactstrap";
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {
    return (
        <div styleName="loader-body">
            <div styleName="loader-wrapper" className="d-flex flex-column justify-content-center align-items-center">
                <CircularProgress />
            </div>
        </div>
    )
}

function WithLoading(Component) {
    return function WithLoadingComponent({ isLoading, noCard, ...props }) {
        if (isLoading) {
            if(noCard) return(
                <React.Fragment>
                    <Loading />
                    <Component {...props} />
                </React.Fragment>
            )
            return <Card className="p-2 mt-4 position-relative">
                <CardBody className="p-4">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <CircularProgress />
                        <div className="dark-gray-text mt-3">Loading, please wait</div>
                    </div>


                </CardBody>
            </Card>
        }
        return (<Component {...props} />);
    }
}


export default WithLoading;
