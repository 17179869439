import React, {useEffect, useState} from 'react';
import moment from 'moment';
import './style.module.css';
import logo from '../Header/elipay-logo.svg';
import CardContent from '@material-ui/core/CardContent';
import {Row, Col} from 'reactstrap';
import Button from '../UI/Button/Button';
import {withRouter} from 'react-router';
import {styled} from '@material-ui/core/styles';
import MatCard from '@material-ui/core/Card';
import QRCode from 'qrcode.react';
import qs from 'qs';
import {CircularProgress} from '@material-ui/core';
import auth from '../../api/auth';
import Input from '../UI/Input/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {replace} from 'connected-react-router';
const Card = styled(MatCard)({
  width: 400,
  padding: 32
});

const OTPRegistration = (props) => {
  /*Constants*/
  const token = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  }).token;
  if (!token) {
    props.replace('/login');
    return null;
  }

  /*Local state*/
  const [error, setError] = useState();
  const [config, setConfig] = useState();
  const [code, setCode] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [tokens, setTokens] = useState();

  /*Methods*/
  const registerDevice = async () => {
    setLoading(true);
    try {
      const response = await auth.registerOtpDevice(config.id, token, code);
      if (response.data.verified) {
        setTokens(response.data.recovery_codes);
      }
    } catch (e) {
      if (e.response) {
        setError(e.response.data.message || e.response.data);
      } else {
        setError(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const finished = () => {
    props.replace('/login');
  };

  /*Hooks*/
  useEffect(() => {
    (async () => {
      try {
        const response = await auth.getOtpConfig(token);
        setConfig(response.data);
      } catch (e) {
        props.replace('/login');
      }
    })();
  }, [token]);

  /*Template*/
  return (
    <div className="d-flex">
      <div styleName="left" className="d-flex">
        <div className="p-5">
          <img src={logo} width="120px" />
        </div>
        <div styleName="copyright" className="p-5">
          <a href>© {moment().format('YYYY')} Elipay</a>
        </div>
      </div>
      <div
        styleName="right"
        className="d-flex justify-content-center align-items-center"
      >
        <Card>
          <CardContent>
            <div className="text-center mb-5">
              <h3>Register OTP Device</h3>
            </div>
            <p className="text-danger mb-5">{error}</p>
            {tokens ? (
              <div>
                <h4>Device activated successfully</h4>
                <p>Recovery codes are listed below</p>
                <Row>
                  {tokens.map((token) => (
                    <Col sm={4}>
                      <code>{token}</code>
                    </Col>
                  ))}
                </Row>
                <Button color="success" onClick={finished}>
                  Continue
                </Button>
              </div>
            ) : config ? (
              <>
                <QRCode value={config.config_url} size={300} />
                <div className="d-flex mt-4" styleName="otp-input">
                  <Input
                    type="text"
                    id="otp-code"
                    value={code}
                    placeholder="Enter code"
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <Button
                    color="primary"
                    isLoading={isLoading}
                    onClick={registerDevice}
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              <div styleName="otp-loader">
                <CircularProgress />
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

function mapState(state) {
  return {};
}

function mapActions(dispatch) {
  return bindActionCreators(
    {
      replace
    },
    dispatch
  );
}

export default withRouter(connect(mapState, mapActions)(OTPRegistration));
