import React from 'react'

import Step from './Step'

import './Stepper.module.css'

const Steper = props => {
    return (
        <div styleName="stepper-box">
            <div styleName="stepper">
                {props.steps.map((step, i) => (
                    <Step 
                        {...step} 
                        key={i}
                        changeActive={() =>  i<=props.activeIndex && props.changeActive(i)}
                        active={i<=props.activeIndex ? true : false}/>
                ))}
            </div>
            <div styleName="progress-box">
                <div styleName="progress">
                    <div styleName="bar" style={{ width: `${100/(props.steps.length-1) * props.activeIndex}%` }}>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Steper;