import React, { useState, useEffect } from "react";
import Modal from "../UI/Modal/Modal";
import { createMessage, notifyError } from "../Util/notification";
import { connect } from "react-redux";
import Select from "../UI/Select/Select";
import organization from "../../api/organization";

const HolisticPOSAddNew = ({
  isOpen,
  toggle,
  onSubmit,
  reloadData,
  selectedCompany,
}) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(selectedCompany);

  useEffect(() => {
    setError("");
    setSelected(selectedCompany);
  }, [isOpen]);

  const submitHandler = async () => {
    try {
      const { full_name, email, country } = selected;

      setIsLoading(true);
       onSubmit(selected.id, {
        full_name,
        email,
        country,
        operated_by: 0,
      });
      setIsLoading(false);
      createMessage(`New company created`);
      toggle();
      reloadData();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      notifyError(err.message);
    }
  };

  const loadGoCryptoCompanyOptions = (input, callback) => {
    organization.getGoCryptoCompanies(input, 0, 20).then((res) => {
      callback(res.data.results);
    });
  };

  return (
    <Modal
      title={`Create Holistic form GoCrypto Classic company`}
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={submitHandler}
      isLoading={isLoading}
    >
      <div>
        <p>GoCrypto Classic companies:</p>
        <Select
          async
          cacheOptions
          defaultOptions
          loadOptions={loadGoCryptoCompanyOptions}
          getOptionLabel={(company) => company.full_name}
          getOptionValue={(company) => company.id}
          onChange={(company) => setSelected(company)}
          value={selected}
        />

        <small className="text-danger">{error}</small>
      </div>
    </Modal>
  );
};

function mapState(state) {
  return {
    countries: state.countries.data || [],
  };
}

export default connect(mapState)(HolisticPOSAddNew);
