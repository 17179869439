import React, {useState, useEffect} from 'react';
import Modal from '../UI/Modal/Modal';
import Input from '../UI/Input/Input';
import {createMessage, notifyError} from '../Util/notification';
import {connect} from 'react-redux';
import DynamicForm from '../DynamicForm/DynamicForm';

const HolisticPOSEditModal = ({
  countries,
  operatedBy,
  paymentMethods,
  uiSchema,
  dataSchema,
  label,
  active,
  toggle,
  reloadData,
  onSubmit,
  sourceLang
}) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const languages = sourceLang.map((item) => {
    item.code = item.code.toLowerCase();
    return item;
  });

  const dynamicSubmitHandler = async (data) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
      setIsLoading(false);
      createMessage(`${label} updated`);
      toggle();
      reloadData();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      notifyError(err.message);
    }
  };

  if (uiSchema && uiSchema) {
    return (
      <DynamicForm
        uiSchema={uiSchema}
        dataSchema={dataSchema}
        initData={{
          form: {...active},
          countries,
          paymentMethods,
          operatedBy,
          languages
        }}
        onSubmit={dynamicSubmitHandler}
        modal={{
          title: `Edit ${label}`,
          isOpen: !!active,
          toggle,
          isLoading,
          size: 'sm'
        }}
      />
    );
  }

  useEffect(() => {
    setError('');
    if (active) {
      setName(active.full_name);
    }
  }, [active]);

  const submitHandler = async () => {
    if (name.trim().length === 0) {
      return setError('This field is required');
    }

    try {
      setIsLoading(true);
      await onSubmit(name);
      setIsLoading(false);
      createMessage(`${label} updated`);
      toggle();
      reloadData();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      notifyError(err.message);
    }
  };

  return (
    <Modal
      title={`Edit ${label}`}
      isOpen={!!active}
      toggle={toggle}
      onSubmit={submitHandler}
      isLoading={isLoading}
    >
      <div>
        <label htmlFor="reason">{label} Name</label>
        <Input
          placeholder={`Enter ${label} Name`}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <small className="text-danger">{error}</small>
      </div>
    </Modal>
  );
};

function mapState(state) {
  return {
    countries: state.countries.data || [],
    sourceLang: state.sourceLang.data
  };
}

export default connect(mapState)(HolisticPOSEditModal);
