import React from 'react'
import { arrayContextWrapper } from '../ArrayContext'
import { formContextWrapper } from '../FormContext'
import TransferList from '../../UI/TransferList/TransferList'
import { isEqual } from 'lodash'

import { getDataProp, getInputConfig, getInputData, getInputError, getProp } from "./util";

class TransferListControl extends React.Component {

    getSource = () => {

        const data = getDataProp(this.props.context.data, this.props.srcOptions.$ref) || [];
        const dataAdd = this.props.srcOptions.$refAdd ? getDataProp(this.props.context.data, this.props.srcOptions.$refAdd) : null;
        const filterRef = this.props.srcOptions.$filter
        if (filterRef) {
            let filter = getDataProp(this.props.context.data, filterRef)
            if (filter && filter.trim()) {
                let filteredData = []
                data.forEach(level => {
                    let filteredActions = []
                    level.actions.forEach(action => {
                        if (action[this.props.display].toLowerCase().includes(filter.toLowerCase())) {
                            filteredActions.push(action)
                        }
                    })
                    if (!isEqual(filteredActions, [])) {
                        filteredData.push({
                            group: level.group,
                            actions: filteredActions
                        })
                    }
                })
                return [filteredData]
            }
        }
        return [data, dataAdd];
    }

    getSelected = () => {
        return getDataProp(this.props.context.data, this.props.scope.$ref)
    }

    applyChanges = (value) => {
        const inputHandler = this.props.array ? this.props.array.onInputChange : this.props.context.onInputChange;
        inputHandler(this.props.scope.$ref, value)
    }

    render() {
        return (
            <TransferList
                labelSrc={this.props.labelSrc}
                labelDest={this.props.labelDest}
                display={this.props.display}
                id={this.props.id}
                data={this.getSource()[0]}
                dataAdd={this.getSource()[1]}
                applyChanges={this.applyChanges}
                selected={this.getSelected()}
            />
        )
    }

}

export default arrayContextWrapper(formContextWrapper(TransferListControl))