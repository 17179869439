import React, {Fragment} from 'react';
import Page from '../UI/Page/Page';
import {Card} from '@material-ui/core';
import ListHeader from '../Util/ListView/ListHeader/ListHeader';
import qs from 'query-string';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import WithLoading from '../Util/HOC/WithLoading';
import ListBody from '../Util/ListView/ListBody/ListBody';
import {listHeader} from '../Util/listView';
import Select from '../UI/Select/Select';
import {getSafeDeep} from '../Util/state';
import balances from '../../api/V3/balances';
import {Helmet} from 'react-helmet';

const ListBodyWithLoading = WithLoading(ListBody);

let wallets = [
  {
    value: 'hot_wallet',
    label: 'Eligma Hot Wallet'
  },
  {
    value: 'cold_wallet',
    label: 'Eligma Cold Wallet'
  },
  {
    value: 'users',
    label: 'Users'
  },
  {
    value: 'accountant',
    label: 'Accountant'
  },
  {
    value: 'loyalty',
    label: 'Loyalty'
  },
  {
    value: 'referral',
    label: 'Referral'
  }
];

class BalanceList extends React.Component {
  state = {
    selectedWallet: wallets[0],
    data: [],
    isLoading: true
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.selectedWallet !== this.state.selectedWallet) {
      this.loadData().catch((error) =>
        console.error('error loading data: ', error.message)
      );
    }
  }

  loadData = async () => {
    this.setState({isLoading: true});
    const data = await balances.get(this.state.selectedWallet.value);

    await this.setState({
      data: data.data,
      isLoading: false
    });
  };

  onChange = (wallet) => {
    this.setState({selectedWallet: wallet});
    this.loadData();
  };

  getOptions = () =>
    wallets.map((wallet) => ({value: wallet.value, label: wallet.label}));

  renderWalletsSelect = () => {
    return (
      <>
        <Select
          value={getSafeDeep(this.state, 'selectedWallet')}
          placeholder={getSafeDeep(this.state, 'selectedWallet.label')}
          options={this.getOptions()}
          isSearchable={true}
          onChange={(w) => this.onChange(w)}
          className="mr-3"
        />
      </>
    );
  };

  balanceListHeaders = [
    listHeader('Currency', 'currency', false),
    listHeader(
      'Price',
      'value',
      false,
      (data) =>
        parseFloat(
          parseFloat(data.euro_amount) === 0 && parseFloat(data.amount) === 0
            ? 0
            : parseFloat(data.euro_amount) / parseFloat(data.amount)
        ).toFixed(8),
      undefined,
      undefined,
      'right'
    ),
    listHeader(
      'Balance',
      'amount',
      false,
      (data) => parseFloat(data.amount).toFixed(8),
      undefined,
      undefined,
      'right'
    ),
    listHeader(
      'Value (€)',
      'euro_amount',
      false,
      (data) => parseFloat(data.euro_amount).toFixed(2),
      undefined,
      undefined,
      'right'
    )
  ];

  render() {
    const {data, isLoading} = this.state;
    const totalFiatSum = (accumulator, currentValue) =>
      accumulator + Number(currentValue.euro_amount);

    let totalAmount = data.reduce(totalFiatSum, 0);

    return (
      <Fragment>
        <Helmet>
          <title>Balances | Elicon</title>
        </Helmet>
        <Page permission={'list_crypto_balances'}>
          <Card>
            <ListHeader
              type="BalanceList"
              renderWalletsSelect={this.renderWalletsSelect}
            />
            <ListBodyWithLoading
              headers={this.balanceListHeaders}
              isLoading={isLoading}
              data={data}
              pagination={true}
              total={true}
              totalAmount={totalAmount}
            />
          </Card>
        </Page>
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(BalanceList));
