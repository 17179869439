

export const supportedLanguages = {
    "en": "English",
    "sl": "Slovenian"
};

export const supportedLanguagesList = [
    {
        lang_code: "en",
        lang_code_numeric: 0,
        lang_name: "English"
    },
    {
        lang_code: "sl",
        lang_code_numeric: 1,
        lang_name: "Slovenian"
    },
    {
        lang_code: "es",
        lang_code_numeric: 2,
        lang_name: "Spanish"
    },
];

export const otherLanguagesList = [
    {
        lang_code: "sl",
        lang_name: "Slovenian"
    },
];
