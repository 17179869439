
import WithLoading from "../Util/HOC/WithLoading";
import ListBody from "../Util/ListView/ListBody/ListBody";
import React, {Fragment} from "react";
import {getSafeDeep} from "../Util/state";
import qs from "query-string";
import {listHeader, RenderHashSmall} from "../Util/listView";
import {Card, withStyles} from "@material-ui/core";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Modal from '../UI/Modal/Modal'
import More from "../UI/More/More";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Edit} from "@material-ui/icons";
import wallets from "../../api/V3/wallets";
import "./WalletDialog.module.css";
import {renderHash, renderCreatedAt} from "../Util/listView";
import AddWalletDialog from "./AddWalletDialog";
import EditNoteDialog from "./EditNoteDialog";
import TransactionExportCSVDialog from "../Transactions/TransactionExportCSVDialog";
import {createMessage, notifyError} from "../Util/notification";
import {parseErrors} from "../Util/form";
import {hasPermission} from "../Util/role-helpers";

const Badge = (props) => {
    return <div style={{ background: props.type == 'IN' ? '#34bfa3' : '#ffb822' }} className="listBadge">
        {props.type}
    </div>
}

const ListBodyWithLoading = WithLoading(ListBody);

class WalletDetailsDialog extends React.Component {
    state = {
        data: [],
        count: 0,
        search: '',
        sort: '',
        page: 0,
        rowsPerPage: 10,
        isLoading: false,
        BillingOverviewOpen: false,
        editNoteDialogOpen: false,
        selectedNoteHash: '',
        selectedTransaction: null
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedWallet && prevProps.selectedWallet != this.props.selectedWallet 
            || this.state.rowsPerPage != prevState.rowsPerPage
            || this.state.page != prevState.page){
            this.loadData()
        }
    }


    loadData = async () => {
        try{
            this.setState({isLoading: true});
            let { search, page, rowsPerPage, sort } = this.state
            if(page > 0 && !this.state.data.length){//page more than 0 and no data
                page = 0;
            }
            const {data: {count, results: data}} = await wallets.getWalletTransaction(this.props.selectedWallet.id, search, page * rowsPerPage, rowsPerPage, sort);
            
            this.setState({
                data,
                count,
                isLoading: false
            });
        }catch(err){
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    };

    onExport = async (data) => {
        try{
            wallets.exportTransactionCSV(data.form.start_date, data.form.end_date);
        }catch (e) {
            notifyError(e.message)
        }
    };

    getActions = (data) => {

        const StyledListItemText = withStyles({
            root: {
                fontSize: "16px!important"
            }
        })(ListItemText)

        const StyledIcon = (Icon) => {
            return withStyles({
                root: {
                    fontSize: "24px"
                }
            })(Icon)
        }

        const StyledListItemIcon = withStyles({
            root: {
                minWidth: 24,
                marginRight: "16px"
            }
        })(ListItemIcon)

        const StyledEdit = StyledIcon(Edit)

        return {
            items: hasPermission(this.props.user, 'edit_wallet_transaction') ? [
                {
                    handler: async() => {await this.setState({selectedTransaction: data});this.toggleDialog("editNote"); },
                    // render: () => (<div styleName="more">div styleName="more"><StyledListItemIcon><StyledEdit/></StyledListItemIcon><StyledListItemText disableTypography primary="Edit note" /></div> )
                    render: () => (<><StyledListItemIcon><StyledEdit/></StyledListItemIcon><StyledListItemText disableTypography primary="Edit note" /></> )
                }
            ] : []
        }
    }

    renderFromTo = data => {
        if(!this.props.selectedWallet || !data.wallet_to) return ''
        if( data.wallet_to.address == this.props.selectedWallet.address ){
            return <Badge type="IN" />
        }
        return <Badge type='OUT' />
    }

    overviewHeaders = [
        listHeader('TxHash', "tx_hash", true, data => <RenderHashSmall bold id={`${data.tx_hash}${data.block_number}${Date.now()}`} text={data.tx_hash} />, undefined, undefined, 'left'),
        listHeader('Time', "tx_time", true, renderCreatedAt, undefined, undefined, "left"),
        listHeader('Block', "block_number", true, undefined, undefined, undefined, "left"),
        listHeader('From', "from", false, data => <RenderHashSmall id={`${data.block_number}${data.wallet_from.address}${Date.now()}`} text={data.wallet_from.address}/>, undefined, "undefined", "left" ),
        listHeader('', "from/to", false, data => this.renderFromTo(data), undefined, "undefined", "center" ),
        listHeader('To', "to", false, data => <RenderHashSmall id={`${Date.now()}${data.block_number}${data.wallet_to.address}`} text={data.wallet_to.address}/>, undefined, "undefined", "left" ),
        listHeader('Currency', "currency", true, undefined, undefined, "undefined", "left" ),
        listHeader('Value', "amount", true, data => parseFloat(data.amount).toFixed(5), undefined, "undefined", "center" ),
        listHeader('Fee', "fee", true, data => parseFloat(data.amount).toFixed(5), undefined, "undefined", "center" ),
        listHeader('Actions', 'actions', false, (data) => (<More {...this.getActions(data)} />), undefined, undefined, "center"),
    ];

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = this.state.page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage, page: newPage }, () => {
            this.loadData()
        })
    }

    requestSort = (sort) => {
        this.setState({ sort, page: 0 }, () => {
            this.loadData()
        })
    }

    toggleDialog = (dialogName) => {
        const fieldName = `${dialogName}DialogOpen`;
        this.setState({ [fieldName]:!this.state[fieldName] });
    };


    changePage = (e, page) => {
        this.setState({ page })
    }

    requestSearch = (e) => { 
        e.preventDefault(); 
        this.loadData() 
    }

    onInputChange = e => {
        this.setState({search: e.target.value}, () => {
            clearTimeout(this.debounceTimer)
            if(this.state.search == "") {
                this.loadData();
            } else {
                this.debounceTimer = setTimeout(() => {
                    this.loadData()
                }, 200)
            }
        });
    }



    render() {

        const {data, page, isLoading, rowsPerPage, count, sort} = this.state;
        return <Modal isLoading={isLoading} isOpen={this.props.isOpen} toggle={() => this.props.toggle()} submitButton={() => null} size="lg" title="Wallet Details" >

            <Card>
                <ListHeader
                    title="Transaction List"
                    type="WalletsTransaction"
                    onChange={this.onInputChange}
                    value={this.state.search}
                    onSubmit={this.requestSearch}
                    toggle={(dialog) => this.toggleDialog(dialog)}
                />
                <ListBodyWithLoading
                    padding="8px 16px"
                    sortField={sort}
                    requestSort={this.requestSort}
                    headers={this.overviewHeaders}
                    isLoading={isLoading}
                    data={data}
                    count={count}
                    currentPage={page}
                    onChangePage={this.changePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
            </Card>
            <EditNoteDialog
                toggle={() => this.toggleDialog("editNote")}
                reloadData={this.loadData}
                isOpen={this.state.editNoteDialogOpen}
                transaction={this.state.selectedTransaction}
                onSubmit={this.onExport.bind(this, data)}
            />
            <TransactionExportCSVDialog
                toggle={() => this.toggleDialog("CSV")}
                isOpen={this.state.CSVDialogOpen}
                id={this.props.selectedWallet ? this.props.selectedWallet.id : -1}
                screen="wallets"
            />
        </Modal>
    };

}


function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        user: state.user.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(WalletDetailsDialog));
