import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SideNav from "../SideNav/SideNav";
import {Col, Row} from "reactstrap";
import Button from '../UI/Button/Button'
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import {push} from 'connected-react-router';
import user from "../../api/user";
import {listHeader, listHeaders, renderEditUser, renderName} from "../Util/listView";
import {getSafe, getSafeDeep} from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';
import organization from "../../api/organization";
import brand from "../../api/brand";
import AddBrandDialog from "./AddBrandDialog";
import EditBrandDialog from "./EditBrandDialog";
import DeleteBrandDialog from "./DeleteBrandDialog";


import Page from '../UI/Page/Page'

import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Edit, Delete } from '@material-ui/icons'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import More from '../UI/More/More'
import { notifyError } from '../Util/notification';
import {hasPermission} from "../Util/role-helpers";
import {Helmet} from "react-helmet";

const ListBodyWithLoading = WithLoading(ListBody);

class BrandList extends React.Component {

    getActions = (data) => {

        const StyledListItemText = withStyles({
            root: {
                fontSize: "16px!important"
            }
        })(ListItemText)

        const StyledIcon = (Icon) => {
            return withStyles({
                root: {
                    fontSize: "24px"
                }
            })(Icon)
        }

        const StyledListItemIcon = withStyles({
            root: {
                minWidth: 24,
                marginRight: "16px"
            }
        })(ListItemIcon)

        const StyledEdit = StyledIcon(Edit)
        const StyledDelete = StyledIcon(Delete)

        const items = []

        if (hasPermission(this.props.user, 'change_franchise')) {
            items.push({
                handler: () => this.toggleEdit(data),
                render: () => (<Fragment><StyledListItemIcon><StyledEdit/></StyledListItemIcon><StyledListItemText disableTypography primary="Edit" /></Fragment> )
            })
        }

        if (hasPermission(this.props.user, 'delete_franchise')) {
            items.push({
                handler: () => this.toggleDelete(data),
                render: () => (<Fragment><StyledListItemIcon><StyledDelete/></StyledListItemIcon><StyledListItemText disableTypography primary="Delete" /></Fragment> )
            })
        }

        return {
            items
        }
    }

    brandListHeaders = [
        listHeader('ID', "id", true),
        listHeader('Name', "name", true),
        listHeader('Logo', "logo", false, ({logo}) => <img height="40px" src={logo}></img>, undefined, undefined, "center"),
        listHeader('Tag', "tag.display_name", false),
        listHeader('Actions', 'actions', false, (data) => (<More {...this.getActions(data)} />), undefined, undefined, "right")
    ];

    state = {
        isLoading: true,
        addBrandDialogOpen: false,
        deleteBrandDialogOpen: false,
        deleteBrandData: undefined,
        editBrandDialogOpen: false,
        editBrandData: undefined,
        rowsPerPage: 10,
        sort: '',
        count: 0
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, prevState) {
        const {query, auth} = this.props;
        const {query: oldQuery, auth: oldAuth} = oldProps;
        if (!immutable.is(query, oldQuery) || this.state.rowsPerPage != prevState.rowsPerPage){
            this.loadData();
        }
    }

    toggleDialog = (dialogName) => {
        const fieldName = `${dialogName}DialogOpen`;
        this.setState({ [fieldName]:!this.state[fieldName] });
    };

    toggleEdit = (data) => {
        this.setState({editBrandData: data});
        this.toggleDialog("editBrand");
    };

    toggleDelete = (data) => {
        this.setState({deleteBrandData: data});
        this.toggleDialog("deleteBrand");
    };

    loadData = async () => {
        // let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        try{
            const query = this.props.query
            const search = query.search;
            let sort = query.sort ? query.sort : "id";
            const page = query.page || 0;
            query.sort = sort;
            this.setState({isLoading: true});
            const {data: {count, results: data}} = await brand.listPaginated("merchant", search, page * this.state.rowsPerPage, this.state.rowsPerPage, sort);
            this.setState({count, data, isLoading: false});
            if(query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
        }catch(err){
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = {...this.props.query};
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    requestSort = (sort) => {
        this.setState({sort});
        const query = {...this.props.query};
        query.page = 0;
        query.sort = sort
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    render() {
        const {isLoading, data, rowsPerPage, count} = this.state;

        return <Fragment>
            <Helmet>
                <title>Brands | Elicon</title>
            </Helmet>
            <Page permission={'view_all_franchises'}>
                <Card>
                    <ListHeader
                        type="BrandList"
                        title = "Brand List"
                        searchTitle = "Search"
                        actionHandler = { () => this.toggleDialog("addBrand") }
                        actionLabel = "Add New Brand"
                        />
                    <ListBodyWithLoading
                        sortField={this.state.sort}
                        requestSort={this.requestSort}
                        headers={this.brandListHeaders}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                        />
                </Card>
            </Page>
            <AddBrandDialog
                toggle={() => this.toggleDialog("addBrand")}
                isOpen={this.state.addBrandDialogOpen}
                reloadData={() => this.loadData()}
            />
            <EditBrandDialog
                toggle={() => this.toggleDialog("editBrand")}
                isOpen={this.state.editBrandDialogOpen}
                reloadData={() => this.loadData()}
                data={this.state.editBrandData}
            />
            <DeleteBrandDialog
                toggle={() => this.toggleDialog("deleteBrand")}
                isOpen={this.state.deleteBrandDialogOpen}
                reloadData={() => this.loadData()}
                data={this.state.deleteBrandData}
                />
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        user: state.user.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(BrandList));
