import React from "react";
import Button from '../UI/Button/Button'
import {Link} from "react-router-dom";
import {supportedLanguages, supportedLanguagesList} from "../Util/language";
import {getLoyaltyInfoRoute} from "../Util/routing";

export const ALPHABET_LOWER = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

export function renderAnswers(item){
    return item.answers.length;
}

export function renderTranslateButton(item){
    return <Button color="outline-primary" >Translate</Button>
}

export function renderReocurring(item){
    if(item.repeatable) return "Yes";
    return "No";
}

export function renderQuestionLink(item, language){
    const path = getLoyaltyInfoRoute(item.question_id);
    if(language.lang_code == supportedLanguagesList[0].lang_code) return <Link style={{color: "#5767DD"}} to={path}>{item.value}</Link>;
    const translation = item.translations.find(iter => iter.lang_code == language.lang_code);
    if(translation) return <Link style={{color: "#5767DD"}} to={path}>{translation.value}</Link>;
    return <Link style={{color: "#5767DD"}} to={path}>{item.value}</Link>;
}

