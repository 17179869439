import React, {Fragment} from 'react';
import Modal from '../UI/Modal/Modal'
import Input from '../UI/Input/Input'
import { Form, Label, Row} from "reactstrap";
import {createMessage} from "../Util/notification";
import {parseErrors} from "../Util/form";
import {changeStateFromInput} from "../Util/controlledComponent";
import kyc from "../../api/kyc";

class KYCCommentDialog extends React.Component {

    state = {
        comment: "",
        isLoading: false,
    };

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true}); 
        try{
            await kyc.comment(this.props.data.id, this.state.comment);
            createMessage('Successfully commented KYC request');
            this.props.toggle();
            this.props.toggleParent();
            this.setState({ isLoading: false }); 
        }catch (e) {
            this.setState({ errors: parseErrors(e), isLoading: false });
        }
    };

    onInput = (e) => {
        this.setState(changeStateFromInput(e));
    };

    render() {
        const {toggle, isOpen} = this.props;
        return (
            <Modal  toggle={toggle}
                    title="Comment KYC Request"
                    isOpen={isOpen} 
                    isLoading={this.state.isLoading}
                    onSubmit={(e) => this.onSubmit(e)}
                    size="sm">
                <Form onSubmit={(e) => this.onSubmit(e)}>
                    <Label className="mb-2" for={"comment"}>Comment</Label>
                    <Input disabled={this.state.isLoading} type="textarea" name="comment" onChange={(e) => this.onInput(e)} value={this.state.comment} />
                </Form>
            </Modal>
        )
    };

}

export default KYCCommentDialog;
