import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isFunction } from "../../types";
import { bindActionCreators } from 'redux';
// import {Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavLink, Table} from "reactstrap";
import qs from 'qs';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from "react-paginate";

import "./table.css";
import WithLoading from "../../HOC/WithLoading";
import { getSafeDeep } from "../../state";


import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const TableHeader = withStyles({
    root: {
        minHeight: "42px",
        height: "42px",
        background: "#fafafa"
    }
})(TableHead)

const SortableTableCell = (props) => {

    return (
        <TableCell classes={props.classes} align={props.align}>
            {props.isSortable ?
                (<TableSortLabel
                    active={props.sort == props.fieldKey.replace('.', '__') || props.sort == "-" + props.fieldKey.replace('.', '__')}
                    direction={props.sort == props.fieldKey.replace('.', '__') ? 'asc' : 'desc'}
                    onClick={() => {
                        if (props.sort) {//if not first sort
                            if (props.sort == props.fieldKey.replace('.', '__') || props.sort == "-" + props.fieldKey.replace('.', '__')) {
                                //if already sorted by this field revers
                                if (props.sort.startsWith('-')) {//if desc make asc
                                    props.requestSort(props.fieldKey.replace('.', '__'))
                                } else {// else if asc make is desc
                                    props.requestSort('-' + props.fieldKey.replace('.', '__'))
                                }
                            } else {
                                props.requestSort('-' + props.fieldKey.replace('.', '__'))

                            }
                        } else {//if the first sort
                            props.requestSort('-' + props.fieldKey.replace('.', '__'))
                        }
                    }}
                >
                    {isFunction(props.label) ? props.label() : props.label}
                </ TableSortLabel>)
                : isFunction(props.label) ? props.label() : props.label}
        </TableCell>
    )
}

const HeaderTableCellWithPadding = (padding) => {
    return withStyles({
        head: {
            color: 'rgba(0,0,0,.54)',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: "11px",
            padding,
        }
    })(SortableTableCell)
}

const BodyTableCellWithPadding = (padding) => {
    return withStyles({
        body: {
            fontWeight: 300,
            fontSize: 14,
            padding,
        }
    })(TableCell)
}

const TableRowStyled = withStyles({
    root: {
        minHeight: "42px",
        height: "53px",
    }
})(TableRow)

const TableRowTotal = withStyles({
    root: {
        minHeight: "42px",
        height: "42px",
        backgroundColor: "#fafafa",
    }
})(TableRow)

const TotalTableCell = withStyles({
    root: {
        fontWeight: 500,
        color: "rgba(0,0,0,.87)",
        fontSize: "14px",
        padding: "4px 24px",
    }
})(TableCell)

const TablePaginationStyled = withStyles({
    root: {
        fontWeight: 300,
        color: "rgba(0,0,0,.54)"
    },
    select: {
        fontWeight: 400,
        color: "rgba(0, 0, 0, 0.87)",
        borderBottom: "1px solid rgba(0,0,0,.42)"
    }
})(TablePagination)

class ListBody extends React.Component {

    state = {
        sortDropdownOpen: false,
        filterDropdownOpen: false
    };

    toggleDropdown = (key) => {
        const keyName = `${key}DropdownOpen`;
        this.setState({ [keyName]: !this.state[keyName] });
    };

    changeSort = (key, desc) => {
        const query = { ...this.props.query };
        query.sort = key;
        query.page = 0;
        query.desc = desc;
        this.props.push(`?${qs.stringify(query)}`);
    };

    changeFilter = (queryKey, queryValue) => {
        const query = { ...this.props.query };
        query[queryKey] = queryValue;
        query.page = 0;
        this.props.push(`?${qs.stringify(query)}`);
    };

    componentDidMount() {
        this.checkIfInvalidPageNumber()
    }

    componentDidUpdate() {
        this.checkIfInvalidPageNumber()
    }

    checkIfInvalidPageNumber = () => {
        if (this.props.count > 0) {//data loaded
            if (this.props.query.page > 0 && !this.props.data.length) {//page more than 0 and no data
                const query = { ...this.props.query };
                query.page = 0;
                this.props.push(`?${qs.stringify(query)}`);
            }
        }
    }

    // renderHeadersForEntry = (data, headers) => {
    //     return headers.map((header, index) => {
    //         return <td key={`${header.label}-${index}`}>
    //             {header.linkTo ?
    //                 <Link to={header.linkTo}>
    //                     {isFunction(header.displayFunction) ? header.displayFunction(data) : getSafeDeep(data, header.fieldKey)}
    //                 </Link>
    //                 : isFunction(header.displayFunction) ? header.displayFunction(data) : getSafeDeep(data, header.fieldKey)
    //             }</td>
    //     })
    // };
    renderHeadersForEntry = (data, headers, BodyTableCell) => {

        return headers.map((header, index) => {
            if (!header) return null;

            return <BodyTableCell align={header.align} key={`${index}`}>
                {header.linkTo ?
                    <Link to={header.linkTo}>
                        {isFunction(header.displayFunction) ? header.displayFunction(data) : getSafeDeep(data, header.fieldKey)}
                    </Link>
                    : isFunction(header.displayFunction) ? header.displayFunction(data) : getSafeDeep(data, header.fieldKey)
                }</BodyTableCell>
        })
    };


    // handlePageClick = (page) => {
    //     const query = {...this.props.query};
    //     query.page = page.selected;
    //     this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    // };
    handlePageClick = (page) => {
        const query = { ...this.props.query };
        query.page = page;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    };

    buildPaginationLink = (index) => {
        const query = { ...this.props.query };
        query.page = index;
        return `${this.props.location.pathname}?${qs.stringify(query)}`;
    };

    getData = (headers, data) => {
        const ret = data.map(item => {
            let cell = {}
            headers.forEach(header => {
                if (header) {
                    cell[header.field] = isFunction(header.displayFunction) ? header.displayFunction(item) : getSafeDeep(item, header.field)
                }
            })
            return cell;
        })
        return ret;
    }

    onChangePage = (e, page) => {
        this.handlePageClick(page)
    }

    render() {
        let { sortField, requestSort, padding, onChangeRowsPerPage, headers, count, query: { sort, page, desc, filter }, data, filters, filterBy, rowsPerPage, totalAmount } = this.props;
        const sortableHeaders = headers.filter(header => header && header.isSortable == true);
        const { sortDropdownOpen, filterDropdownOpen } = this.state;

        const { pagination, total } = this.props;
        const wrapped = this.props.wrapped ? true : false;
        page = parseInt(page || 0, 10);

        const BodyTableCell = this.props.padding ? BodyTableCellWithPadding(padding) : BodyTableCellWithPadding("4px 24px")
        const HeaderTableCell = this.props.padding ? HeaderTableCellWithPadding(padding) : HeaderTableCellWithPadding("16px 24px")

        return (
            <TableContainer>
                {data && data.length > 0 ?
                    <Fragment>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    {headers.map((item, i) => {
                                        if (!item) return null;

                                        return (
                                            <HeaderTableCell {...item} sort={sortField} requestSort={requestSort} key={i} />
                                        )
                                    })}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {data.map((dataEntry, index) => {
                                    return <TableRowStyled hover key={`${data}-${index}`}>
                                        {this.renderHeadersForEntry(dataEntry, headers, BodyTableCell)}
                                    </TableRowStyled>
                                })}
                                {total &&
                                    <TableRowTotal>
                                        <TotalTableCell colSpan={2}>Total</TotalTableCell>
                                        <TotalTableCell colSpan={2} align="right">{totalAmount}</TotalTableCell>
                                    </TableRowTotal>
                                }
                            </TableBody>
                        </Table>
                        {!pagination &&
                            <TablePaginationStyled
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                onChangePage={this.props.onChangePage ? this.props.onChangePage : this.onChangePage}
                                labelRowsPerPage="Items per page:"
                                page={this.props.currentPage ? this.props.currentPage : page}
                            />
                        }
                    </Fragment>
                    :
                    <div className="d-flex justify-content-center p-5">No results found.</div>
                }
            </TableContainer>)
    };

}

ListBody.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        fieldKey: PropTypes.string.isRequired,
        displayFunction: PropTypes.func,
        linkTo: PropTypes.func,
        isSortable: PropTypes.bool
    })).isRequired,
    filters: PropTypes.array,
    pages: PropTypes.number,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    totalAmount: PropTypes.number,
};

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(ListBody));
