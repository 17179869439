import React, {Fragment, useState} from "react";
import Page from "../../UI/Page/Page";
import {Card} from "@material-ui/core";
import ListHeader from "../../Util/ListView/ListHeader/ListHeader";
import qs from "query-string";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import WithLoading from "../../Util/HOC/WithLoading";
import ListBody from "../../Util/ListView/ListBody/ListBody";
import {listHeader, renderExchangeBalanceCurrency} from "../../Util/listView";
import {Helmet} from "react-helmet";
import exchange from "../../../api/V3/exchange";
import immutable from "immutable";


const ListBodyWithLoading = WithLoading(ListBody);


class BalanceList extends React.Component {
    state = {
        exchange_id: this.props.location.pathname.split("/")[3],
        data: [],
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const {query, auth} = this.props;
        const {query: oldQuery, auth: oldAuth} = prevProps;
        if (!immutable.is(query, oldQuery) || this.state.rowsPerPage != prevState.rowsPerPage){
            this.loadData();
        }

    }

    loadData = async () => {
        this.setState({isLoading: true});
        const data = await exchange.balanceList(this.state.exchange_id);

        let newArr = Object.entries(data.data).filter((item) => item !== ["0", "1"]);
        let total = 0.0;
        newArr.map(item => {
            total = total + parseFloat(item[1].conversion.amount)
        })


        total = total.toFixed(4) + " EUR"

        await this.setState({
            data: newArr,
            totalAmount: total,
            isLoading: false,
        });
    };


    balanceListHeaders = [
        listHeader('Currency', "currency", false, (data) => renderExchangeBalanceCurrency(data[0])),
        listHeader('Value', "[1].total", false, data =>  {return data[1].total.toFixed(4)}, undefined, undefined, "right"),
        listHeader('Price', "amount", false, data => {return data[1].conversion.rate.toFixed(4)}, undefined, undefined, "right"),
        listHeader('Value (€)', "euro_amount", false, data => {return data[1].conversion.amount.toFixed(4)}, undefined, undefined, "right" )
    ];





    render() {

        const {data, isLoading} = this.state;



        return <Fragment>
            <Helmet>
                <title>Exchange Balances | Elicon</title>
            </Helmet>
            <Page >
                <Card>
                    <ListHeader
                        type="OnlyTitle"
                        title={"Balance List"}
                    />
                    <ListBodyWithLoading
                        headers={this.balanceListHeaders}
                        isLoading={isLoading}
                        data={data}
                        pagination={true}
                        total={true}
                        totalAmount={this.state.totalAmount}
                    />
                </Card>
            </Page>
        </Fragment>
    };

}


function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(BalanceList));
