import React, { useState } from 'react'

import Modal from '../../UI/Modal/Modal'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Select from '../../UI/Select/Select'
import Link from '../../UI/Link/Link'

const units = [
    { value: 'eur', label: "EUR" },
    { value: 'hrk', label: "HRK" },
    { value: 'eth', label: "ETH" },
    { value: 'perc', label: "%" },
]

const CreateNewLabel = (props) => {
    
    const [open, setOpen] = useState(false)
    const [amount, setAmount] = useState('')
    const [unit, setUnit] = useState('')

    const toggle = () => {
        setOpen(!open)
    }

    const submit = ( ) => {
        alert('submited')
    }

    let modal = null;

    if(props.createNew == "Brand"){
        modal =  null
    }else { //exchange fee or transaction free 
        modal = (
            <Modal  title={`Create new ${props.createNew} Fee`} 
                    isOpen={open} 
                    submitButton={() => <Button onClick={submit} disabled={!(amount && unit)} className="ml-2" color="danger">Create</Button>}
                    cancelLabel="Close"
                    toggle={toggle}>
                <div className="d-flex">
                    <div className="mr-3 flex-1">
                        <label>Amount</label>
                        <Input 
                            type="text" 
                            value={amount} 
                            onChange={e => setAmount(e.target.value)} 
                            placeholder="Insert value" />    
                    </div>
                    <div className="flex-1">
                        <label>Amount</label>
                        <Select
                            options={units}
                            placeholder="Select unit"  
                            onChange={setUnit} 
                            value={unit}
                            label="Unit" />
                    </div>
                </div>
            </Modal>
        )
    }

    const styles = {
        color: "#575962", 
        cursor: "pointer"
    }

    return (
        <div className="d-flex">
            {props.children}
            <Link className="ml-auto" onClick={props.onClick ? props.onClick : toggle}>Create New</Link>
            {modal}
        </div>
    )
}

export default CreateNewLabel
