import {fulfilled, fulfilledState, initState, pending, pendingState, rejected, rejectedState} from "./middleware";
import auth from "../api/auth";


export const SET_GLOBAL_ERROR = "SET_GLOBAL_ERROR";

export const SET_REASON = "SET_REASON";

export default (state = initState, action) => {
    switch (action.type){
        case SET_GLOBAL_ERROR:
            return rejectedState(state, action.error);
        case pending(SET_REASON):
            return pendingState(state);
        case fulfilled(SET_REASON):
            return fulfilledState(state, action.payload);
        case rejected(SET_REASON):
            return rejectedState(state, action.payload);
        default:
            return state;
    }
};

export const setError = (error) => ({
    type: SET_GLOBAL_ERROR,
    error
});

export const setReason = (reason) => ({
    type: SET_REASON,
    payload: auth.setReason(reason)
});
