import {apiClientV3} from "../util";

export default {
    get: (search, offset=0, limit=10, ordering) => {
        let params = {
            limit,
            offset,
        }

        if(ordering){
            params.ordering = ordering
        }
        if(search){
            params.search = search;
        }
        return apiClientV3.get(`admin/company/withdraw/pending/`,{params} )
    },

    approve: (id) => apiClientV3.post(`admin/company/withdraw/${id}/approve`),

    decline: (id) => apiClientV3.post(`admin/company/withdraw/${id}/decline`),

    getCompanyWithdraw: (id) => apiClientV3.get(`admin/company/withdraw/${id}`)
}



