import {fulfilled, fulfilledState, initState} from "./middleware";
import tier from "../api/tier";

export const GET_TIER_LIST = "GET_TIER_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_TIER_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getTierList = () => ({
    type: GET_TIER_LIST,
    payload: tier.list()
});
