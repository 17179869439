import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Select from '../UI/Select/Select';
import {createMessage, notifyError} from '../Util/notification';
import DynamicForm from '../DynamicForm/DynamicForm';
import {
  deepCopy,
  getSafeDeep,
  stripByRule,
  removeEmptyFields
} from '../Util/state';
import {objectToArray, parseErrors} from '../Util/form';
import editKYCDataSchema from './schema/editKycData';
import editKYCUiSchema from './schema/editKycUi';
import editKYCPassportDataSchema from './schema/editKycPassportData';
import editKYCPassportUiSchema from './schema/editKycPassportUi';
import kyc from '../../api/kyc';
import moment from 'moment';

class KYCEditDialog extends React.Component {
  state = {
    errors: undefined,
    documentType: undefined,
    isLoading: false
  };

  componentDidMount() {
    this.loadSchema();
  }

  extractImages = (data) => {
    const images = {
      photo: data.document.photo ? data.document.photo.split(',')[1] : '',
      front_image: data.document.document_front_image
        ? data.document.document_front_image.split(',')[1]
        : '',
      back_image: data.document.document_back_image
        ? data.document.document_back_image.split(',')[1]
        : ''
    };
    delete data.document.photo;
    delete data.document.document_front_image;
    delete data.document.document_back_image;

    if (!images.photo) delete images.photo;
    if (!images.document_front_image) delete images.document_front_image;
    if (!images.document_back_image) delete images.document_back_image;

    return images;
  };

  handleApiRequest = async (data) => {
    const userId = this.props.data.id;
    const document = deepCopy(data);
    const images = this.extractImages(document);
    await kyc.update(userId, document);
    await kyc.updateImages(userId, images);
  };

  onSubmit = async (data) => {
    this.setState({isLoading: true});
    try {
      const dataCopy = deepCopy(data);
      stripByRule(dataCopy, (f) => f === '');
      let submitData = removeEmptyFields(dataCopy.form);

      await this.handleApiRequest(submitData);
      createMessage('Successfully edited KYC request');
      this.props.reloadData();
      this.props.toggle();
    } catch (e) {
      console.error(e);
      notifyError(e.message);
      this.setState({errors: parseErrors(e)});
    } finally {
      this.setState({isLoading: false});
    }
  };

  handleDocumentTypeChange = (e) => {
    let dataSchema = editKYCDataSchema;
    let uiSchema = editKYCUiSchema;
    if (parseInt(e.id) === 1) {
      dataSchema = editKYCPassportDataSchema;
      uiSchema = editKYCPassportUiSchema;
    }

    this.setState({
      documentType: e,
      dataSchema,
      uiSchema
    });
  };

  loadSchema = async () => {
    if (!this.props.data.profile) return;
    const documentTypeId = this.props.data.profile.document[0].type;
    const documentType = this.props.documentTypes.find(
      (item) => parseInt(item.id) === documentTypeId
    );
    const uiSchema =
      documentTypeId === 1 ? editKYCPassportUiSchema : editKYCUiSchema;
    const dataSchema =
      documentTypeId === 1 ? editKYCPassportDataSchema : editKYCDataSchema;

    this.setState({uiSchema, dataSchema, documentType});
  };

  render() {
    const {toggle, isOpen, data, documentTypes} = this.props;
    const {errors} = this.state;
    if (!isOpen) return '';

    let profile = deepCopy(data.profile);

    const modal = {
      toggle,
      isOpen,
      errors,
      title: 'Edit KYC',
      size: 'lg'
    };

    return (
      <DynamicForm
        uiSchema={this.state.uiSchema}
        dataSchema={this.state.dataSchema}
        modal={modal}
        isLoading={this.state.isLoading}
        renderBefore={() => (
          <>
            <label>Document Type</label>
            <Select
              isDisabled={this.state.isLoading}
              className="mb-3"
              placeholder="Document type"
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              value={this.state.documentType}
              onChange={this.handleDocumentTypeChange}
              options={this.props.documentTypes}
              name={'documentType'}
            />
          </>
        )}
        initData={{
          documentTypes,
          form: {
            identity_number: profile.identity_number,
            birth_date: getSafeDeep(
              profile,
              'birth_date',
              moment().format('yyyy-DD-MM')
            ),
            document: {
              ...profile.document[0],
              type: this.state.documentType.id,
              photo: profile.photo
            }
          }
        }}
        errors={errors}
        onSubmit={(data) => {
          this.onSubmit(data);
        }}
      />
    );
  }
}

function mapState(state) {
  return {
    documentTypes: objectToArray(state.documentTypes.data, 'id', 'name')
  };
}

function mapActions(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(KYCEditDialog));
