import {IconButton} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core/styles';
import {Search} from '@material-ui/icons';
import {push} from 'connected-react-router';
import {getName} from 'country-list';
import qs from 'query-string';
import React, {Fragment, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import organization from '../../api/organization';
import company from '../../api/V3/company';
import exportApi from '../../api/V3/export';
import Badge from '../UI/Badge/Badge';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import Input from '../UI/Input/Input';
import Modal from '../UI/Modal/Modal';
import Page from '../UI/Page/Page';
import WithLoading from '../Util/HOC/WithLoading';
import {
  listHeader,
  renderDoneClear,
  renderFullDate,
  renderLink
} from '../Util/listView';
import ListBody from '../Util/ListView/ListBody/ListBody';
import ListHeader from '../Util/ListView/ListHeader/ListHeader';
import {createMessage} from '../Util/notification';
import {getOrganizationInfoRoute, routes} from '../Util/routing';
import {getSafeDeep} from '../Util/state';
import AddOrganizationDialog from './AddOrganizationDialog';

const ListBodyWithLoading = WithLoading(ListBody);

export const renderKybStatus = (data) =>
  [
    'Not started',
    'Not started',
    'Not started',
    'Not started',
    'In process',
    `Call missed (${renderFullDate(data.kyb_call_missed_at)})`,
    `Rejected (${renderFullDate(data.kyb_rejected_at)})`,
    `Passed (${renderFullDate(data.kyb_approved_at)})`
  ][data.kyb_status];

export const steps = ['First Lead', 'Second Lead'];

export const approvalStates = ['Pending', 'Approved', 'Rejected', 'Terminated'];

const RejectModal = ({isOpen, toggle, companies, onRejectClick, isLoading}) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setReason('');
    setError('');
  }, [isOpen]);

  const onSubmit = () => {
    if (reason.trim().length === 0) {
      return setError('This field is required');
    }
    onRejectClick(reason);
  };

  return (
    <Modal
      title="Reject multiple companies"
      isOpen={isOpen}
      toggle={toggle}
      isLoading={isLoading}
      submitButton={() => (
        <Button color="danger" isLoading={isLoading} onClick={onSubmit}>
          Reject
        </Button>
      )}
    >
      <div>Are you sure you want to reject the following companies:</div>
      <ul className="my-3">
        {companies.map((company) => (
          <li key={company.id}>{company.full_name}</li>
        ))}
      </ul>
      <div>
        <label htmlFor="reason">Reason</label>
        <Input
          placeholder="Enter reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <small className="text-danger">{error}</small>
      </div>
    </Modal>
  );
};

class OrganizationList extends React.Component {
  state = {
    isLoading: true,
    addOrganizationDialogOpen: false,
    count: 0,
    rowsPerPage: 10,
    sort: '-created',
    selected: [],
    data: [],
    exportOpen: false,
    exportInProgress: false,
    rejectDialogOpen: false,
    rejectInProgress: false
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(oldProps, prevState) {
    const {query, auth} = this.props;
    const {query: oldQuery, auth: oldAuth} = oldProps;
    if (
      JSON.stringify(query) !== JSON.stringify(oldQuery) ||
      this.state.rowsPerPage !== prevState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  toggleDialog = (dialogName) => {
    const fieldName = `${dialogName}DialogOpen`;
    this.setState({[fieldName]: !this.state[fieldName]});
  };

  loadData = async () => {
    {
      let {page, search, sort, pageSize, ...filter} = {...this.props.query};
      this.setState({isLoading: true});
      try {
        const response = await organization.listPaginated(
          search,
          (page || 0) * (pageSize || 10),
          pageSize || 10,
          sort,
          filter
        );

        if (this.props.query.search !== search) {
          this.setState({isLoading: false});
        } else {
          this.setState({
            count: response.data.count,
            data: response.data.results,
            isLoading: false
          });
        }
      } catch (e) {
        createMessage(
          getSafeDeep(
            e,
            'response.data.message',
            getSafeDeep(e, 'response.data', e.message)
          ),
          'error'
        );
      } finally {
        this.setState({isLoading: false});
      }
    }
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    const query = {...this.props.query};
    let currentItem = query.page * query.pageSize || 0;
    query.page = Math.floor(currentItem / newRowsPerPage);
    query.pageSize = newRowsPerPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  requestSort = (sort) => {
    this.setState({sort});
    const query = {...this.props.query};
    query.page = 0;
    query.sort = sort;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  checkBoxHeader = () => {
    const StyledCheckbox = withStyles({
      root: {
        '&:hover': {
          backgroundColor: 'rgba(88, 103, 221, 0.04) !important'
        }
      },
      checked: {
        color: 'rgb(88, 103, 221) !important',
        '&:hover': {
          backgroundColor: 'rgba(88, 103, 221, 0.04) !important'
        }
      },
      indeterminate: {
        color: 'rgb(88, 103, 221) !important'
      }
    })(Checkbox);

    const onItemClick = (id) => {
      this.setState((prev) => ({
        ...prev,
        selected:
          prev.selected.indexOf(id) === -1
            ? prev.selected.concat(id)
            : prev.selected.filter((itemID) => itemID !== id)
      }));
    };

    const onHeaderClick = () => {
      this.setState((prev) => ({
        ...prev,
        selected:
          prev.selected.length === prev.data.length
            ? []
            : prev.data.map((item) => item.id)
      }));
    };

    return listHeader(
      () => (
        <StyledCheckbox
          onClick={onHeaderClick}
          indeterminate={
            this.state.selected.length > 0 &&
            this.state.selected.length < this.state.data.length
          }
          checked={this.state.selected.length === this.state.data.length}
          inputProps={{'aria-label': 'select all items'}}
        />
      ),
      'checkbox',
      false,
      (item) => (
        <StyledCheckbox
          inputProps={{'aria-label': item.id}}
          onClick={() => onItemClick(item.id)}
          checked={this.state.selected.indexOf(item.id) !== -1}
        />
      )
    );
  };

  getOrganizationListHeaders = () => {
    const StyledCheckbox = withStyles({
      root: {
        '&:hover': {
          backgroundColor: 'rgba(88, 103, 221, 0.04) !important'
        }
      },
      checked: {
        color: 'rgb(88, 103, 221) !important',
        '&:hover': {
          backgroundColor: 'rgba(88, 103, 221, 0.04) !important'
        }
      },
      indeterminate: {
        color: 'rgb(88, 103, 221) !important'
      }
    })(Checkbox);

    const onItemClick = (item) => {
      this.setState((prev) => ({
        ...prev,
        selected: !prev.selected.find((selected) => item.id === selected.id)
          ? prev.selected.concat(item)
          : prev.selected.filter((selected) => selected.id !== item.id)
      }));
    };

    const onHeaderClick = () => {
      this.setState((prev) => ({
        ...prev,
        selected: prev.selected.length === prev.data.length ? [] : prev.data
      }));
    };

    const generateApprovalRecord = (item) => {
      if (item.step < 2) {
        if (item.approval_state === 1) {
          return approvalStates[item.approval_state];
        }
        return steps[item.step];
      }
      return approvalStates[item.approval_state];
    };

    return [
      listHeader(
        () => (
          <StyledCheckbox
            onClick={onHeaderClick}
            indeterminate={
              this.state.selected.length > 0 &&
              this.state.selected.length < this.state.data.length
            }
            checked={this.state.selected.length === this.state.data.length}
            inputProps={{'aria-label': 'select all items'}}
          />
        ),
        'checkbox',
        false,
        (item) => (
          <StyledCheckbox
            inputProps={{'aria-label': item.id}}
            onClick={() => onItemClick(item)}
            checked={this.state.selected.find(
              (selected) => selected.id === item.id
            )}
          />
        )
      ),
      listHeader('Company Name', 'full_name', true, (item) => {
        return renderLink(item.full_name, getOrganizationInfoRoute(item.id));
      }),
      listHeader('Referral Name', 'referrals__owner', true, (data) =>
        getSafeDeep(data, 'referrals.0.owner', 'None')
      ),
      listHeader('Country', 'primary_address.country', false, (item) =>
        item.primary_address ? getName(item.primary_address.country) : ''
      ),
      listHeader('Status', 'approval_state', false, (item) =>
        generateApprovalRecord(item)
      ),
      listHeader(
        'CEO KYC',
        'kyb_status',
        true,
        (item) => renderDoneClear(item.kyb_status >= 7),
        undefined,
        undefined,
        'center'
      ),
      listHeader(
        'Number of Pending PoS',
        'pendingPos',
        false,
        (item) => {
          let color = 'info';
          if (item.count_all_pending_sources === 2) {
            color = 'warning';
          } else if (item.count_all_pending_sources > 2) {
            color = 'danger';
          }
          return <Badge color={color} val={item.count_all_pending_sources} />;
        },
        undefined,
        undefined,
        'center'
      ),
      listHeader('Created', 'created', true, (data) =>
        renderFullDate(data.created)
      ),
      listHeader(
        'Actions',
        'actions',
        false,
        (item) => (
          <Link to={getOrganizationInfoRoute(item.id)}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
        ),
        undefined,
        undefined,
        'center'
      )
    ];
  };

  onExport = async () => {
    this.setState({exportInProgress: true});
    try {
      let {search, sort, created_from, created_to, ...filter} = {
        ...this.props.query
      };

      const response = await exportApi.exportCompanyList(search, sort, {
        ...filter,
        date_from: created_from,
        date_to: created_to
      });
      const href = URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('A');
      a.href = href;
      a.download = 'company-list-export.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(href);
      this.setState({exportOpen: false});
    } catch (e) {
      console.error(e);
      createMessage(
        getSafeDeep(
          e,
          'response.data.message',
          getSafeDeep(e, 'response.data', e.message)
        ),
        'error'
      );
    } finally {
      this.setState({exportInProgress: false});
    }
  };

  onRejectClick = async (reason) => {
    this.setState({rejectInProgress: true});
    try {
      await company.companyActionBulk(
        this.state.selected.map((it) => it.id),
        'reject',
        reason
      );
      this.setState({selected: []});
      this.toggleDialog('reject');
      this.loadData();
    } catch (e) {
      console.error(e);
      createMessage(
        getSafeDeep(
          e,
          'response.data.message',
          getSafeDeep(e, 'response.data', e.message)
        ),
        'error'
      );
    } finally {
      this.setState({rejectInProgress: false});
    }
  };

  render() {
    const {isLoading, data, count} = this.state;
    const modal = {
      toggle: () => this.setState({exportOpen: false}),
      isOpen: this.state.exportOpen,
      title: 'Export Stores',
      size: 'md',
      confirmLabel: 'Export'
    };
    return (
      <Fragment>
        <Helmet>
          <title>Companies | Elicon</title>
        </Helmet>
        <Page permission={'view_all_companies'}>
          <Card>
            <ListHeader
              type="CompanyList"
              title="Company List"
              searchTitle="Search"
              actionHandler={() =>
                this.props.history.push(routes.newOrganization)
              }
              rejectHandler={() => this.toggleDialog('reject')}
              exportLoading={this.state.exportInProgress}
              onExportClicked={this.onExport}
            />
            <ListBodyWithLoading
              sortField={this.state.sort}
              requestSort={this.requestSort}
              padding="4px"
              headers={this.getOrganizationListHeaders()}
              isLoading={isLoading}
              data={data}
              count={count}
              rowsPerPage={this.props.query.pageSize || 10}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
            <RejectModal
              isOpen={this.state.rejectDialogOpen}
              onRejectClick={this.onRejectClick}
              isLoading={this.state.rejectInProgress}
              toggle={() => this.toggleDialog('reject')}
              companies={this.state.selected}
            />
          </Card>
        </Page>
        <AddOrganizationDialog
          isOpen={this.state.addOrganizationDialogOpen}
          toggle={() => this.toggleDialog('addOrganization')}
          reloadData={() => this.loadData()}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(OrganizationList));
