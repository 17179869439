import React, {useState} from 'react';
import {createMessage, notifyError} from '../Util/notification';
import {getSafeDeep} from '../Util/state';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import user from '../../api/user';

const UnblockLoginDialog = ({isOpen, toggle, data, reloadData}) => {
  const [isLoading, setLoading] = useState(false);

  const onUnblockLogin = async () => {
    if (!getSafeDeep(data, 'is_login_blocked')) return;
    setLoading(true);

    try {
      await user.update(getSafeDeep(data, 'id'), {
        ...data,
        is_login_blocked: false
      });
      toggle();
      reloadData();
      createMessage('Success');
    } catch (e) {
      console.error(e);
      notifyError(getSafeDeep(e, 'response.data.message', e.message), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Unblock Login"
      isOpen={isOpen}
      toggle={toggle}
      isLoading={isLoading}
      submitButton={() => (
        <Button color="primary" isLoading={isLoading} onClick={onUnblockLogin}>
          Yes
        </Button>
      )}
    >
      <span>
        Are you sure you want to unblock{' '}
        <strong>{`${getSafeDeep(data, 'first_name')} ${getSafeDeep(
          data,
          'last_name'
        )}`}</strong>{' '}
        user?
      </span>
    </Modal>
  );
};

export default UnblockLoginDialog;
