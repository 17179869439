import React from 'react'

import { Form } from 'reactstrap'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Select from '../../../UI/Select/Select'

import './styles.module.css'
import { PermissionGuard } from "../../role-helpers";

const kybStatuses = [
    { value: 1, label: "Representative Saved" },
    { value: 2, label: "Documents Saved" },
    { value: 3, label: "Steps Completed" },
    { value: 4, label: "Call Scheduled" },
    { value: 7, label: "Approved" }
]

export default props => {
    return (
        <div>
            <div styleName="header" className="d-flex align-items-center justify-content-end">
                <h4 className="mr-auto">{props.title}</h4>
                <PermissionGuard code="create_company_kyb">
                    <Button onClick={props.actionHandler} className="mr-3" color="outline-primary">Add</Button>
                </PermissionGuard>
            </div>
            <Form onSubmit={props.onSubmit} className="d-flex" styleName="search">
                <div>
                    <label>&nbsp;</label>
                    <Input
                        className="mr-3"
                        style={{ width: "256px" }}
                        onChange={props.onChange}
                        name="search"
                        placeholder={props.searchTitle} />
                </div>
                <div style={{ width: "256px" }}>
                    <label>KYB Status</label>
                    <Select
                        value={kybStatuses.find(status => status.value == props.selected)}
                        options={kybStatuses}
                        isClearable={true}
                        onChange={props.onSelectedChange}
                        className="mr-3" />
                </div>
                <Button color="outline-primary" className="ml-auto" type="submit">Search</Button>
            </Form>
        </div>
    )
}
