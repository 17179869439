import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Button from '../../../UI/Button/Button';
import {push} from 'connected-react-router';
import * as qs from 'qs';
import './listHeader.module.css';
import {createMessage} from '../../notification';
import BrandListHeader from '../ListHeaders/Brand';
import QRCodeBundlesHeader from '../ListHeaders/QRCodeBundles';
import QRCodes from '../ListHeaders/QRCodes';
import KYCAdmin from '../ListHeaders/KYCAdmin';
import CompanyList from '../ListHeaders/OrganizationList';
import Loyality from '../ListHeaders/Loyality';
import Question from '../ListHeaders/Question';
import BalanceListHeader from '../ListHeaders/Balance';
import TransactionHeader from '../ListHeaders/Transaction';
import ReferralHeader from '../ListHeaders/Referral';
import BillingListHeader from '../ListHeaders/Billing';
import BillingOverviewHeader from '../ListHeaders/BillingOverview';
import POSProvidersBillingList from '../ListHeaders/POSProvidersBillingList';
import PosList from '../ListHeaders/PosList';
import KYBList from '../ListHeaders/KYBList';
import WalletTransaction from '../ListHeaders/WalletTransaction';
import OnlySearch from '../ListHeaders/OnlySearch';
import ReferralActionHeader from '../ListHeaders/ReferralActions';
import ActivitiesHeader from '../ListHeaders/Activities';
import Onboarding from '../ListHeaders/Onboarding';
import AllUsersList from '../ListHeaders/AllUsersList';
import LedgerHeader from '../ListHeaders/Ledger';
import OnlyTitleListHeader from '../ListHeaders/OnlyTitle';

class ListHeader extends React.Component {
  state = {
    search: ''
  };

  debounceTimer;

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const search = query.search;
    if (search) {
      this.setState({search});
    }
  }

  componentWillUnmount() {
    clearTimeout(this.debounceTimer);
  }

  onInput = (e) => {
    this.setState({search: e.target.value}, () => {
      clearTimeout(this.debounceTimer);
      if (this.state.search == '') {
        this.performSearch();
      } else {
        this.debounceTimer = setTimeout(() => {
          this.performSearch();
        }, 200);
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.performSearch();
  };

  performSearch = () => {
    const query = {...this.props.query};
    if (this.state.search == '' || this.state.search == null) {
      delete query.search;
    } else {
      query.search = this.state.search;
    }
    query.page = 0;

    this.props.push(`?${qs.stringify(query)}`);
  };

  render() {
    const {title, actionHandler, actionLabel} = this.props;

    switch (this.props.type) {
      case 'BalanceList':
        return <BalanceListHeader {...this.props} />;
      case 'BrandList':
        return (
          <BrandListHeader
            {...this.props}
            value={this.state.search}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'QRCodeBundles':
        return (
          <QRCodeBundlesHeader
            {...this.props}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'QRCodes':
        return (
          <QRCodes
            {...this.props}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'KYCAdmin':
        return (
          <KYCAdmin
            {...this.props}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'CompanyList':
        return (
          <CompanyList
            {...this.props}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
            search={this.state.search}
          />
        );
      case 'KYBList':
        return (
          <KYBList
            {...this.props}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'Loyality':
        return (
          <Loyality
            {...this.props}
            value={this.state.search}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'Question':
        return <Question {...this.props} />;
      case 'Transaction':
        return (
          <TransactionHeader
            {...this.props}
            value={this.state.search}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'Referral':
        return (
          <ReferralHeader
            {...this.props}
            value={this.state.search}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
            searchTitle="Search"
          />
        );
      case 'Billing':
        return <BillingListHeader {...this.props} title="Billing List" />;
      case 'BillingOverview':
        return <BillingOverviewHeader {...this.props} />;
      case 'POSProvidersBillingList':
        return <POSProvidersBillingList {...this.props} />;
      case 'PoSList':
        return (
          <PosList
            {...this.props}
            onChange={this.onInput}
            search={this.state.search}
          />
        );
      case 'OnlySearch':
        return (
          <OnlySearch
            {...this.props}
            value={this.state.search}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'OnlyTitle':
        return (
          <OnlyTitleListHeader
            {...this.props}
            value={this.state.search}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'WalletsTransaction':
        return <WalletTransaction {...this.props} />;
      case 'ReferralAction':
        return <ReferralActionHeader {...this.props} />;
      case 'Activities':
        return <ActivitiesHeader {...this.props} />;
      case 'Onboarding':
        return (
          <Onboarding
            {...this.props}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'AllUsers':
        return (
          <AllUsersList
            {...this.props}
            value={this.state.search}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );
      case 'Ledger':
        return (
          <LedgerHeader
            {...this.props}
            value={this.state.search}
            onChange={this.onInput}
            onSubmit={this.onSubmit}
          />
        );

      default:
        return (
          <div>
            <div styleName="header" className="d-flex align-items-center">
              <h4>{title}</h4>
              <Button
                className="ml-auto"
                color="primary"
                onClick={actionHandler}
              >
                {actionLabel}
              </Button>
            </div>
          </div>
        );
    }
  }
}

// Set default props
ListHeader.defaultProps = {
  searchTitle: 'Search',
  title: 'Listing',
  actionLabel: 'Add',
  actionHandler: () => createMessage('not implemented'),
  createLabel: 'Create',
  createHandler: () => createMessage('not implemented')
};

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(ListHeader));
