import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SideNav from "../SideNav/SideNav";
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import {push} from 'connected-react-router';
import {listHeader, renderEditUser, renderName} from "../Util/listView";
import {getSafe, getSafeDeep} from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';
import question from "../../api/question";
import {renderAnswers, renderQuestionLink, renderReocurring, renderTranslateButton} from "./util";
import AddQuestionDialog from "./AddQuestionDialog";
import {supportedLanguages, supportedLanguagesList} from "../Util/language";
import Select from '../UI/Select/Select';
import TranslateQuestionDialog from "./TranslateQuestionDialog";

import Page from '../UI/Page/Page'
import Button from '../UI/Button/Button'
import { Card } from '@material-ui/core';
import { notifyError } from '../Util/notification';
import {Helmet} from "react-helmet";

const ListBodyWithLoading = WithLoading(ListBody);

class LoyaltyQuestionList extends React.Component {

    loyaltyQuestionListHeaders = [
        listHeader('ID', "question_id", false),
        listHeader('Question', "value", false, (item) => renderQuestionLink(item, this.state.language)),
        listHeader('Number of answers', "answers", false, renderAnswers),
        listHeader('Reoccurring', 'repeatable', false, renderReocurring)
    ];

    loyaltyQuestionListHeadersTranslatable = [
        listHeader('ID', "question_id", false),
        listHeader('Question', "value", false, (item) => renderQuestionLink(item, this.state.language)),
        listHeader('Number of answers', "answers", false, renderAnswers),
        listHeader('Reoccurring', 'repeatable', false, renderReocurring),
        listHeader('Translate', '', false, (item) =>  <Button onClick={() => this.toggleTranslateDialog(item)} color="outline-primary">Translate</Button>),
    ];

    state = {
        isLoading: true,
        addQuestionDialogOpen: false,
        translateQuestionDialogOpen: false,
        translateQuestionDialogData: undefined,
        language: supportedLanguagesList[0],
        headers: this.loyaltyQuestionListHeaders,
        rowsPerPage: 10,
        count: 0
    };

    componentDidMount() {
        this.loadData();
    }

    toggleTranslateDialog = (data) => {
        this.setState({
            translateQuestionDialogData: data,
            translateQuestionDialogOpen: !this.state.translateQuestionDialogOpen
        });
    };

    componentDidUpdate(oldProps, prevState) {
        const {query, auth} = this.props;
        const {query: oldQuery, auth: oldAuth} = oldProps;
        if (!immutable.is(query, oldQuery) || this.state.rowsPerPage != prevState.rowsPerPage){
            this.loadData();
        }
    }

    onLanguageChange = (language) => {
        const lang_code = language.lang_code;
        let headers = this.state.headers;
        if(lang_code == 'en'){
            headers = this.loyaltyQuestionListHeaders;
        }else{
            headers = this.loyaltyQuestionListHeadersTranslatable;
        }
        this.setState({
            headers,
            language
        })
    };

    toggleDialog = (dialogName) => {
        const fieldName = `${dialogName}DialogOpen`;
        this.setState({ [fieldName]:!this.state[fieldName] });
    };

    renderLanguageSelect = () => {
        return <Fragment>
            <div style={{ width: "256px" }}>
                <Select
                    options={supportedLanguagesList}
                    getOptionLabel={item => item.lang_name}
                    getOptionValue={item => item.lang_code}
                    value={this.state.language}
                    onChange={(e) => {this.onLanguageChange(e)}}
                />
            </div>
        </Fragment>
    };

    loadData = async () => {
        // const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        try{
            const query = this.props.query
            const page = query.page || 0;
            const search = query.search;
            this.setState({isLoading: true});
            const {data: {count, results: data}} = await question.listPaginated("question", search, (page) * this.state.rowsPerPage, this.state.rowsPerPage);
            this.setState({count, data, isLoading: false});
            if(query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
        }catch(err){
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = {...this.props.query};
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    render() {
        const {isLoading, data, count, rowsPerPage} = this.state;

        return <Fragment>
            <Helmet>
                <title>Loyalty Questions | Elicon</title>
            </Helmet>
            <Page roles={['System Administrator', 'MKTG Questioner']}>
                <Card>
                    <ListHeader
                        type="Loyality"
                        title = "In-App Questions"
                        searchTitle = "Search for questions"
                        actionHandler = { () => this.toggleDialog("addQuestion") }
                        actionLabel = "Add question"
                        renderLanguageSelect={this.renderLanguageSelect}
                    />
                    <ListBodyWithLoading
                        headers={this.state.headers}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                </Card>
            </Page>
            <AddQuestionDialog
                toggle={() => this.toggleDialog("addQuestion")}
                isOpen={this.state.addQuestionDialogOpen}
                reloadData={() => this.loadData()}
                language={this.state.language}
            />
            <TranslateQuestionDialog
                toggle={() => this.toggleDialog("translateQuestion")}
                isOpen={this.state.translateQuestionDialogOpen}
                reloadData={() => this.loadData()}
                data={this.state.translateQuestionDialogData}
                language={this.state.language}
            />
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(LoyaltyQuestionList));
