import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SideNav from "../SideNav/SideNav";
import Button from '../UI/Button/Button'
import {Col, Row} from "reactstrap";
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import {push} from 'connected-react-router';
import user from "../../api/user";
import {listHeader, renderEditUser, renderLink, renderName, renderProfileName} from "../Util/listView";
import {getSafe, getSafeDeep} from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';
import ElipayAddUserDialog from "./ElipayAddUserDialog";
import EditUserDialog from "./EditUserKYCDialog";
import {API_URL} from "../../config";
import { Card } from '@material-ui/core';
import Page from '../UI/Page/Page';
import { notifyError } from '../Util/notification';
import {routes} from "../Util/routing";



const ListBodyWithLoading = WithLoading(ListBody);

class UserList extends React.Component {

    state = {
        isLoading: true,
        addUserDialogOpen: false,
        editUserDialogOpen: false,
        editUserData: undefined,
        rowsPerPage: 10,
        count: 0
    };

    userListHeaders = [
        listHeader('Full Name', "name", false, (item) => renderLink(renderProfileName(item),routes.userInfoPattern.replace(':id', item.id))),
        listHeader('E-Mail', "email", false),
        listHeader('Country', "country", false),
        listHeader('Action', "", false,(item) => <Button onClick={() => this.loadEditUserModal(item.id)} color="outline-primary">Edit User</Button>),
    ];

    loadEditUserModal = async(id) => {
        this.setState({ editUserDialogOpen: false});
        const {data} = await user.get(id);
        await this.setState({
            editUserData: data,
            editUserDialogOpen: true
        });
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, oldState) {
        const {query, auth} = this.props;
        const {query: oldQuery, auth: oldAuth} = oldProps;
        if (!immutable.is(query, oldQuery) || this.state.rowsPerPage != oldState.rowsPerPage){
            this.loadData();
        }
    }

    toggleDialog = (dialogName) => {
        const fieldName = `${dialogName}DialogOpen`;
        this.setState({ [fieldName]:!this.state[fieldName] });
    };

    loadData = async () => {
        try{
            // const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            const query = this.props.query
            const page = query.page || 0;
            const search = query.search;
            const tier = query.tier;
            this.setState({isLoading: true});
            const {data: {count, results: data}} = await user.listElipayUsers("user", search, (page) * this.state.rowsPerPage, this.state.rowsPerPage, tier);
            this.setState({count, data, isLoading: false});
            if(query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
        }catch(err){
            notifyError(err.message)
            this.setState({ isLoading: false })
        }
    };

    renderAdditionalComponents = () => {
        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const search = query.search;
        let exportUrl = API_URL + `admin/users/export`;
        if(search) exportUrl += `?q= + ${search}`;
        return (
            <Fragment>
                {/* <Button className="ml-3" color="primary" */}
                <Button className="ml-3" color="outline-primary" target="_blank" href={exportUrl}>Export users</Button>
            </Fragment>
        )
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        this.setState({isLoading: true});
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = {...this.props.query};
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    render() {
        const {isLoading, data, rowsPerPage, count} = this.state;

        return <Fragment>
            <Page>
                <Card>
                    <ListHeader
                        type='OnlySearch'
                        title = "User List"
                        searchTitle = "Filter"
                        actionHandler = { () => this.toggleDialog("addUser") }
                        actionLabel = "Add user"
                        additionalComponent = {this.renderAdditionalComponents}
                    />
                    <ListBodyWithLoading
                        headers={this.userListHeaders}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                </Card>
            </Page>
            <ElipayAddUserDialog
                            reloadData={this.loadData}
                            isOpen={this.state["addUserDialogOpen"]}
                            toggle={() => this.toggleDialog("addUser")}/>
            <EditUserDialog
                            reloadData={this.loadData}
                            isOpen={this.state["editUserDialogOpen"]}
                            toggle={() => this.toggleDialog("editUser")}
                            data={this.state.editUserData}
            />
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(UserList));
