import qs from "query-string";
import React, { Fragment } from "react";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Card from "../../UI/Card/Card";
import Page from "../../UI/Page/Page";
import Button from "../../UI/Button/Button";
import Modal from "../../UI/Modal/Modal";
import WithLoading from "../../Util/HOC/WithLoading";
import { listHeader, renderFullDate, renderLink } from "../../Util/listView";
import ListBody from "../../Util/ListView/ListBody/ListBody";
import ListHeader from "../../Util/ListView/ListHeader/ListHeader";
import { createMessage } from "../../Util/notification";
import { getSafeDeep } from "../../Util/state";
import holistic from "../../../api/V3/holistic";
import { parseErrors } from "../../Util/form";
import HolisticPOSAddNew from "../HolisticPOSAddNew";
import HolisticPOSDeleteModal from "../HolisticPOSDeleteModal";
import HolisticPOSEditModal from "../HolisticPOSEditModal";
import LinkExternalCompanyModal from "../LinkExternalCompanyModal";
import LinkGoCryptoCompanyModal from "../LinkGoCryptoCompanyModal";
import companyUiSchema from "../schemas/lab4payCompanyUI.json";
import companyDataSchema from "../schemas/lab4payCompanyData.json";
import merchantUiSchema from "../schemas/merchantUI.json";
import merchantDataSchema from "../schemas/merchantData.json";
import InfoForm from "../../InfoForm/InfoForm";
import { infoField, infoFieldCategory } from "../../Util/infoForm";
import { dictionaryToArrayOfObjects } from "../../../utils/helperFunctions";

const ListBodyWithLoading = WithLoading(ListBody);

class HolisticPOSLab4payCompanyDetails extends React.Component {
    state = {
        isLoading: true,
        count: 0,
        rowsPerPage: 10,
        sort: "",
        addNew: false,
        delete: null,
        stores: [],
        merchant: null,
        merchants: [],
        previewMerchant: false,
        previewMerchants: false,
        previewExternalCompany: false,
        previewGoCryptoCompany: false,
        about: {},
        paymentMethods: [],
        operatedBy: [],
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            match: {
                params: { id },
            },
        } = this.props;
        const {
            match: {
                params: { id: oldId },
            },
        } = prevProps;
        if (id !== oldId) {
            this.loadData();
        }

        const { query, auth } = this.props;
        const { query: oldQuery, auth: oldAuth } = prevProps;
        if (
            JSON.stringify(query) !== JSON.stringify(oldQuery) ||
            this.state.rowsPerPage != prevState.rowsPerPage
        ) {
            this.loadData();
        }
    }

    loadData = async () => {
        const {
            match: {
                params: { id },
            },
        } = this.props;
        let { page, search, sort, pageSize, ...filter } = { ...this.props.query };

        this.setState({ isLoading: true });
        try {
            const { data: about } = await holistic.getCompanyDetails(id);
            const response = await holistic.getCompanyStoresListPaginated(
                id,
                search,
                (page || 0) * (pageSize || 10),
                pageSize || 10,
                sort,
                filter
            );
            const paymentMethodsRes = await holistic.getPaymentMethods();
            const operatedByRes = await holistic.getOperatedBy();

            this.setState({
                about,
                stores: response.data.results,
                count: response.data.count,
                paymentMethods: paymentMethodsRes.data,
                operatedBy: dictionaryToArrayOfObjects(operatedByRes.data),
            });

            //get merchant
            const merchantResponse = await holistic.getCompanyMerchant(id);
            this.setState({
                merchant: merchantResponse.data,
                merchants: merchantResponse.data,
            });
        } catch (e) {
            // createMessage(getSafeDeep(e, "response.data.message", getSafeDeep(e, "response.data", e.message)), 'error')
        } finally {
            this.setState({ isLoading: false });
        }
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        const query = { ...this.props.query };
        let currentItem = query.page * query.pageSize || 0;
        query.page = Math.floor(currentItem / newRowsPerPage);
        query.pageSize = newRowsPerPage;
        this.props.history.push(
            `${this.props.location.pathname}?${qs.stringify(query)}`
        );
    };

    requestSort = (sort) => {
        this.setState({ sort });
        const query = { ...this.props.query };
        query.page = 0;
        query.sort = sort;
        this.props.history.push(
            `${this.props.location.pathname}?${qs.stringify(query)}`
        );
    };

    getCompaniesListHeaders = () => {
        return [
            listHeader("Store Name", "full_name", true, (item) => {
                return renderLink(item.full_name, `/holistic-pos/lab4pay/stores/${item.id}`);
            }),
            listHeader("Created", "created", true, (data) =>
                renderFullDate(data.created)
            ),
            listHeader("Delete", "", false, (item) => {
                return (
                    <Button
                        color="danger"
                        isLoading={this.state.isLoading}
                        onClick={() => {
                            this.setState({ delete: item });
                        }}
                    >
                        Delete
                    </Button>
                );
            }),
        ];
    };

    render() {
        const { isLoading, stores, count } = this.state;
        return (
            <Fragment>
                <Helmet>
                    <title>Holistic POS Lab4pay Company Details | Elicon</title>
                </Helmet>
                <Page permission={"view_all_holistic_companies"}>
                    <Card>
                        <ListHeader
                            noSearch={true}
                            type="OnlySearch"
                            title={this.state.about.full_name}
                            searchTitle="Search"
                            noBtn={true}
                            additionalComponent={() => {
                                return (
                                    <>
                                        <Button
                                            className="ml-3"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ edit: true });
                                            }}
                                        >
                                            Edit Company
                                        </Button>
                                        <Button
                                            className="ml-3"
                                            color="primary"
                                            onClick={() => {
                                                this.setState({ addNew: true });
                                            }}
                                        >
                                            Add New Store
                                        </Button>
                                    </>
                                );
                            }}
                        />
                        <ListBodyWithLoading
                            sortField={this.state.sort}
                            requestSort={this.requestSort}
                            padding="16px"
                            headers={this.getCompaniesListHeaders()}
                            isLoading={isLoading}
                            data={stores}
                            count={count}
                            rowsPerPage={this.props.query.pageSize || 10}
                            onChangeRowsPerPage={this.onChangeRowsPerPage}
                        />
                    </Card>
                </Page>
                <HolisticPOSAddNew
                    isOpen={this.state.addNew}
                    reloadData={() => this.loadData()}
                    toggle={() => this.setState({ addNew: false })}
                    label={"Store"}
                    onSubmit={(name) => {
                        return holistic
                            .createStore(name)
                            .then((res) => res.data.id)
                            .then((id) => {
                                return holistic.organizationAddStore(this.state.about.id, id);
                            });
                    }}
                />
                <HolisticPOSDeleteModal
                    isOpen={!!this.state.delete}
                    reloadData={() => {
                        this.loadData();
                    }}
                    toggle={() => this.setState({ delete: null })}
                    label={getSafeDeep(this.state.delete, "full_name")}
                    onSubmit={() => {
                        return holistic.deleteStore(this.state.delete.id);
                    }}
                />
                <HolisticPOSEditModal
                    uiSchema={companyUiSchema}
                    dataSchema={companyDataSchema}
                    active={this.state.edit ? this.state.about : null}
                    reloadData={() => {
                        this.loadData();
                    }}
                    toggle={() => this.setState({ edit: false })}
                    label="Company"
                    paymentMethods={this.state.paymentMethods}
                    operatedBy={this.state.operatedBy}
                    onSubmit={(data) => {
                        return holistic.updateCompany(this.state.about.id, data.form);
                    }}
                />
                <HolisticPOSAddNew
                    uiSchema={merchantUiSchema}
                    dataSchema={merchantDataSchema}
                    isOpen={this.state.addMerchant}
                    reloadData={() => this.loadData()}
                    toggle={() => this.setState({ addMerchant: false })}
                    label={"Merchant"}
                    onSubmit={(data) => {
                        return holistic.addMerchant(this.state.about.id, data.form);
                    }}
                />
                {/* current merchant */}
                <Modal
                    title="Current Merchant"
                    isOpen={this.state.previewMerchant}
                    toggle={() => this.setState({ previewMerchant: false })}
                    size="sm"
                    submitButton={() => null}
                    cancelLabel="Close"
                >
                    {this.state.merchant &&
                    this.state.merchants.map((merchant) =>
                        getSafeDeep(merchant, "current") ? (
                            <InfoForm
                                fields={[
                                    infoFieldCategory("Merchant details", [
                                        infoField("first_name", "First name"),
                                        infoField("last_name", "Last name"),
                                        infoField("email", "E-mail"),
                                        infoField("id", "ID"),
                                        infoField("date_joined", "Date Joined", "date"),
                                    ]),
                                ]}
                                data={merchant}
                            />
                        ) : null
                    )}
                </Modal>
                {/* additional merchants */}
                <Modal
                    title="Additional Merchants"
                    isOpen={this.state.previewMerchants}
                    toggle={() => this.setState({ previewMerchants: false })}
                    size="sm"
                    submitButton={() => null}
                    cancelLabel="Close"
                >
                    {this.state.merchant &&
                    this.state.merchants.map((merchant) =>
                        getSafeDeep(merchant, "current") ? null : (
                            <InfoForm
                                fields={[
                                    infoFieldCategory("Merchant details", [
                                        infoField("first_name", "First name"),
                                        infoField("last_name", "Last name"),
                                        infoField("email", "E-mail"),
                                        infoField("id", "ID"),
                                        infoField("date_joined", "Date Joined", "date"),
                                    ]),
                                ]}
                                data={merchant}
                            />
                        )
                    )}
                </Modal>
                <LinkExternalCompanyModal
                    isOpen={this.state.previewExternalCompany}
                    operatedByList={this.state.operatedBy}
                    reloadData={() => this.loadData()}
                    toggle={() => this.setState({ previewExternalCompany: false })}
                    defaultValue={this.state.about["external_company_id"]}
                    onSubmit={(externalCompanyId) => {
                        return holistic.linkExternalCompany(
                            this.state.about.id,
                            externalCompanyId
                        );
                    }}
                />
                <LinkGoCryptoCompanyModal
                    isOpen={this.state.previewGoCryptoCompany}
                    reloadData={() => this.loadData()}
                    toggle={() => this.setState({ previewGoCryptoCompany: false })}
                    selectedCompany={this.state.about.gocrypto_company}
                    companyId={this.state.about.id}
                    onSubmit={(id) => {
                        return holistic.linkGoCryptoCompany(this.state.about.id, id);
                    }}
                />
            </Fragment>
        );
    }
}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(
    connect(mapState, mapActions)(HolisticPOSLab4payCompanyDetails)
);
