import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ListBody from '../../Util/ListView/ListBody/ListBody';
import WithLoading from '../../Util/HOC/WithLoading';
import immutable from 'immutable';
import qs from 'query-string';
import {push} from 'connected-react-router';
import {
  listHeader,
  renderCreatedAt,
  renderLinkButton
} from '../../Util/listView';
import ListHeader from '../../Util/ListView/ListHeader/ListHeader';
import ActivitiesModal from '../ActivitiesModal';
import Page from '../../UI/Page/Page';
import {Card} from '@material-ui/core';
import referral from '../../../api/V3/referral';
import {notifyError} from '../../Util/notification';
import {Helmet} from 'react-helmet';

const ListBodyWithLoading = WithLoading(ListBody);

const statuses = ['STARTED', 'FINISHED', 'EXTRA'];

const Badge = (props) => {
  const styles = {
    color: '#fff',
    background: '#5867dd',
    display: 'inline-block',
    padding: '.25em .4em',
    fontSize: '75%',
    fontWeight: 700,
    lineHeight: 1,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '.25rem'
  };
  return <div style={styles}>{props.children}</div>;
};

class Activities extends React.Component {
  state = {
    isLoading: true,
    rowsPerPage: 10,
    count: 0,
    search: ''
  };

  activitiesHeaders = [
    listHeader('ReferralID', 'referral__id', true, ({referral: {id}}) =>
      renderLinkButton(id, () => this.setState({id}))
    ),
    listHeader(
      'Owner',
      'referral__owner',
      true,
      ({referral: {owner}}) => owner,
      undefined,
      undefined,
      'center'
    ),
    listHeader(
      'Onboarding status',
      'onboarding_status ',
      false,
      ({onboarding_status}) => {
        return <Badge>{statuses[onboarding_status]}</Badge>;
      },
      undefined,
      undefined,
      'center'
    ),
    listHeader(
      'Onboarding company',
      'company_name',
      false,
      undefined,
      undefined,
      'undefined',
      'center'
    ),
    listHeader(
      'Created at',
      'created_at',
      true,
      (row) => renderCreatedAt(row),
      undefined,
      'undefined',
      'right'
    )
  ];

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(oldProps, prevState) {
    const {query, auth} = this.props;
    const {query: oldQuery, auth: oldAuth} = oldProps;

    // eslint-disable-next-line no-undef
    if (
      !immutable.is(query, oldQuery) ||
      this.state.rowsPerPage != prevState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  loadData = async () => {
    try {
      const query = this.props.query;
      const page = query.page || 0;
      const sort = query.sort;
      const search = query.search;

      this.setState({isLoading: true, sort});
      const {
        data: {count, results: data}
      } = await referral.getActivities(
        page * this.state.rowsPerPage,
        this.state.rowsPerPage,
        sort,
        search
      );
      this.setState({count, data, isLoading: false});

      if (query.page > Math.ceil(count / this.state.rowsPerPage))
        query.page = 0;
    } catch (err) {
      this.setState({isLoading: false});
      notifyError(err.message);
    }
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    let currentItem =
      qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).page *
        this.state.rowsPerPage || 0 + 1;
    let newPage = Math.floor(currentItem / newRowsPerPage);
    this.setState({rowsPerPage: newRowsPerPage});
    const query = {...this.props.query};
    query.page = newPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  requestSort = (sort) => {
    this.setState({sort});
    const query = {...this.props.query};
    query.page = 0;
    query.sort = sort;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  render() {
    const {isLoading, rowsPerPage, data, count} = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Activities | Elicon</title>
        </Helmet>
        <Page permisson={'viewAll_company_referral_activities'}>
          <Card>
            <ListHeader
              type="OnlySearch"
              title="Activities"
              searchTitle="Search"
              noBtn
            />
            <ListBodyWithLoading
              sortField={this.state.sort}
              requestSort={this.requestSort}
              headers={this.activitiesHeaders}
              isLoading={isLoading}
              data={data}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
            <ActivitiesModal
              isOpen={!!this.state.id}
              toggle={() => this.setState({id: null})}
              id={this.state.id}
            />
          </Card>
        </Page>
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(Activities));
