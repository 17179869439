import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Button from '../UI/Button/Button'
import Modal from '../UI/Modal/Modal'
import {createMessage, notifyError} from "../Util/notification";
import {parseErrors} from "../Util/form";
import organization from "../../api/organization";

class RestartKYBDialog extends React.Component {

    state = {
        errors: undefined,
        isLoading: false
    };

    componentDidMount() {

    }

    componentDidUpdate() {
    }

    restartKyb = async() => {
        try{
            const userData = this.props.data;
            this.setState({ isLoading: true })
            await organization.restartKyb(userData.id);
            createMessage(`KYB was restarted !`);
            this.setState({ isLoading: false })
            this.props.reloadData();
            this.props.toggle();
        }catch (e) {
            notifyError(e.message)
            this.setState({ isLoading: false, errors: parseErrors(e)});
        }
    };

    render() {
        const {toggle, isOpen, tiers} = this.props;
        const {errors, isLoading} = this.state;
        if(!isOpen) return "";

        return <Fragment>
            <Modal title="Are you Sure"
                   isLoading={isLoading}
                   toggle={toggle}
                   submitButton={() => <Button isLoading={isLoading} color={"danger"} className="ml-3" onClick={() => this.restartKyb()}> Restart </Button>}
                   isOpen={isOpen} size="xs">
                <p>Are you sure that you want to <b>Restart KYB</b></p>
            </Modal>
        </Fragment>
    };

}

function mapState(state) {
    return {
        tiers: state.tiers.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(RestartKYBDialog));
