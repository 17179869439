import type {Source} from "./model/source";
import {apiClient, apiClientV3} from "./util";

export default {
    // Get source by ID
    get: (id) => {
        return apiClient.get(`/admin/source/${id}`)
    },

    // Create a store
    create: (source) => {
        return apiClient.post('/admin/source', source);
    },

    // Update working hours
    updateWorkingHours: (source) => {
        return apiClient.post(`admin/source/workinghours/${source.id}`,{
            "working_hours":source.new_working_hours
        });
    },

    // Update a store
    update: (id: number, source: Source) =>{
        let sourceData = {...source};
        if(sourceData.product_groups) delete sourceData.product_groups;
        return apiClient.put(`/admin/source/${id}`, sourceData);
    },

    // Add a tag to store
    addTag: (sourceId, tagName, ordering=undefined) => {
        let body = undefined;
        if(ordering != undefined) body = {ordering: ordering};
        return apiClient.post(`/admin/source/${sourceId}/tag/${tagName}/`, body);
    },

    // Add list of tags to a store
    addTagList: (source, list) => {
        let params = {
            names: list
        };
        return apiClient.put(`/admin/source/tag/${source.id}/`, params);
    },

    // Remove a tag from a store
    removeTag: (sourceId, tagName) => {
        return apiClient.delete(`/admin/source/${sourceId}/tag/${tagName}/`);
    },

    // Upload an image to a store logo
    uploadImage: (image, progressEventListener: (ProgressEvent) = undefined) => apiClientV3.post('/admin/upload/image/', image, {onUploadProgress: progressEventListener}),

    // Update images for a store
    updateImages: (id, images) => apiClient.post(`/admin/source/image/${id}`, {images}),


    // Get hashes for a source
    getSourceHashes: (id) => {
        return apiClient.get(`/admin/identifiers?object=user&referring_id=${id}`);
    },

    // Link accountant to source
    linkAccountant: (sourceId, accountantId) => {
        return apiClient.put(`/admin/source/${sourceId}/accountant/${accountantId}`);
    },

    // Activate the source
    activate: (sourceId) => {
        return apiClient.post(`/admin/source/activation/${sourceId}`);
    }
}
