import React, {useEffect, useState} from 'react';
import {Form} from 'reactstrap';

import Input from '../../../UI/Input/Input';

import './styles.module.css';
import Select from '../../../UI/Select/Select';
import * as qs from 'qs';
import Button from '../../../UI/Button/Button';
import cx from 'classnames';
import DatePicker from '../../../UI/DatePicker/DatePicker';
import {getSafeDeep} from '../../state';
import {PermissionGuard} from '../../role-helpers';

const extractDate = (data) => {
  let date = '';
  let month;
  let separatedString = data.toString().split(' ');
  switch (separatedString[1]) {
    case 'Jan':
      month = '01';
      break;
    case 'Feb':
      month = '02';
      break;
    case 'Mar':
      month = '03';
      break;
    case 'Apr':
      month = '04';
      break;
    case 'May':
      month = '05';
      break;
    case 'Jun':
      month = '06';
      break;
    case 'Jul':
      month = '07';
      break;
    case 'Aug':
      month = '08';
      break;
    case 'Sep':
      month = '09';
      break;
    case 'Oct':
      month = '10';
      break;
    case 'Nov':
      month = '11';
      break;
    case 'Dec':
      month = '12';
      break;
  }

  date = date.concat(separatedString[3], '-', month, '-', separatedString[2]);

  return date;
};

// Resolves whether the mark action may be performed
const canMark = (uuidList, dataSet) => {
  return (
    uuidList.length > 0 &&
    uuidList.reduce((accumulator, currentUuid) => {
      if (accumulator !== false) {
        return true;
      }
      return accumulator;
    }, true)
  );
};

const POSProvidersBillingListHeader = (props) => {
  const {startDate, endDate, setStartDate, setEndDate, selectedList, data} =
    props;
  const canMarkBillings = canMark(selectedList, data);
  return (
    <div>
      <div styleName="header" className="d-flex align-items-center">
        <h4 className="mr-auto">{props.title}</h4>
        <PermissionGuard
          code={
            props.completed
              ? 'delete-payout-payments'
              : 'create-payout-payments'
          }
        >
          <Button
            onClick={canMarkBillings ? () => props.onCompleted() : () => {}}
            styleName={cx(
              'green-button',
              {disabled: !canMarkBillings},
              {'yellow-button': !props.completed}
            )}
          >
            {props.completed ? 'Mark as Completed' : 'Mark as Not Completed'}
          </Button>
        </PermissionGuard>
      </div>
      <div styleName="billing-bottom-header">
        <div styleName="date-pickers">
          <div styleName="picker">
            <div styleName="picker-label">Start Date</div>
            <DatePicker
              format="DD/MM/YYYY"
              value={startDate}
              onChange={(data) => setStartDate(data)}
            />
          </div>
          <div styleName="picker">
            <div styleName="picker-label">End Date</div>
            <DatePicker
              format="DD/MM/YYYY"
              value={endDate}
              onChange={(data) => setEndDate(data)}
            />
          </div>
        </div>

        <Button
          color="outline-primary"
          onClick={() =>
            props.onSearch(
              extractDate(getSafeDeep(startDate, '_d')),
              extractDate(getSafeDeep(endDate, '_d'))
            )
          }
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default POSProvidersBillingListHeader;
