import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCountryData} from '../../countryList';

import Button from '../../../UI/Button/Button';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import organization from '../../../../api/organization';
import stores from '../../../../api/V3/stores';
import {filterCategories} from '../../../Source/util';

import './body.module.css';
import {getSafeDeep} from '../../state';
import company from '../../../../api/V3/company';

const countries = getCountryData();

class Body extends Component {
  state = {
    form: {},
    fiatCurrencies: [],
    settlementCurrencies: []
  };

  componentDidMount() {
    const orgElement = document.getElementsByName(
      '#/properties/form/properties/organization_type'
    );
    const requiresPos = document.getElementsByName(
      '#/properties/form/properties/merchant_requires_pos_device'
    );

    if (orgElement.length) {
      if (orgElement[0].value == 0) {
        requiresPos[0].parentElement.parentElement.style.display = 'none';
      } else if (orgElement[0].value == 1) {
        requiresPos[0].parentElement.parentElement.style.display = 'block';
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    let localCurrency = null;

    if (this.props.country != prevProps.country && this.props.country) {
      localCurrency = 0;
    }

    if (
      this.props.activeFiat != prevProps.activeFiat &&
      this.props.activeFiat
    ) {
      localCurrency = this.props.activeFiat;
    }

    if (localCurrency == null) return;

    try {
      const fiatCurrenciesRes = await organization.getFiatCurrencies(
        this.props.country
      );

      const settlementCurrenciesRes =
        await organization.getSettlementCurrencies(localCurrency);

      this.setState({
        settlementCurrencies: settlementCurrenciesRes.data,
        fiatCurrencies: fiatCurrenciesRes.data
      });
    } catch (e) {
      this.setState({
        settlementCurrencies: [],
        fiatCurrencies: [],
        payfacOptions: []
      });
    }
  }

  render() {
    const {active} = this.props;

    return (
      <DynamicForm
        errors={this.props.errors}
        wrappers={(dynamicForm, reset, ctx) => (
          <div>
            <div styleName="body">
              <h3 styleName="title">{active.title}</h3>
              {dynamicForm}
            </div>
            <div styleName="footer" className="d-flex justify-content-end">
              {this.props.activeIndex == 0 ? null : (
                <Button
                  color="secondary"
                  isLoading={this.props.isLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.changeActive(
                      this.props.activeIndex - 1,
                      ctx.data
                    );
                  }}
                  className="mr-3"
                >
                  Back
                </Button>
              )}
              {this.props.activeIndex == this.props.stepsCount - 1 ? (
                <Button
                  color="success"
                  isLoading={this.props.isLoading}
                  type="submit"
                >
                  Create
                </Button>
              ) : (
                <Button
                  color="primary"
                  isLoading={this.props.isLoading}
                  type="submit"
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        )}
        initData={{
          toggleDialog: this.props.toggleDialog,
          systemTags: filterCategories(this.props.categories, true),
          brands: this.props.brands,
          categories: filterCategories(this.props.categories, false),
          mainCategories: this.props.categories.filter(
            (it) =>
              it.system_setting == false && it.name != 'online' && it.visible
          ),
          countries,
          posProviders: this.props.posProviders.filter(
            (p) =>
              p.country ===
                getSafeDeep(
                  this.props.organization,
                  'primary_address.country'
                ) || p.id === 28
          ),
          platforms: this.props.platforms,
          posTypes: this.props.posTypes,
          hwPosOptions: this.props.hwPosOptions,
          hwPosCryptoBuy: this.props.hwPosOptions.filter(
            ({hw_pos_can_buy}) => hw_pos_can_buy
          ),
          hwPosCryptoSell: this.props.hwPosOptions.filter(
            ({hw_pos_can_sell}) => hw_pos_can_sell
          ),
          booleanTypes: [
            {value: true, label: 'Yes'},
            {value: false, label: 'No'}
          ],
          languages: this.props.sourceLang.map((item) => {
            item.code = item.code.toLowerCase();
            return item;
          }),
          exchangeFees: this.props.exchangeFees,
          fiatCurrencies: this.state.fiatCurrencies,
          settlementCurrencies: this.state.settlementCurrencies,
          payfacOptions: this.props.payfacList,
          currencies: this.props.currencies,
          ...active.schema.initData(this.props.formData)
        }}
        uiSchema={active.schema.ui}
        dataSchema={active.schema.data}
        getValues={(form) => this.setState({form})}
        onSubmit={(form) => this.props.next(form)}
      />
    );
  }
}

const mapState = (state) => {
  return {
    exchangeFees: state.exchangeFees.data,
    categories: state.categories.data,
    brands: state.brands.data,
    posProviders: state.posProviders.data,
    platforms: state.platforms.data,
    posTypes: state.posTypes.data,
    hwPosOptions: state.hwPosOptions.data,
    currencies: (state.currencies.data || []).filter(
      (c) => !c.crypto && c.name != 'Eli VIP'
    ),
    sourceLang: state.sourceLang.data,
    payfacList: state.payfacList.data
  };
};

export default connect(mapState)(Body);
