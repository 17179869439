import { fulfilled, fulfilledState, initState } from "./middleware";
import currency from "../api/currency";

export const GET_HW_POS_OPTIONS_LIST = "GET_HW_POS_OPTIONS_LIST";

export default (state = initState, action) => {
  switch (action.type) {
    case fulfilled(GET_HW_POS_OPTIONS_LIST):
      return fulfilledState(state, action.payload.data);
    default:
      return state;
  }
};

export const getHwPosOptionsList = () => ({
  type: GET_HW_POS_OPTIONS_LIST,
  payload: currency.list(true)
});
