import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import Button from '../UI/Button/Button'
import Modal from '../UI/Modal/Modal'
import {createMessage, notifyError} from "../Util/notification";
import {Form, Text} from 'informed';
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import {ERR_VALIDATION} from "../../api/errorCodes";
import {getSafeDeep} from "../Util/state";
import {mapBackendErrors} from "../DynamicForm/controls/util";
import {parseErrors} from "../Util/form";
// import addUserDataSchema from "./schema/addUserData";
// import addUserUiSchema from "./schema/addUserUi";

class ResetPasswordDialog extends React.Component {

    state = {
        errors: undefined,
        isLoading: false
    };

    componentDidMount() {

    }

    componentDidUpdate() {
    }

    resetPassword = async() => {
        try{
            const email = this.props.data.email;
            this.setState({ isLoading: true })
            await user.reset_password(email);
            createMessage(`Password was reset for user ${email} and was sent to e-mail address partners@elipay.com`);
            this.setState({ isLoading: false })
            this.props.reloadData();
            this.props.toggle();
        }catch (e) {
            notifyError(e.message)
            this.setState({ isLoading: false, errors: parseErrors(e)});
        }
    };

    render() {
        const {toggle, isOpen, tiers} = this.props;
        const {errors, isLoading} = this.state;
        if(!isOpen) return "";

        return <Fragment>
            <Modal title="Are you Sure" 
            isLoading={isLoading}
            toggle={toggle} 
            submitButton={() => <Button isLoading={isLoading} color="danger" className="ml-3" onClick={() => this.resetPassword()}>Reset</Button>}
            isOpen={isOpen} size="xs">
                <p>Are you sure that you want to <b>Reset Password</b> for selected user</p>
            </Modal>
        </Fragment>
    };

}

function mapState(state) {
    return {
        tiers: state.tiers.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(ResetPasswordDialog));
