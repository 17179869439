import React from 'react'
import _ from 'lodash'
import Modal from '../../UI/Modal/Modal'
import Select from '../../UI/Select/Select'
import Alert from '../../UI/Alert/Alert'

import organization from '../../../api/organization'
import {createMessage} from "../../Util/notification";
import {getSafeDeep} from "../../Util/state";
import stores from "../../../api/V3/stores";

class LinkPointOfSaleDialog extends React.Component {

    state = {
        companies: [],
        targetCompany: null,
        targetSource: null
    }

    loadCompanyOptions = async (input) => {
        return (await organization.listPaginated(input, 0, 10)).data.results
    }

    loadSourceOptions = async (input) => {
        return (await stores.list(10, 0, input)).data.results
    }

    linkHandler = async () => {
        const targetCompany = this.props.organization || this.state.targetCompany
        const source = this.props.source || this.state.targetSource
        try {
            if (source.company[0]) {
                await organization.unlink(source.company[0], source)
            }
            await organization.link(targetCompany, source)
            this.props.toggle()
            createMessage("Source linked")
        } catch (e) {
            createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
        }
    }

    render(){
        const labelStyles = {
            color: "red",
            fontWeight: 300
        }

        const targetProps = this.props.organization ? {
            getOptionValue: c => c.id,
            getOptionLabel: c => c.full_name,
            value: this.props.organization,
            isDisabled: true,
            options: [this.props.organization]
        } : {
            defaultOptions: true,
            cacheOptions: true,
            getOptionValue: c => c.id,
            getOptionLabel: c => c.full_name,
            value: this.state.targetCompany,
            onChange: e => this.setState({ targetCompany: e }),
            loadOptions: this.loadCompanyOptions
        }

        const sourceProps = this.props.source ? {
            getOptionValue: c => c.id,
            getOptionLabel: c => c.full_name,
            value: this.props.source,
            isDisabled: true,
            options: [this.props.source]
        } : {
            defaultOptions: true,
            cacheOptions: true,
            getOptionValue: c => c.id,
            getOptionLabel: c => c.full_name,
            value: this.state.targetSource,
            onChange: e => this.setState({ targetSource: e }),
            loadOptions: this.loadSourceOptions
        }

        return (
            <Modal
                cancelLabel="Close"
                confirmLabel="Link"
                isLoading={this.state.isLoading}
                onSubmit={this.linkHandler}
                title="Link Point of Sale"
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}>
                    <div className="mt-5 mb-2 px-3">
                        <div className="mb-3" style={sourceProps.isDisabled ? { cursor: "not-allowed" } : null }>
                            <label>Source <span style={labelStyles}>*</span></label>
                            <Select async {...sourceProps} />
                        </div>
                        <div className="mb-3" style={targetProps.isDisabled ? { cursor: "not-allowed" } : null }>
                            <label>Company <span style={labelStyles}>*</span></label>
                            <Select async {...targetProps} />
                        </div>
                    </div>
            </Modal>
        )
    }
}

export default LinkPointOfSaleDialog;
