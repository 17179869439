import React from 'react'

import "./Stepper.module.css"

const Step = props => {

    return (
        <div styleName="step-box">
            <a onClick={props.changeActive} styleName="step" style={props.active ? { background: "#5867dd" } : null}>
                <i className={`flaticon-${props.icon}`}></i>
            </a>
            <span styleName="text" style={props.active ? { color: "#5867dd" } : null} >{props.title}</span>
        </div>
    )
}

export default Step;