import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Modal from '../UI/Modal/Modal';
import {createMessage} from '../Util/notification';
import DynamicForm from '../DynamicForm/DynamicForm';
import user from '../../api/user';
import {ERR_VALIDATION} from '../../api/errorCodes';
import {deepCopy, getSafeDeep} from '../Util/state';
import {mapBackendErrors} from '../DynamicForm/controls/util';
import {parseErrors} from '../Util/form';
import brand from '../../api/brand';
import categories from '../../redux/category';
import {isBase64} from '../Util/image';
import editBrandDataSchema from './schema/addBrandData';
import editBrandUiSchema from './schema/addBrandUi';

class EditBrandDialog extends React.Component {
  state = {
    errors: undefined
  };

  componentDidMount() {
    this.loadSchema();
  }

  componentDidUpdate() {}

  onSubmit = async (data) => {
    try {
      const dataCopy = deepCopy(data);
      if (!isBase64(dataCopy.logo)) delete dataCopy.logo;
      await brand.update(dataCopy);
      createMessage('Successfully edited brand');
      this.props.reloadData();
      this.props.toggle();
    } catch (e) {
      console.error(e);
      this.setState({errors: parseErrors(e)});
    }
  };

  loadSchema = async () => {
    const uiSchema = editBrandUiSchema;
    const dataSchema = editBrandDataSchema;
    this.setState({uiSchema, dataSchema});
  };

  render() {
    const {toggle, isOpen, categories, data} = this.props;
    const {errors} = this.state;
    if (!isOpen) return '';

    const transformedData = deepCopy(data);
    transformedData.tag_name = transformedData.tag.name;

    const modal = {
      toggle,
      isOpen,
      title: 'Edit Brand',
      errors,
      size: 'md'
    };

    return (
      <DynamicForm
        uiSchema={this.state.uiSchema}
        dataSchema={this.state.dataSchema}
        initData={{categories, form: transformedData}}
        errors={errors}
        modal={modal}
        onSubmit={(data) => {
          this.onSubmit(data.form);
        }}
      />
    );
  }
}

function mapState(state) {
  return {
    categories: state.categories.data.filter(
      (it) => it.system_setting == false && it.name != 'online' && it.visible
    )
  };
}

function mapActions(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(EditBrandDialog));
