import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col, Row} from 'reactstrap';
import {renderInfoField} from '../Util/infoForm';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class InfoForm extends React.Component {
  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const {additionalComponents, fields, data, requiredProps} = this.props;

    return (
      <div>
        <div className="d-flex flex-wrap">{additionalComponents}</div>

        <br />

        {fields.map((category, i) => (
          <ExpansionPanel key={i} defaultExpanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel-header-${i}`}
            >
              <Typography variant="subtitle1">{category.label}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Row>
                {category.items.map((item, j) => {
                  return (
                    <Col key={j} sm={6} className="my-2">
                      <div className="form-label text-muted">{item.label}</div>
                      {renderInfoField(item, data, requiredProps)}
                    </Col>
                  );
                })}
              </Row>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    );
  }
}

function mapState(state) {
  return {};
}

function mapActions(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(InfoForm));
