import React, { useEffect, useState } from 'react'
import './reasons.module.css'
import { renderFullDate } from "../../../../../Util/listView";
import { connect } from "react-redux";

import reasonsApi from '../../../../../../api/reasons'


const SingleReason = props => {

    const { reason, reasons } = props

    return (
        <div styleName="singleReason" className={props.index == reasons.length - 1 ? "border-0 pb-3" : ""}>
            <div>
                <div className="d-flex align-items-center">
                    <span className="mr-2" styleName="author">{reason.changed_fields}</span>
                    <span styleName="time">{renderFullDate(reason.last_updated)}</span>
                </div>
                <p styleName="text">{reason.reason}</p>
            </div>
        </div>
    )
}

const Reasons = ({ organization, user }) => {
    const [reasonsList, setReasonsList] = useState([])
    const loadReasons = async () => {
        try {
            const { data } = await reasonsApi.list(organization.id)
            setReasonsList(data)
        } catch (err) {
            console.error(err)
        }
    }
    useEffect(() => {
        loadReasons()
    }, [organization.id])

    return (
        <>
            {reasonsList.map((reason, i) => (
                <SingleReason index={i} key={reason.id} reason={reason} reasons={reasonsList} />
            ))}
            {!reasonsList.length && <p className="text-center">No Edit reasons yet.</p>}
        </>
    )
}

const mapState = state => {
    return {
        user: state.user.data
    }
}

export default connect(mapState)(Reasons);
