
import React from 'react'
import { Form } from 'reactstrap'

import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'

import './styles.module.css'
import {Route} from "react-router";
import {Link} from "react-router-dom";
import {routes} from "../../routing";
import Activities from "../../../Referrals/Activities/Activities";
import {PermissionGuard} from "../../role-helpers";

const ReferralHeader = props => {
    return (
        <div>
            <div styleName="header" className="d-flex align-items-center">
                <h4>{props.title}</h4>
                <PermissionGuard code={'viewAll_company_referral_activities'}>
                    <Link className="ml-auto mr-2" styleName="link" to="referrals/activities">
                        <Button className="ml-auto mr-2" styleName="button-black" color="outline-primary">Activities</Button>
                    </Link>
                </PermissionGuard>
                <PermissionGuard code={'add_company_referral'}>
                    <Button color="outline-primary"  onClick={() => props.toggle("addReferral")}>Add Referral</Button>
                </PermissionGuard>
            </div>
            <Form onSubmit={props.onSubmit} styleName="search">
                <Input
                    style={{ width: "256px" }}
                    onChange={props.onChange}
                    value={props.value}
                    name="search"
                    placeholder={props.searchTitle} />
            </Form>
        </div>
    )
}

export default ReferralHeader
