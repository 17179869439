import React from 'react'
import {computeIsRequired, getDataProp, getInputConfig, getInputData, getInputError, getProp} from "./util";
import * as classnames from "classnames";
import {formContextWrapper} from "../FormContext";
import type {FormContextProps} from "../FormContext";
import type {ArrayContextProps} from "../ArrayContext";
import {arrayContextWrapper} from "../ArrayContext";

import Keywords from '../../UI/Keywords/Keywords'

type Props = {
    label: string,
    outterhtmlClass: string,
    allowedClear: boolean,
    arrayOptions: {
        displayFieldName: string,
        idFieldName: string,
        loadItemsOnOpen: boolean,
        $ref: string
    },
    scope: {
        $ref: string
    },
    context: FormContextProps,
    array?: ArrayContextProps
}


class KeywordsControl extends React.Component<Props> {
    state = {
        required: false,
        $ref: "",
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scope.$ref !== prevState.$ref) {
            return {
                required: computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref),
                $ref: nextProps.scope.$ref
            }
        }
        return prevState;
    }

    getValue = () => {
        const {context, array, scope: {$ref}, arrayOptions} = this.props;
        const config = getInputConfig(context, array, $ref) ;
        const dataValue = getInputData(context, array, $ref);

        return dataValue || [];
    };

    shouldComponentUpdate(nextProps, nextState) {
        const context = this.props.context;
        const nextContext = nextProps.context;
        const $ref = this.props.scope.$ref;
        const next$ref = nextProps.scope.$ref;
        const array = this.props.array;
        const nextArray = nextProps.array;
        try{
            return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) ||
                getDataProp(context.data, this.props.arrayOptions.$ref) !== getDataProp(nextContext.data, nextProps.arrayOptions.$ref) ||
                getInputError(context, array, $ref) !== getInputError(nextContext, nextArray, next$ref) ||
                $ref !== next$ref || 
                context.isLoading !== nextProps.isLoading ||
                this.state.createNew != nextState.createNew
        } catch (e) {
            return true
        }
    }

    render() {
        const {outterhtmlClass, scope: {$ref}, disabled, context, label, arrayOptions, array} = this.props;
        const config = getInputConfig(context, array, $ref);
        const error = getInputError(context, array, $ref);

        return (
            <div className={classnames(outterhtmlClass, "mb-3")}>
                <label htmlFor={$ref}>{label}</label>
                <Keywords
                    disabled={this.props.disabled}
                    list={this.getValue()}
                    name={$ref}
                    removeItem={(e) => {context.onInputChange($ref, this.getValue().filter(item => item.toLowerCase().trim() != e.toLowerCase().trim() ))} }
                    addItem={e => { e.trim() && context.onInputChange($ref, this.getValue().concat(e)) }}
                />
            </div>
        );
    }
}

export default arrayContextWrapper(formContextWrapper(KeywordsControl))
