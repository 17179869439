import {
  fulfilled,
  fulfilledState,
  initState,
  pending,
  pendingState,
  rejected,
  rejectedState
} from './middleware';
import auth from '../api/auth';
import user from '../api/user';

export const GET_USER = 'GET_USER';
export const GET_USER_LIST = 'GET_USER_LIST';
export const CLEAR_USER = 'CLEAR_USER';

export default function (state = initState, action) {
  switch (action.type) {
    case pending(GET_USER):
      return pendingState(state);
    case fulfilled(GET_USER):
      const responses = action.payload;
      const data = responses[0].data;
      data.roles = responses[1].data;
      data.permissions = data.roles.reduce((permissions, role) => {
        role.permissions.forEach((perm) => {
          if (!permissions.find((p) => p.code === perm.code)) {
            permissions.push(perm);
          }
        });
        return permissions;
      }, []);
      return fulfilledState(state, data);
    case rejected(GET_USER):
      return rejectedState(state, action.payload);
    case CLEAR_USER:
      return initState;
    default:
      return state;
  }
}

export function allUsers(state = initState, action) {
  switch (action.type) {
    case pending(GET_USER_LIST):
      return pendingState(state);
    case fulfilled(GET_USER_LIST):
      return fulfilledState(state, action.payload.data);
    case rejected(GET_USER_LIST):
      return rejectedState(state, action.payload);
    case CLEAR_USER:
      return initState;
    default:
      return state;
  }
}

export const getUser = () => ({
  type: GET_USER,
  payload: Promise.all([auth.getUser(), auth.getRole()])
});

export const getUserList = () => ({
  type: GET_USER_LIST,
  payload: user.listPaginated()
});

export const clearUser = () => ({
  type: CLEAR_USER
});
