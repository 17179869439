import React, { useState } from 'react';
import Modal from '../UI/Modal/Modal'
import Button from '../UI/Button/Button'
import { createMessage, notifyError } from '../Util/notification';


const HolisticPOSDeleteModal = ({ isOpen, reloadData, label, toggle, onSubmit }) => {

    const [isLoading, setIsLoading] = useState(false)

    const submitHandler = async (e) => {
        try {
            setIsLoading(true)
            await onSubmit(e)
            createMessage('Success')
            toggle();
            reloadData();
        } catch (err) {
            notifyError(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal title="Are you sure ?"
            isOpen={isOpen}
            toggle={toggle}
            isLoading={isLoading}
            submitButton={() => <Button className="ml-3" color="danger" isLoading={isLoading} onClick={submitHandler}>Delete</Button>}
        >
            <div>Are you sure that you want to delete <b>{label}</b></div>
        </Modal>
    )
}

export default HolisticPOSDeleteModal;
