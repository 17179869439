import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createMessage, notifyError } from "../Util/notification";
import { Form, Text } from 'informed';
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import { parseErrors, parseProfileNested } from "../Util/form";
import editUserDataSchema from "./schema/editUserData";
import editUserUiSchema from "./schema/editUserUi";
import { meetingTypeOptions } from "../Util/meetingTypeOptions";
import { booleanTypes } from "../Source/util";
import { getUserList } from "../../redux/user";
import { getCountryData } from "../Util/countryList";
import moment from 'moment'
import _ from 'lodash'

import Modal from '../UI/Modal/Modal'
import { deepCopy, removeEmptyFields } from "../Util/state";

const countries = getCountryData();

class EditUserDialog extends React.Component {

    state = {
        errors: {},
        isLoading: false
    };

    onSubmit = async (data) => {
        try {
            this.setState({ isLoading: true })
            let submitData = removeEmptyFields(data)

            await user.update(this.props.data.id, submitData);
            createMessage('Successfully submitted');
            this.props.getUserList();
            this.props.reloadData();
            this.props.toggle();
            this.setState({ isLoading: false })
        } catch (e) {
            notifyError(e.message)
            this.setState({ errors: parseProfileNested(e), isLoading: false })
        }
    };

    render() {
        const { toggle, isOpen, tiers, data } = this.props;
        const { errors } = this.state;
        if (!isOpen) return "";
        const modal = {
            isOpen,
            toggle,
            isLoading: this.state.isLoading,
            title: "Edit User",
            size: "lg"
        }

        return <DynamicForm
            modal={modal}
            uiSchema={editUserUiSchema}
            dataSchema={editUserDataSchema}
            initData={{
                meetingTypes: meetingTypeOptions,
                booleanTypes,
                tiers,
                form: {
                    ...data,
                    profile: {
                        ...data.profile,
                        date_and_time: data.profile ? data.profile.date_and_time ? data.profile.date_and_time : new Date().toISOString() : new Date().toISOString()
                    }
                },
                countries
            }}
            errors={errors}
            onSubmit={(data) => { this.onSubmit(data.form) }} />
    };

}

function mapState(state) {
    return {
        tiers: state.tiers.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ getUserList }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(EditUserDialog));
