import React from 'react'

import './chip.module.css'

const Chip = props => {
    return (
        <div styleName="chip" style={props.color ? {color: props.color} : null}>
            {props.text}
        </div>
    )
}

export default Chip;