import React from 'react'

import Button from '../UI/Button/Button'
import Modal from '../UI/Modal/Modal'
import withdraw from "../../api/V3/withdrawal";
import { notifyError, createMessage } from "../Util/notification";
import { getSafeDeep } from '../Util/state'

import "./Withdrawal.module.css"


export default props => {
    const onSubmit = async () => {

        try {

            if (props.reason === "approve") {
                await withdraw.approve(props.withdrawal.id);
                createMessage("Withdrawal approved")
            } else {
                await withdraw.decline(props.withdrawal.id);
                createMessage("Withdrawal declined")
            }
            props.loadData();
        } catch (err) {
            notifyError(getSafeDeep(err, 'response.data.message', err.message))
        }
    }

    return (
        <Modal title="Are you sure"
            toggle={props.toggle}
            isOpen={props.isOpen}
            size="sm"
            cancelLabel="Close"
            submitButton={() => <Button className="ml-3" onClick={() => { onSubmit(); props.toggle() }} color={props.reason == 'approve' ? "success" : "danger"}>{props.reason == 'approve' ? "Approve" : "Decline"}</Button>}>
            {
                props.reason == 'approve' ?
                    <div>Do you want to approve: {props.withdrawal ? <div className={"dialogContent"}><br /> {"Wallet: " + props.withdrawal.wallet}  <br /><br />  {"Amount: " + props.withdrawal.amount + props.currencies.find(c => c.id == props.withdrawal.currency).display_symbol}  <br /><br />  {`Local Amount: ` + getSafeDeep(props, 'withdrawal.extra.living_la_vida_local_total_amount')}{props.currencies.find(c => c.id == getSafeDeep(props.withdrawal, 'extra.local_currency')).display_symbol}</div> : ""}</div>
                    :
                    <div>Do you want to decline: {props.withdrawal ? <div className={"dialogContent"}><br /> {"Wallet: " + props.withdrawal.wallet}  <br /><br />  {"Amount: " + props.withdrawal.amount + props.currencies.find(c => c.id == props.withdrawal.currency).display_symbol}  <br /><br />  {`Local Amount: ` + getSafeDeep(props, 'withdrawal.extra.living_la_vida_local_total_amount')}{props.currencies.find(c => c.id == getSafeDeep(props.withdrawal, 'extra.local_currency')).display_symbol}</div> : ""}</div>
            }
        </Modal>
    )
}