import React, {useEffect, useState} from 'react';
import Button from '../../../UI/Button/Button';
import {Domain, ChevronLeft} from '@material-ui/icons';
import cx from 'classnames';
import './pos-menu.module.css';
import {approvalStates} from '../../PoSList/PoSList';
import Link from 'react-router-dom/Link';
import {getOrganizationInfoRoute, routes} from '../../../Util/routing';
import {getSafeDeep} from '../../../Util/state';
import stores from '../../../../api/V3/stores';
import {createMessage} from '../../../Util/notification';
import {PermissionGuard} from '../../../Util/role-helpers';
import {withRouter} from 'react-router';
import Modal from '../../../UI/Modal/Modal';
import Input from '../../../UI/Input/Input';
import CheckBox from '../../../UI/CheckBox/CheckBox';

const Ribbon = (props) => {
  let type = ['Offline', 'Online'][props.type];

  return (
    <div styleName="ribbon-box">
      <div
        styleName="ribbon"
        style={{background: props.type ? '#2da285' : '#177aa6'}}
      >
        {type}
      </div>
    </div>
  );
};

const InfoModal = (props) => {
  return (
    <Modal
      title="Company needs to be approved first"
      isOpen={props.isOpen}
      toggle={props.toggle}
      submitButton={() => null}
    >
      The Company to which this Store belongs needs to be approved first in
      order for PoS approval action to occur!
    </Modal>
  );
};

const RejectModal = ({isOpen, toggle, pos, onRejectClick, isLoading}) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setReason('');
    setError('');
  }, [isOpen]);

  const onSubmit = () => {
    if (reason.trim().length === 0) {
      return setError('This field is required');
    }
    onRejectClick(reason);
  };

  return (
    <Modal
      title="Reject store"
      isOpen={isOpen}
      toggle={toggle}
      isLoading={isLoading}
      submitButton={() => (
        <Button color="danger" isLoading={isLoading} onClick={onSubmit}>
          Reject
        </Button>
      )}
    >
      <div className="mb-5">
        Are you sure you want to reject{' '}
        <strong>{getSafeDeep(pos, 'full_name')}</strong>
      </div>
      <div>
        <label htmlFor="reason">Reason</label>
        <Input
          placeholder="Enter reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <small className="text-danger">{error}</small>
      </div>
    </Modal>
  );
};

const Menu = (props) => {
  const {pos, links, checkbox} = props;

  const [isLoading, setLoading] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  const storeAction = async (action, reason) => {
    setLoading(true);
    try {
      await stores.storeAction(pos.id, action, reason);
      switch (action) {
        case 'reject':
          props.history.replace(
            routes.organizationInfoPattern.replace(
              ':id',
              getSafeDeep(pos, 'company.0.id')
            )
          );
          break;
        default:
          const {data} = await stores.get(pos.id);
          props.dataUpdate(data);
          break;
      }
    } catch (e) {
      createMessage(
        getSafeDeep(e, 'response.data.message', e.message),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async () => {
    if (getSafeDeep(pos, 'company.0.approval_state', 0) !== 1) {
      setInfoModal(true);
      return;
    }
    await storeAction('approve');
  };

  const renderButtons = () => {
    if (pos.approval_state === 0) {
      return (
        <>
          <Button
            isLoading={isLoading}
            color="outline-success"
            className="mr-4"
            onClick={handleApprove}
          >
            Approve
          </Button>
          <Button
            isLoading={isLoading}
            color="outline-danger"
            onClick={() => setRejectOpen(true)}
          >
            Reject
          </Button>
        </>
      );
    } else if (pos.approval_state === 1) {
      return (
        <>
          <Button
            isLoading={isLoading}
            color="outline-danger"
            className="mr-4"
            onClick={() => storeAction('terminate')}
          >
            Terminate
          </Button>
          {pos.deleted ? (
            <Button
              isLoading={isLoading}
              color="outline-primary"
              onClick={() => storeAction('make-visible')}
            >
              Make visible
            </Button>
          ) : (
            <Button
              isLoading={isLoading}
              color="outline-primary"
              onClick={() => storeAction('make-invisible')}
            >
              Make invisible
            </Button>
          )}
        </>
      );
    }
  };

  const updateEmailState = (event) => {
    props.updateEmailCheckbox(event.target.checked);
  };

  /*Template*/
  return (
    <div styleName="menu">
      <div styleName="profile">
        <Link
          to={getOrganizationInfoRoute(getSafeDeep(pos, 'company.0.id'))}
          styleName="backIcon"
        >
          <ChevronLeft />
          <Domain />
        </Link>
        <div styleName={'company'}>
          {getSafeDeep(pos, 'company.0.full_name')}
        </div>
        <Ribbon type={pos.source_type} />
        <img styleName="picture" src={pos.logo_url} />
        <span styleName="title">{pos.full_name}</span>
        <span styleName="status">{approvalStates[pos.approval_state]}</span>
        <br />
        <span styleName="status">{pos.deleted ? 'Invisible' : 'Visible'}</span>
        <br />
        <span styleName="status">
          Referral: {getSafeDeep(pos, 'company.0.referrals.0.owner', 'None')}
        </span>
        <br />
        <span styleName="status">
          Company: {getSafeDeep(pos, 'company.0.full_name', 'None')}
        </span>
      </div>
      <div styleName="divider"></div>
      <div>
        {links.map(
          (link, index) =>
            link && (
              <div
                styleName={cx('link', {
                  active: link.icon == props.active.icon
                })}
                onClick={() => props.navigateTab(index)}
                key={link.icon}
              >
                <i className={`flaticon-${link.icon}`}></i>
                <span>{link.name}</span>
              </div>
            )
        )}
      </div>
      <div styleName="divider"></div>
      <PermissionGuard code="change_source_state">
        <div className="px-4">
          <div styleName="actions" className="mb-4">
            actions
          </div>
          <div className="d-flex pb-5">{renderButtons()}</div>
          <div className="pb-5">
            <CheckBox
              className="mt-3"
              onClick={updateEmailState}
              checked={checkbox}
              label="Send Emails"
            />
          </div>
        </div>
      </PermissionGuard>
      <RejectModal
        isOpen={rejectOpen}
        onRejectClick={(reason) => storeAction('reject', reason)}
        isLoading={isLoading}
        toggle={() => setRejectOpen(false)}
        pos={pos}
      />
      <InfoModal isOpen={infoModal} toggle={() => setInfoModal(false)} />
    </div>
  );
};

export default withRouter(Menu);
