import React, {Fragment} from "react";
import Page from "../UI/Page/Page";
import WithLoading from "../Util/HOC/WithLoading";
import ListBody from "../Util/ListView/ListBody/ListBody";
import {PaginatedTable} from "../Util/HOC/PaginatedTable";
import transaction from "../../api/V3/transactions";
import {listHeader, renderFullDate} from "../Util/listView";
import {Helmet} from "react-helmet";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import {Card} from "@material-ui/core";
import qs from "query-string";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import TransactionExportCSVDialog from "./TransactionExportCSVDialog";
import CardDisplay from "../Util/AmountCardDisplay/CardDisplay";
import {getSafeDeep} from "../Util/state";
import "./transactions.module.css"

const ListBodyWithLoading = WithLoading(ListBody);


class TransactionsExperimental extends React.Component {

    state = {
        totalData: {}
    };

    async loadData () {
        const totalData = (await transaction.getTotal()).data;
        this.setState({ totalData });
    };

    componentDidMount() {
        this.loadData();
    }

    transactionsHeaders = [
        listHeader('Date', "last_updated", true, row => renderFullDate(row.last_updated)),
        listHeader('Store', "store", false, undefined, undefined, undefined, "left"),
        listHeader('Price', "crypto_amount", true, undefined, undefined, undefined, "right"),
        listHeader('Crypto Currency', "crypto_currency", false, undefined, undefined, "undefined", "left"),
        listHeader('EUR', "amount", true, undefined, undefined, "undefined", "right"),
    ];

    render() {
        const {totalData} = this.state;
        const {data, isLoading, rowsPerPage, count, sort, requestSort, onChangeRowsPerPage, CSVDialogOpen, toggleDialog} = this.props;

        return <Page permission={['viewAll_payments', 'view_payments_total']}>
            <Helmet>
                <title>Transactions | Elicon</title>
            </Helmet>
            <div styleName="card-display">
                <CardDisplay topLabel="All purchases" topValue={getSafeDeep(totalData, 'all.count')} bottomLabel="Amount EUR"
                             bottomValue={getSafeDeep(totalData, 'all.amount_eur')}/>
                <CardDisplay topLabel="Today's purchases" topValue={getSafeDeep(totalData, 'last_24h.count')}
                             bottomLabel="Amount EUR" bottomValue={getSafeDeep(totalData, 'last_24h.amount_eur')}/>
            </div>
            <Card>

                <ListHeader
                    type="Transaction"
                    toggle={(dialog) => toggleDialog(dialog)}
                />
                <ListBodyWithLoading
                    sortField={sort}
                    requestSort={requestSort}
                    headers={this.transactionsHeaders}
                    isLoading={isLoading}
                    data={data}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                />
            </Card>
            <TransactionExportCSVDialog
                toggle={() => toggleDialog("CSV")}
                isOpen={CSVDialogOpen}
                screen="transactions"
            />
        </Page>
    }
}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

const TransactionsPaginatedTable =  PaginatedTable(TransactionsExperimental, { apiCall: transaction.getT, modals: ['CSV']});

export default withRouter(connect(mapState, mapActions)(TransactionsPaginatedTable));

