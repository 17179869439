import React from 'react'
import moment from 'moment';

import cx from 'classnames'

import './footer.css'

const Footer = (props) => {
    return (
        <div className="footer d-flex align-items-center">
            <span className={cx({
               "footer-floating": props.floating
            })}>{moment().format("YYYY")} &#169; Eligma</span>
        </div>
    )
}

export default Footer;
