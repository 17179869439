import React from 'react';
import cx from 'classnames';

import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import SideBarSub from './SideBarSub/SideBarSub';

import './sideBarItem.module.css';
import {Collapse} from 'reactstrap';

const SideBarItem = (props) => {
  const clickHandler = () => {
    if (props.subLinks) {
      props.toggleExpanded();
    }

    if (!props.link) {
      return;
    }

    props.history.push(props.link);
  };

  return (
    <li styleName="item">
      <a styleName="link" onClick={clickHandler}>
        <i
          styleName={cx('icon', {'icon-expanded': props.expanded})}
          className={`flaticon-${props.icon} icon`}
        ></i>
        {props.show && <span styleName={'label'}>{props.label}</span>}
        {props.subLinks && props.show && (
          <small styleName={cx('chevron', {expanded: props.expanded})}>
            &#10095;
          </small>
        )}
      </a>
      {props.subLinks && props.show && (
        <Collapse isOpen={props.expanded} styleName="box">
          <SideBarSub
            tiers={props.tiers}
            subLinks={props.subLinks}
            show={props.show}
          />
        </Collapse>
      )}
    </li>
  );
};

const mapState = (state) => {
  return {
    tiers: state.tiers.data,
    fullRoute: `${state.router.location.pathname}${state.router.location.search}`
  };
};

export default withRouter(connect(mapState)(SideBarItem));
