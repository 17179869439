import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createMessage} from "../Util/notification";
import Modal from '../UI/Modal/Modal'
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import {ERR_VALIDATION} from "../../api/errorCodes";
import {getSafeDeep} from "../Util/state";
import {mapBackendErrors} from "../DynamicForm/controls/util";
import {parseErrors} from "../Util/form";
import brand from "../../api/brand";
import categories from "../../redux/category";
import dataSchema from "./schema/addBrandData";
import uiSchema from "./schema/addBrandUi";
import {getBrandList} from "../../redux/brand";

class AddBrandDialog extends React.Component {

    state = {
        errors: undefined,
        isLoading: false
    };

    onSubmit = async (data) => {
        try{
            this.setState({ isLoading: true })
            await brand.create(data);
            this.props.getBrandList();
            createMessage('Successfully created brand');
            this.props.reloadData();
            this.setState({ isLoading: false })
            this.props.toggle();
        }catch (error) {
            console.error("ERROR HAPPENED", error.message);
            this.setState({ isLoading: false, errors: parseErrors(error)});
        }
    };

    render() {
        const {toggle, isOpen, categories} = this.props;
        const {errors, isLoading} = this.state;
        if(!isOpen) return "";

        const modal = {
            toggle,
            isOpen,
            title: "Add new Brand",
            errors,
            isLoading,
            size: "md"
        }

        return(
            <DynamicForm    uiSchema={uiSchema}
                            dataSchema={dataSchema}
                            initData={{categories}}
                            modal={modal}
                            errors={errors}
                            onSubmit={(data) => {this.onSubmit(data.form)}}/>
        )
    };
}

function mapState(state) {
    return {
        categories: state.categories.data.filter(it => it.system_setting == false && it.name != "online" && it.visible)
    };
}

function mapActions(dispatch) {
    return bindActionCreators({getBrandList}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(AddBrandDialog));
