import React, {Fragment} from 'react';
import {renderMenuStructure} from "../MainMenu/MainMenu";
import MainMenuStructure from "../MainMenu/MainMenuStructure";
import {Card} from "reactstrap";

class SideNav extends React.Component {
    componentDidMount() {
    }

    componentDidUpdate() {
    }

    render() {

        return <Fragment>
            <Card className="py-4 d-flex flex-column align-items-center justify-content-center">
                <MainMenuStructure />
            </Card>
        </Fragment>
    };

}

export default SideNav;
