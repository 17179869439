import {fulfilled, fulfilledState, initState} from "./middleware";
import country from "../api/V3/country";

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_COUNTRY_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getCountryList = () => ({
    type: GET_COUNTRY_LIST,
    payload: country.list()
});


