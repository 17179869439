import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col, FormGroup, Row} from "reactstrap";
import Modal from '../UI/Modal/Modal'
import Button from '../UI/Button/Button'
import Input from '../UI/Input/Input'
import CheckBox from '../UI/CheckBox/CheckBox'
import {createMessage, notifyError} from "../Util/notification";
import {parseErrors} from "../Util/form";
import question from "../../api/question";
import {changeStateFromInput} from "../Util/controlledComponent";
import {supportedLanguages} from "../Util/language";
import {ALPHABET_LOWER} from "./util";
import {deepCopy, getSafeDeep} from "../Util/state";
import {setProp} from "../DynamicForm/controls/util";

const defaultQuestion = {
    translations: [],
    repeatable: false,
    value: "",
    isLoading: false,
    answers: [
        ""
    ]
};

class AddQuestionDialog extends React.Component {

    state = {
        errors: {},
        question: deepCopy(defaultQuestion)
    };

    componentDidMount() {


    }

    componentDidUpdate() {
    }

    onSubmit = async (e) => {
        e.preventDefault();
        const errors = this.state.errors
        let shouldStop = false
        if (this.state.question.answers) {
            this.state.question.answers.forEach((answer, i) => {
                if (answer == null || answer === "") {
                    setProp(errors, 'This field is required', `answers/${i}`)
                    shouldStop = true
                }
            })
        }
        if (this.state.question.value == null || this.state.question.value === "") {
            setProp(errors, 'This field is required', 'value')
            shouldStop = true
        }
        if (shouldStop) {
            this.setState({errors})
            return
        }
        this.setState({ isLoading: true })
        try{
            await question.create(this.state.question);
            createMessage('Successfully created question');
            this.setState({ question: deepCopy(defaultQuestion), isLoading: false });
            this.props.reloadData();
            this.props.toggle();
        }catch (e) {
            this.setState({ errors: parseErrors(e), isLoading: false });
        }
    };

    popQuestion = () => {
        const question = this.state.question;
        question.answers.pop();
        this.setState({ question });
    };

    appendQuestion = () => {
        const question = this.state.question;
        question.answers.push("");
        this.setState({ question });
    };

    handleInput = (e) => {
        const question = this.state.question;
        question[e.target.name] = e.target.value;
        this.setState({ question });
    };

    toggleReoccurring = (e) => {
        const question = this.state.question;
        question.reocurring = e;
        this.setState({ question });
    };

    handleAnswerInput = (e, index) => {
        const question = this.state.question;
        question.answers[index] = e.target.value;
        this.setState({ question });
    };

    render() {
        const {toggle, isOpen, categories} = this.props;
        const {errors} = this.state;
        if(!isOpen) return "";

        return <Fragment>
            <Modal  title="Add New Question"
                    toggle={toggle} 
                    isOpen={isOpen} 
                    isLoading={this.state.isLoading}
                    footer={() => {
                        return (
                            <>
                                <Button isLoading={this.state.isLoading} color="outline-primary" className="mr-3" onClick={() => this.appendQuestion()}>Add Answer</Button>
                                {this.state.question.answers.length > 1 && <Button isLoading={this.state.isLoading} color="danger" className="mr-3" onClick={() => this.popQuestion()}>Remove Answer</Button> }
                                <Button isLoading={this.state.isLoading} type="submit" color="primary" className="mr-3" onClick={(e) => this.onSubmit(e)}>Create Question</Button>
                                <Button isLoading={this.state.isLoading} color="secondary" onClick={toggle}>Back</Button>
                            </>
                        )
                    }}
                    size="lg">
                    <FormGroup>
                        <Row className="mb-4">
                            <Col sm={3}>
                                Question <sup className="text-danger">*</sup>
                            </Col>
                            <Col sm={9}>
                                <Input disabled={this.state.isLoading} name="value" required={true} value={this.state.question.value} onChange={(e) => this.handleInput(e)} />
                                <small className="text-danger">{getSafeDeep(errors, 'value')}</small>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col sm={3}>
                                Recurring
                            </Col>
                            <Col sm={9}>
                                <CheckBox disabled={this.state.isLoading} className="ml-2" name="repeatable" checked={this.state.question.reocurring} onChange={(e) => this.toggleReoccurring(!this.state.question.reocurring)} />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col sm={3}>
                                Language
                            </Col>
                            <Col sm={9}>
                                {supportedLanguages['en']}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <div className="font-weight-bold">Predefined answers</div>
                            </Col>
                        </Row>
                        {this.state.question.answers.map((question, index) => {
                            return <Row key={index} className="mb-4">
                                <Col sm={3}>
                                    <div className="d-flex align-items-center">
                                        {ALPHABET_LOWER[index]} <sup className="text-danger">*</sup>
                                    </div>

                                </Col>
                                <Col sm={9}>
                                    <Input disabled={this.state.isLoading} name="answer" required={true} value={question} onChange={(e) => this.handleAnswerInput(e, index)} />
                                    <small className="text-danger">{getSafeDeep(errors, `answers.${index}`)}</small>
                                </Col>
                            </Row>
                        }
                        )}
                    </FormGroup>
            </Modal>
        </Fragment>
    };

}

function mapState(state) {
    return {
        categories: state.categories.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(AddQuestionDialog));
