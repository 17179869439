import _ from 'lodash';

export function isFunction(object) {
    return _.isFunction(object);
}

export function clearProps(passedProps, keys){
    const props = {}
    //some props throw error when passed to <Modal> e.g. noFooter
    Object.keys(passedProps)
        .filter(key => keys.indexOf(key) == -1)
        .forEach(key => { props[key] = passedProps[key] })
    return props
}