import React, { useState } from 'react'

import { Form } from 'reactstrap'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'

import { getCountryData } from "../../countryList";

import './styles.module.css'
import { withRouter } from "react-router";
import qs from 'qs'
import { PermissionGuard } from "../../role-helpers";
import cn from 'classnames';
import { set } from "immutable";

const countries = getCountryData();
const approvalStates = [
    { value: 0, label: "First Lead" },
    { value: 1, label: "Second Lead" },
    { value: 2, label: "Pending" },
    { value: 3, label: "Approved" },
    { value: 4, label: "Rejected" },
    { value: 5, label: "Terminated" },
]

const OrgList = props => {

    const query = qs.parse(props.query, { ignoreQueryPrefix: true })

    const setFilter = (key, value) => {
        const newQuery = { ...query }

        if (value == null || value === "") {
            delete newQuery[key]
        } else {
            newQuery[key] = value
        }

        props.push(`?${qs.stringify(newQuery)}`)
    }


    const getFilter = (key) => query[key]
    const dataFormat = "YYYY-MM-DD";

    return (
        <div>
            <div styleName="header" className="d-flex align-items-center justify-content-end">
                <h4 className="mr-auto">{props.title}</h4>
                {/* <PermissionGuard code={'create_company'}>
                    <Button onClick={props.actionHandler} className="mr-3" color="outline-primary">Add</Button>
                </PermissionGuard> */}
                <PermissionGuard code={'company_update_state'}>
                    <Button onClick={props.rejectHandler} onClick={props.openModal} className="mr-3" color="danger">Reject</Button>
                </PermissionGuard>
            </div>
            <div styleName="onboardingSearchContainer" className={"d-flex"}>
                <Form onSubmit={props.onSubmit} styleName={"onboardingSearch"} >
                    <div>
                        <label>&nbsp;</label>
                        <Input
                            className="mr-3"
                            style={{ width: "256px" }}
                            onChange={props.onChange}
                            name="search"
                            placeholder={props.searchTitle} />
                    </div>
                </Form>
                <div className={"d-flex"}>
                    {/* <Button onClick={() => {
                        props.toggleInactiveCountries();
                        setFilter("inactive_countries", props.inactiveCountries? "" : !props.inactiveCountries )
                    }} className="mr-3" color={cn({"outline-primary" : !props.inactiveCountries, "primary" : props.inactiveCountries})}>Inactive Countries</Button> */}
                    <Button onClick={() => {
                        props.toggleUnfinishedOnboarding();
                        setFilter("uncompleted_onboarding", props.unfinishedOnboarding ? "" : !props.unfinishedOnboarding)
                    }} className="mr-3" color={cn({ "outline-primary": !props.unfinishedOnboarding, "primary": props.unfinishedOnboarding })}>Unfinished onboarding</Button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(OrgList)
