import {apiClient, apiClientV3} from './util';
import {transformUserData} from './model/user';
import {AxiosPromise} from 'axios';
import moment from 'moment';

export default {
  listPaginated: (filter, q, offset = 0, limit = 10, sort) => {
    let params = {
      limit,
      offset,
      filter,
      scope: 'system'
    };

    if (sort) {
      params.sort = sort;
    }
    if (q) {
      params.q = q;
    }

    return apiClient.get('/admin/users', {params});
  },

  listElipayUsers: (filter, q, offset = 0, limit = 10, tier) => {
    let params = {
      tier,
      limit,
      offset,
      filter,
      scope: 'custodian'
    };
    if (q) params.q = q;
    return apiClient.get('/admin/users', {params});
  },

  resendActivationEmail: (id) => {
    return apiClient.post(`admin/user/${id}/resend-activation`);
  },

  // Get a user
  get: (id: number): AxiosPromise<Object> =>
    apiClient.get(`/admin/user/${id}`).then(transformUserData),

  // Update a user
  update: (id: number, user: Object) => {
    return apiClient.put(`/admin/user/${id}`, {...user});
  },

  // Suspend a user
  suspend: (id: number, suspended_until: Date): AxiosPromise =>
    apiClient.put(`/admin/user/suspend/${id}`, {
      suspended_until: moment(suspended_until).toISOString()
    }),

  // Reset password for a user
  reset_password: (email: string): AxiosPromise =>
    apiClient.post('admin/user/change/password', {email}),

  change_password: (data) => apiClient.post('user/password/change', data),

  // Delete a user -- WARNING -- not to be used now
  delete: (id: number): AxiosPromise => apiClient.delete(`/admin/user/${id}`),

  // Create a user
  create: (user: Object): AxiosPromise => apiClient.post('/admin/user', user),

  // Get API keys of a user
  getKeys: (id) => apiClient.get(`/admin/api_auth/${id}`),

  // Generate user API keys
  generateKeys: (id) => apiClient.post(`/admin/api_auth/${id}`),

  // Link hash to the user
  linkHash: (userId, hash) => {
    let params = {
      hash: hash,
      object: 'user',
      referring_id: userId
    };

    return apiClient.post(`/admin/identifiers`, params);
  },

  listUnlinked: () =>
    apiClient.get(`/admin/sources?list_results=true&linked=false`),

  //Update merchant user
  updateMerchant: (id, user) =>
    apiClient.put(`/admin/user/merchant/${id}`, user),

  activate: (id, is_active) =>
    apiClientV3.post(`/admin/user/activation/${id}`, {is_active})
};
