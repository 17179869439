import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import user from './user';
import brands from './brand';
import auth from './auth';
import categories from './category';
import currencies from './currency';
import documentTypes from './documentType';
import tiers from './tier';
import exchangeFees from './exchangeFee';
import languages from './language';
import platforms from './platform';
import posProviders from './posProvider';
import transactionFees from './transactionFee';
import {allUsers} from './user';
import sourceLang from './sourceLang';
import posTypes from './posType';
import countries from './countries';
import hwPosOptions from './hwPosOptions';
import payfacList from './payfac';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    auth,
    allUsers,
    categories,
    currencies,
    documentTypes,
    brands,
    tiers,
    exchangeFees,
    languages,
    platforms,
    posProviders,
    posTypes,
    transactionFees,
    sourceLang,
    countries,
    hwPosOptions,
    payfacList
  });
