import React, {useState} from 'react';
import DynamicForm from '../../DynamicForm/DynamicForm';
import dataSchema from '../schema/representativeInfoData.json';
import uiSchema from '../schema/representativeInfoUi.json';
import companyKyb from '../../../api/companyKyb';
import {parseErrors} from '../../Util/form';
import {createMessage} from '../../Util/notification';
import {difference} from '../../DynamicForm/controls/util';
import {deepCopy, getSafeDeep, removeEmptyFields} from '../../Util/state';
import {connect} from 'react-redux';
import {hasPermission} from '../../Util/role-helpers';

const RepresentativeInfo = ({
  data,
  dataUpdated,
  countries,
  user,
  reloadData
}) => {
  /*Local state*/
  const [errors, setErrors] = useState({});

  /*Constants*/
  const country = countries.find(
    (c) =>
      c.a2_iso_code == getSafeDeep(data.kyb_data, 'document.issuing_country')
  );

  /*Methods*/
  const onSubmit = async (formData) => {
    setErrors({});
    const newData = difference(formData.form, data.kyb_data);

    const temporaryCountry =
      formData.form.temporary_address &&
      formData.form.temporary_address.country;
    const permanentCountry =
      formData.form.permanent_address &&
      formData.form.permanent_address.country;

    if (
      getSafeDeep(newData, 'document') &&
      getSafeDeep(newData, 'document.document_front_image') === ''
    ) {
      delete newData.document.document_front_image;
    }
    if (
      getSafeDeep(newData, 'document') &&
      getSafeDeep(newData, 'document.document_back_image') === ''
    ) {
      delete newData.document.document_back_image;
    }
    if (newData.temporary_address) {
      const allBlank = Object.entries(newData.temporary_address).reduce(
        (allBlank, [key, value]) => {
          if (key == 'country') return allBlank;
          return allBlank && value === '';
        },
        true
      );
      if (allBlank) {
        delete newData.temporary_address;
      }
    }
    if (newData.permanent_address) {
      const allBlank = Object.entries(newData.permanent_address).reduce(
        (allBlank, [key, value]) => {
          if (key == 'country') return allBlank;
          return allBlank && value === '';
        },
        true
      );
      if (allBlank) {
        delete newData.permanent_address;
      }
    }
    if (newData.ownership_percentage) {
      newData.ownership_percentage = newData.ownership_percentage / 100;
    }
    try {
      const oldData = removeEmptyFields(data.kyb_data);
      let submitData;
      if (newData.ownership_percentage === 0) {
        submitData = removeEmptyFields(newData);
        submitData.ownership_percentage = 0;
      } else {
        submitData = removeEmptyFields(newData);
      }

      if (temporaryCountry) {
        submitData['temporary_address'] = {
          ...submitData['temporary_address'],
          country: temporaryCountry
        };
      }

      if (permanentCountry) {
        submitData['permanent_address'] = {
          ...submitData['permanent_address'],
          country: permanentCountry
        };
      }

      if (getSafeDeep(oldData, 'document.document_back_image')) {
        delete oldData.document.document_back_image;
      }

      if (getSafeDeep(oldData, 'document.document_front_image')) {
        delete oldData.document.document_front_image;
      }

      const response = await companyKyb.update(data.id, {
        ...oldData,
        ...submitData
      });
      dataUpdated(response.data);
      reloadData();
      createMessage('Data updated successfully');
    } catch (e) {
      console.error(e);
      setErrors(parseErrors(e));
    }
  };
  const watchInput = (data) => {
    const country = countries.find(
      (c) =>
        c.a2_iso_code == getSafeDeep(data.kyb_data, 'form.document.country')
    );
    data.required_fields = getSafeDeep(
      country,
      'configuration.required_fields.passport',
      getSafeDeep(country, 'configuration.required_fields.id', [])
    );
    return deepCopy(data);
  };

  return (
    <DynamicForm
      submit
      dataSchema={dataSchema}
      readOnly={!hasPermission(user, 'modify_company_kyb')}
      uiSchema={uiSchema}
      onSubmit={onSubmit}
      inputChangeListener={watchInput}
      errors={errors}
      initData={{
        form: {
          ...data.kyb_data,
          ownership_percentage:
            data.kyb_data.ownership_percentage <= 1
              ? parseFloat(data.kyb_data.ownership_percentage * 100).toFixed(2)
              : data.kyb_data.ownership_percentage
        },
        countries,
        document_types: [
          {value: 0, label: 'ID Card'},
          {value: 1, label: 'Passport'}
        ],
        required_fields: getSafeDeep(
          country,
          'configuration.required_fields.passport',
          getSafeDeep(country, 'configuration.required_fields.id', [])
        )
      }}
    />
  );
};

const mapState = (state) => {
  return {
    countries: state.countries.data
  };
};

export default connect(mapState)(RepresentativeInfo);
