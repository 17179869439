import React from 'react';
import "./CardDisplay.module.css"
import * as qs from "qs";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Card} from "@material-ui/core";

class CardDisplay extends React.Component {


    render() {


        return(
            // eslint-disable-next-line react/jsx-no-undef
            <Card styleName="container">
                <div styleName="row">
                    {this.props.topLabel}
                    <div styleName="value">
                        {this.props.topValue}
                    </div>
                </div>
                <div styleName="row">
                    {this.props.bottomLabel}
                    <div styleName="value">
                        {this.props.bottomValue}
                    </div>
                </div>
            </Card>
        )
    }

};



function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(CardDisplay));