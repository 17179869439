import React from 'react'
import Select from 'react-select'
import {arrayContextWrapper} from "../ArrayContext";
import {formContextWrapper} from "../FormContext";
import { getInputError, getInputData, getSafe, computeIsRequired } from './util'

import classnames from 'classnames'

import './phone.css'

import examplePhoneNumbers from 'libphonenumber-js/examples.mobile.json'
import  { getName, overwrite } from 'country-list'
import { parsePhoneNumberFromString, getCountries, getCountryCallingCode , getExampleNumber }  from 'libphonenumber-js'
import flags from 'country-flag-icons/react/3x2'

overwrite([{
    code: 'AC',
    name: 'Ascension Island'
}, {
    code: 'TA',
    name: 'Tristan de Cunha'
}])

const options = getCountries().map(c => {
    return {
        value: c,
        label: getName(c),
        calling: getCountryCallingCode(c)
    }
})

const getCountryFromCalling = (calling) => {
    try{
        return options.filter(c => {
            return c.calling == calling
        })[0].value
    }catch(e){
        return null
    }
}

class Phone extends React.Component{
    
    state = {
        required: false,
        $refPrefix: "",
        $ref: "",
        focused: false,
        menuIsOpen: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scope.$ref !== prevState.$ref || prevState.required !== computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref)) {
            return {
                required: computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref),
                $ref: nextProps.scope.$ref
            }
        }
        return prevState;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const context = this.props.context;
        const nextContext = nextProps.context;
        const $ref = this.props.scope.$ref;
        const $refPrefix = this.props.scope.$refPrefix
        const next$ref = nextProps.scope.$ref;
        const next$refPrefix = nextProps.scope.$refPrefix
        const array = this.props.array;
        const nextArray = nextProps.array;
        return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) ||
            getInputData(context, array, $refPrefix) !== getInputData(nextContext, nextArray, next$refPrefix) ||
            getInputError(context, array, $ref) !== getInputError(nextContext, nextArray, next$ref) ||
            $ref !== next$ref || 
            $refPrefix !== next$refPrefix || 
            context.isLoading !== nextProps.isLoading ||
            context.disabled != nextProps.context.disabled 
    }

    setPhone = async (phone) => {
        let phoneNumber = parsePhoneNumberFromString(phone)
        const inputHandler = this.props.array ? this.props.array.onInputChange : this.props.context.onInputChange;
        
        const { theSame, $refPrefix, $ref, to$refPrefix, to$ref } = this.props.scope;

        const to = theSame && $refPrefix && $ref && getInputData(this.props.context, null, theSame)
        const valuePrefix = getInputData(this.props.context, this.props.array, this.props.scope.$refPrefix);

        if(phoneNumber){
            const localPhone = phoneNumber.formatInternational().split(' ')
            localPhone.shift()

            await inputHandler($refPrefix, phoneNumber.countryCallingCode);
            await inputHandler($ref, localPhone.join(' '))
            if(to){
                await inputHandler(to$refPrefix, phoneNumber.countryCallingCode);
                await inputHandler(to$ref, localPhone.join(' '))
            }
            return;
        }
        phoneNumber = parsePhoneNumberFromString(`+${getSafe(valuePrefix)}${phone}`)
        if(phoneNumber){
            const localPhone = phoneNumber.formatInternational().split(' ')
            localPhone.shift()

            await inputHandler($ref, localPhone.join(' '))
            if(to){
                await inputHandler(to$ref, localPhone.join(' '))
            }
            return;
        }
        if(phone.match(/^(\+?[0-9 ]*)?$/)){
            await inputHandler($ref, phone)
            if(to){
                await inputHandler(to$ref, phone)
            }
        }
    } 

    onSelectChange = async (c) => {
        const inputHandler = this.props.array ? this.props.array.onInputChange : this.props.context.onInputChange;
        const { theSame, $refPrefix, $ref, to$refPrefix } = this.props.scope;
        const to = theSame && $refPrefix && $ref && getInputData(this.props.context, null, theSame)

        await inputHandler($refPrefix, getCountryCallingCode(c.value))
        if(to){
            await inputHandler(to$refPrefix, getCountryCallingCode(c.value));
        }
    }

    renderInput = (error) => {
        let valuePrefix = getInputData(this.props.context, this.props.array, this.props.scope.$refPrefix)
        valuePrefix = valuePrefix ? valuePrefix.split('+').pop() : valuePrefix
        const valueNumber = getInputData(this.props.context, this.props.array, this.props.scope.$ref);
        
        if(!valuePrefix && !valueNumber) return null;

        const SingleValue = props => {
            const Flag = flags[props.data.value]
            return (
                <div className="d-flex align-items-center" >
                    <Flag width="15" height="15" />
                    <span className="pl-1">+{getCountryCallingCode(props.data.value)}</span>
                </div>
            )
        }

        const Option = (props) => {
            const Flag = flags[props.value];
            return <div className="d-flex py-2 country-option"  ref={props.innerRef} {...props.innerProps} >
                <Flag className="pl-2" width="25" height="20" />
                <span className="pl-2">{props.label}</span>
                <span className="pl-2">+{getCountryCallingCode(props.value)}</span>
            </div>
        }

        let placeholder = getExampleNumber(getCountryFromCalling(valuePrefix), examplePhoneNumbers).formatInternational().split(' ')
        placeholder.shift()
        
        return(
            <div className="phone" style={ error ? {borderColor: "#dc3545"} : this.state.focused ? {borderColor: "#716aca"} : null} >
                <Select 
                    isSearchable
                    value={{ value: getCountryFromCalling(valuePrefix), label: getName(getCountryFromCalling(valuePrefix)) }}
                    options={options}
                    isDisabled={this.props.context.disabled.indexOf(this.props.scope.$refPrefix) != -1 || this.props.prefixDisabled || this.props.scope.disabled}
                    onChange={this.onSelectChange}
                    className="country" 
                    classNamePrefix="country" 
                    components = {{ 
                        SingleValue,
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => {
                            return (
                                <span className="caret">&#9662;</span>
                            )
                        },
                        Option
                     }}
                    onFocus={() => this.setState({focused: true})} 
                    onBlur={() => this.setState({focused: false})} 
                />
                <input  value={getSafe(valueNumber)}  
                        disabled={this.props.context.disabled.indexOf(this.props.scope.$ref) != -1 || this.props.disabled}
                        placeholder={placeholder.join(' ')}
                        onChange={e => {this.setPhone(e.target.value)}} 
                        type="text" className="input" 
                        onFocus={() => this.setState({focused: true})} 
                        onBlur={() => this.setState({focused: false })} />
            </div>
        )
    }

    render() {
        const { context, array, scope: { $ref }, label, outterhtmlClass } = this.props
        const error = getInputError(context, array, $ref);
        return(
            <div className={classnames(outterhtmlClass, "mb-3")}>
                <label>{label}{this.state.required ? <sup className="text-danger">*</sup> : null}</label>
                {this.renderInput(error)}
                {error && <small className="text-danger">{error}</small>}
            </div>
        )
    }
}

export default arrayContextWrapper(formContextWrapper(Phone))
