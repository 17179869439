const crypto = require('crypto');

export const dictionaryToArrayOfObjects = (dictionary) => {
  try {
    return Object.entries(dictionary).map((pair) => ({
      id: pair[0],
      name: pair[1]
    }));
  } catch (e) {
    return [];
  }
};

export const openNewTab = (url: string) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: url
  }).click();
};

export const generateSignature = (apiKey: string, msgData = '12345') => {
  const hmac = crypto.createHmac('sha256', apiKey);
  hmac.update(msgData); // Update with message data

  const digest = hmac.digest(); // Generate the raw digest (binary)
  return btoa(String.fromCharCode(...new Uint8Array(digest)));
};

export const clearAllCookies = () => {
  const cookies = document.cookie.split('; ');
  for (let cookie = 0; cookie < cookies.length; cookie++) {
    const hostnameParts = window.location.hostname.split('.');
    while (hostnameParts.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[cookie].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        hostnameParts.join('.') +
        ' ;path=';
      const pathnameParts = window.location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (pathnameParts.length > 0) {
        document.cookie = cookieBase + pathnameParts.join('/');
        pathnameParts.pop();
      }
      hostnameParts.shift();
    }
  }
};

export function deleteCookie(name: string, path?: string, domain?: string) {
  const newCookieValue = {
    path: path ? 'path=' + path : '',
    domain: domain ? 'domain=' + domain : '',
    expires: 'expires=Thu, 01 Jan 1970 00:00:01 GMT'
  };

  document.cookie = `${name}=${Object.values(newCookieValue).join(';')}`;
}

export const generateRiskLevel = (level: number) => {
  switch (level) {
    case 0: {
      return 'Low';
    }
    case 1: {
      return 'Medium';
    }
    case 2: {
      return 'High';
    }
    default: {
      return 'Unspecified score';
    }
  }
};
