import { apiClientV3 } from "../util";

export default {

    get: (start_date, end_date, offset = 0, limit = 10, ordering) => {
        let params = {
            start_date,
            end_date,
            offset,
            limit,
        }
        if (ordering) {
            params.ordering = ordering
        }
        return apiClientV3.get(`/admin/accounting/provider/list`, { params })
    },

    download: (data) => apiClientV3.post(`/admin/accounting/external/billing/download`, data),

    markCompleted: (data) => apiClientV3.post(`/admin/accounting/payout/providers/manual`, data,),

    markNotCompleted: (data) => apiClientV3.delete(`/admin/accounting/payout/providers/manual`, { data: data }),

}



