export function getGeocodeLatlng(latlng) {
  const google = window.google;

  if (!google) {
    throw Error('Google not accessible');
  }

  const geocoder = new window.google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    if (!geocoder) {
      throw Error('Geocoder not accessible');
    }
    geocoder.geocode({location: latlng}, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        resolve(results);
      } else {
        reject('Geocoder failed due to: ' + status);
      }
    });
  });
}
