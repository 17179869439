import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Modal from '../UI/Modal/Modal';
import {createMessage} from '../Util/notification';
import DynamicForm from '../DynamicForm/DynamicForm';
import user from '../../api/user';
import {ERR_VALIDATION} from '../../api/errorCodes';
import {getSafeDeep} from '../Util/state';
import {mapBackendErrors} from '../DynamicForm/controls/util';
import {parseErrors} from '../Util/form';
import brand from '../../api/brand';
import categories from '../../redux/category';
import organization from '../../api/organization';
import addOrganizationDataSchema from './schema/addOrganizationData';
import addOrganizationUiSchema from './schema/addOrganizationUi';
import {getCountryData} from '../Util/countryList';

const countries = getCountryData();

const copyElements = [
  {
    from: '#/properties/form/properties/business_contact_support/properties/first_name',
    to: '#/properties/form/properties/technical_contact_support/properties/first_name'
  },
  {
    from: '#/properties/form/properties/business_contact_support/properties/last_name',
    to: '#/properties/form/properties/technical_contact_support/properties/last_name'
  },
  {
    from: '#/properties/form/properties/business_contact_support/properties/email',
    to: '#/properties/form/properties/technical_contact_support/properties/email'
  },
  {
    from: '#/properties/form/properties/business_contact_support/properties/phone_number',
    to: '#/properties/form/properties/technical_contact_support/properties/phone_number'
  }
];

class AddOrganizationDialog extends React.Component {
  state = {
    errors: undefined
  };

  componentDidMount() {
    this.loadSchema();
  }

  componentDidUpdate() {}

  onSubmit = async (data) => {
    try {
      await organization.create(data);
      createMessage('Successfully created organization');
      this.props.reloadData();
      this.props.toggle();
    } catch (e) {
      console.error(e);
      this.setState({errors: parseErrors(e)});
    }
  };

  loadSchema = async () => {
    const uiSchema = addOrganizationUiSchema;
    const dataSchema = addOrganizationDataSchema;
    this.setState({uiSchema, dataSchema});
  };

  render() {
    const {toggle, isOpen, exchangeFees} = this.props;
    const {errors} = this.state;
    if (!isOpen) return '';

    return (
      <Fragment>
        <Modal
          toggle={toggle}
          isOpen={isOpen}
          title="Add New Organization"
          cancelHandler={toggle}
          errors={errors}
          size="lg"
        >
          <DynamicForm
            uiSchema={this.state.uiSchema}
            dataSchema={this.state.dataSchema}
            initData={{countries, exchangeFees, copyElements}}
            errors={errors}
            onSubmit={(data) => {
              this.onSubmit(data.form);
            }}
          />
        </Modal>
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    categories: state.categories.data,
    exchangeFees: state.exchangeFees.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(AddOrganizationDialog));
