import React from 'react'
import {renderElement} from "./util";
import {Button} from "reactstrap";

type SectionLayoutProps = {
    elements: Array
}

class SectionLayout extends React.Component<SectionLayoutProps> {
    state = {
        openTab: 0
    };
    render() {
        const {elements} = this.props;
        const childElements = elements[this.state.openTab].elements;
        return (
            <div className="d-flex flex-column">
                <div className="d-flex flex-wrap border-bottom mb-3">
                    {elements.map((section, i) =>
                        <Button color="link" onClick={() => this.setState({openTab: i})} key={section.label}>{section.label}</Button>
                    )}
                </div>
                {childElements && childElements.map(renderElement)}
            </div>
        );
    }
}

export default SectionLayout
