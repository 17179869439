import {apiClientV3, apiClient} from '../util';

export default {
  list: (limit = 10, offset = 0, search, ordering = '-created', filter = {}) =>
    apiClientV3.get('/admin/stores/', {
      params: {limit, offset, search, ordering, ...filter}
    }),

  get: (id) => apiClientV3.get(`/admin/store/${id}/`),

  create: (data) => apiClientV3.post(`/admin/store/`, data),

  update: (id, data) => {
    if (
      data.integrator_id &&
      data.integrator_id !== '29' &&
      data.integrator_id !== '28'
    ) {
      data.payment_integrator_description = '';
    }

    return apiClientV3.put(`/admin/store/${id}/`, data);
  },

  posTypeList: () => apiClientV3.get('/admin/pos-types/'),

  listCashiers: (store_id) =>
    apiClientV3.get(`/admin/store/${store_id}/cashiers/`),

  createCashier: (store_id, data) =>
    apiClientV3.post(`/admin/store/${store_id}/cashiers/`, data),

  editCashier: (id, data) => apiClientV3.put(`/admin/cashiers/${id}/`, data),

  storeAction: (id, action, reason) =>
    apiClientV3.post(
      `/admin/store/${id}/${action}`,
      reason ? {reason} : undefined
    ),

  sendEmails: (id, value) =>
    apiClientV3.patch(
      `/admin/store/${id}/mails/${value ? 'enable' : 'disable'}`
    ),

  getTransactionFees: (country, type) =>
    apiClientV3.get(`/admin/fees/transaction/${country}?source_type=${type}`),

  getExchangeFees: (country) =>
    apiClientV3.get(`/admin/fees/exchange/${country}`),

  getTimezones: (a2_iso_code = '') => apiClient.get(`/timezone/${a2_iso_code}`),

  resetPIN: (id) => apiClientV3.get(`/admin/store/${id}/pin/reset`),

  resendPIN: (id) => apiClientV3.get(`/admin/store/${id}/pin/resend`),

  getMccList: (id) => apiClientV3.get(`/admin/store/${id}/mcc`)
};
