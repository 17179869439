import {ELIPAY_LOCAL_STORAGE} from '../../config';

export async function loadApiResources(props) {
  localStorage.setItem(`${ELIPAY_LOCAL_STORAGE}/LoggingIn`, true);

  let apiCalls = [
    props.getUser(),
    props.getCurrencyList(),
    props.getHwPosOptionsList(),
    props.getDocumentTypeList(),
    props.getTransactionFeeList(),
    props.getExchangeFeeList(),
    props.getCategoryList(),
    props.getPosProviderList(),
    props.getPlatformList(),
    props.getTierList(),
    props.getPosTypeList(),
    props.getGlobalLanguageList(),
    props.getCountryList(),
    props.getPayfacList()
  ];

  return Promise.all(apiCalls);
}
