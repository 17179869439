import React, {Fragment} from 'react'
import Group from "./Group";
import {Table} from "reactstrap";
import {getDataProp, getProp, renderElement, setProp} from "./util";
import type {FormContextProps} from "../FormContext";
import {formContextWrapper} from "../FormContext";
import ArrayContext from "../ArrayContext";
import Ajv from "ajv";
import classnames from 'classnames'
import Button from '../../UI/Button/Button'

type ArrayProps = {
    componentId: String,
    label: String,
    labelType: String,
    defaultPageSizes: String,
    deniedActions: Array<String>,
    arrayOperationHandler : String,
    columnDefinitions: Array<{
        type: String,
        title: String,
        name: String,
        scope: {$ref: String},
        template?: String
    }>,
    detailSubUISchema: String,
    scope: {$ref: String},
    subUISchema: Object,
    context: FormContextProps
}

class ArrayComponent extends React.Component<ArrayProps>{
    validator = null;
    state = {
        newItem: {},
        errors: {},
        showNewItemForm: false,
        editedItem: {},
        editedIndex: -1,
        expandedItems: []
    };

    toggleForm = () => this.setState({showNewItemForm: !this.state.showNewItemForm});

    onInputChange = (path, value) => {
        const newItem = JSON.parse(JSON.stringify(this.state.newItem));
        setProp(newItem, value, path);
        this.setState({newItem});
    };

    onEditChange = (path, value) => {
        const editedItem = JSON.parse(JSON.stringify(this.state.editedItem));
        setProp(editedItem, value, path);
        this.setState({editedItem});
    };

    editItem = (index) => {
        const {context, scope: {$ref}} = this.props;
        const item = JSON.parse(JSON.stringify(getDataProp(context.data, `${$ref}/${index}`)));
        this.setState({editedItem: item, editedIndex: index});
    };

    discardEdit = () => {
        this.setState({editedIndex: -1, editedItem: {}});
    };

    saveEdit = () => {
        const {context, scope: {$ref}} = this.props;
        const data = this.state.newItem;
        const valid = this.validator(data);
        if (!valid) {
            const errors = this.validator.errors.reduce((errors, error) => {
                let path = error.schemaPath.split("/");
                path.pop();
                path = path.join("/");
                if (error.keyword === "required") {
                    path += `/properties/${error.params.missingProperty}`;
                    errors[path] = "This field is required";
                } else {
                    errors[path] = error.message;
                }
                return errors;
            }, {});
            this.setState({errors});
        } else {
            const newArray = getDataProp(context.data, $ref) || [];
            newArray[this.state.editedIndex] = this.state.editedItem;
            context.onInputChange($ref, newArray);
            this.setState({editedIndex: -1, errors: {}})
        }
    };

    deleteItem = (index) => {
        const {context, scope: {$ref}} = this.props;
        const newArray = getDataProp(context.data, $ref);
        newArray.splice(index, 1);
        context.onInputChange($ref, newArray);
        this.setState({editedIndex: -1, errors: {}})
    };

    onAddRow = () => {
        const {context, scope: {$ref}} = this.props;
        const data = this.state.newItem;
        const valid = this.validator(data);
        if (!valid) {
            const errors = this.validator.errors.reduce((errors, error) => {
                let path = error.schemaPath.split("/");
                path.pop();
                path = path.join("/");
                if (error.keyword === "required") {
                    path += `/properties/${error.params.missingProperty}`;
                    errors[path] = "This field is required";
                } else {
                    errors[path] = error.message;
                }
                return errors;
            }, {});
            this.setState({errors});
        } else {
            const newArray = getDataProp(context.data, $ref) || [];
            newArray.push(this.state.newItem);
            context.onInputChange($ref, newArray);
            this.setState({newItem: {}, errors: {}, showNewItemForm: false})
        }
    };

    componentDidUpdate(oldProps) {
        if (this.props.scope.$ref !== oldProps.scope.$ref){
            const {context, scope: {$ref}} = this.props;
            const ajv = new Ajv({ coerceTypes: true, useDefaults: true, allErrors: true , schemaId: "auto"});
            ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));
            this.validator = ajv.compile(getProp(context.dataSchema, $ref + "/items"));
            this.validator(this.state.newItem);
            this.setState({newItem: {}, errors: {}})
        }
    }

    componentDidMount() {
        const {context, scope: {$ref}} = this.props;
        const ajv = new Ajv({ coerceTypes: true, useDefaults: true, allErrors: true , schemaId: "auto"});
        ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));
        this.validator = ajv.compile(getProp(context.dataSchema, $ref + "/items"));
        this.validator(this.state.newItem);
    }


    render() {
        const {label, columnDefinitions, scope: {$ref}, context, subUISchema, detailSubUISchema} = this.props;
        const data = getDataProp(context.data, $ref) || [];
        return (
            <div>

                <div className="">{label}</div>


                <Table bordered className="mt-3">
                    <thead>
                    <tr>
                        {columnDefinitions.map((column, i) =>
                            <td key={i}>{column.title.split("_").pop()}</td>
                        )}
                        <td width="1">Actions</td>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, i) =>
                        <Fragment key={i}>
                            <tr className={classnames({"bg-light": this.state.editedIndex === i})}>
                                {columnDefinitions.map((column, i) =>
                                    <td key={i}>{getDataProp(item, column.scope ? column.scope.$ref : column.$ref)}</td>
                                )}
                                <td>
                                    <div className="d-flex">
                                        <Button color="outline-primary" className="mr-2" onClick={() => this.editItem(i)}>Edit</Button>
                                        <Button color="outline-danger" className="mr-2" onClick={() => this.deleteItem(i)}>Delete</Button>
                                        {detailSubUISchema && <Button><span className="caret"/></Button>}
                                    </div>
                                </td>
                            </tr>
                            {this.state.editedIndex === i &&
                                <tr className="bg-light">
                                    <td colSpan={columnDefinitions.length + 1}>
                                        <div className="pl-5">

                                            <ArrayContext.Provider value={{item: this.state.editedItem, onInputChange: this.onEditChange, $ref, errors: this.state.errors}}>
                                                {renderElement(subUISchema)}
                                            </ArrayContext.Provider>
                                            <div className="d-flex justify-content-end">
                                                <Button color="outline-primary" className="mr-2" onClick={this.saveEdit}>Ok</Button>
                                                <Button color="outline-danger" onClick={this.discardEdit}>Cancel</Button>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            }
                            {this.state.expandedItems.indexOf(i) !== -1 &&
                                <tr className="bg-light">
                                    <td colSpan={columnDefinitions.length + 1}>
                                        {renderElement(detailSubUISchema)}
                                    </td>
                                </tr>
                            }
                        </Fragment>
                    )}
                    </tbody>
                </Table>
                <div className="mb-4">
                    {this.state.showNewItemForm ?
                        <Fragment>
                            <div className="pl-5">
                                <ArrayContext.Provider value={{item: this.state.newItem, onInputChange: this.onInputChange, $ref, errors: this.state.errors}}>
                                    {renderElement(subUISchema)}
                                </ArrayContext.Provider>
                                <div className="d-flex justify-content-end">
                                    <Button color="outline-primary" className="mr-2" onClick={this.onAddRow}>Ok</Button>
                                    <Button color="outline-danger" onClick={this.toggleForm}>Cancel</Button>
                                </div>
                            </div>



                        </Fragment>
                        :
                        <div className="w-100 d-flex justify-content-end">
                            <Button color="outline-primary" onClick={this.toggleForm}>Add Item</Button>
                        </div>

                    }
                </div>

            </div>
        );
    }
}

export default formContextWrapper(ArrayComponent)
