import React from 'react'

import cx from 'classnames'

import './styles.module.css'

export default (props) => {
    return (
        <div styleName={cx("badge", props.color)} >
            {props.val}
        </div>
    )
}