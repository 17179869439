import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import SideBarItem from "./SideBarItem/SideBarItem";

import { routes } from "../Util/routing";

import "./sideBar.module.css";
import { PermissionGuard } from "../Util/role-helpers";

class SideNav extends React.Component {
  state = {
    timeout: null,
    menu: [
      {
        label: "Dashboard",
        link: "/",
        icon: "line-graph",
        expanded: false,
      },
      {
        label: "GoCrypto",
        icon: "business",
        expanded: false,
        subLinks: [
          {
            label: "Transactions",
            link: routes.transactions,
            permission: ["viewAll_payments", "view_payments_total"],
          },
          {
            label: "Billing",
            link: routes.billing,
            permission: ["view-billing-overview", "view-billing-stores"],
          },
          {
            label: "POS Providers Billing",
            link: routes.externalPOSProviders,
            permission: ["view-billing-overview", "view-billing-stores"],
          },
          {
            label: "Onboarding referrals",
            link: routes.referral,
            permission: "viewAll_company_referrals",
          },
          {
            label: "Balances",
            link: routes.balanceList,
            permission: "list_crypto_balances",
          },
          {
            label: "Merchant Admin",
            permission: [
              "view_all_companies",
              "view_all_sources_v3",
              "view_all_franchises",
              "get_qr_bundle",
              "view_qr_code_list",
              "list_pending_withdraws",
              "view_all_onboarding_companies",
            ],
            atLeastOne: true,
            subLinks: [
              {
                label: "Companies",
                link: routes.organizationList,
                permission: "view_all_companies",
              },
              {
                label: "Points of Sale",
                link: routes.posList,
                permission: "view_all_sources_v3",
              },
              {
                label: "Brands",
                link: routes.brandList,
                permission: "view_all_franchises",
              },
              {
                label: "QR Bundles",
                link: routes.QRBundleList,
                permission: "get_qr_bundle",
              },
              {
                label: "QR Codes",
                link: routes.QRCodeList,
                permission: "view_qr_code_list",
              },
              {
                label: "Withdrawal",
                link: routes.merchantWithdrawal,
                permission: "list_pending_withdraws",
              },
              {
                label: "Onboarding",
                link: routes.onboarding,
                permission: "view_all_onboarding_companies",
              },
            ],
          },
          {
            label: "Holistic Companies",
            link: routes.holisticPOSCompanies,
          },
        ],
      },
      {
        label: "Exchanges",
        icon: "open-box",
        expanded: false,
        subLinks: [
          {
            label: "Kraken",
            expanded: true,
            subLinks: [
              {
                label: "Trades",
                link: routes.exchangeTradesKraken,
                permission: "view_exchanges_trades",
              },
              {
                label: "Ledgers",
                link: routes.exchangeLedgersKraken,
                permission: "view_exchanges_ledgers",
              },
              {
                label: "Balances",
                link: routes.exchangeBalancesKraken,
                permission: "view_exchanges_balances",
              },
            ],
          },
          {
            label: "Bitstamp",
            expanded: true,
            subLinks: [
              {
                label: "Trades",
                link: routes.exchangeTradesBitstamp,
                permission: "view_exchanges_trades",
              },
              {
                label: "Ledgers",
                link: routes.exchangeLedgersBitstamp,
                permission: "view_exchanges_ledgers",
              },
              {
                label: "Balances",
                link: routes.exchangeBalancesBitstamp,
                permission: "view_exchanges_balances",
              },
            ],
          },
          {
            label: "Tokens.net",
            expanded: true,
            subLinks: [
              {
                label: "Trades",
                link: routes.exchangeTradesTokensnet,
                permission: "view_exchanges_trades",
              },
              {
                label: "Ledgers",
                link: routes.exchangeLedgersTokensnet,
                permission: "view_exchanges_ledgers",
              },
              {
                label: "Balances",
                link: routes.exchangeBalancesTokensnet,
                permission: "view_exchanges_balances",
              },
            ],
          },
          {
            label: "Bitcoin.com",
            expanded: true,
            subLinks: [
              {
                label: "Trades",
                link: routes.exchangeTradesBitcoincom,
                permission: "view_exchanges_trades",
              },
              {
                label: "Ledgers",
                link: routes.exchangeLedgersBitcoincom,
                permission: "view_exchanges_ledgers",
              },
              {
                label: "Balances",
                link: routes.exchangeBalancesBitcoincom,
                permission: "view_exchanges_balances",
              },
            ],
          },
          {
            label: "Blockchain.com",
            expanded: true,
            subLinks: [
              {
                label: "Trades",
                link: routes.exchangeTradesBlockchaincom,
                permission: "view_exchanges_trades",
              },
              {
                label: "Ledgers",
                link: routes.exchangeLedgersBlockchaincom,
                permission: "view_exchanges_ledgers",
              },
              {
                label: "Balances",
                link: routes.exchangeBalancesBlockchaincom,
                permission: "view_exchanges_balances",
              },
            ],
          },
          {
            label: "Binance",
            expanded: true,
            subLinks: [
              {
                label: "Trades",
                link: routes.exchangeTradesBinance,
                permission: "view_exchanges_trades",
              },
              {
                label: "Ledgers",
                link: routes.exchangeLedgersBinance,
                permission: "view_exchanges_ledgers",
              },
              {
                label: "Balances",
                link: routes.exchangeBalancesBinance,
                permission: "view_exchanges_balances",
              },
            ],
          },
          {
            label: "Okex",
            expanded: true,
            subLinks: [
              {
                label: "Trades",
                link: routes.exchangeTradesOkex,
                permission: "view_exchanges_trades",
              },
              {
                label: "Ledgers",
                link: routes.exchangeLedgersOkex,
                permission: "view_exchanges_ledgers",
              },
              {
                label: "Balances",
                link: routes.exchangeBalancesOkex,
                permission: "view_exchanges_balances",
              },
            ],
          },
          {
            label: "Bitmart",
            expanded: true,
            subLinks: [
              {
                label: "Trades",
                link: routes.exchangeTradesBitmart,
                permission: "view_exchanges_trades",
              },
              {
                label: "Ledgers",
                link: routes.exchangeLedgersBitmart,
                permission: "view_exchanges_ledgers",
              },
              {
                label: "Balances",
                link: routes.exchangeBalancesBitmart,
                permission: "view_exchanges_balances",
              },
            ],
          },
        ],
      },
      {
        label: "Sales",
        icon: "graph",
        expanded: false,
        combined: [
          "view_pending_kyc",
          "list_company_kyb",
          "System Administrator",
          "MKTG Questioner",
        ],
        subLinks: [
          {
            label: "In-App Questions",
            link: routes.loyaltyQuestionList,
            allowedRoles: ["System Administrator", "MKTG Questioner"],
          },
          {
            label: "Broadcast Notifications",
            link: routes.broadcastNotifications,
            allowedRoles: ["System Administrator", "MKTG Questioner"],
          },
          {
            label: "KYC List",
            link: routes.kycList,
            permission: "view_pending_kyc",
          },
          {
            label: "Company KYB",
            link: routes.listCompanyKYB,
            permission: "list_company_kyb",
          },
        ],
      },
      {
        label: "Accounting",
        icon: "piggy-bank",
        expanded: false,
        permission: ["list_wallets"],
        atLeastOne: true,
        subLinks: [
          {
            label: "Wallets",
            link: routes.walletsList,
            permission: "list_wallets",
          },
        ],
      },
      {
        label: "User Management",
        icon: "user",
        expanded: false,
        combined: [
          "System Administrator",
          "Sales Admin",
          "KYC Approver",
          "KYC Manager",
          "view_all_users",
          "view_all_roles",
        ],
        subLinks: [
          {
            label: "Users",
            link: routes.userList,
            allowedRoles: ["System Administrator", "Sales Admin"],
          },
          {
            label: "Roles",
            link: routes.roles,
            allowedRoles: ["System Administrator", "Sales Admin"],
          },
          {
            label: "Elly Users",
            link: routes.elipayUserList,
            subLinkMapping: "tiers",
            allowedRoles: [
              "System Administrator",
              "KYC Approver",
              "KYC Manager",
            ],
            subLinks: [
              {
                label: "All users",
                link: routes.elipayUserList,
              },
            ],
          },
        ],
      },
    ],
  };

  toggleExpanded(index) {
    this.setState((prev) => {
      const newState = {
        ...prev,
        menu: [],
      };
      prev.menu.forEach((item, index) => {
        newState.menu[index] = {
          ...item,
          expanded: false,
        };
      });
      newState.menu[index].expanded = !prev.menu[index].expanded;
      return newState;
    });
  }

  mouseEnteredHandler = () => {
    if (this.state.timeout) clearTimeout(this.state.timeout);

    this.props.setNav({ lowerNav: true });
  };

  mouseLeftHandler = () => {
    if (this.state.timeout) clearTimeout(this.state.timeout);

    if (!this.props.upperNav) {
      const timeout = setTimeout(() => {
        this.props.setNav({ lowerNav: this.props.upperNav });
      }, 400);
      this.setState({ timeout });
    }
  };

  render() {
    const full = this.props.lowerNav && this.props.upperNav;
    const floating = this.props.lowerNav && !this.props.upperNav;

    return (
      <div
        styleName={cx("sidebar", { full: full })}
        onMouseEnter={this.mouseEnteredHandler}
        onMouseLeave={this.mouseLeftHandler}
      >
        <div styleName={cx("box", { grow: floating })}></div>
        <ul styleName={cx("menu", { grow: floating || full })}>
          {this.state.menu.map((item, i) => (
            <PermissionGuard
              code={item.permission}
              key={item.label + "Main"}
              combined={item.combined}
            >
              {/* <PermissionGuard code={item.permission} key={item.label + "Main"} > */}
              <SideBarItem
                show={this.props.lowerNav || this.props.upperNav}
                toggleExpanded={() => this.toggleExpanded(i)}
                {...item}
              />
            </PermissionGuard>
          ))}
        </ul>
      </div>
    );
  }
}

const mapState = (state) => ({
  tiers: state.tiers.data,
});

export default connect(mapState)(SideNav);
