import React from 'react'
import { isEqual } from 'lodash'

import Button from '@material-ui/core/Button';
import Modal from '../../UI/Modal/Modal'
import Select from '../../UI/Select/Select'

import './transferList.module.css'
import { withStyles } from '@material-ui/core';
import { getSafeDeep } from '../../Util/state';

const StyledButton = withStyles({
    root: {
        color: "#716aca",
        fontSize: 14,
        "&:hover": {
            background: "transparent"
        }
    },
    label: {
        textTransform: 'capitalize',
    }
})(Button)

class AddModal extends React.Component {

    state = {
        region_id: null
    }

    onSubmit = () => {
        if (this.state.region_id == null) return;

        if (this.props.onlyLevel) {
            this.props.handleSubmit({ ...this.props.data.level, region_id: this.state.region_id.id })
        } else {
            this.props.handleSubmit(this.props.data.level, { ...this.props.data.item, region_id: this.state.region_id.id })
        }

        this.props.toggle();
        this.onRegionChange(null)
    }

    onRegionChange = (e) => {
        this.setState({ region_id: e })
    }

    render() {

        const { toggle, errors, data, addOptions } = this.props;

        return (
            <Modal
                toggle={toggle}
                isOpen={!!data}
                size="sm"
                errors={errors}
                cancelHandler={toggle}
                onSubmit={this.onSubmit}
                title="Choose Region">
                <Select
                    options={addOptions}
                    getOptionLabel={item => item.name}
                    getOptionValue={item => item.id}
                    value={this.state.region_id}
                    onChange={this.onRegionChange}
                />
            </Modal>
        )
    }
}

class TransferList extends React.Component {

    state = {
        action: null,
        group: null
    }

    getFrom = (what, from, field) => {
        for (let item of from) {
            if (item[field] == what[field]) {
                return item
            }
        }
        return null
    }

    addRegionToRolesGroup = (group) => {
        if (group.region_id == null) return group;

        let newGroup = {
            ...group,
            actions: [
                ...group.actions
            ]
        };

        for (let i = 0; i < newGroup.actions.length; i++) {
            newGroup.actions[i] = {
                ...newGroup.actions[i],
                region_id: group.region_id
            }
        }
        return newGroup;
    }

    addGroup = (level) => {

        const { data, dataAdd, selected } = this.props;
        const newSelected = []

        data.forEach(lvl => {
            let current = this.getFrom(lvl, selected, 'group')
            if (current) {
                if (current.group == level.group) {
                    newSelected.push(this.addRegionToRolesGroup(level))
                } else {
                    newSelected.push(current)
                }
            } else {
                if (lvl.group == level.group) {
                    // newSelected.push(this.addRegionToRolesGroup(lvl))
                    newSelected.push(this.addRegionToRolesGroup(level))
                }
            }
        })
        this.props.applyChanges(newSelected)
    }

    removeGroup = level => {
        const newSelected = []
        this.props.data.forEach(lvl => {
            let current = this.getFrom(lvl, this.props.selected, 'group')
            if (current && current.group != level.group) {
                newSelected.push(current)
            }
        })
        this.props.applyChanges(newSelected)
    }

    addAction = (newLvl, action) => {

        const { data, dataAdd, selected, id, applyChanges } = this.props;

        let newSelected = [];

        if (dataAdd) {//allow only one role
            newSelected.push({
                group: newLvl.group,
                actions: [action]
            })
            applyChanges(newSelected)
            return
        }

        data.forEach(currentLvl => {
            let selectedLvl = this.getFrom(currentLvl, selected, 'group')

            if (selectedLvl) {
                if (newLvl.group == currentLvl.group) {
                    let actions = []

                    for (let act of currentLvl.actions) {
                        const oldAct = this.getFrom(act, selectedLvl.actions, id)
                        if (oldAct) {
                            //copy old
                            actions.push(oldAct)
                        } else if (act[id] == action[id]) {
                            //add new
                            actions.push(action)
                        }
                    }
                    newSelected.push({
                        group: selectedLvl.group,
                        actions
                    })

                } else {
                    newSelected.push(this.addRegionToRolesGroup(selectedLvl))
                }
            } else {
                if (newLvl.group == currentLvl.group) {
                    newSelected.push({
                        group: currentLvl.group,
                        actions: [action]
                    })
                }
            }
        })
        applyChanges(newSelected)
    }

    removeAction = (removeLvl, action) => {

        const { id } = this.props;

        const newSelected = []
        this.props.data.forEach(currentLvl => {
            let selectedLvl = this.getFrom(currentLvl, this.props.selected, 'group')
            if (selectedLvl) {
                if (removeLvl.group == currentLvl.group) {
                    let actions = []
                    for (let act of currentLvl.actions) {
                        const oldAct = this.getFrom(act, selectedLvl.actions, id)
                        if (oldAct && act[id] != action[id]) {
                            //copy old
                            actions.push(oldAct)
                        }
                        // if (this.getFrom(act, selectedLvl.actions, this.props.id) && act[this.props.id] != action[this.props.id]) {
                        //     actions.push(act)
                        // }
                    }
                    if (!isEqual(actions, [])) {
                        newSelected.push({
                            group: selectedLvl.group,
                            actions
                        })
                    }
                } else {
                    newSelected.push(selectedLvl)
                }
            }
        })
        this.props.applyChanges(newSelected)
    }

    handleAddAction = (level, item) => {

        if (this.props.dataAdd) {
            this.setState({ action: { level, item } })
        } else {
            this.addAction(level, item)
        }
    }

    handleAddGroup = (level) => {
        if (this.props.dataAdd) {
            return;
        }
        this.addGroup(level)
    }

    render() {

        const { data, dataAdd, selected, applyChanges } = this.props

        return (
            <div styleName="transferList">
                <div className="d-flex flex-column flex-1">
                    <div className="py-2">{this.props.labelSrc}</div>
                    <div styleName="source">
                        {data.map((level, i) => {
                            const current = this.getFrom(level, selected, 'group')
                            if (current && data[i].actions.length <= current.actions.length) return null
                            return <div key={level.group}>
                                <div styleName="level" onClick={() => this.handleAddGroup(level)}>{level.group}</div>
                                {level.actions.map(item => {
                                    const current = this.getFrom(level, selected, 'group')
                                    if (current && this.getFrom(item, current.actions, this.props.id)) return null
                                    return <div onClick={() => this.handleAddAction(level, item)} key={item[this.props.id]} styleName="item">{item[this.props.display]}</div>
                                })}
                            </div>
                        })}
                        {isEqual(data, selected) && (
                            <div className="d-flex h-100 align-items-center justify-content-center">
                                <p>No groups selected</p>
                            </div>)}
                    </div>
                    <StyledButton onClick={() => applyChanges([...data])}>Add all</StyledButton>
                </div>
                <div className="p-3"></div>
                <div className="d-flex flex-column flex-1">
                    <div className="py-2">{this.props.labelDest}</div>
                    <div styleName="target">
                        {selected.map(level => (
                            <div key={level.group}>
                                <div styleName="level" onClick={() => this.removeGroup(level)}>{level.group}</div>
                                {level.actions.map(item => {
                                    return <div onClick={() => this.removeAction(level, item)} key={item[this.props.id]} styleName="item">
                                        {item[this.props.display]} {item.region_id != null && <b>{getSafeDeep(dataAdd.find(o => o.id == item.region_id), 'name')}</b>}
                                    </div>
                                })}
                            </div>
                        ))}
                        {isEqual(selected, []) && (
                            <div className="d-flex h-100 align-items-center justify-content-center">
                                <p>No groups selected</p>
                            </div>
                        )}
                    </div>
                    <StyledButton onClick={() => applyChanges([])}>Remove all</StyledButton>
                </div>
                <AddModal
                    addOptions={dataAdd}
                    data={this.state.action}
                    handleSubmit={this.addAction}
                    toggle={() => this.setState({ action: null })}
                />
                <AddModal
                    onlyLevel={true}
                    addOptions={dataAdd}
                    data={this.state.group}
                    handleSubmit={this.addGroup}
                    toggle={() => this.setState({ group: null })}
                />
            </div>
        )
    }
}

export default TransferList