import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import Button from '../Button/Button'
import { withRouter } from 'react-router-dom'
import './dropdown.module.css'

const Dropdown = props => {
    
    const [isOpen, open] = useState(false)
    const menu = (
        <>
            <div styleName="backdrop" onClick={() => open(false)} ></div>
            <div styleName="menu">
                {props.links.map(link => {
                    return (
                        <a key={link.to} styleName="item" onClick={() => { props.history.push(link.to) }}>
                            {link.label} 
                        </a>
                    )
                })}
            </div>
        </>
    )

    return (
        <div className={props.className} styleName="box" >
            <Button 
                    onClick={() => open(true)}
                    color={isOpen ? (props.activeColor ? props.activeColor : "primary" ) : props.color ? props.color : "outline-primary"}>
                {props.btnText} &#9662;
            </Button>
            {isOpen ? menu : null}
        </div> 
    )
}

export default withRouter(Dropdown)