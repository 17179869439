import React, {Fragment} from 'react';
import v3hash from '../../api/V3/hash';
import {createMessage} from '../Util/notification';
import {parseErrors} from '../Util/form';
import {Col, Form, Row} from 'reactstrap';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import Input from '../UI/Input/Input';
import {getSafeDeep} from '../Util/state';
import {changeStateFromInput} from '../Util/controlledComponent';

class AddBundleDialog extends React.Component {
  state = {
    errors: undefined,
    quantity: 0,
    isLoading: false
  };

  componentDidMount() {}

  componentDidUpdate() {}

  onSubmit = async (e) => {
    const quantity = this.state.quantity;
    e.preventDefault();
    try {
      this.setState({isLoading: true});
      await v3hash.generate(quantity);
      this.setState({isLoading: false});
      createMessage(`Successfully generated ${quantity} hashes.`);
      this.props.reloadData();
      this.props.toggle();
    } catch (e) {
      console.error(e);
      this.setState({errors: parseErrors(e)});
    }
  };

  onInput = (e) => {
    this.setState(changeStateFromInput(e));
  };

  render() {
    const {toggle, isOpen} = this.props;
    if (!isOpen) return '';

    return (
      <Fragment>
        <Form onSubmit={(e) => this.onSubmit(e)}>
          <Modal
            toggle={toggle}
            isLoading={this.state.isLoading}
            title="Add QR Bundle"
            cancelButton={() => (
              <Button color="secondary" className="mr-3 " onClick={toggle}>
                Back
              </Button>
            )}
            submitButton={() => (
              <Button
                color="success"
                onClick={(e) => this.onSubmit(e)}
                type="submit"
              >
                Generate
              </Button>
            )}
            isOpen={isOpen}
            size="sm"
          >
            <Row>
              <Col sm={3}>Quantity</Col>
              <Col sm={9}>
                <Input
                  type="number"
                  min="1"
                  max="100"
                  step="1"
                  name="quantity"
                  value={this.state.quantity}
                  onChange={(e) => this.onInput(e)}
                />
              </Col>
            </Row>
          </Modal>
        </Form>
      </Fragment>
    );
  }
}

export default AddBundleDialog;
