import React, {useState} from 'react';
import qs from 'qs';
import auth from '../../api/auth';
import logo from '../Header/elipay-logo.svg';
import CardContent from '@material-ui/core/CardContent';
import {Col, Form, FormGroup, Label, Row} from 'reactstrap';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import './style.module.css';
import {bindActionCreators} from 'redux';
import {replace} from 'connected-react-router';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {styled} from '@material-ui/core/styles';
import MatCard from '@material-ui/core/Card';
import {deepCopy, getSafeDeep} from '../Util/state';

const Card = styled(MatCard)({
  width: 400,
  padding: 32
});

const UserActivate = (props) => {
  const path = props.match.path;
  const token = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  }).token;
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState(undefined);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (path === '/activate/confirm') {
        await auth.activateUser(token, password, passwordConfirm);
      } else {
        await auth.setPassword(token, password, passwordConfirm);
      }
      props.replace('/login');
    } catch (e) {
      let validationError = getSafeDeep(
        deepCopy(e),
        'response.data.message',
        undefined
      );
      try {
        validationError = JSON.parse(validationError);
        validationError = getSafeDeep(validationError, '0.reason', undefined);
      } catch (e) {}
      setError(
        validationError
          ? validationError
          : getSafeDeep(deepCopy(e), 'response.data.message')
      );
    }
  };

  return (
    <div className="d-flex">
      <div styleName="left" className="d-flex">
        <div className="p-5">
          <img src={logo} width="120px" />
        </div>
        <div styleName="copyright" className="p-5">
          <a href>© 2020 Elipay</a>
        </div>
      </div>
      <div
        styleName="right"
        className="d-flex justify-content-center align-items-center"
      >
        <Card>
          <CardContent>
            <div className="text-center mb-5">
              {path === '/activate/confirm' ? (
                <h3>Register</h3>
              ) : (
                <h3>Set New Password</h3>
              )}
            </div>
            <Form onSubmit={(e) => onSubmit(e)}>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Type here"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password-confirm">Confirm password</Label>
                <Input
                  type="password"
                  name="password-confirm"
                  id="password-confirm"
                  placeholder="Type here"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
              </FormGroup>
              <Button class={'mt-2'} color="primary">
                Submit
              </Button>
            </Form>

            <p className="text-danger mt-4">{error}</p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

function mapState(state) {
  return {};
}

function mapActions(dispatch) {
  return bindActionCreators(
    {
      replace
    },
    dispatch
  );
}

export default withRouter(connect(mapState, mapActions)(UserActivate));
