export const getSafe = (obj, def = '') =>
  obj !== null && obj !== undefined ? obj : def;

export function getSafeDeep(object, path, undefinedValue = '') {
  let currentObject = object;
  const pathStrings = path.split('.');

  for (let pathString of pathStrings) {
    if (currentObject == null) return undefinedValue;
    currentObject = currentObject[pathString];
  }

  if (currentObject == null) return undefinedValue;
  return currentObject;
}

export function removeEmpty(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (obj[key] == undefined || obj[key] == null || obj[key] == '')
      delete obj[key];
  });
  return obj;
}

export function removeEmptyFields(obj) {
  return Object.keys(obj)
    .filter((k) => obj[k] != null && obj[k] !== '' && obj[k] !== undefined)
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object'
          ? {...newObj, [k]: removeEmptyFields(obj[k])} // Recurse.
          : {...newObj, [k]: obj[k]}, // Copy value.
      {}
    );
}

export function setSafeDeep(object, path, value) {
  let a = path.split('.');
  let o = object;
  for (let i = 0; i < a.length - 1; i++) {
    let n = a[i];
    if (n in o) {
      o = o[n];
    } else {
      o[n] = {};
      o = o[n];
    }
  }
  o[a[a.length - 1]] = value;
}

export function deepCopy(item) {
  const ret = JSON.parse(JSON.stringify(item));
  Object.keys(item).map((key) => {
    if (typeof item[key] === 'function') {
      ret[key] = item[key];
    }
  });
  return ret;
}

export function stripByRule(obj, rule) {
  Object.keys(obj).forEach(function (key) {
    if (rule(obj[key])) {
      delete obj[key];
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      stripByRule(obj[key], rule);
    }
  });
}
