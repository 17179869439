import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col, Row} from "reactstrap";
import Modal from '../UI/Modal/Modal'
import Select from '../UI/Select/Select'
import {createMessage} from "../Util/notification";
import DynamicForm from "../DynamicForm/DynamicForm";
import {deepCopy, getSafeDeep, stripByRule, stripUndefined} from "../Util/state";
import {objectToArray, parseErrors} from "../Util/form";
import editReferralData from "./schema/editReferralData";
import editReferralUi from "./schema/editReferralUI";
import referral from "../../api/V3/referral";

class EditReferralDialog extends React.Component {

    state = {
        errors: undefined,
        isLoading: false,
    };

    componentDidMount() {

        this.loadSchema();
    }

    componentDidUpdate() {
    }

    onSubmit = async (data) => {

        try {
            this.setState({ isLoading: true })
            await referral.edit(data.form, this.props.id);
            createMessage('Edit Successful');
            this.props.reloadData();
            this.props.toggle();
            this.setState({ isLoading: false })
        } catch (e) {
            console.error(e);
            this.setState({ isLoading: false, errors: parseErrors(e) });
        }
    };

    loadSchema = async () => {
        const uiSchema = editReferralUi;
        const dataSchema = editReferralData;
        this.setState({uiSchema, dataSchema});
    };


    render() {
        const {toggle, isOpen, currencies, selectedReferral} = this.props;
        const {errors, isLoading} = this.state;
        if (!isOpen) return "";

        const modal = {
            toggle,
            isOpen,
            errors,
            isLoading,
            title: "Edit Referral",
            size: "md",
        }

        return (
            <DynamicForm uiSchema={this.state.uiSchema}
                         dataSchema={this.state.dataSchema}
                         initData={{currencies, form: {...selectedReferral}}}
                         modal={modal}
                         isLoading={this.state.isLoading}
                         errors={errors}
                         onSubmit={(data) => {
                             this.onSubmit(data)
                         }}/>
        )
    };

}

function mapState(state) {
    return {
        currencies: state.sourceLang.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(EditReferralDialog));
