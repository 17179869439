import React, {Fragment, useState} from 'react';

import './styles.module.css';

import dataSchema from '../schema/beneficialOwnersData.json';
import uiSchema from '../schema/beneficialOwnersUi.json';

import Button from '../../UI/Button/Button';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {getCountryData} from '../../Util/countryList';
import companyKyb from '../../../api/companyKyb';
import {deepCopy, getSafeDeep} from '../../Util/state';
import {parseErrors} from '../../Util/form';
import {createMessage} from '../../Util/notification';
import * as _ from 'lodash';
import {
  infoField,
  infoFieldCategory,
  renderInfoField
} from '../../Util/infoForm';
import {Col, Row} from 'reactstrap';
import {CardContent, CardHeader, ListItemText} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import More from '../../UI/More/More';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {Delete, Edit} from '@material-ui/icons';
import DeleteOwnerDialog from './DeleteOwnerDialog';

const countries = getCountryData();

const infoFields = [
  infoFieldCategory('Personal Information', [
    infoField('first_name', 'First Name'),
    infoField('last_name', 'Last Name'),
    infoField('citizenship', 'Citizenship'),
    infoField('date_of_birth', 'Date of birth', 'date'),
    infoField('tax_number', 'Tax Number')
  ]),
  infoFieldCategory('Permanent address', [
    infoField('permanent_address.address1', 'Address'),
    infoField('permanent_address.city', 'City'),
    infoField('permanent_address.zipcode', 'Zip code'),
    infoField('permanent_address.country', 'Country', 'country')
  ]),
  infoFieldCategory(
    'Temporary address',
    [
      infoField('temporary_address.address1', 'Address'),
      infoField('temporary_address.city', 'City'),
      infoField('temporary_address.zipcode', 'Zip code'),
      infoField('temporary_address.country', 'Country', 'country')
    ],
    (data) => data.temporary_address
  ),
  infoFieldCategory('', [
    infoField(
      (data) => Math.round(data.ownership_percentage * 100) / 100,
      'Ownership Percentage of Authorized Representative (%)'
    )
  ])
];

const Owner = ({data, requiredProps, onEditClicked, onDeleteClicked, user}) => {
  const items = [];

  const percentage = parseFloat(
    data.ownership_percentage <= 1
      ? data.ownership_percentage * 100
      : data.ownership_percentage
  ).toFixed(2);

  if (true /*hasPermission(user, 'change_admin_company_profile_owner')*/) {
    items.push({
      handler: () => onEditClicked(data),
      render: () => (
        <Fragment>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText disableTypography primary="Edit" />
        </Fragment>
      )
    });
  }
  if (true /*hasPermission(user, 'delete_admin_company_profile_owner')*/) {
    items.push({
      handler: () => onDeleteClicked(data),
      render: () => (
        <Fragment>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText disableTypography primary="Delete" />
        </Fragment>
      )
    });
  }

  const checkIfNull = (fieldKey) => {
    return data[fieldKey] !== null;
  };

  return (
    <div className="mt-5 border">
      <CardHeader
        className="border-bottom"
        title={`${data.first_name} ${data.last_name} (${percentage}%)`}
        action={<More items={items} />}
      />
      <CardContent>
        {infoFields
          .filter((it) => it.visible(data))
          .map((category, i) => (
            <Row key={i}>
              <Col xs={12} className={i ? 'mt-4' : ''}>
                <Typography variant="h6">{category.label}</Typography>
              </Col>
              {category.items.map((item, j) => (
                <Fragment key={j}>
                  {checkIfNull(item.fieldKey) && (
                    <Col sm={6} className="my-2">
                      <div className="form-label text-muted">{item.label}</div>
                      {renderInfoField(item, data, requiredProps)}
                    </Col>
                  )}
                </Fragment>
              ))}
            </Row>
          ))}
      </CardContent>
    </div>
  );
};

const EditBeneficialOwner = ({owner, toggle, companyId, onDataChanged}) => {
  const [errors, setErrors] = useState({});
  const ownerApi = companyKyb.owners(companyId);
  const onSubmit = async (data) => {
    let newData = deepCopy(data.form);
    newData.temporary_address = _.omitBy(newData.temporary_address, _.isNil);
    newData.permanent_address = _.omitBy(newData.permanent_address, _.isNil);
    newData.ownership_percentage /= 100;
    const tempAddressBlank = Object.values(
      getSafeDeep(newData, 'temporary_address', {})
    ).reduce((allBlank, value) => {
      return allBlank && value === '';
    }, true);
    try {
      if (owner.id !== undefined) {
        if (tempAddressBlank) {
          newData.temporary_address = null;
        }
        const response = await ownerApi.update(owner.id, newData);
        response.data.ownership_percentage *= 100;
        onDataChanged(response.data);
      } else {
        if (tempAddressBlank) {
          delete newData.temporary_address;
        }
        newData = _.omitBy(newData, _.isNil);
        const response = await ownerApi.create(newData);
        response.data.ownership_percentage *= 100;
        onDataChanged(response.data);
      }
    } catch (e) {
      setErrors(parseErrors(e));
    }
  };

  return (
    <DynamicForm
      dataSchema={dataSchema}
      uiSchema={uiSchema}
      modal={{
        title: getSafeDeep(owner, 'id')
          ? 'Edit Beneficial Owner'
          : 'Create Beneficial Owner',
        isOpen: !!owner,
        size: 'md',
        toggle
      }}
      errors={errors}
      onSubmit={onSubmit}
      initData={{
        form: {
          ...owner
        },
        countries
      }}
    />
  );
};

const BeneficialOwners = ({data, dataUpdated, user}) => {
  const [owner, setOwner] = useState(null);
  const [deleteOwner, setDeleteOwner] = useState(null);

  const onDataChanged = (newData) => {
    let newKybData = deepCopy(data);
    if (owner.id !== undefined) {
      newKybData.kyb_data.beneficial_owners =
        newKybData.kyb_data.beneficial_owners.map((item) => {
          if (owner.id === item.id) {
            return {
              ...item,
              ...newData
            };
          }
          return item;
        });
      dataUpdated(newKybData);
    } else {
      newKybData.kyb_data.beneficial_owners.splice(0, 0, newData);
      dataUpdated(newKybData);
    }
    createMessage('Beneficial Owner Saved');
    setOwner(null);
  };

  const onDeleteClicked = async () => {
    try {
      await companyKyb.owners(data.id).delete(deleteOwner.id);
      const newKybData = deepCopy(data);
      const owners = newKybData.kyb_data.beneficial_owners;
      const ownerIndex = owners.findIndex((o) => o === deleteOwner.id);
      owners.splice(ownerIndex, 1);
      setDeleteOwner(null);
      dataUpdated(newKybData);
      createMessage('Owner deleted successfully');
    } catch (e) {
      createMessage('Failed to delete owner', 'error');
    }
  };
  if (
    owner &&
    owner.ownership_percentage &&
    typeof owner.ownership_percentage === 'number'
  )
    owner.ownership_percentage = owner.ownership_percentage.toFixed(2);
  return (
    <>
      <Button
        color="outline-primary"
        onClick={() => setOwner({permanent_address: {}})}
      >
        Add
      </Button>
      {data.kyb_data.beneficial_owners.map((owner) => (
        <Owner
          key={owner.id}
          data={owner}
          onEditClicked={setOwner}
          onDeleteClicked={setDeleteOwner}
          user={user}
        />
      ))}
      <EditBeneficialOwner
        toggle={() => setOwner(null)}
        owner={owner}
        user={user}
        companyId={data.id}
        onDataChanged={onDataChanged}
      />
      <DeleteOwnerDialog
        toggle={() => setDeleteOwner(null)}
        user={user}
        name={`${getSafeDeep(deleteOwner, 'first_name')} ${getSafeDeep(
          deleteOwner,
          'last_name'
        )}`}
        isOpen={deleteOwner != null}
        onDelete={onDeleteClicked}
      />
    </>
  );
};

export default BeneficialOwners;
