import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import Button from '../../../UI/Button/Button';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import Cashiers from './Others/Cashiers';

import './panel.module.css';

import {getCountryData} from '../../../Util/countryList';
import {filterCategories} from '../../../Source/util';
import CheckBox from '../../../UI/CheckBox/CheckBox';
import {deepCopy, getSafeDeep} from '../../../Util/state';
import {hasPermission} from '../../../Util/role-helpers';
import Modal from '../../../UI/Modal/Modal';
import tenantsAPI from '../../../../api/V3/connectTenants';

const countries = getCountryData();

const Panel = (props) => {
  /*Props*/
  const {active, isLoading} = props;

  /*Local state*/
  const [sendInstructions, setSendInstructions] = useState(false);
  const [merchantKitSent, setMerchantKitSent] = useState(false);
  const [allowTenantConnectSubmit, setAllowTenantConnectSubmit] =
    useState(false);
  const [list, setList] = useState([]);
  const [modalNoOk, setModalNoOk] = useState(false);
  const [modalSure, setModalSure] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const [modalInfoTenants, setModalInfoTenants] = useState('');

  /*Methods*/
  const checkHandler = ({target}) => {
    const {checked, id} = target;
    setList((prev) => {
      const clickedItem = prev.find((item) => item.id.toString() === id);
      clickedItem['active'] = checked;
      return [...prev];
    });
  };

  const getTenants = async (newData) => {
    const response = await tenantsAPI.getTenants();
    const output = response.data.map((tenant) => {
      let activeInd = false;

      if (newData.length) {
        newData.map((activeTenant) => {
          if (activeTenant.id === tenant.id) {
            activeInd = activeTenant.included;
          }
        });
      }

      return {
        ...tenant,
        active: false,
        locked: activeInd
      };
    });

    setList(output);
  };

  const getPos = async (id) => {
    setIsProcessing(true);
    const response = await tenantsAPI.getTenant(id);
    const output = response.data.map((tenant) => {
      setIsProcessing(false);
      return {
        ...tenant,
        active: false
      };
    });
    await getTenants(output);
  };

  const makeAllowCheck = () => {
    const validated =
      props.pos.full_name &&
      props.pos.store_account_contact &&
      props.pos.address &&
      props.pos.postal_code &&
      props.pos.city &&
      props.pos.country &&
      props.pos.integrator_id != null &&
      props.pos.pos_type != null;
    if (validated) {
      setAllowTenantConnectSubmit(true);
    }
  };

  const closeModalHandler = () => {
    setModalSure(false);
  };

  const closeModalNoOk = () => {
    setModalNoOk(false);
  };

  const allowConnectionFuse = (e) => {
    e.preventDefault();
    makeAllowCheck();

    if (allowTenantConnectSubmit) {
      setModalSure(true);
      return;
    }

    setModalNoOk(true);
  };

  const connectTenant = async (tenantId) => {
    try {
      await tenantsAPI.connect(props.pos.id, tenantId);
    } catch (error) {
      console.error('Error connecting tenants: ', error.message);
    }
  };

  const submitConnectTenants = async () => {
    setIsProcessing(true);

    let activeTenants = list.filter((tenant) => tenant.active);

    await Promise.all(activeTenants.map((tenant) => connectTenant(tenant.id)));

    await getPos(props.pos.id);
    closeModalHandler();
  };

  const modalInfoTenantsHandler = () => {
    let output = list
      .filter((tenant) => tenant.active === true)
      .map((tenant) => tenant.display_name + ' (' + tenant.name + ')')
      .join(', ')
      .toString();

    setModalInfoTenants(output);
  };

  /*Hooks*/
  useEffect(() => {
    if (props.pos.id) {
      makeAllowCheck();
      getPos(props.pos.id).catch((error) =>
        console.error('Error fetching pos: ', error.message)
      );
    }
    modalInfoTenantsHandler();
  }, [props.pos]);

  useEffect(() => {
    modalInfoTenantsHandler();
  }, [list]);

  useEffect(() => {
    setSendInstructions(props.pos.send_email_instructions_on_approve);
    setMerchantKitSent(props.pos.merchant_kit_sent);
  }, [
    props.pos.send_email_instructions_on_approve,
    props.pos.merchant_kit_sent,
    props.pos.id
  ]);

  /*Fragments*/
  const getCashierList = () => {
    let cashierList = null;

    if (active.name === 'Tenants') {
      cashierList = (
        <Fragment>
          <Modal
            toggle={closeModalNoOk}
            isOpen={modalNoOk}
            size="sm"
            title="Some of the POS Basic Information is missing data"
            footer={() => (
              <div className="d-flex justify-content-end">
                <Button color="primary" onClick={closeModalNoOk}>
                  Ok
                </Button>
              </div>
            )}
          >
            <div>
              Please enter all of the required data.
              <ul className={'check_ul'}>
                <li className={`check_li ${props.pos.full_name ? 'ok' : ''}`}>
                  full_name
                </li>
                <li
                  className={`check_li ${
                    props.pos.store_account_contact ? 'ok' : ''
                  }`}
                >
                  store_account_contact
                </li>
                <li className={`check_li ${props.pos.address ? 'ok' : ''}`}>
                  address
                </li>
                <li className={`check_li ${props.pos.postal_code ? 'ok' : ''}`}>
                  postal_code
                </li>
                <li className={`check_li ${props.pos.city ? 'ok' : ''}`}>
                  city
                </li>
                <li
                  className={`check_li ${
                    props.pos.store_account_contact ? 'ok' : ''
                  }`}
                >
                  store_account_contact
                </li>
                <li className={`check_li ${props.pos.country ? 'ok' : ''}`}>
                  country
                </li>
                <li
                  className={`check_li ${props.pos.integrator_id ? 'ok' : ''}`}
                >
                  integrator_id
                </li>
                <li className={`check_li ${props.pos.pos_type ? 'ok' : ''}`}>
                  pos_type
                </li>
              </ul>
            </div>
          </Modal>
          <Modal
            toggle={closeModalHandler}
            isOpen={modalSure}
            size="sm"
            title="ARE YOU SURE?"
            footer={() => (
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  className="mr-3"
                  onClick={closeModalHandler}
                >
                  Cancel
                </Button>
                <Button color="danger" onClick={submitConnectTenants}>
                  I understand
                </Button>
              </div>
            )}
          >
            <div>
              You are about to create a record of{' '}
              <strong>{props.pos.full_name}</strong> in the tenants{' '}
              <strong>{modalInfoTenants}</strong> - do you want to proceed?
            </div>
          </Modal>
          <div className="connectTenants mb-4">
            {isLoading && (
              <div className={`isProcessing ${isProcessing ? 'active' : ''}`}>
                Loading...
              </div>
            )}
            {!isLoading && (
              <Fragment>
                {list.map((tenant, tenantId) => (
                  <div
                    className={`tenants ${
                      tenant.active || tenant.locked ? 'active' : ''
                    } ${tenant.locked ? 'locked' : ''}`}
                    key={tenantId}
                  >
                    <input
                      type="checkbox"
                      id={tenant.id}
                      onChange={checkHandler}
                      checked={tenant.active}
                    />
                    <label htmlFor={tenant.id}>
                      {tenant.display_name}
                      <small>({tenant.name})</small>
                      {tenant.locked && (
                        <span className="tag flaticon-lock-1"></span>
                      )}
                    </label>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        </Fragment>
      );
    }

    if (active.name === 'Cashiers') {
      cashierList = (
        <Fragment>
          <Cashiers active={active} pos={props.pos} user={props.user} />
          <div className="row">
            <div className="col-6">
              <div>
                <CheckBox
                  disabled={!hasPermission(props.user, 'change_source_v3')}
                  checked={sendInstructions}
                  onClick={(e) => {
                    e.preventDefault();
                    setSendInstructions(!sendInstructions);
                  }}
                  label="Send Login information documents to sales team mail upon successfully approved Point of Sale"
                />
              </div>
              {props.pos.source_type === 0 && (
                <div>
                  <CheckBox
                    disabled={
                      !hasPermission(props.user, 'change_source_v3') ||
                      props.pos.approval_state !== 1
                    }
                    checked={merchantKitSent}
                    onClick={(e) => {
                      e.preventDefault();
                      if (props.pos.approval_state !== 1) {
                        setMerchantKitSent(false);
                      } else {
                        setMerchantKitSent(!merchantKitSent);
                      }
                    }}
                    label="Merchant kit sent"
                  />
                </div>
              )}
            </div>
          </div>
        </Fragment>
      );
    }
    return cashierList;
  };

  /*Template*/
  if (!active || !active.schema) return <Fragment></Fragment>;

  return (
    <Fragment>
      <div styleName="panel">
        <DynamicForm
          errors={props.errors}
          readOnly={!hasPermission(props.user, 'change_source_v3')}
          wrappers={(dynamicForm) => (
            <Fragment>
              <div styleName="body">
                <h3 styleName="header" className="pb-5 pt-3">
                  {active.name}
                </h3>
                {active.name !== 'Tenants' && dynamicForm && (
                  <div>{dynamicForm}</div>
                )}
                {getCashierList()}
              </div>
              {active.schema &&
                hasPermission(props.user, 'change_source_v3') && (
                  <div styleName="footer" className="d-flex">
                    {active.name !== 'Tenants' && (
                      <Button
                        color="primary"
                        className="mr-2 ml-auto"
                        type="submit"
                        isLoading={isLoading}
                      >
                        Save
                      </Button>
                    )}
                    {props.activeTab < props.tabsCount - 1 && (
                      <Button
                        color="primary"
                        type="submit"
                        data-name="next"
                        isLoading={isLoading}
                      >
                        Save & Next
                      </Button>
                    )}
                    {active.name === 'Tenants' && (
                      <Button
                        color="primary"
                        className="mr-2 ml-auto"
                        onClick={allowConnectionFuse}
                        isLoading={isLoading}
                      >
                        Connect
                      </Button>
                    )}
                  </div>
                )}
            </Fragment>
          )}
          clearOnChange
          initData={{
            toggleDialog: props.toggleDialog,
            systemTags: filterCategories(props.categories, true),
            brands: props.brands,
            categories: filterCategories(props.categories, false),
            mainCategories: props.categories.filter(
              (it) => !it.system_setting && it.name !== 'online' && it.visible
            ),
            countries,
            exchangeFees: props.exchangeFees,
            transactionFees: props.transactionFees,
            posProviders: props.posProviders.filter((p) =>
              p.country == null
                ? p
                : p.country == getSafeDeep(props, 'pos.country')
                ? p
                : false
            ),
            platforms: props.platforms,
            posTypes: props.posTypes,
            hwPosOptions: props.hwPosOptions,
            hwPosCryptoBuy: props.hwPosOptions.filter(
              ({hw_pos_can_buy}) => hw_pos_can_buy
            ),
            hwPosCryptoSell: props.hwPosOptions.filter(
              ({hw_pos_can_buy}) => hw_pos_can_buy
            ),
            booleanTypes: [
              {value: true, label: 'Yes'},
              {value: false, label: 'No'}
            ],
            languages: props.sourceLang,
            currencies: props.currencies,
            ...active.schema.initData
          }}
          uiSchema={active.schema.ui}
          onSubmit={(data, caller) => {
            if (active.name === 'Cashiers') {
              data = deepCopy(data);
              data.form.send_email_instructions_on_approve = sendInstructions;
              data.form.merchant_kit_sent = merchantKitSent;
            }
            props.update(data, caller);
          }}
          dataSchema={active.schema.data}
        />
      </div>
    </Fragment>
  );
};

const mapState = (state) => {
  return {
    exchangeFees: state.exchangeFees.data,
    transactionFees: state.transactionFees.data,
    categories: state.categories.data,
    brands: state.brands.data,
    posProviders: state.posProviders.data,
    platforms: state.platforms.data,
    posTypes: state.posTypes.data,
    hwPosOptions: state.hwPosOptions.data,
    currencies: (state.currencies.data || []).filter(
      (c) => !c.crypto && c.name !== 'Eli VIP'
    ),
    sourceLang: state.sourceLang.data,
    user: state.user.data
  };
};

export default connect(mapState)(Panel);
