import React from 'react'
import {getDataProp} from "./util";
import {formContextWrapper} from "../FormContext";

class ReadOnly extends React.Component{
    render() {
        return (
            <div className="p-2">
                <strong>{this.props.label}: </strong> {getDataProp(this.props.context.data, this.props.scope.$ref)}
            </div>
        );
    }
}

export default formContextWrapper(ReadOnly)
