import qs from "query-string";


export const pushSortToQuery = ({query, history, sort, location}) => {
    const newQuery = {...query};
    newQuery.page = 0;
    newQuery.sort = sort;
    history.push(`${location.pathname}?${qs.stringify(newQuery)}`);
};

export const calculateRowsPerPage = ({newRowsPerPage, location, rowsPerPage}) => {
    const currentPage = qs.parse(location.search, {ignoreQueryPrefix: true}).page;
    let currentCount = (currentPage * rowsPerPage) || 0;
    currentCount++;
    return Math.floor(currentCount / newRowsPerPage);
};

export const pushRowsPerPageToQuery = ({query, history, location, page}) => {
    const newQuery = {...query};
    newQuery.page = page;
    history.push(`${location.pathname}?${qs.stringify(newQuery)}`);
};


