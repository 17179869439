import React from 'react'
import ReactSelect, {Async} from "react-select";
import {computeIsRequired, getDataProp, getInputConfig, getInputData, getInputError, getProp} from "./util";
import * as classnames from "classnames";
import {formContextWrapper} from "../FormContext";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import type {FormContextProps} from "../FormContext";
import type {ArrayContextProps} from "../ArrayContext";
import {arrayContextWrapper} from "../ArrayContext";
import hash from "../../../api/hash";
type SelectProps = {
    label: string,
    outterhtmlClass: string,
    allowedClear: boolean,
    arrayOptions: {
        displayFieldName: string,
        idFieldName: string,
        loadItemsOnOpen: boolean,
        $ref: string
    },
    scope: {
        $ref: string
    },
    context: FormContextProps,
    array?: ArrayContextProps
}

class MultiSelect extends React.Component<SelectProps> {
    state = {
        required: false,
        $ref: ""
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scope.$ref !== prevState.$ref) {
            return {
                required: computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref),
                $ref: nextProps.scope.$ref
            }
        }
        return prevState;
    }

    getValue = () => {
        const {context, array, scope: {$ref}, arrayOptions} = this.props;
        const config = getInputConfig(context, array, $ref);
        const dataValue = getInputData(context, array, $ref);
        if(dataValue && dataValue.length == 0) return null;
        //return dataValue;
        if(dataValue && !dataValue.value){
            return {
                value: dataValue,
                label: dataValue
            }
        }
        return dataValue;
    };

    getOptions = () => {
        const {context, array, scope: {$ref}, arrayOptions, allowedClear, diffRef} = this.props;
        const config = getInputConfig(context, array, $ref);
        if (config.enum) {
            return config.enum.map(o => ({value: o, label: o}))
        } else {
            let  dataItems = getDataProp(context.data, arrayOptions.$ref) || [];
            if (allowedClear) {
                dataItems.splice(0, {[arrayOptions.idFieldName]: null, [arrayOptions.displayFieldName]: ""});
            }

            if(diffRef){
                let diffedDataItems = [];
                let forbiddenItems = getInputData(context, array, diffRef);
                diffedDataItems = dataItems.filter( item => {
                    if( forbiddenItems.find(subItem => item[arrayOptions.idFieldName] == subItem[arrayOptions.idFieldName]) === undefined){
                        return item;
                    }
                });
                dataItems = diffedDataItems;
            }
            return dataItems.map(item => ({value: item[arrayOptions.idFieldName], label: item[arrayOptions.displayFieldName]}))
        }
    };

    loadOptions = inputValue => new Promise(resolve => {
        setTimeout(() => {

            let suggested = new Promise(resolve => {
                resolve(hash.suggest(inputValue));
            });

            suggested.then((response) => {
                resolve(response.data.map(item => {
                    //return item;
                    return {
                        value: item,
                        label: item
                    }
                }))
            });


        }, 1000);
    });

    shouldComponentUpdate(nextProps, nextState) {
        const context = this.props.context;
        const nextContext = nextProps.context;
        const $ref = this.props.scope.$ref;
        const next$ref = nextProps.scope.$ref;
        const array = this.props.array;
        const nextArray = nextProps.array;
        try{
            return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) ||
                getDataProp(context.data, this.props.arrayOptions.$ref) !== getDataProp(nextContext.data, nextProps.arrayOptions.$ref) ||
                getInputError(context, array, $ref) !== getInputError(nextContext, nextArray, next$ref) ||
                $ref !== next$ref
        } catch (e) {
            return true
        }
    }




    render() {
        const {outterhtmlClass, scope: {$ref}, context, label, arrayOptions, array} = this.props;
        const config = getProp(context.dataSchema, $ref);
        const error = getInputError(context, array, $ref);

        return (
            <FormGroup className={outterhtmlClass}>
                <div className="row">

                    <Label className="mb-0 d-flex align-items-center col-3" htmlFor={$ref}>{label}{this.state.required && <sup className="text-danger">*</sup>}</Label>
                    <Async value={this.getValue()}
                           loadOptions={this.loadOptions}
                           //getOptionLabel={(item) => item}
                           //getOptionValue={(item) => item}

                         className={classnames(outterhtmlClass, "col-9")}
                         name={$ref}

                         onChange={e => context.onInputChange($ref, e.value)}/>
                    <FormFeedback valid={!error} className={classnames({"d-block": !!error},"offset-3 col-9")}>{error}</FormFeedback>
                </div>
            </FormGroup>

        );
    }
}

export default arrayContextWrapper(formContextWrapper(MultiSelect))
