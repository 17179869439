import React from 'react';
import {Button, Container} from 'reactstrap';
import './style.module.css';
import logo from './logo_default_dark.svg';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import userIcon from './user_eligma.svg';
import {clearUser} from '../../redux/user';
import auth from '../../api/auth';
import {replace} from 'connected-react-router';
import cx from 'classnames';

import Dropdown from './Dropdown/Dropdown.js';
import {clearAllCookies, deleteCookie} from '../../utils/helperFunctions';

class Header extends React.Component {
  state = {
    dropdown: false
  };

  logout = async () => {
    await auth.logout();
    await this.props.clearUser();
    clearAllCookies();
    this.props.replace('/login');
  };

  toggleDropdown = () => {
    this.setState((prev) => ({
      dropdown: !prev.dropdown
    }));
  };

  toggleSideBar = () => {
    this.props.setNav({
      upperNav: !this.props.upperNav,
      lowerNav: !this.props.upperNav
    });
  };

  render() {
    const user = this.props.user.data;

    let headerStart = (
      <div onClick={this.toggleSideBar} styleName="header__start--btn">
        <span></span>
      </div>
    );

    if (this.props.upperNav) {
      headerStart = (
        <div className="d-flex align-items-end w-100 px-4 py-4">
          <img src={logo} alt="logo" styleName="logo" />
          {/* <sub className="pt-2 d-block">Elipay Admin v{VERSION}</sub> */}
          <div onClick={this.toggleSideBar} styleName="header__start--btn">
            <span></span>
          </div>
        </div>
      );
    }

    return (
      <div styleName="header" className="d-flex">
        <div
          styleName={cx('header__start--box', {
            'header__box--opened': this.props.upperNav
          })}
          className="d-flex align-items-center justify-content-center"
        >
          {headerStart}
        </div>
        {user && (
          <div
            onClick={this.toggleDropdown}
            styleName="actions"
            className="d-flex align-items-center ml-auto flex-wrap"
          >
            <img
              src={userIcon}
              styleName="user-image"
              className="rounded-circle"
            />
          </div>
        )}
        {user && this.state.dropdown && (
          <Dropdown
            user={user}
            logout={this.logout}
            removeDropdown={this.toggleDropdown}
          />
        )}
      </div>
    );
  }
}

function mapState(state) {
  return {
    user: state.user
  };
}

function mapActions(dispatch) {
  return bindActionCreators({clearUser, replace}, dispatch);
}

export default connect(mapState, mapActions)(Header);
