export const sourceTypes=[
    {
        "label": "Offline",
        "value": 0
    },
    {
        "label": "Online",
        "value": 1
    }
];

export const booleanTypes=[
    {
        "label": "Yes",
        "value": true
    },
    {
        "label": "No",
        "value": false
    }
];

export const emptyWorkingHoursArrays = [
    [],[],[],[],[],[],[]
];

export function getTagList(source){
    let tags = [];
    if(source.main_tag) tags.push(source.main_tag);
    const sourceTags = source.tags ? source.tags.map(tag => tag.name) : [];
    tags = [...tags, ...sourceTags];
    if(source.accepts_goc) tags.push("accepts_goc");
    if(source.viberate_tonight) tags.push("viberate_tonight");
    if(source.croatia) tags.push("croatia");
    if (!source.accepts_eurt) tags.push("disable_eurt")
    return tags;
}


export function filterCategories(categoryList, system_setting){
    return categoryList.filter(item => item.system_setting == system_setting && item.visible);
}
