import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Modal from '../UI/Modal/Modal'
import Button from '../UI/Button/Button'
import {createMessage} from "../Util/notification";
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import {ERR_VALIDATION} from "../../api/errorCodes";
import {getSafeDeep} from "../Util/state";
import {mapBackendErrors} from "../DynamicForm/controls/util";
import {parseErrors} from "../Util/form";
import InfoForm from "../InfoForm/InfoForm";
import {getDocumentType, getPermanentAddressCountry, infoField, infoFieldCategory} from "../Util/infoForm";
import KYCCommentDialog from "./KYCCommentDialog";
import KYCDeclineDialog from "./KYCDeclineDialog";
import kyc from "../../api/kyc";
import {PermissionGuard} from "../Util/role-helpers";

const kycInfoFields = [
    infoFieldCategory("Personal Information", [
        infoField("profile.first_name", "First name"),
        infoField("profile.last_name", "Last name"),
        infoField("profile.phone_number", "Phone number"),
        infoField("email", "E-mail"),
        infoField("profile.birth_date", "Date of birth", "date"),
        infoField("profile.identity_number", "Identity number"),

        infoField("profile.citizenship", "Citizenship"),
        infoField("language", "Language","language"),
    ]),
    infoFieldCategory("Address Information", [
        infoField("profile.permanent_address.address1", "Address"),
        infoField("profile.permanent_address.address2", "Address 2"),
        infoField("profile.permanent_address.address3", "Address 3"),
        infoField("profile.permanent_address.city", "City"),
        infoField("profile.permanent_address.zipcode", "Zip code"),
        infoField("profile.permanent_address.region", "Region"),
        infoField("profile.permanent_address.country", "Country", "country"),
    ]),
    infoFieldCategory("Temporary address", [
        infoField("profile.temporary_address.address1", "Address"),
        infoField("profile.temporary_address.address2", "Address 2"),
        infoField("profile.temporary_address.address3", "Address 3"),
        infoField("profile.temporary_address.city", "City"),
        infoField("profile.temporary_address.zipcode", "Zip code"),
        infoField("profile.temporary_address.region", "Region"),
        infoField("profile.temporary_address.country", "Country", "country"),
    ]),
    infoFieldCategory("Documents", [
        infoField(getDocumentType, "Document type"),
        infoField("", ""),
        infoField("profile.document.0.serial_number", "Serial number"),
        infoField("profile.document.0.administrative_unit", "Administrative unit"),
        infoField("profile.document.0.date_of_issue", "Date of issue", "date"),
        infoField("profile.document.0.date_of_expiry", "Date of expiry", "date"),
        infoField("profile.document.0.document_front_image", "Front image", "kycImage"),
        infoField("", ""),
        infoField("profile.document.0.document_back_image", "Back image", "kycImage"),
        infoField("", ""),
        infoField("profile.photo", "Photo", "kycImage"),
        infoField("", ""),

    ])
];


class KYCActivateDialog extends React.Component {

    state = {
        errors: undefined,
        commentModalOpen: false,
        declineModalOpen: false,
        isLoading: false
    };

    componentDidMount() {

    }

    componentDidUpdate() {
    }

    toggleModal = (key) => {
        const fullKey = `${key}ModalOpen`;
        this.setState({ [fullKey]: !this.state[fullKey] });
    };

    toggleAndReload = () => {
        this.props.reloadData();
        this.props.toggle();
    };

    onApprove = async () => {
        this.setState({ isLoading: true});
        try{
            await kyc.approve(this.props.data.id);
            createMessage('Successfully approved KYC request');
            this.setState({ isLoading: false});
            this.toggleAndReload();
        }catch (e) {
            this.setState({ errors: parseErrors(e), isLoading: false });
        }
    };

    render() {
        const {toggle, isOpen, tiers, data} = this.props;
        if(!isOpen) return "";
        return <Fragment>
            <Modal  toggle={toggle}
                    isOpen={!!isOpen}
                    isLoading={this.state.isLoading}
                    size="lg"
                    title="KYC Request"
                    footer={() => (
                            <div className="d-flex justify-content-center">
                                <Button isLoading={this.state.isLoading} color="secondary" className="mr-2" onClick={toggle}>Cancel</Button>
                                <PermissionGuard code="approve_kyc">
                                    <Button isLoading={this.state.isLoading} color="success" className="mr-2" onClick={() => this.onApprove()}>Approve</Button>
                                </PermissionGuard>
                                <PermissionGuard code="send_comment_kyc">
                                    <Button isLoading={this.state.isLoading} color="primary" className="mr-2" onClick={() => this.toggleModal("comment")}>Comment</Button>
                                </PermissionGuard>
                                <PermissionGuard code="decline_kyc">
                                    <Button isLoading={this.state.isLoading} color="danger" onClick={() => this.toggleModal("decline")}>Decline</Button>
                                </PermissionGuard>
                            </div>
                        )}
                >
                <InfoForm
                    isLoading={false}
                    additionalComponents={[]}
                    fields={kycInfoFields}
                    data={data}
                    requiredProps={{documentTypes: this.props.documentTypes}}
                />
            </Modal>
            <KYCCommentDialog
                toggle={() => this.toggleModal("comment")}
                isOpen={this.state.commentModalOpen}
                toggleParent={() => this.toggleAndReload()}
                data={data}
            />
            <KYCDeclineDialog
                toggle={() => this.toggleModal("decline")}
                isOpen={this.state.declineModalOpen}
                toggleParent={() => this.toggleAndReload()}
                data={data}
            />
        </Fragment>
    };

}

function mapState(state) {
    return {
        tiers: state.tiers.data,
        documentTypes: state.documentTypes.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(KYCActivateDialog));
