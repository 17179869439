import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {createMessage} from "../../../Util/notification";
import DynamicForm from "../../../DynamicForm/DynamicForm";
import {parseErrors} from "../../../Util/form";
import editNoteDataSchema from "./schema/editNoteData.json";
import editNoteUiSchema from "./schema/editNoteUi.json";
import exchange from "../../../../api/V3/exchange";


class EditNoteDialog extends React.Component {

    state = {
        errors: undefined,
        isLoading: false
    };

    componentDidMount() {
        this.loadSchema();
    }

    componentDidUpdate() {
    }

    onSubmit = async (data) => {
        try{
            this.setState({ isLoading: true })

            if(this.props.ledger){
                await exchange.editLedgerNote(this.props.referenceId, data.note)
            }else{
                await exchange.editNote(this.props.trade_id, data.note)
            }

            createMessage('Successfully edited note');
            this.setState({ isLoading: false })
            this.props.toggle();
            this.props.reloadData();
        }catch (e) {
            this.setState({ isLoading: false, errors: parseErrors(e)});
        }
    };

    loadSchema = async() => {
        const uiSchema = editNoteUiSchema;
        const dataSchema = editNoteDataSchema;
        this.setState({ uiSchema, dataSchema });
    };

    render() {
        const {toggle, isOpen, } = this.props;
        const {errors, isLoading} = this.state;
        if(!isOpen) return "";



        const modal = {
            toggle,
            isOpen,
            isLoading,
            title: "Edit Note",
            errors,
            size: "md"
        }

        return(
            <DynamicForm    uiSchema={this.state.uiSchema}
                            dataSchema={this.state.dataSchema}
                            modal={modal}
                            initData={{ form: { ...this.props.note }}}
                            errors={errors}
                            onSubmit={(data) => this.onSubmit(data.form)}/>
        )
    };
}


export default withRouter((EditNoteDialog));
