import WithLoading from "../Util/HOC/WithLoading";
import ListBody from "../Util/ListView/ListBody/ListBody";
import React, {Fragment} from "react";
import {getSafeDeep} from "../Util/state";
import qs from "query-string";
import {listHeader, renderCreatedAt, renderFullDate} from "../Util/listView";
import {Card} from "@material-ui/core";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "./MerchantDetailsModal.module.css";
import Modal from '../UI/Modal/Modal';
import Select from '../UI/Select/Select';
import billing from "../../api/V3/billing";
import { notifyError } from "../Util/notification";


const ListBodyWithLoading = WithLoading(ListBody);




class MerchantDetailsModal extends React.Component {
    state = {
        data: [],
        search: '',
        sort: '',
        page: 0,
        count: 0,
        rowsPerPage: 10,
        isLoading: false,
        BillingOverviewOpen: false,
        selectedStore: "",
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.id && (prevProps.id !== this.props.id))
        || this.state.rowsPerPage != prevState.rowsPerPage
        || this.state.page != prevState.page)
            this.loadData()
    }

    loadData = async () => {
        try{
            this.setState({isLoading: true});

            const {start_date, end_date} = this.props;
            const startDate = start_date.format('YYYY-MM-DD');
            const endDate = end_date.format('YYYY-MM-DD');

            const { data: details } = await billing.getDetails(this.props.id, startDate, endDate);

            this.onStoreChange(details[0])
            this.setState({
                details,
                isLoading: false,
            });

        }catch(err){
            notifyError(err.message)
            this.setState({ isLoading: false })
        }
    };



    overviewHeaders = [
        listHeader('Created', "created", true, row => renderFullDate(row.created)),
        listHeader('Amount', "amount", true, undefined, undefined, "undefined", "right" ),
        listHeader('Currency', "currency", false, undefined, undefined, "undefined", "left" ),
        listHeader('Crypto Amount', "crypto_amount", true, undefined, undefined, "undefined", "right" ),
        listHeader('Crypto Currency', "crypto_currency", false, undefined, undefined, "undefined", "left" ),
        listHeader('Conversion Rate', "conversion_rate", false, undefined, undefined, "undefined", "center" ),
        listHeader('Transaction Fee', "transaction_fee", false, undefined, undefined, "undefined", "center" ),
        listHeader('Exchange Fee', "provision_fee", false, undefined, undefined, "undefined", "right" ),
    ];


    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value
        let currentItem = this.state.page * this.state.rowsPerPage || 0 + 1
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage, page: newPage }, () => {
            this.loadTransactions()
        })
    }

    loadTransactions = async () => {
        try{
            this.setState({ isLoading: true })
            let { search, page, rowsPerPage, sort, selectedStore } = this.state
            if(page > 0 && !this.state.data.length){//page more than 0 and no data
                page = 0;
            }
            const {start_date, end_date} = this.props;
            const startDate = start_date.format('YYYY-MM-DD');
            const endDate = end_date.format('YYYY-MM-DD');
            const {data: {count, results: data}} = await billing.getDetailsTransactions(this.state.selectedStore.id, startDate, endDate, search, page * rowsPerPage, rowsPerPage, sort )

            this.setState({
                data,
                count,
                isLoading: false
            })
        }catch(err){
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    }


    onStoreChange = (store) => {
        this.setState({selectedStore: store}, () => {
            this.loadTransactions()
        })
    };

    requestSort = (sort) => {
        this.setState({ sort, page: 0 }, () => {
            this.loadTransactions()
        })
    }

    changePage = (e, page) => {
        this.setState({ page })
    }

    requestSearch = (e) => {
        e.preventDefault();
        this.loadTransactions()
    }

    onInputChange = e => {
        this.setState({search: e.target.value}, () => {
            clearTimeout(this.debounceTimer)
            if(this.state.search == "") {
                this.loadTransactions();
            } else {
                this.debounceTimer = setTimeout(() => {
                    this.loadTransactions()
                }, 200)
            }
        });
    }

    render() {

        const {data, sort, page, isLoading, rowsPerPage,details, selectedStore} = this.state;
        const sumData = {amount: 1, tFee: 1, pFee: 2, total: 1};

        return (<Modal isLoading={isLoading} isOpen={this.props.isOpen} toggle={() => {
            this.setState({ selectedStore: ""}, () => {
                this.props.toggle();
            });

        }} submitButton={() => null} size="xl" title="Merchant Details" >

            <div>
                Store
                <Select options={details}
                        getOptionLabel={item => item.full_name}
                        getOptionValue={item => item.id}
                        value={this.state.selectedStore}
                        onChange={(e) => this.onStoreChange(e)}>
                </Select>
            </div>
            <div styleName="store-details">
                <div styleName="title">Store Details</div>
                <div styleName="content">
                    <img styleName="image" src={getSafeDeep(selectedStore, "logo_url")} />
                    <div styleName="info">
                        <div styleName="title">GoCrypto</div>
                        <div styleName="row">
                            <div styleName="column">

                                <div styleName="column-title">Address</div>
                                <div>{getSafeDeep(selectedStore, "address")}</div>
                                <div>{getSafeDeep(selectedStore,"city")}</div>
                                <div>{getSafeDeep(selectedStore, "postal_code")}</div>


                            </div>
                            <div styleName="column">
                                <div styleName="title"></div>
                                <div styleName="column-title">Bank Info</div>
                                <div>vat:{getSafeDeep(selectedStore,"vat")}</div>
                                <div>trr:{getSafeDeep(selectedStore,"TRR")}</div>
                                <div>bank:{getSafeDeep(selectedStore,"bank_name")}</div>
                                <div>swift:{getSafeDeep(selectedStore,"swift")}</div>
                            </div>
                            <div styleName="column">
                                <div styleName="title"></div>
                                <div styleName="column-title">Contact</div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div styleName="table-title">Store Transactions</div>
            <Card>
                <ListHeader
                    type="BillingOverview"
                    title="Store Transactions"
                    onChange={this.onInputChange}
                    value={this.state.search}
                    onSubmit={this.requestSearch}
                    // toggle={(dialog) => this.toggleDialog(dialog)}
                />
                <ListBodyWithLoading
                    sortField={sort}
                    requestSort={this.requestSort}
                    headers={this.overviewHeaders}
                    isLoading={isLoading}
                    data={data}
                    count={this.state.count}
                    currentPage={page}
                    onChangePage={this.changePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
            </Card>
        </Modal>)
    };

}


function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(MerchantDetailsModal));
