import React from 'react'

import './link.module.css'

const Link = props => {
    return (
        <a  styleName={"link"}
            className={props.className ? props.className : ''} 
            onClick={props.onClick ? props.onClick : () => {}}>
            {props.children}
        </a>
    )
}

export default Link