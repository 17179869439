import {deepCopy, getSafeDeep} from "../../components/Util/state";

export function transformOrganizationData(data){
    data.data.sources = data.data.sources.map(item => {
        const source = deepCopy(item);


        // Transform accountant object
        source.accountant_id = getSafeDeep(source, "accountant.id");
        source.accountant_email = getSafeDeep(source, "accountant.email");
        source.accountant_hash = getSafeDeep(source, "accountant.hash");
        source.accountant_first_name = getSafeDeep(source, "accountant.first_name");
        source.currency = getSafeDeep(source, "config.currency.id");
        source.language = getSafeDeep(source, "config.language")
        delete source.accountant;

        // Transform tags into fields
        const tagList = [...source.tags];


        const systemSettings = ['accepts_goc', 'viberate_tonight', 'croatia'];
        systemSettings.forEach(setting => {
            source[setting] = false;
            const systemSettingIndex = tagList.findIndex(tag => tag.name == setting);
            if (systemSettingIndex != -1){
                tagList.splice(systemSettingIndex, 1);
                source[setting] = true;
            }
        });

        const eurtIndex = tagList.findIndex(tag => tag.name === "disable_eurt");
        if (eurtIndex !== -1) {
            tagList.splice(eurtIndex, 1);
            source.accepts_eurt = false;
        } else {
            source.accepts_eurt = true;
        }

        if(tagList.length > 0){
            source.main_tag = tagList.shift().name;
        }

        delete source.tags;
        source.tags = tagList;


        //Remove source integrator object
        delete source.integrator;
        return source;
    });
    return data;
}

export function mapSourceTags(source) {
    const tagList = [...source.tags];

    const systemSettings = ['accepts_goc', 'viberate_tonight'];
    systemSettings.forEach(setting => {
        source[setting] = false;
        const systemSettingIndex = tagList.findIndex(tag => tag.name == setting);
        if (systemSettingIndex != -1){
            tagList.splice(systemSettingIndex, 1);
            source[setting] = true;
        } else {
            source[setting] = false;
        }
    });

    const eurtIndex = tagList.findIndex(tag => tag.name === "disable_eurt");
    if (eurtIndex !== -1) {
        tagList.splice(eurtIndex, 1);
        source.accepts_eurt = false;
    } else {
        source.accepts_eurt = true;
    }

    if(tagList.length > 0){
        source.main_tag = tagList.shift().name;
    }

    delete source.tags;
    source.tags = tagList;
    return source
}
