import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import './mainMenu.module.css';
import {routes, mainMenu} from "../Util/routing";
import MainMenuStructure from "./MainMenuStructure";


const MainMenu = ({push, ...props}) => (
    <Row>
        <Col md={8} lg={6} className="offset-md-2 offset-lg-3">
            <Card>
                <CardBody className="px-5 d-flex flex-column align-items-center justify-content-center">
                    <h2 className="text-center my-3 dark-gray-text">Dashboard</h2>

                </CardBody>
            </Card>
        </Col>
    </Row>
);

const mapState = state => ({
    tiers: state.tiers.data
});
const mapActions = dispatch => bindActionCreators({push}, dispatch);

export default connect(mapState, mapActions)(MainMenu);
