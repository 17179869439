import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import DynamicForm from "../../../DynamicForm/DynamicForm";
import {objectToArray, parseErrors} from "../../../Util/form";
import exportCSVData from "./Schema/exportCSVData.json";
import exportCSVUi from "./Schema/exportCSVUi.json";
import moment from "moment";
import {API_URL_V3} from "../../../../api/util";

class ExportCSVDialog extends React.Component {

    state = {
        errors: undefined,
        documentType: this.props.documentTypes[0],
        isLoading: false,
    };

    componentDidMount() {
        this.loadSchema();
    }

    onSubmit = async (data) => {
        try{
            window.open(`${API_URL_V3}admin/exchanges/ledgers/export/${this.props.exchange_id}?date_from=${moment(data.form.start_date).toISOString()}&date_to=${moment(data.form.end_date).toISOString()}`, '_blank');
        }catch (e) {
            console.error(e);
            this.setState({ errors: parseErrors(e)});
        }


    };
    loadSchema = async() => {
        const uiSchema =  exportCSVData;
        const dataSchema = exportCSVUi;
        this.setState({ uiSchema, dataSchema });
    };

    render() {
        const {toggle, isOpen} = this.props;
        const {errors} = this.state;
        if(!isOpen) return "";

        const modal = {
            toggle,
            isOpen,
            errors,
            title: "Export CSV",
            size: "md",
            confirmLabel: "Export"
        }

        return (
            <DynamicForm    uiSchema={exportCSVUi}
                            dataSchema={exportCSVData}
                            modal={modal}
                            initData={{form: {
                                    start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                                    end_date: moment().format('YYYY-MM-DD')
                                }}}
                            isLoading={this.state.isLoading}
                            errors={errors}
                            onSubmit={(data) => {this.onSubmit(data)}}/>
        )
    };

}

function mapState(state) {
    return {
        documentTypes: objectToArray(state.documentTypes.data, "id", "name")
    };
}

function mapActions(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(ExportCSVDialog));
