import {withStyles} from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

export const StyledIcon = (Icon) => {
    return withStyles({
        root: {
            fontSize: "24px"
        }
    })(Icon)
};

export const StyledListItemIcon = withStyles({
    root: {
        minWidth: 24,
        marginRight: "16px"
    }
})(ListItemIcon);

export const StyledListItemText = withStyles({
    root: {
        fontSize: "16px!important"
    }
})(ListItemText);
