import React, {Fragment} from 'react';
import Page from '../UI/Page/Page';
import {Card, withStyles} from '@material-ui/core';
import ListHeader from '../Util/ListView/ListHeader/ListHeader';
import qs from 'query-string';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import WithLoading from '../Util/HOC/WithLoading';
import ListBody from '../Util/ListView/ListBody/ListBody';
import {listHeader} from '../Util/listView';
import {getSafeDeep} from '../Util/state';
import Checkbox from '@material-ui/core/Checkbox';
import externalPOSProviders from '../../api/V3/externalPosProviders';
import moment from 'moment';
import {Helmet} from 'react-helmet';
import {notifyError} from '../Util/notification';

const ListBodyWithLoading = WithLoading(ListBody);

class POSProvidersBillingList extends React.Component {
  state = {
    data: [],
    count: 0,
    rowsPerPage: 10,
    isLoading: false,
    rowSelected: false,
    selected: [],
    billingOverviewModalOpen: false,
    merchantDetailsModalOpen: false,
    status: false,
    start_date: moment().subtract(1, 'days'),
    end_date: moment().subtract(1, 'days'),
    id: '',
    completed: true
  };

  componentDidUpdate(oldProps, prevState) {
    const {query, auth} = this.props;
    const {query: oldQuery, auth: oldAuth} = oldProps;
    if (
      JSON.stringify(query) !== JSON.stringify(oldQuery) ||
      this.state.rowsPerPage != prevState.rowsPerPage
    ) {
      this.onSearch();
    }
  }

  loadData = async () => {
    try {
      this.setState({isLoading: true});
      const query = {...this.props.query};
      query.search = getSafeDeep(this.state, 'selectedWallet.value');
      this.props.push(`?${qs.stringify(query)}`);
      this.setState({isLoading: false});
    } catch (err) {
      notifyError(err.message);
      this.setState({isLoading: false});
    }
  };

  onSearch = async () => {
    try {
      this.setState({isLoading: true});
      const {start_date, end_date} = this.state;
      const query = this.props.query;
      const page = query.page || 0;
      const sort = query.sort;

      const {
        data: {count, results: data}
      } = await externalPOSProviders.get(
        start_date.format('YYYY-MM-DD'),
        end_date.format('YYYY-MM-DD'),
        page * this.state.rowsPerPage,
        this.state.rowsPerPage,
        sort
      );

      await this.setState({
        data,
        count,
        isLoading: false,
        selected: [],
        rowSelected: false
      });
    } catch (err) {
      this.setState({isLoading: false});
      notifyError(err.message);
    }
  };

  requestSort = (sort) => {
    this.setState({sort});
    const query = {...this.props.query};
    query.page = 0;
    query.sort = sort;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  getOrganizationListHeaders = () => {
    const StyledCheckbox = withStyles({
      root: {
        '&:hover': {
          backgroundColor: 'rgba(88, 103, 221, 0.04) !important'
        }
      },
      checked: {
        color: 'rgb(88, 103, 221) !important',
        '&:hover': {
          backgroundColor: 'rgba(88, 103, 221, 0.04) !important'
        }
      },
      indeterminate: {
        color: 'rgb(88, 103, 221) !important'
      }
    })(Checkbox);

    const onItemClick = async (id) => {
      let isAnyNotCompleted = false;

      await this.setState((prev) => ({
        ...prev,
        selected:
          prev.selected.indexOf(id) == -1
            ? prev.selected.concat(id)
            : prev.selected.filter((itemID) => itemID != id)
      }));
      if (this.state.selected.length) {
        this.setState({rowSelected: true});
      } else {
        this.setState({rowSelected: false});
      }
      this.state.selected.map((id) => {
        this.state.data.map((item) => {
          if (item.id === id && item.status != 0 && !isAnyNotCompleted) {
            this.setState({completed: false});
          }
          if (item.id === id && item.status === 0) {
            isAnyNotCompleted = true;
            this.setState({completed: true});
          }
        });
      });
    };

    const onHeaderClick = async () => {
      await this.setState((prev) => ({
        ...prev,
        selected:
          prev.selected.length == prev.data.length
            ? []
            : prev.data.map((item) => item.id)
      }));

      this.setState({
        rowSelected:
          this.state.rowSelected && this.state.selected.length > 0
            ? true
            : !this.state.rowSelected,
        completed: true
      });
    };

    return [
      listHeader(
        () => (
          <StyledCheckbox
            onClick={onHeaderClick}
            indeterminate={
              this.state.selected.length > 0 &&
              this.state.selected.length < this.state.data.length
            }
            checked={this.state.selected.length == this.state.data.length}
            inputProps={{'aria-label': 'select all items'}}
          />
        ),
        'checkbox',
        false,
        (item) => (
          <StyledCheckbox
            inputProps={{'aria-label': item.id}}
            onClick={() => onItemClick(item.id)}
            disabled={false}
            checked={this.state.selected.indexOf(item.id) != -1}
          />
        )
      ),
      listHeader(
        'Name',
        'full_name',
        true,
        (data) => data.name,
        undefined,
        undefined,
        'left'
      ),
      // listHeader('Country', "primary_address__country__a2_iso_code", true, data => data.country_code, undefined, undefined, "left"),
      listHeader(
        '#Stores',
        'num_stores',
        false,
        undefined,
        undefined,
        undefined,
        'right'
      ),
      listHeader(
        '#Transactions',
        'num_transactions',
        false,
        undefined,
        undefined,
        'undefined',
        'left'
      ),
      listHeader(
        'Amount',
        'amount',
        true,
        undefined,
        undefined,
        'undefined',
        'right'
      ),
      listHeader(
        'Completed',
        'completed_amount',
        false,
        undefined,
        undefined,
        'undefined',
        'right'
      ),
      listHeader(
        'Not Completed',
        'not_completed_amount',
        false,
        undefined,
        undefined,
        'undefined',
        'right'
      ),
      listHeader(
        'Status',
        'status',
        false,
        (data) => this.renderStatus(data),
        undefined,
        'undefined',
        'right'
      ),
      listHeader(
        'Currency',
        'currency',
        true,
        undefined,
        undefined,
        'undefined',
        'right'
      )
    ];
  };

  renderStatus = (data) => {
    if (data.status === 0)
      return <div className="not-completed">Not Completed</div>;
    if (data.status === 1) return <div className="completed">Completed</div>;
    if (data.status === 2)
      return <div className="bellow-threshold">Below Threshold</div>;
  };

  isSelected = async () => {
    const isRowSelected = await this.state.selected.some((id) => {
      return !id.isNumber;
    });
    this.state.selected.map((id) => {
      if (this.state.data[id - 1].status === 0) {
        this.setState({status: false});
      }
    });
    await this.setState({rowSelected: isRowSelected});
  };

  onCompleted = async () => {
    try {
      this.setState({isLoading: true});
      let statusData = {
        ids: this.state.selected,
        start_date: this.state.start_date.format('YYYY-MM-DD'),
        end_date: this.state.end_date.format('YYYY-MM-DD')
      };

      if (this.state.completed) {
        await externalPOSProviders.markCompleted(statusData);
      } else {
        await externalPOSProviders.markNotCompleted(statusData);
      }
      this.setState({isLoading: false});
      this.onSearch();
    } catch (err) {
      notifyError(err.message);
      this.setState({isLoading: false});
    }
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    let currentItem = this.props.query.page * this.state.rowsPerPage || 0 + 1;
    let newPage = Math.floor(currentItem / newRowsPerPage);
    this.setState({rowsPerPage: newRowsPerPage});
    const query = {...this.props.query};
    query.page = newPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  toggleDialog = (dialogName) => {
    const fieldName = `${dialogName}DialogOpen`;
    this.setState({[fieldName]: !this.state[fieldName]});
  };

  render() {
    const {count, isLoading, rowsPerPage, selected, rowSelected, data} =
      this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Billings | Elicon</title>
        </Helmet>
        <Page permission={['view-billing-overview', 'view-billing-stores']}>
          <Card>
            <ListHeader
              type="POSProvidersBillingList"
              selected={rowSelected}
              completed={this.state.completed}
              startDate={this.state.start_date}
              selectedList={selected}
              title="External POS Providers Billing List"
              data={data}
              setStartDate={(value) => this.setState({start_date: value})}
              endDate={this.state.end_date}
              setEndDate={(value) => this.setState({end_date: value})}
              toggle={() => this.toggleDialog('download')}
              onSearch={(start_date, end_date) =>
                this.onSearch(start_date, end_date)
              }
              onCompleted={() => this.onCompleted()}
            />
            <ListBodyWithLoading
              sortField={this.state.sort}
              requestSort={this.requestSort}
              padding="4px 16px"
              headers={this.getOrganizationListHeaders()}
              isLoading={isLoading}
              count={count}
              data={data}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
          </Card>
        </Page>
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(
  connect(mapState, mapActions)(POSProvidersBillingList)
);
