import React, {useState} from 'react';
import Modal from '../../../../../UI/Modal/Modal';
import Select from '../../../../../UI/Select/Select';

const AddTagDialog = (props) => {
  const [tags, setTags] = useState([]);

  const labelStyles = {
    color: 'red',
    fontWeight: 300
  };

  return (
    <Modal
      toggle={props.toggle}
      onSubmit={() => props.onSubmit(tags)}
      isOpen={props.isOpen}
      title="Add Tags"
    >
      <div className="mb-3">
        <label>
          Tags<span style={labelStyles}>*</span>
        </label>
        <Select
          onChange={(t) => setTags(t)}
          options={props.options}
          placeholder="Select..."
        />
      </div>
    </Modal>
  );
};

export default AddTagDialog;
