import {apiClientV3} from '../util';

export default {
  get: (search, offset = 0, limit = 10, sort = '-created_at') =>
    apiClientV3.get(`/admin/company/referrals/`, {
      params: {
        limit,
        offset,
        search,
        ordering: sort
      }
    }),

  getT: ({search, offset = 0, limit = 10, sort = '-created_at'}) =>
    apiClientV3.get(`/admin/company/referrals/`, {
      params: {
        limit,
        offset,
        search,
        ordering: sort
      }
    }),

  add: (data) => apiClientV3.post(`/admin/company/referral/`, data),

  delete: (id) => apiClientV3.delete(`/admin/company/referral/${id}`),

  edit: (data, id) => apiClientV3.put(`/admin/company/referral/${id}`, data),

  getReferral: (id) => apiClientV3.get(`/admin/company/referral/${id}`),

  getActivities: (offset = 0, limit = 10, sort, search) =>
    apiClientV3.get(`/admin/company/referral-activities`, {
      limit,
      offset,
      search,
      ordering: sort
    }),

  getReferralActivities: (id, offset, limit, sort, orderBy) =>
    apiClientV3.get(`/admin/company/referral-activities`, {
      ref_id: id,
      offset,
      limit,
      sort,
      order_by: orderBy
    }),

  getActivity: (id) =>
    apiClientV3.get(`/admin/company/referral-activity/${id}`),

  createReferralActivity: (referral_id, company_id, reseller_fee) =>
    apiClientV3.post('/admin/company/referral-activity/', {
      referral_id,
      company_id,
      onboarding_status: 2,
      reseller_fee
    }),

  editReferralActivity: (company_id, id, referral_id, reseller_fee) =>
    apiClientV3.put(`/admin/company/referral-activity/${id}`, {
      referral_id,
      reseller_fee,
      company_id
    })
};
