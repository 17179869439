import { connect } from "react-redux";
import { getSafeDeep } from "./state";

const permissionGuard = ({ code, children, user, atLeastOne, allowedRoles, combined }) => {
    //roles check
    if (allowedRoles) {
        if (hasRole(user, allowedRoles)) {
            return children;
        }
        return null;
    }

    //has role or permission
    if (combined) {
        if (containsPermission(user, combined) || hasRole(user, combined))
            return children
        return null;
    }

    //at least one permission 
    if (atLeastOne) {
        if (containsPermission(user, code))
            return children;
        return null;
    }

    //has all permissions
    if (hasPermission(user, code)) {
        return children
    } else {
        return null
    }
}

export function hasRole(user, allowedRoles) {
    return allowedRoles.includes(getSafeDeep(user, 'roles.0.name'))
}

export function hasPermission(user, code) {
    if (code instanceof Array && user.permissions.filter(p => code.indexOf(p.code) !== -1).length === code.length) {
        return true
    } else return !!(!code || user.permissions.find(p => p.code === code));
}

export function containsPermission(user, code) {
    if (!code) return false;

    let contains = false;

    code.map(permission => {
        if (user.permissions.find(p => p.code === permission)) {
            contains = true;
        }
    })

    return contains
}

export const mapRoleState = state => {
    return {
        user: state.user.data
    }
}

export const PermissionGuard = connect(mapRoleState)(permissionGuard)
