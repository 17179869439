import React from 'react'
import { useState } from 'react';

import Button from '../../../UI/Button/Button'
import Modal from '../../../UI/Modal/Modal'
import Input from "../../../UI/Input/Input";
import { Form } from "reactstrap";
import { changeStateFromInput } from "../../../Util/controlledComponent";

export default props => {

    const [reason, setReason] = useState("");

    const oninput = (e) => {
        setReason(e.target.value);
    }

    const onSubmit = async () => {
        props.submit(reason)
        setReason('')
        props.toggle()
    }

    return (
        <Modal title="Please enter reason"
            toggle={() => {
                setReason('')
                props.toggle()
            }}
            isOpen={props.isOpen}
            size="sm"
            cancelLabel="Close"
            submitButton={() => <Button className="ml-3" onClick={() => onSubmit()} color={"success"}>Submit</Button>}>
            <Input type="textarea" name="reason" onChange={(e) => oninput(e)} value={reason} />
        </Modal>
    )
}