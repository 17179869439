import React from 'react'
import { Form } from 'reactstrap'

import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'

import './styles.module.css'
import {PermissionGuard} from "../../role-helpers";

const OnlyTitleListHeader = props => {
    return (
            <div styleName="header" className="d-flex align-items-center">
                <h4>{props.title}</h4>

                {props.additionalComponent && props.additionalComponent()}
            </div>
    )
}

export default OnlyTitleListHeader
