import language from "../api/language";
import {fulfilled, fulfilledState, initState} from "./middleware";

export const GET_GLOBAL_LANGUAGE_LIST = "GET_GLOBAL_LANGUAGE_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_GLOBAL_LANGUAGE_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getGlobalLanguageList = () => ({
    type: GET_GLOBAL_LANGUAGE_LIST,
    payload: language.languages()
});
