import React, {Fragment} from 'react';
import Modal from "../UI/Modal/Modal"
import Input from '../UI/Input/Input'
import Button from '../UI/Button/Button'
import {Form, Label, Row} from "reactstrap";
import {createMessage} from "../Util/notification";
import {parseErrors} from "../Util/form";
import {changeStateFromInput} from "../Util/controlledComponent";
import kyc from "../../api/kyc";

class KYCDeclineDialog extends React.Component {

    state = {
        reason: "",
        isLoading: false,
    };

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true}); 
        try{
            await kyc.decline(this.props.data.id, this.state.reason);
            createMessage('Successfully declined KYC request');
            this.props.toggle();
            this.props.toggleParent();
            this.setState({ isLoading: false }); 
        }catch (e) {
            this.setState({ errors: parseErrors(e), isLoading: false });
        }
    };

    onInput = (e) => {
        this.setState(changeStateFromInput(e));
    };

    render() {
        const {toggle, isOpen} = this.props;
        return (
            <Modal  toggle={toggle} 
                    isOpen={isOpen}
                    size="sm"
                    title="Decline KYC Request" 
                    isLoading={this.state.isLoading}
                    footer={() => (
                        <div className="d-flex justify-content-end pt-5">
                            <Button isLoading={this.state.isLoading} color="secondary" className="mr-3" onClick={toggle}>Cancel</Button>
                            <Button isLoading={this.state.isLoading} color="danger" onClick={(e) => this.onSubmit(e)} >Decline</Button>
                        </div>
                    )}>
                    <Form className="form-group mb-0">
                        <Label className="mb-2" for={"reason"}>Reason</Label>
                        <Input disabled={this.state.isLoading} type="textarea" required={true} name="reason" onChange={(e) => this.onInput(e)} value={this.state.reason} />
                    </Form>
            </Modal>
        )
    };

}

export default KYCDeclineDialog;
