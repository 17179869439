import {apiClient} from "./util";
import type {PaginatedList} from "./model/list";
import type {Kyc} from "./model/kyc";
import {AxiosPromise} from 'axios';

export default {
    // List KYC paginated
    listPaginated: (offset: number, limit: number = 10, sort: string = "-profile__last_updated", country: string = ''): AxiosPromise<PaginatedList<Kyc>> => {
        return apiClient.get("/admin/kyc/pending", {params: {offset, limit, sort, country}})
    },

    // Approve KYC request
    approve: (id: number): AxiosPromise => apiClient.post(`/admin/kyc/approve/${id}`),

    // Decline KYC request
    decline: (user_id: number, comment: string): AxiosPromise => apiClient.post('/admin/kyc/decline/', {user_id, comment}),

    // Update KYC data
    update: (id: number, data: Object) => apiClient.post(`/admin/kyc/update/${id}`, data),

    // Update KYC images
    updateImages: (id: number, data: Object) => apiClient.post(`/admin/kyc/update/${id}/photo/`,data),

    // Comment on KYC
    comment: (user_id: number, comment: string) => apiClient.post(`/admin/kyc/comment/`, {user_id, comment}),
};
