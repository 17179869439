import {apiClientV3} from "../util";

export default {
    resendInstructions: (id) => apiClientV3.get(`/admin/cashiers/${id}/resend/instructions/`),
    resetApiKey: (id) => apiClientV3.post(`/admin/cashiers/${id}/reset-api-key/`),
    resetPassword: (id) => apiClientV3.post(`/admin/cashiers/${id}/reset-password/`),
    delete: (id) => apiClientV3.delete(`/admin/cashiers/${id}/`),
    assignHash: (id, hash) => apiClientV3.post(`/admin/cashiers/${id}/qr-code/assign/`, {qr_code_ids: [hash]}),
    releaseHash: (id, hash) => apiClientV3.post(`/admin/cashiers/${id}/qr-code/${hash}/release`)
}
