import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Form} from 'reactstrap';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import {createMessage} from '../Util/notification';
import DynamicForm from '../DynamicForm/DynamicForm';
import user from '../../api/user';
import {ERR_VALIDATION} from '../../api/errorCodes';
import {getSafeDeep} from '../Util/state';
import {mapBackendErrors} from '../DynamicForm/controls/util';
import {parseErrors} from '../Util/form';
import categories from '../../redux/category';
import wallets from '../../api/V3/wallets';

class DeleteWalletDialog extends React.Component {
  state = {
    errors: undefined,
    idLoading: false
  };

  componentDidMount() {}

  componentDidUpdate() {}

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({isLoading: true});
      await wallets.deleteWallet(this.props.wallet.id);
      createMessage('Successfully deleted wallet');
      this.props.reloadData();
      this.props.toggle();
      this.setState({isLoading: false});
    } catch (e) {
      console.error(e);
      this.setState({isLoading: false, errors: parseErrors(e)});
    }
  };

  render() {
    const {toggle, isOpen, wallet} = this.props;
    const {errors, isLoading} = this.state;
    if (!isOpen) return '';

    return (
      <Modal
        toggle={toggle}
        isOpen={isOpen}
        errors={errors}
        isLoading={isLoading}
        centered
        title="Delete Wallet"
        cancelButton={() => (
          <Button color="secondary" className="mr-3" onClick={toggle}>
            Cancel
          </Button>
        )}
        submitButton={() => (
          <Button color="danger" onClick={(e) => this.onSubmit(e)}>
            Delete
          </Button>
        )}
        size="xs"
      >
        <div className="text-center mb-5 mt-4">
          Are you sure you want to delete Wallet{' '}
          <strong>{getSafeDeep(wallet, 'title')}</strong> ?
        </div>
      </Modal>
    );
  }
}

export default DeleteWalletDialog;
