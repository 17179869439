import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import {Close, Done} from '@material-ui/icons';
import cn from 'classnames';
import moment from 'moment';
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {Button} from 'reactstrap';
import {API_URL} from '../../config';
import CheckBox from '../UI/CheckBox/CheckBox';
import './listView.css';
import {getSafe, getSafeDeep} from './state';

export function getBulkDownloadLink(token) {
  if (token.indexOf('http') == 0) {
    return token;
  }
  return `${API_URL}admin/identifiers/generator/${token}`;
}

export function renderName(item, undefinedMessage = 'Name not set') {
  if (
    getSafe(item.first_name, '').length + getSafe(item.last_name, '').length >
    0
  ) {
    return `${item.first_name} ${item.last_name}`;
  } else {
    return undefinedMessage;
  }
}

export function renderProfileName(item, undefinedMessage = 'Name not set') {
  if (item.profile) {
    return renderName(item.profile);
  }
  return renderName(item);
}

export function renderCreatedBy(item) {
  return renderName(item.user);
}

export function renderBundle(item) {
  return (
    <a
      style={{color: '#5867dd'}}
      href={getBulkDownloadLink(item.token)}
      rel="noreferrer noopener"
      target="_blank"
    >
      {item.token}
    </a>
  );
}

export function renderFullDate(item) {
  return moment(item).format('DD/MM/YYYY HH:mm:ss');
}

export function renderCreated(item) {
  return renderFullDate(item.created);
}

export function renderCreatedAt(item) {
  return renderFullDate(item.created_at);
}

export function renderLedgerAmount(amounts) {
  if (amounts.length === 1) {
    return <div className={'ledgerAmountRed'}>{amounts[0].amount}</div>;
  } else if (amounts.length > 1) {
    return (
      <div>
        <div className={'ledgerAmountGreen'}>{amounts[0].amount}</div>
        <div className={'ledgerAmountRed'}>{amounts[1].amount}</div>
      </div>
    );
  }

  if (amounts.length > 1) {
    return (
      <div>
        <div className={'ledgerAmountGreen'}>{amounts[0]}</div>
        <div className={'ledgerAmountRed'}>{amounts[1]}</div>
      </div>
    );
  } else {
    return <div className={'ledgerAmountRed'}>{amounts[0]}</div>;
  }
}

export function renderLink(label, route, target_blank = false) {
  return (
    <div className="userFullName">
      {target_blank ? (
        <a href={route} target="_blank">
          {label}
        </a>
      ) : (
        <Link to={route}>{label}</Link>
      )}
    </div>
  );
}

export function renderLinkButton(label, clickHandler) {
  return (
    <div className="userFullName">
      <a onClick={clickHandler}>{label}</a>
    </div>
  );
}

export function renderAccountantName(item) {
  if (!item.accountant) return '';
  return renderName(item.accountant);
}
export function renderConnectedUser(item) {
  if (!item.accountant) return '';
  return item.accountant.email;
}
export function renderSource(item) {
  if (!item.sources) return '';
  if (item.sources[0]) return item.sources[0].full_name;
  return '';
}
export function renderHash(item) {
  return <span style={{fontWeight: 500}}>{item.hash}</span>;
}

export function RenderHashSmall(props) {
  const {text, id, bold} = props;

  return (
    <Fragment>
      <div data-tip={text} data-for={id}>
        <div
          style={{fontWeight: bold ? 'bold' : 300}}
          id={id}
          className="renderHashSmall"
        >
          <div>{text}</div>
        </div>
      </div>
      <ReactTooltip place="bottom" id={id}>
        {text}
      </ReactTooltip>
    </Fragment>
  );
}

export function renderEditUser(item) {
  return <Button color="outline-info">Edit User</Button>;
}

export function renderCheckBox(value) {
  return <CheckBox getValue={() => value} onClick={() => {}} disabled={true} />;
  // return <input align="center" type={'checkbox'} disabled={true} checked={value} />
}

export function renderCheckmark(status) {
  return status ? (
    <div className={'active'}>&#10003;</div>
  ) : (
    <div className={'inactive'}>&#10007;</div>
  );
}

export function renderDoneClear(conditionDone) {
  return conditionDone ? (
    <Done style={{color: '#34bfa3', fontSize: '24px'}} />
  ) : (
    <Close style={{color: '#f4516c', fontSize: '24px'}} />
  );
}

export function renderCryptoAmount(amount, crypto, decimals = 2) {
  return parseFloat(amount).toFixed(decimals) + ' ' + crypto;
}

export function renderActivityStatus(status) {
  return (
    <div
      className={cn({
        active: status === 'active',
        inactive: status === 'inactive'
      })}
    >
      {status.toUpperCase()}
    </div>
  );
}

export function renderMultipleLedgers(items = [], key) {
  return (
    <div>
      {items.map((item) => {
        return (
          <div>
            {item[`${key}`]}
            <br />
          </div>
        );
      })}
    </div>
  );
}

export function renderCurrencyPair(pair = '', currencies) {
  //Try to find match for first 3 digits
  let val_first = currencies.filter(
    (currency) => currency.symbol == pair.substr(0, 3)
  );
  let first_len = 3;

  //If no match look for 4 digits
  if (val_first.length < 1) {
    val_first = currencies.filter(
      (currency) => currency.symbol == pair.substr(0, 4)
    );
    first_len = 4;
  }

  //Because USDT is not in currencies this is a hardcoded case when the first value is USDT
  if (
    getSafeDeep(val_first, '0.symbol') === 'USD' &&
    pair.slice(first_len, first_len + 1) === 'T' &&
    pair.slice(first_len, pair.length).length > 3
  ) {
    val_first = [{display_name: 'USDT'}];
    first_len = 4;
  }

  //Do same for second value
  let val_second = currencies.filter(
    (currency) => currency.symbol == pair.substr(first_len, pair.length)
  );

  if (val_first[0] && val_second[0]) {
    return `${val_first[0].display_name} -> ${val_second[0].display_name}`;
  }
}

export function renderExchangeBalanceCurrency(item) {
  return item;
}

export function getStyledActions(items) {
  const StyledListItemText = withStyles({
    root: {
      fontSize: '16px!important'
    }
  })(ListItemText);

  const StyledIcon = (Icon) => {
    return withStyles({
      root: {
        fontSize: '24px'
      }
    })(Icon);
  };

  const StyledListItemIcon = withStyles({
    root: {
      minWidth: 24,
      marginRight: '16px'
    }
  })(ListItemIcon);

  return items.map((item) => {
    return {
      handler: item.handler,
      render: () => {
        const Icon = StyledIcon(item.Icon);
        return (
          <Fragment>
            <StyledListItemIcon>
              <Icon />
            </StyledListItemIcon>
            <StyledListItemText disableTypography primary={item.text} />
          </Fragment>
        );
      }
    };
  });
}

export function renderBooleanField(value) {
  return value ? 'Yes' : 'No';
}

export function listHeader(
  label,
  fieldKey,
  isSortable = true,
  displayFunction = undefined,
  linkTo = undefined,
  instance = undefined,
  align = 'left'
) {
  return {
    label,
    fieldKey,
    isSortable,
    displayFunction,
    linkTo,
    instance,
    align
  };
}

export function listHeaders(
  title,
  field,
  sorting = false,
  displayFunction = undefined,
  linkTo = undefined,
  instance = undefined
) {
  return {
    title,
    field,
    sorting,
    displayFunction,
    linkTo,
    instance
  };
}

export function listFilter(label, queryKey, queryValue) {
  return {
    label,
    queryKey,
    queryValue
  };
}
