import React from 'react'
import {renderElement} from "./util";
import classnames from 'classnames';

type HorizontalLayoutProps = {
    componentId?: string,
    outterhtmlClass?: string,
    elements?: Array
}

export default class HorizontalLayout extends React.Component<HorizontalLayoutProps>{
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.elements !== nextProps.elements;
    }

    render() {
        const {componentId, outterhtmlClass, elements} = this.props;
        return (
            <div id={componentId} className={classnames('horizontal-layout', outterhtmlClass)}>
                {elements && elements.map(renderElement)}
            </div>
        );
    }
}
