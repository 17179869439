import React from 'react'

import './Card.module.css'

const Card = props => {
    return (
        <div styleName="card">
            {props.children}
        </div>
    )
}

export default Card;