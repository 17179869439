import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col, FormGroup, Row} from 'reactstrap';
import Modal from '../UI/Modal/Modal';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import {createMessage} from '../Util/notification';
import {parseErrors} from '../Util/form';
import question from '../../api/question';
import {changeStateFromInput} from '../Util/controlledComponent';
import {supportedLanguages} from '../Util/language';
import {ALPHABET_LOWER} from './util';
import {deepCopy, getSafeDeep} from '../Util/state';

class TranslateQuestionDialog extends React.Component {
  state = {
    errors: undefined,
    question: undefined,
    stateReady: false
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const data = this.props.data;
    const {language} = this.props;

    if (this.props.isOpen != prevProps.isOpen) {
      if (this.props.isOpen) {
        this.setState({
          stateReady: true,
          translation: {
            value: getSafeDeep(
              getSafeDeep(data, 'translations', []).find(
                (t) => t.lang_code == language.lang_code_numeric
              ),
              'value',
              ''
            ),
            lang_code: language.lang_code_numeric,
            question_id: data.question_id,
            predefined_answers: getSafeDeep(data, 'predefined_answers', []).map(
              (predefinedAnswer) => {
                return {
                  enum: predefinedAnswer.enum,
                  lang_code: language.lang_code_numeric,
                  value: getSafeDeep(
                    getSafeDeep(predefinedAnswer, 'translations', []).find(
                      (t) => t.lang_code == language.lang_code_numeric
                    ),
                    'value',
                    ''
                  )
                };
              }
            )
          }
        });
      } else {
        this.setState({stateReady: false});
      }
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      await question.translate(this.state.translation);
      createMessage('Successfully translated question');
      this.setState({translation: undefined});
      this.props.reloadData();
      this.props.toggle();
    } catch (e) {
      console.error(e);
      this.setState({errors: parseErrors(e)});
    }
  };

  handleInput = (e) => {
    const translation = this.state.translation;
    translation.value = e.target.value;
    this.setState({translation});
  };

  handleAnswerInput = (e, index) => {
    const translation = this.state.translation;
    translation.predefined_answers[index].value = e.target.value;
    this.setState({translation});
  };

  render() {
    const {toggle, isOpen, categories} = this.props;
    const question = this.props.data;
    const {errors, stateReady} = this.state;
    if (!isOpen) return '';
    if (!this.state.translation) return '';
    if (!stateReady) return '';
    return (
      <Modal
        toggle={toggle}
        isOpen={isOpen}
        size="lg"
        errors={errors}
        onSubmit={(e) => this.onSubmit(e)}
        cancelLabel="Back"
        confirmLabel="Translate"
        title="Translate Question"
      >
        <form onSubmit={(e) => this.onSubmit(e)}>
          <FormGroup>
            <Row className="mb-4">
              <Col sm={3}>
                <div className="font-weight-bold">Translation language</div>
              </Col>
              <Col sm={9}>{this.props.language.lang_name}</Col>
            </Row>
            <Row className="mb-4">
              <Col sm={3}>
                <div className="font-weight-bold">Question</div>
              </Col>
              <Col sm={9}>
                <Input type="textarea" disabled value={this.props.data.value} />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col sm={3}>
                <div className="font-weight-bold">Question translation</div>
              </Col>
              <Col sm={9}>
                <Input
                  required={true}
                  onChange={(e) => this.handleInput(e)}
                  type="textarea"
                  value={this.state.translation.value}
                />
              </Col>
            </Row>
            {this.state.translation.predefined_answers &&
              this.state.translation.predefined_answers.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <Row className="mb-4">
                      <Col sm={3}>
                        <div className="font-weight-bold">
                          {this.props.data.predefined_answers[index].enum}
                        </div>
                      </Col>
                      <Col sm={9}>
                        <Input
                          type="textarea"
                          disabled
                          value={
                            this.props.data.predefined_answers[index].value
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col sm={3}>
                        <div className="font-weight-bold"></div>
                      </Col>
                      <Col sm={9}>
                        <Input
                          required={true}
                          onChange={(e) => this.handleAnswerInput(e, index)}
                          type="textarea"
                          value={
                            this.state.translation.predefined_answers[index]
                              .value
                          }
                        />
                      </Col>
                    </Row>
                  </Fragment>
                );
              })}
          </FormGroup>
        </form>
      </Modal>
    );
  }
}

function mapState(state) {
  return {
    categories: state.categories.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapState, mapActions)(TranslateQuestionDialog)
);
