import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import bg from './user_profile_bg.jpg'
import userIcon from '../user_eligma.svg'

import './dropdown.css'

const Dropdown = (props) => (
    <>
        <div className="header__dropdown--content">
            <div className="header__dropdown--arrow"></div>  
            <div className="dropdown__card d-flex flex-column">
                <div    style={{ backgroundImage: `url('${bg}')` }} 
                        className="dropdown__card--header flex-grow-1 d-flex align-items-center">
                    <img src={userIcon} className="rounded-circle dropdown__card--avatar"></img>
                    <div className="dropdown__card--info d-flex flex-column justify-content-center ml-3">
                        <Link to="/profile" className="card__info--name">{props.user.first_name} {props.user.last_name}</Link>
                        <a href={`mailto:${props.user.email}`} className="card__info--email">{props.user.email}</a>
                    </div>
                </div>
                <div className="dropdown__card--body flex-grow-1" >
                    <Link className="card__body--link" to="/profile">
                        <i className="flaticon-profile-1"></i>My Profile
                    </Link>
                    <a className="card__body--link" href="mailto:support@eligma.com">
                        <i className="flaticon-lifebuoy"></i>Contact Support
                    </a>
                    <Button outline className="d-block mt-3" onClick={props.logout}>Logout</Button>
                </div>
            </div>
        </div>
        <div className="header__dropdown--backOff" onClick={props.removeDropdown}></div>
    </>
)

export default Dropdown