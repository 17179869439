import React, {useEffect, useState, useRef} from 'react'
import { Form } from 'reactstrap'

import Input from '../../../UI/Input/Input'

import "./styles.module.css"
import Select from "../../../UI/Select/Select";
import * as qs from "qs";
import Button from "../../../UI/Button/Button";
import cx from 'classnames';
import {PermissionGuard} from "../../role-helpers";

export function useOutsideAlerter(ref, isOpen, setIsOpen) {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && isOpen) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}

const TransactionHeader = props => {
    const wrapperRef = useRef(null);
    const [toggle, setToggle] = useState(false);
    const query = qs.parse(props.query, { ignoreQueryPrefix: true })
    useOutsideAlerter(wrapperRef, toggle, setToggle);

    const toggleDropdown = () => {
        setToggle(!toggle)
    };

    const setFilter = (key, value) => {
        const newQuery = { ...query }
        if (value == null || value === "") {
            delete newQuery[key]
        } else {
            newQuery[key] = value
        }
        props.push(`?${qs.stringify(newQuery)}`)
    }

    const getFilter = (key) => query[key]

    return (
        <div>
            <div styleName="header" className="d-flex align-items-center">
                <Form onSubmit={props.onSubmit} className="d-flex">
                    <div style={{ width: "256px" }}>
                        <label>Search</label>
                        <Input
                            onChange={props.onChange}
                            name="Search"
                            placeholder="Filter" />
                    </div>
                    <div style={{ width: "256px" }} className="ml-3">
                        <label>Type</label>
                        <Select
                            value={props.typeFilters.find(c => c.value == (getFilter("type") || props.typeFilters[0].value))}
                            placeholder="All types"
                            options={props.typeFilters}
                            isSearchable={false}
                            onChange={c => setFilter("type", c ? c.value : null)}
                            className="mr-3" />
                    </div>
                </Form>
                <PermissionGuard code={'view_admin_transactions_report'}>
                    <div className="ml-auto"  ref={wrapperRef}>
                        <Button styleName="dropdown-button" onClick={toggleDropdown}>Export <div styleName="chevron"> &#10095; </div></Button>
                        <div styleName={cx("dropdown-content", {"show" : toggle})}>
                            <a onClick={() => props.toggle("CSV")}>CSV</a>
                        </div>
                    </div>
                </PermissionGuard>
            </div>
        </div>
    )
}

export default TransactionHeader
