import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createMessage} from "../Util/notification";
import Modal from '../UI/Modal/Modal'
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import {ERR_VALIDATION} from "../../api/errorCodes";
import {getSafeDeep} from "../Util/state";
import {mapBackendErrors} from "../DynamicForm/controls/util";
import {parseErrors} from "../Util/form";
import brand from "../../api/brand";
import addWalletDataSchema from "./schema/addWalletData";
import addWalletUiSchema from "./schema/addWalletUi";
import {getBrandList} from "../../redux/brand";
import currencies from "../../api/V3/currency"
import {apiClientV3} from "../../api/util";
import wallets from "../../api/V3/wallets";


class AddWalletDialog extends React.Component {

    state = {
        errors: undefined
    };

    componentDidMount() {

        this.loadSchema();
    }

    componentDidUpdate() {
    }

    onSubmit = async (data) => {
        try{
            await wallets.add(data);
            createMessage('Successfully created wallet');
            this.props.toggle();
            this.props.reloadData();

        }catch (e) {
            console.error(e);
            this.setState({ errors: parseErrors(e)});
        }


    };

    loadSchema = async() => {
        const uiSchema = addWalletUiSchema;
        const dataSchema = addWalletDataSchema;
        this.setState({ uiSchema, dataSchema });
    };

    render() {
        const {toggle, isOpen, currencies} = this.props;
        const {errors} = this.state;
        if(!isOpen) return "";

        const filteredCurrencies = currencies.filter(currency => currency.crypto && currency.symbol === 'ETH');

        const modal = {
            toggle,
            isOpen,
            title: "Add Wallet",
            errors,
            size: "md"
        }

        return(
            <DynamicForm    uiSchema={this.state.uiSchema}
                            dataSchema={this.state.dataSchema}
                            initData={{currencies: filteredCurrencies}}
                            modal={modal}
                            errors={errors}
                            onSubmit={(data) => this.onSubmit(data.form)}/>
        )
    };
}

function mapState(state) {
    return {
        currencies: state.currencies.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({getBrandList}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(AddWalletDialog));
