import axios, {AxiosInstance} from 'axios';
import {API_URL, MAPI_URL} from '../config';

/** API V2 */
/*  Setting axios base client                       */
export const apiClient: AxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
});

/*  Setting up interception middleware for future authorization                       */
apiClient.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  }
);

/*  Setting up interception middleware for future authorization                       */
apiClient.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  }
);

/**API V3*/
export const API_URL_V3 = API_URL.replace('v2', 'v3');

export const apiClientV3: AxiosInstance = axios.create({
  baseURL: API_URL_V3,
  withCredentials: true
});

apiClientV3.interceptors.request.use((req) => {
  req.headers['accept-language'] = 'en';

  const allCookies = document.cookie.replace(/\s/g, '').split(';');
  const csrfCookies = allCookies
    .filter((item) => item.includes('csrftoken'))
    .map((item) => item.replace('csrftoken=', ''));

  if (window.location.host === 'admin.elipay.com') {
    req.headers['X-CSRFToken'] = csrfCookies[0];
  } else {
    req.headers['X-CSRFToken'] = csrfCookies[csrfCookies.length - 1];
  }

  return req;
});

/** MAPI */
export const mapiClient: AxiosInstance = axios.create({
  baseURL: MAPI_URL,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
});

mapiClient.interceptors.request.use((req) => {
  req.headers['accept-language'] = 'en';
  return req;
});

/*************  UTILITY METHODS ********************/

/*  Getting image from S3 (or not) on different environments */
export function getImageS3(src, acceptsBase64 = false) {
  if (!src) return '';
  if (acceptsBase64) {
    if (src.indexOf('http') == 0)
      return `${API_URL}image/${src}?${new Date().getTime()}`;
    return src;
  } else {
    if (src.indexOf('http') != 0 && src.indexOf('/media') != 0) return '';
  }
  if (API_URL == 'http://localhost:8001/api/v2/') {
    if (src.indexOf('/media') == 0)
      return 'http://localhost:8001' + src + '?' + new Date().getTime();
    if (src.indexOf('http') == 0) return src + '?' + new Date().getTime();
    return src;
  }
  return `${API_URL}image/${src}?${new Date().getTime()}`;
}

/*  Getting bulk download link, is safe to use    */
export function getBulkDownloadLink(token) {
  if (token.indexOf('http') == 0) {
    return token;
  }
  return `${API_URL}admin/identifiers/generator/${token}`;
}
