import { parsePhoneNumber } from "libphonenumber-js/max";

export function checkInObject(obj, additionalPath = "") {
  if (!obj) return {};

  const keys = Object.keys(obj).filter((key) =>
    key.includes("national_number")
  );

  const errors = {};

  keys.forEach(async (key) => {
    if (!obj[key]) return;
    let number = `${
      obj[key.replace("national_number", "country_phone_prefix")]
    }${obj[key]}`;
    if (number[0] != "+") {
      number = "+" + number;
    }
    const phoneNumber = parsePhoneNumber(number);
    if (!phoneNumber.isValid()) {
      let path = "#/properties/form/properties";
      if (!!additionalPath) {
        additionalPath.split(".").forEach((property) => {
          path += `/${property}/properties`;
        });
      }
      path += `/${key}`;
      errors[path] = "Invalid Phone Number";
    }
  });

  return errors;
}

export function checkIfPhoneIsValid(newErrors, form) {
  //check errors in form
  const errors = checkInObject(form);
  const errorKeys = Object.keys(errors);
  if (errorKeys.length !== 0) {
    errorKeys.forEach((key) => {
      newErrors[key] = errors[key];
    });
  }

  //check for inner object in form
  const formKeys = Object.keys(form);
  for (let key of formKeys) {
    if (typeof form[key] === "object" && form[key] !== null) {
      const errors = checkInObject(form[key], key);
      const errorKeys = Object.keys(errors);
      if (errorKeys.length !== 0) {
        errorKeys.forEach((key) => {
          newErrors[key] = errors[key];
        });
      }
    }
  }

  return newErrors;
}

export function shouldProceed(errors, backendErrors, dataSchema) {
  if (Object.keys(errors).length == 0) {
    //if no errors
    return true;
  }
  //is not in current step
  let occurred = false;

  Object.keys(errors).forEach((key) => {
    let obj = dataSchema;
    key
      .substring(2)
      .split("/")
      .forEach((field) => {
        if (obj) {
          obj = obj[field];
        }
      });
    if (obj) occurred = true;
  });
  if (!occurred) return true;

  //if backend error
  let isBackendErr = false;

  Object.keys(errors).forEach((key) => {
    if (Object.keys(backendErrors).includes(key)) {
      isBackendErr = true;
    }
  });

  if (isBackendErr) return true;

  return false;
}
