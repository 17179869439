import React, { Fragment } from 'react'

import userIcon from './user_eligma.svg'

import Modal from '../UI/Modal/Modal'

import moment from 'moment'

import { renderDoneClear, renderProfileName } from '../Util/listView'

import './userDetailsDialog.module.css'
import rolesApi from '../../api/V3/roles'
import { notifyError } from '../Util/notification';

const contained = (item, items, field) => {
    for (let i = 0; i < items.length; i++) {
        if (item[field] == items[i][field]) {
            return i
        }
    }
    return -1
}

const groupPermissions = (data) => {
    let permissions = []
    data.forEach(item => {
        const index = contained(item, permissions, 'group')
        if (index != -1) {
            if (contained(item, permissions[index].actions, 'code') == -1) {
                permissions[index].actions.push(item)
            }
        } else {
            permissions.push({ group: item.group, actions: [item] })
        }
    })
    return permissions;
}
class UserDetailsDialog extends React.Component {

    state = {
        roles: [],
        permissions: []
    }

    async componentDidUpdate(prevProps) {
        if (this.props.user != prevProps.user) {
            if (this.props.user) {

                let permissions = []
                this.props.user.roles.map(role => {
                    permissions = [...permissions, ...role.permissions]
                })

                let roles = [{
                    group: 'Roles',
                    actions: this.props.user.roles
                }]
                try {
                    const { data: regionData } = await rolesApi.getUserRegion(this.props.user.id)

                    roles[0].actions = roles[0].actions.map(role => {
                        return { ...role, region: regionData.region }
                    })

                    await this.setState({
                        roles,
                        permissions: groupPermissions(permissions)
                    })
                } catch (err) {
                    notifyError(err.message)
                }
            }
        }
    }


    render() {

        const fields = {
            'lan': 'Language',
            'timezone': 'Timezone',
            'last_login': "Last Login",
            // 'joined': 'Joined',
            'is_login_blocked': 'Is Login Blocked',
            'activated': 'Activated'
        }

        const userData = {
            'lan': 'English',
            'timezone': 'UTC',
            'last_login': this.props.user ? (this.props.user.last_login ? moment(this.props.user.last_login).format('DD.MM.YYYY HH:mm:ss') : 'Never Logged In') : null,
            // 'joined': '18.7.2019 09:26',
            'is_login_blocked': this.props.user ? this.props.user.is_login_blocked : null,
            'activated': this.props.user ? this.props.user.registered_token == null : false
        }

        return (
            <Modal
                title="User Details"
                toggle={this.props.toggle}
                isOpen={!!this.props.user}
                size="lg"
                cancelLabel="Close"
                submitButton={() => null}>
                <div className="d-flex justify-content-between">
                    <div styleName="col">
                        <div styleName="imageBox">
                            <div styleName="image">
                                <img src={userIcon} />
                            </div>
                            <span styleName="name">{this.props.user && `${renderProfileName(this.props.user)}`}</span>
                            <span styleName="email">{this.props.user && this.props.user.email}</span>
                        </div>
                        <div>
                            {Object.keys(fields).map((key, i) => {
                                return (
                                    <div key={`${key}--${i}`} className="d-flex">
                                        <label styleName="label">{fields[key]}</label>
                                        <span>{key == 'is_login_blocked' || key == 'activated' ? renderDoneClear(userData[key]) : userData[key]}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div styleName="col">
                        <p>Roles</p>
                        <div styleName="data">
                            {this.state.roles.map(role => (
                                <Fragment key={role.group}>
                                    <div styleName="level">{role.group}</div>
                                    {role.actions.map((item, i) => {
                                        return <div key={`${item.id}--${i}`} styleName="item">
                                            {item.name} <b>{item.region ? item.region.name : ''}</b>
                                        </div>
                                    })}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                    <div styleName="col">
                        <p>Permissions</p>
                        <div styleName="data">
                            {this.state.permissions.map(permission => (
                                <Fragment key={permission.group}>
                                    <div styleName="level">{permission.group}</div>
                                    {permission.actions.map((item, i) => {
                                        return <div key={`${item.code}--${i}`} styleName="item">
                                            {item.display_name}
                                        </div>
                                    })}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default UserDetailsDialog;
