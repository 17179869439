import React, {useRef, useState} from 'react';

import document_icon from '../document-icon.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import '../../DynamicForm/controls/base64Image.module.css';
import {Input} from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import companyKyb from '../../../api/companyKyb';
import {createMessage} from '../../Util/notification';
import {deepCopy} from '../../Util/state';
import DeleteDocumentDialog from './DeleteDocumentDialog';
import {hasPermission, PermissionGuard} from '../../Util/role-helpers';

const Documents = ({data, dataUpdated, user}) => {
  const uploadImageRef = useRef();
  const [uploading, setUploading] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const onUpload = async (e) => {
    const files = Array.from(e.target.files);
    setUploading(true);

    const formData = new FormData();
    formData.append('document_image', files[0]);

    try {
      const response = await companyKyb.documents(data.id).create(formData);
      const newData = deepCopy(data);
      newData.kyb_data.company_documents.splice(0, 0, response.data);
      dataUpdated(newData);
    } catch (e) {
      console.error(e);
      createMessage('Company document upload failed', 'error');
    } finally {
      setUploading(false);
    }
  };

  const deleteDocument = async () => {
    try {
      await companyKyb.documents(data.id).delete(documentToDelete.id);
      createMessage('Document deleted successfully');
      const newData = deepCopy(data);
      const index = newData.kyb_data.company_documents.findIndex(
        (doc) => doc.id === documentToDelete.id
      );
      newData.kyb_data.company_documents.splice(index, 1);
      dataUpdated(newData);
      setDocumentToDelete(null);
    } catch (e) {
      console.error(e);
      createMessage('Failed to delete document');
    }
  };
  return (
    <div className="d-flex">
      {data.kyb_data.company_documents.map((document) => (
        <div
          key={document.id}
          styleName="image-thumb"
          className="position-relative mr-3"
        >
          <a
            href={document.document_image}
            className="d-inline-block"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              style={{objectFit: 'contain'}}
              width="120"
              height="120"
              src={
                document.document_image.indexOf('.pdf') === -1
                  ? document.document_image
                  : document_icon
              }
              alt=""
            />
          </a>
          <div
            styleName="cross"
            className="position-absolute"
            onClick={() => setDocumentToDelete(document)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      ))}
      <div styleName="image-thumb upload-wrapper" className="position-relative">
        {!uploading && (
          <Input
            disabled={
              !hasPermission(user, 'create_admin_company_profile_documents')
            }
            ref={uploadImageRef}
            styleName="hidden-input"
            className="position-absolute"
            type="file"
            id="single"
            onChange={onUpload}
          />
        )}
        <div
          styleName="image-thumb bordered"
          style={{width: 120, height: 120}}
          className="d-flex justify-content-center align-items-center"
        >
          {uploading ? (
            <CircularProgress />
          ) : (
            <FontAwesomeIcon icon={faPlus} color="#bbbbbb" size="2x" />
          )}
        </div>
      </div>
      <DeleteDocumentDialog
        toggle={() => setDocumentToDelete(null)}
        isOpen={documentToDelete}
        onDelete={deleteDocument}
      />
    </div>
  );
};

export default Documents;
