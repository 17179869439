import React from 'react';
import {withRouter} from 'react-router-dom';
import Page from '../UI/Page/Page';
import './kyb.module.css';
import qs from 'query-string';
import {bindActionCreators} from 'redux';
import {replace} from 'connected-react-router';
import {connect} from 'react-redux';
import NewKybForm from './RepresentativeInfo/NewKybForm';
import {Helmet} from 'react-helmet';

class KYBNew extends React.Component {
  state = {
    links: [
      {
        icon: 'profile',
        name: 'Submit Company KYB',
        render: NewKybForm,
        show: () => true
      }
    ]
  };

  navigateTab = (tab) => {
    this.props.replace(
      `${this.props.location.pathname}?${qs.stringify({tab})}`
    );
  };

  render() {
    const active = this.state.links[0];

    return (
      <Page noCard title="KYB">
        <Helmet>
          <title>New Company KYB | Elicon</title>
        </Helmet>
        <div styleName="panel">
          <div styleName="body">
            <h3 styleName="header" className="pb-5 pt-3">
              {active.name}
            </h3>
            <active.render />
          </div>
        </div>
      </Page>
    );
  }
}
function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({replace}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(KYBNew));
