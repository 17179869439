import {apiClientV3} from "../util";

export default {

    get: (search, offset=0, limit=10, sort='date', extra ={}) =>{
        const params = {
            limit,
            offset,
            search,
            completed_onboarding: true,
            ...extra
        }
        if(sort){
            params.ordering = sort
        }
        if(search){
            params.search = search;
        }
        return apiClientV3.get(`/admin/onboarding/companies/`, {params})
    },
    reject: (ids, reason='onboarding reject') => {
        return apiClientV3.post(`/admin/company/reject`, {
            uuids: ids,
            reason
        })
    }
}


