import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import store, {history} from "./store";
import {ConnectedRouter as Router} from "connected-react-router";

const initStore = store();

ReactDOM.render(<Provider store={initStore}>
                    <Router history={history}>
                        <App/>
                    </Router>
                </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
