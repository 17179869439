import {fulfilled, fulfilledState, initState} from "./middleware";
import exchangeFee from "../api/exchangeFee";

export const GET_EXCHANGE_FEE_LIST = "GET_EXCHANGE_FEE_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_EXCHANGE_FEE_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getExchangeFeeList = () => ({
    type: GET_EXCHANGE_FEE_LIST,
    payload: exchangeFee.list()
});
