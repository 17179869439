import React from 'react'
import {renderElement} from "./util";
import classnames from 'classnames';

type VerticalLayoutProps = {
    componentId?: string,
    outterhtmlClass?: string,
    elements?: Array
}

export default class VerticalLayout extends React.Component<VerticalLayoutProps> {
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.elements !== nextProps.elements;
    }

    render() {
        const {componentId, outterhtmlClass, elements} = this.props;
        return (
            <div id={componentId} style={this.props.style} className={classnames('vertical-layout', outterhtmlClass)}>
                {elements && elements.map(renderElement)}
            </div>
        );
    }
}
