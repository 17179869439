import React from 'react';
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUndo} from "@fortawesome/free-solid-svg-icons";
import {getImageS3} from "../../api/util";
import "./kycImageDisplay.module.css"

class KYCImageDisplay extends React.Component {

    state = {
        rotation: 0
    };

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    onRotate = (degrees) => {
        this.setState({ rotation: (this.state.rotation + degrees) % 360});
    };

    render() {

        const {imageData} = this.props;
        return <div styleName="img-wrapper">
                <Button styleName="rotate-button" onClick={() => this.onRotate(90)} ><FontAwesomeIcon icon={faUndo}>&nbsp;</FontAwesomeIcon></Button>
                <img alt="document photo" styleName="img-rotate" src={imageData} style={{transform: "rotate("+ this.state.rotation +"deg)"}} />
            </div>
    };

}

export default KYCImageDisplay;
