import React from 'react';
import Select from '../../UI/Select/Select';
import {
  computeIsRequired,
  getDataProp,
  getInputConfig,
  getInputData,
  getInputError
} from './util';
import * as classnames from 'classnames';
import type {FormContextProps} from '../FormContext';
import {formContextWrapper} from '../FormContext';
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import type {ArrayContextProps} from '../ArrayContext';
import {arrayContextWrapper} from '../ArrayContext';
import CreateNewLabel from './CreateNewLabel.js';
import {getSafeDeep} from '../../Util/state';
import * as _ from 'lodash';
import {apiClient, apiClientV3} from '../../../api/util';
import {AxiosInstance} from 'axios';

type SelectProps = {
  label: string,
  outterhtmlClass: string,
  allowedClear: boolean,
  arrayOptions: {
    displayFieldName: string,
    idFieldName: string,
    loadItemsOnOpen: boolean,
    $ref: string
  },
  scope: {
    $ref: string
  },
  context: FormContextProps,
  array?: ArrayContextProps
};

class SelectControl extends React.Component<SelectProps> {
  state = {
    required: false,
    $ref: '',
    createNew: false,
    initialItem: undefined
  };

  loadingItem = false;

  options = [];

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.scope.$ref !== prevState.$ref ||
      (nextProps.dependsOn &&
        prevState.depValue !==
          getInputData(nextProps.context, nextProps.array, nextProps.dependsOn))
    ) {
      if (nextProps.scope.$ref !== prevState.$ref) {
        prevState.initialItem = undefined;
      }
      return {
        required: computeIsRequired(
          nextProps.context,
          nextProps.array,
          nextProps.scope.$ref
        ),
        $ref: nextProps.scope.$ref,
        depValue: nextProps.dependsOn
          ? getInputData(
              nextProps.context,
              nextProps.array,
              nextProps.dependsOn
            )
          : null,
        initialItem: prevState.initialItem
      };
    }
    return prevState;
  }

  loadAsyncOptions = async (input) => {
    const {
      arrayOptions: {url, params: {queryParam = 'q', limit = 10} = {}}
    } = this.props;
    let client: AxiosInstance;
    if (_.startsWith(url, '/v3/')) {
      client = apiClientV3;
    } else {
      client = apiClient;
    }
    const response = await client.get(url.substring(4), {
      params: {
        [queryParam]: input,
        limit
      }
    });
    this.options = response.data.results;
    return response.data.results;
  };

  getAsyncValue = () => {
    const {
      context,
      array,
      scope: {$ref},
      arrayOptions
    } = this.props;
    const {
      arrayOptions: {url, single}
    } = this.props;
    const dataValue = getInputData(context, array, $ref);
    const item = this.options.find(
      (option) => option[arrayOptions.idFieldName] == dataValue
    );
    if (
      item == null &&
      this.state.initialItem === undefined &&
      single &&
      dataValue &&
      !this.loadingItem
    ) {
      (async () => {
        this.loadingItem = true;
        let client: AxiosInstance;
        if (_.startsWith(url, '/v3/')) {
          client = apiClientV3;
        } else {
          client = apiClient;
        }
        try {
          const response = await client.get(
            single.substring(4).replace('{id}', dataValue)
          );
          this.setState({initialItem: response.data});
        } catch (e) {
          setTimeout(() => {
            this.setState({initialItem: null});
          });
        } finally {
          this.loadingItem = false;
        }
      })();
    }
    return item || this.state.initialItem;
  };

  getValue = () => {
    const {
      context,
      array,
      scope: {$ref},
      arrayOptions,
      allowedClear
    } = this.props;
    const config = getInputConfig(context, array, $ref);
    const dataValue = getInputData(context, array, $ref);

    let label;
    if (config.enum) {
      label = dataValue;
    } else {
      const options = getDataProp(context.data, arrayOptions.$ref) || [];
      let item = options.find(
        (option) => option[arrayOptions.idFieldName] == dataValue
      );

      if (item == null) {
        if (config.default) {
          item = options.find(
            (option) => option[arrayOptions.idFieldName] === config.default
          );
        } else if (!allowedClear) {
          item = options[0];

          let revisedItem = item;
          if (context.data.form) {
            const splitRef = $ref.split('/');
            const refName = splitRef[splitRef.length - 1];
            if (context.data.form[refName]) {
              const contextData = options.find((option) => {
                if (typeof option[arrayOptions.idFieldName] === 'string') {
                  return (
                    option[arrayOptions.idFieldName].toLowerCase() ===
                    context.data.form[refName].toLowerCase()
                  );
                }
                return (
                  option[arrayOptions.idFieldName] ===
                  context.data.form[refName]
                );
              });
              if (contextData) {
                revisedItem = contextData;
              }
            }
          }

          if (item != null) {
            context.onInputChange(
              $ref,
              getSafeDeep(revisedItem, arrayOptions.idFieldName)
            );
          }
        }
      }

      return {
        value: getSafeDeep(item, arrayOptions.idFieldName),
        label: getSafeDeep(item, arrayOptions.displayFieldName)
      };
    }

    if (dataValue !== undefined && label !== undefined) {
      return {
        value: dataValue,
        label: label
      };
    }

    return null;
  };

  getOptions = () => {
    const {
      context,
      array,
      scope: {$ref},
      arrayOptions,
      allowedClear
    } = this.props;
    const config = getInputConfig(context, array, $ref);
    if (config.enum) {
      return config.enum.map((o) => ({value: o, label: o}));
    } else {
      const dataItems = getDataProp(context.data, arrayOptions.$ref) || [];
      if (allowedClear) {
        dataItems.splice(0, {
          [arrayOptions.idFieldName]: null,
          [arrayOptions.displayFieldName]: ''
        });
      }
      return dataItems.map((item) => ({
        value: item[arrayOptions.idFieldName],
        label: item[arrayOptions.displayFieldName]
      }));
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const requiresPos = document.getElementsByName(
      '#/properties/form/properties/merchant_requires_pos_device'
    );

    if (this.state.org == 1 || this.state.org == 2) {
      requiresPos[0].parentElement.parentElement.style.display = 'block';
    } else if (this.state.org == 0) {
      requiresPos[0].parentElement.parentElement.style.display = 'none';
    }

    if (this.state.org !== null) {
      this.setState({org: null});
    }
  }

  render() {
    const {
      outterhtmlClass,
      scope: {$ref},
      context,
      label,
      arrayOptions,
      array,
      allowedClear,
      async
    } = this.props;

    const config = getInputConfig(context, array, $ref);
    const error = getInputError(context, array, $ref);

    if (!getSafeDeep(config, 'enum') && !arrayOptions) {
      return (
        <FormGroup className={outterhtmlClass}>
          <Label className="mb-0 d-flex align-items-center" htmlFor={$ref}>
            {label}
            {this.state.required && <sup className="text-danger">*</sup>}
          </Label>
          <Input />
          <FormFeedback
            valid={!error}
            className={classnames({'d-block': !!error})}
          >
            {error}
          </FormFeedback>
        </FormGroup>
      );
    }

    let inputLabel = label && (
      <label htmlFor={$ref}>
        {label}
        {(this.state.required || this.props.required) && (
          <sup className="text-danger">*</sup>
        )}
      </label>
    );
    if (this.props.createNew) {
      inputLabel = (
        <CreateNewLabel
          onClick={context.data.toggleDialog}
          createNew={this.props.createNew}
        >
          {inputLabel}
        </CreateNewLabel>
      );
    }

    if (async) {
      return (
        <div className="mb-3">
          {inputLabel}
          <Select
            value={this.getAsyncValue()}
            isDisabled={context.isLoading || this.props.scope.disabled}
            placeholder={
              this.props.placeholder ? this.props.placeholder : 'Enter ' + label
            }
            className={classnames(outterhtmlClass)}
            isMulti={this.props.multi}
            name={$ref}
            isClearable={allowedClear}
            getOptionLabel={(item) => item[arrayOptions.displayFieldName]}
            getOptionValue={(item) => item[arrayOptions.idFieldName]}
            defaultOptions
            async
            loadOptions={this.loadAsyncOptions}
            error={error}
            onChange={(e) => {
              context.onInputChange(
                $ref,
                e ? e[arrayOptions.idFieldName] : null
              );

              if (
                $ref ==
                '#/properties/form/properties/primary_address/properties/country'
              ) {
                this.setState({country: e ? e.target.value : null});
              }
            }}
          />
          <FormFeedback
            valid={!error}
            className={classnames({'d-block': !!error})}
          >
            {error}
          </FormFeedback>
        </div>
      );
    }

    return (
      <div className="mb-3">
        {inputLabel}
        <Select
          value={this.getValue()}
          isDisabled={context.isLoading || this.props.scope.disabled}
          placeholder={
            this.props.placeholder ? this.props.placeholder : 'Enter ' + label
          }
          className={classnames(outterhtmlClass)}
          options={this.getOptions()}
          isMulti={this.props.multi}
          name={$ref}
          isClearable={allowedClear}
          error={error}
          onChange={(e) => {
            context.onInputChange($ref, e ? e.value : '');
            if (
              $ref ===
              '#/properties/form/properties/primary_address/properties/country'
            )
              this.setState({val: e ? e.value : null});
            if ($ref === '#/properties/form/properties/organization_type')
              this.setState({org: e ? e.value : null});
          }}
        />
        <FormFeedback
          valid={!error}
          className={classnames({'d-block': !!error})}
        >
          {error}
        </FormFeedback>
      </div>
    );
  }
}

export default arrayContextWrapper(formContextWrapper(SelectControl));
