import React from 'react'

import Button from '../UI/Button/Button'
import Modal from '../UI/Modal/Modal'


export default props => {

    return (
        <Modal title="Are you sure"
            toggle={props.toggle} 
            isOpen={!!props.referral}
            size="xs"
            cancelLabel="Close" 
            submitButton={() => <Button className="ml-3" onClick={() => { props.onSubmit(props.referral); props.toggle() }} color="danger">Delete</Button>}>
            <p>Referral "{props.referral && props.referral.id}" is going to be deleted</p>
        </Modal>
    )
}