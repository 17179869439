import {apiClient, apiClientV3} from "./util";
import {AxiosPromise} from 'axios';
import {transformExchangeFeeData} from "./model/exchangeFee";

export default {
    // List all exchange fees
    list: ():AxiosPromise => {
        return apiClientV3.get(`/admin/fees/exchange`).then(transformExchangeFeeData);
    }
}
