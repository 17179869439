import {apiClientV3} from '../util';

export default {
  get: (id) => apiClientV3.get(`/admin/company/${id}/`),
  update: (id, data, reason) =>
    apiClientV3.put(`/admin/company/${id}/`, {...data, reason: reason}),
  create: (data) => apiClientV3.post(`/admin/company/`, data),
  delete: (id) => apiClientV3.delete(`/admin/company/${id}/`),
  addTag: (orgId, tagId) =>
    apiClientV3.post(`/admin/company/${orgId}/tag/${tagId}/`),
  removeTag: (orgId, tagId) =>
    apiClientV3.delete(`/admin/company/${orgId}/tag/${tagId}/`),
  contacts: (companyID) => ({
    get: (id) =>
      apiClientV3.get(`/admin/company/${companyID}/support-contact/${id}/`),
    update: (id, data) =>
      apiClientV3.patch(
        `/admin/company/${companyID}/support-contact/${id}/`,
        data
      ),
    create: (data) =>
      apiClientV3.post(`/admin/company/${companyID}/support-contact/`, data),
    delete: (id) =>
      apiClientV3.delete(`/admin/company/${companyID}/support-contact/${id}/`)
  }),
  companyAction: (
    id,
    action: 'approve' | 'reject' | 'terminate' | 'pending',
    reason
  ) => apiClientV3.post(`/admin/company/${id}/${action}/`, {reason}),
  companyActionBulk: (
    idList,
    action: 'approve' | 'reject' | 'terminate' | 'pending',
    reason
  ) => apiClientV3.post(`/admin/company/${action}/`, {uuids: idList, reason}),
  regenerateContract: (id) =>
    apiClientV3.get(`/admin/company/${id}/regenerate/`),
  sendEmails: (id, value) =>
    apiClientV3.patch(
      `/admin/company/${id}/mails/${value ? 'enable' : 'disable'}`
    ),
  getSettlementCurrency: (id) =>
    apiClientV3.get(`admin/company/currencies/${id}`),
  getCompanySettlement: (id) =>
    apiClientV3.get(`admin/company/settlement/currency/${id}`),
  setSettlementCurrency: (id, active, pending) =>
    apiClientV3.put(`admin/company/settlement/currency/${id}`, {
      active,
      pending
    }),
  getCryptoFunds: (id) => apiClientV3.get(`admin/company/balance/${id}`),
  getDocumentsCheck: (companyId) =>
    apiClientV3.get(`/admin/company/${companyId}/kyb-re-overview`),
  updateDocumentsCheck: (companyId) =>
    apiClientV3.patch(`/admin/company/${companyId}/kyb-re-overview`),
  getTransactionsCheck: (companyId) =>
    apiClientV3.get(`/admin/company/${companyId}/transaction-check`),
  updateTransactionsCheck: (companyId) =>
    apiClientV3.patch(`/admin/company/${companyId}/transaction-check`),
  getPayfac: () => apiClientV3.get(`/admin/payfac`)
};
