import { apiClientV3 } from "../util";

export default {
  get: (search, start_date, end_date, offset = 0, limit = 10, ordering, country) => {
    let params = {
      search,
      start_date,
      end_date,
      offset,
      limit,
      country,
    };
    if (country == undefined) {
      delete params.country;
    }
    if (ordering) {
      params.ordering = ordering;
    }
    return apiClientV3.get(`/admin/accounting/billing/list`, { params });
  },

  exportCSV: (start_date, end_date, offset = 0, limit = 10, country, uuids) => {
    let url = `${apiClientV3.defaults.baseURL}reports/generate?format_type=0&sync=true&save=false&start_date=${start_date}&end_date=${end_date}&report_type=admin_billing_list_report&offset=${offset}&limit=${limit}`;

    if (uuids.length > 0) {
      url = `${url}&uuids=${uuids.join("&uuids=")}`;
    }

    if (country) {
      url = `${url}&country=${country}`;
    }

    if (window) {
      window.open(url, "_blank");
    }
  },

  getBillingTransactions: (
    start_date,
    end_date,
    search,
    offset = 0,
    limit = 10,
    ordering
  ) => {
    let params = {
      start_date,
      end_date,
      offset,
      limit,
    };
    if (search) {
      params.search = search;
    }
    if (ordering) {
      params.ordering = ordering;
    }
    return apiClientV3.get(`/admin/accounting/billing/overview`, { params });
  },

  getBillingTransactionsTotal: (start_date, end_date) =>
    apiClientV3.get(
      `/admin/accounting/billing/overview/total?start_date=${start_date}&end_date=${end_date}`
    ),

  getDetails: (id, start_date, end_date) =>
    apiClientV3.get(
      `/admin/accounting/billing/details/${id}/stores?start_date=${start_date}&end_date=${end_date}`
    ),

  getDetailsTransactions: (
    id,
    start_date,
    end_date,
    search,
    offset = 0,
    limit = 10,
    ordering
  ) => {
    let params = {
      start_date,
      end_date,
      offset,
      limit,
    };
    if (search) {
      params.search = search;
    }
    if (ordering) {
      params.ordering = ordering;
    }
    return apiClientV3.get(`/admin/accounting/billing/details/${id}`, {
      params,
    });
  },

  download: (data) =>
    apiClientV3.post(`/admin/accounting/billing/download`, data),

  markCompleted: (data) =>
    apiClientV3.post(`/admin/accounting/payout/manual`, data),

  markNotCompleted: (data) =>
    apiClientV3.delete(`/admin/accounting/payout/manual`, { data: data }),
};
