import React, {Fragment} from "react";
import user from "../../api/user";
import SideNav from "../SideNav/SideNav";
import {getSafeDeep} from "../Util/state";
import EditUserDialog from "../User/EditUserDialog";
// import ResetPasswordDialog from "../User/ResetPasswordDialog";
// import SuspendUserDialog from "../User/SuspendUserDialog";
import * as immutable from "immutable";
import * as qs from "qs";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router";
import WithLoading from "../Util/HOC/WithLoading";
import InfoForm from "../InfoForm/InfoForm";
import question from "../../api/question";
import {infoField, infoFieldCategory} from "../Util/infoForm";
import ListBody from "../Util/ListView/ListBody/ListBody";
import {listHeader} from "../Util/listView";

import Page from '../UI/Page/Page';
import { Card } from '@material-ui/core';
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import { notifyError } from "../Util/notification";
import {Helmet} from "react-helmet";

const InfoFormWithLoading = WithLoading(InfoForm);
const ListBodyWithLoading = WithLoading(ListBody);

const loyaltyQuestionInfoFields = [
    infoFieldCategory("Question", [
        infoField("value", "Question"),
        infoField("repeatable", "Reoccurring", "bool"),
        infoField("predefined_answers", "Predefined answers", "answerList")
    ]),
    infoFieldCategory("Answers", [])
];

const loyaltyQuestionAnswerListHeaders = [
    listHeader('Answer', 'value', false),
    listHeader('Payment', 'payment_id', false),
    listHeader('User ID', 'user_id', false),
    listHeader('Claimed amount', 'claimed_amount', false),
];

class LoyaltyQuestionInfo extends React.Component {

    state = {
        question: {},
        isLoading: true
    };


    loadData = async() => {
        try{
            const {match: {params: {id}}} = this.props;
            this.setState({ isLoading: true });
            const {data} = await question.get(id);
            this.setState({ question: data, isLoading: false });
        }catch(err){
            notifyError(err.message)
            this.setState({ isLoading: false });
        }
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
    }

    renderAdditionalComponents = () => {
        const isSuperAdmin = false;
        return <Fragment>
        </Fragment>
    };

    render() {
        const {user, isLoading,question} = this.state;
        const answers = question.answers ? question.answers : [];
        const {documentTypes} = this.props;
        
        return (
            <Fragment>
                <Helmet>
                    <title>Loyalty Questions | Elicon</title>
                </Helmet>
                <Page>
                    <Card>
                        <ListHeader 
                            type="Question"
                            title="Question overview"
                            question={question}
                        />
                        <ListBodyWithLoading
                            isLoading={isLoading}
                            headers={loyaltyQuestionAnswerListHeaders}
                            data={answers}
                            pagination
                        />
                    </Card>
                </Page>
            </Fragment>
        );
    };

}

function mapState(state) {
    return {
        query: immutable.Map(qs.parse(state.router.location.search, { ignoreQueryPrefix: true })),
    }
}

export default connect(mapState)(withRouter(LoyaltyQuestionInfo));
