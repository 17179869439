import React from 'react';

import DynamicForm from '../DynamicForm/DynamicForm'

const EditRoleDialog = props => {

    let form = {}

    if(props.role){
        form = {
            ...props.role
        }
    }

    return (
        <DynamicForm 
            onSubmit={props.onSubmit}
            uiSchema={require('./roleUi.json')}
            dataSchema={require('./roleData.json')}
            initData={{ form,  permissions: props.permissions }}
            roleId='code'
            roleDisplay='display_name'  
            modal={{
                isLoading: props.isLoading,
                title: "Edit Role",
                isOpen: !!props.role,
                size: 'lg',
                cancelLabel: 'Close',
                toggle: props.toggle
            }}
        />
    );
};

export default EditRoleDialog;