import React from 'react'
import {
    getProp,
    getDataProp,
    getSafe,
    computeIsRequired,
    getInputConfig,
    getInputData,
    getInputError,
    setProp
} from "./util";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import Button from '../../UI/Button/Button'
import ReactSelect from 'react-select';
import 'react-datepicker/dist/react-datepicker.min.css';
import * as classnames from "classnames";
import {formContextWrapper} from "../FormContext";
import {arrayContextWrapper} from "../ArrayContext";
import type {FormContextProps} from "../FormContext";
import type {ArrayContextProps} from "../ArrayContext";
import {deepCopy, getSafeDeep, setSafeDeep} from "../../Util/state";

type InputControlProps = {
    label: string,
    outterhtmlClass: string,
    readOnly: boolean,
    allowedClear?: boolean,
    scope: {
        $ref: string
    },
    context: FormContextProps,
    array?: ArrayContextProps
}

class InputControl extends React.Component<InputControlProps> {
    state = {
        required: false,
        $ref: ""
    };

    shouldComponentUpdate(nextProps, nextState){
        const context = this.props.context;
        const nextContext = nextProps.context;
        const $ref = this.props.scope.$ref;
        const next$ref = nextProps.scope.$ref;
        const array = this.props.array;
        const nextArray = nextProps.array;

        return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) 
    }

    copy = async () => {

        const {context, stateRefs} = this.props;

        for(let stateRef of stateRefs){
            await context.onInputChange(stateRef.to, getInputData(context, null, stateRef.from));
        }
        this.assureChanges()
        context.refreshForm();
    };

    componentDidMount(){
        this.applyChanges()
    }

    componentDidUpdate(){
        this.applyChanges()
    }

    handle = async () => {
        await this.props.context.onInputChange(this.props.scope.$ref, !this.getValue())
        this.applyChanges()
    }
    
    applyChanges = () => {
        const value = this.getValue()
        
        if(value){
            this.copy()
            this.props.context.setDisabled(this.props.disabled)
        }else{
            this.props.context.setDisabled([])
        }
    }

    assureChanges = async () => {

        const { context, array, stateRefs } = this.props

        if(this.getValue()){
            for(let ref of stateRefs){
                if( getInputData(context, array, ref.to) != getInputData(context, array, ref.from)){
                    await context.onInputChange(ref.to, getInputData(context, null, ref.from));   
                }
            }
        }
    }

    getValue = () => {
        const {context, array, scope: {$ref}, arrayOptions} = this.props;
        const config = getInputConfig(context, array, $ref);
        const dataValue = getInputData(context, array, $ref);
        if(dataValue === undefined) return !!config.default

        return !!dataValue;
    };


    render() {
        const {context, scope: { $ref },  label, refs, outterhtmlClass} = this.props;
        return (
            <label 
                onClick={this.handle}
                className={outterhtmlClass} 
                htmlFor={$ref ? $ref : this.props.name}>
                <input name={$ref ? $ref : this.props.name}
                    className="mr-1"
                    type="checkbox" 
                    checked={this.getValue()} 
                    onChange={() => {}} ></input>
                {label}
            </label>
        )
    }
}

export default arrayContextWrapper(formContextWrapper(InputControl))
