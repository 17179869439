import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createMessage, notifyError} from '../Util/notification';
import DynamicForm from '../DynamicForm/DynamicForm';
import user from '../../api/user';
import {parseErrors} from '../Util/form';
import addUserDataSchema from './schema/userData';
import addUserUiSchema from './schema/userUi';
import {getUserList} from '../../redux/user';
import rolesApi from '../../api/V3/roles';

class AddUserDialog extends React.Component {
  state = {
    errors: undefined,
    isLoading: false,
    roles: [],
    regions: []
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {}

  onSubmit = async (data) => {
    try {
      this.setState({isLoading: true});
      const userRes = await user.create(data);
      if (data.roles[0] && data.roles[0].actions) {
        await rolesApi.assign(userRes.data.id, data.roles[0].actions);
      }
      createMessage('Successfully submitted');
      this.props.getUserList();
      this.props.reloadData();
      this.setState({isLoading: false});
      this.props.toggle();
    } catch (e) {
      notifyError(e.message);
      this.setState({isLoading: false});
      this.setState({errors: parseErrors(e)});
    }
  };

  loadData = async () => {
    this.setState({isLoading: true});

    try {
      let {data: roles} = await rolesApi.getAllRoles();
      roles = [
        {
          group: 'roles',
          actions: roles
        }
      ];
      let {data: regions} = await rolesApi.getRegions();
      this.setState({roles, regions, isLoading: false});
    } catch (err) {
      this.setState({isLoading: false});
      notifyError(err.message);
    }
  };

  render() {
    const {toggle, isOpen, tiers} = this.props;
    const {errors} = this.state;
    if (!isOpen) return '';

    const modal = {
      isOpen,
      toggle,
      title: 'Add User',
      size: 'lg',
      isLoading: this.state.isLoading
    };

    return (
      <DynamicForm
        isLoading={this.state.isLoading}
        modal={modal}
        uiSchema={addUserUiSchema}
        dataSchema={addUserDataSchema}
        initData={{
          form: {is_login_blocked: false, roles: []},
          roles: this.state.roles,
          regions: this.state.regions
        }}
        onSubmit={(data) => {
          this.onSubmit(data.form);
        }}
        errors={errors}
      />
    );
  }
}

function mapActions(dispatch) {
  return bindActionCreators({getUserList}, dispatch);
}

export default withRouter(connect(null, mapActions)(AddUserDialog));
