import React, {Fragment, useEffect, useState} from 'react';
import {deepCopy, getSafeDeep} from '../../../../Util/state';
import {createMessage, notifyError} from '../../../../Util/notification';
import companyKyb from '../../../../../api/companyKyb';
import Button from '../../../../UI/Button/Button';
import DeleteOwnerDialog from '../../../../KYB/BeneficialOwners/DeleteOwnerDialog';
import Modal from '../../../../UI/Modal/Modal';
import {
  infoField,
  infoFieldCategory,
  renderInfoField
} from '../../../../Util/infoForm';
import {CardContent, CardHeader, ListItemText} from '@material-ui/core';
import More from '../../../../UI/More/More';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {Delete, Edit} from '@material-ui/icons';
import {Col, Row} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import * as _ from 'lodash';
import {parseErrors} from '../../../../Util/form';
import DynamicForm from '../../../../DynamicForm/DynamicForm';
import dataSchema from '../../schema/contactInformationData.json';
import uiSchema from '../../schema/contactInformationUi.json';
import {isFunction} from '../../../../Util/types';
import {getCountryData} from '../../../../Util/countryList';
import company from '../../../../../api/V3/company';
import {
  hasPermission,
  mapRoleState,
  PermissionGuard
} from '../../../../Util/role-helpers';
import {connect} from 'react-redux';

const countries = getCountryData();

class DeleteContactDialog extends React.Component {
  render() {
    const {toggle, isOpen, name, onDelete} = this.props;
    if (!isOpen) return '';

    return (
      <Modal
        toggle={toggle}
        isOpen={isOpen}
        centered
        title="Delete Contact"
        cancelButton={() => (
          <Button color="secondary" className="mr-3" onClick={toggle}>
            Cancel
          </Button>
        )}
        submitButton={() => (
          <Button color="danger" onClick={onDelete}>
            Delete
          </Button>
        )}
        size="xs"
      >
        <div className="text-center mb-5 mt-4">
          Are you sure you want to delete contact <strong>{name}</strong> ?
        </div>
      </Modal>
    );
  }
}

const infoFields = [
  infoFieldCategory('', [
    infoField('first_name', 'First Name'),
    infoField('last_name', 'Last Name'),
    infoField('email', 'Email'),
    infoField(
      (data) => `${data.country_phone_prefix} ${data.national_number}`,
      'Phone'
    )
  ])
];

const Contact = ({
  data,
  requiredProps,
  onEditClicked,
  onDeleteClicked,
  user
}) => {
  const items = [];
  if (hasPermission(user, 'change_contact')) {
    items.push({
      handler: () => onEditClicked(data),
      render: () => (
        <Fragment>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText disableTypography primary="Edit" />
        </Fragment>
      )
    });
  }
  if (hasPermission(user, 'delete_contact')) {
    items.push({
      handler: () => onDeleteClicked(data),
      render: () => (
        <Fragment>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText disableTypography primary="Delete" />
        </Fragment>
      )
    });
  }
  return (
    <div className="mt-5 border">
      <CardHeader
        className="border-bottom"
        title={`${data.first_name} ${data.last_name}`}
        action={<More items={items} />}
      />
      <CardContent>
        {infoFields
          .filter((it) => it.visible(data))
          .map((category, i) => (
            <Row key={i}>
              <Col xs={12} className={i ? 'mt-4' : ''}>
                <Typography variant="h6">{category.label}</Typography>
              </Col>
              {category.items.map((item, j) => (
                <Col key={j} sm={6} className="my-2">
                  <div className="form-label text-muted">{item.label}</div>
                  {renderInfoField(item, data, requiredProps)}
                </Col>
              ))}
            </Row>
          ))}
      </CardContent>
    </div>
  );
};

const EditContact = ({owner, toggle, companyId, onDataChanged}) => {
  const [errors, setErrors] = useState({});
  useEffect(() => {
    setErrors({});
  }, [owner]);
  const contactApi = company.contacts(companyId);
  const onSubmit = async ({form}) => {
    try {
      if (owner.id !== undefined) {
        form.country_phone_prefix =
          form.country_phone_prefix[0] == '+'
            ? form.country_phone_prefix
            : `+${form.country_phone_prefix}`;
        const response = await contactApi.update(owner.id, form);
        onDataChanged(response.data);
      } else {
        form.country_phone_prefix =
          form.country_phone_prefix[0] == '+'
            ? form.country_phone_prefix
            : `+${form.country_phone_prefix}`;
        const response = await contactApi.create(form);
        onDataChanged(response.data);
      }
      toggle();
    } catch (e) {
      console.error(e);
      setErrors(parseErrors(e));
    }
  };
  return !!owner ? (
    <DynamicForm
      dataSchema={dataSchema}
      uiSchema={uiSchema}
      modal={{
        title: getSafeDeep(owner, 'id') ? 'Edit Contact' : 'Create Contact',
        isOpen: !!owner,
        size: 'md',
        toggle
      }}
      clearOnChange
      errors={errors}
      onSubmit={onSubmit}
      initData={{
        form: {
          ...owner,
          country_phone_prefix:
            getSafeDeep(owner, 'country_phone_prefix') || '+386'
        },
        countries
      }}
    />
  ) : null;
};

const CompanyContacts = ({organization, dataUpdated, user}) => {
  const [contacts, setContacts] = useState([]);
  const [editContact, setEditContact] = useState();
  const [deleteContact, setDeleteContact] = useState();

  useEffect(() => {
    setContacts(organization.contacts);
  }, [organization.contacts]);

  const onEdit = (newData) => {
    if (editContact.id) {
      const index = organization.contacts.findIndex(
        (c) => c.id === editContact.id
      );
      organization.contacts[index] = newData;
    } else {
      organization.contacts.splice(0, 0, newData);
    }
    dataUpdated(organization);
  };

  const deleteContactClicked = async () => {
    try {
      await company.contacts(organization.id).delete(deleteContact.id);
      const index = organization.contacts.findIndex(
        (c) => c.id === deleteContact.id
      );
      organization.contacts.splice(index, 1);
      dataUpdated(organization);
      setDeleteContact(null);
    } catch (e) {
      console.error(e);
      notifyError(getSafeDeep(e, 'response.data.message', e.message));
    }
  };
  return (
    <>
      <PermissionGuard code={'create_contact'}>
        <Button color="outline-primary" onClick={() => setEditContact({})}>
          Add
        </Button>
      </PermissionGuard>
      {contacts.map((contact, i) => (
        <Contact
          user={user}
          key={JSON.stringify({index: i, ...contact})}
          data={contact}
          onEditClicked={setEditContact}
          onDeleteClicked={setDeleteContact}
        />
      ))}
      <EditContact
        companyId={organization.id}
        owner={editContact}
        toggle={() => setEditContact(null)}
        onDataChanged={onEdit}
      />
      <DeleteContactDialog
        toggle={() => setDeleteContact(null)}
        name={getSafeDeep(deleteContact, 'first_name')}
        isOpen={deleteContact != null}
        onDelete={deleteContactClicked}
      />
    </>
  );
};

export default connect(mapRoleState)(CompanyContacts);
