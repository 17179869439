import WithLoading from '../../Util/HOC/WithLoading';
import ListBody from '../../Util/ListView/ListBody/ListBody';
import React, {Fragment} from 'react';
import qs from 'query-string';
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import {listHeader, renderFullDate, renderLink} from '../../Util/listView';
import {
  getKybInfoRoute,
  getOrganizationInfoRoute,
  routes
} from '../../Util/routing';
import Badge from '../../UI/Badge/Badge';
import {Link, withRouter} from 'react-router-dom';
import {IconButton} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import Page from '../../UI/Page/Page';
import Card from '../../UI/Card/Card';
import ListHeader from '../../Util/ListView/ListHeader/ListHeader';
import AddOrganizationDialog from '../../Organization/AddOrganizationDialog';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import companyKyb from '../../../api/companyKyb';
import {createMessage} from '../../Util/notification';
import {getSafeDeep} from '../../Util/state';
import {Helmet} from 'react-helmet';
import moment from 'moment';

const ListBodyWithLoading = WithLoading(ListBody);

export const KYB_STEPS = [
  'Registered',
  'Representative Saved',
  'Documents Saved',
  'Completed',
  'Call Scheduled',
  'Call Missed',
  'Declined',
  'Approved'
];

export const COMPANY_TYPES = ['Sole Proprietor', 'Company'];

class KybList extends React.Component {
  state = {
    isLoading: true,
    addKybDialogOpen: false,
    count: 0,
    rowsPerPage: 10,
    selected: [],
    sort: '-created'
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(oldProps, prevState) {
    const {query} = this.props;
    const {query: oldQuery} = oldProps;
    if (
      JSON.stringify(query) !== JSON.stringify(oldQuery) ||
      this.state.rowsPerPage != prevState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  toggleDialog = (dialogName) => {
    const fieldName = `${dialogName}DialogOpen`;
    this.setState({[fieldName]: !this.state[fieldName]});
  };

  loadData = async () => {
    this.setState({isLoading: true});
    try {
      const query = this.props.query;
      const page = query.page || 0;
      const search = query.search;
      const filter = query.filter || '1,2,3,4';
      const order = query.order || '-created';
      const {
        data: {count, results: data}
      } = await companyKyb.list(
        filter,
        search,
        order,
        page * this.state.rowsPerPage,
        this.state.rowsPerPage
      );
      this.setState({count, data});
      if (query.page > Math.ceil(count / this.state.rowsPerPage))
        query.page = 0;
    } catch (e) {
      console.error(e);
      createMessage(
        getSafeDeep(e, 'response.data.message', e.message),
        'error'
      );
    } finally {
      this.setState({isLoading: false});
    }
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    let currentItem =
      qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).page *
        this.state.rowsPerPage || 0 + 1;
    let newPage = Math.floor(currentItem / newRowsPerPage);
    this.setState({rowsPerPage: newRowsPerPage});
    const query = {...this.props.query};
    query.page = newPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  requestSort = (sort) => {
    this.setState({sort});
    const query = {...this.props.query};
    query.page = 0;
    query.order = sort;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  headers = () => {
    return [
      listHeader('Name', 'full_name', true, (item) =>
        renderLink(item.full_name, {
          pathname: getKybInfoRoute(item.id),
          state: item
        })
      ),
      listHeader(
        'KYB Status',
        'kyb_status',
        true,
        (item) => KYB_STEPS[item.kyb_status]
      ),
      listHeader('KYB Approved Date', 'kyb_approved_at', true, (item) => {
        if (!item.kyb_approved_at) return '-';
        return renderFullDate(item.kyb_approved_at);
      }),
      listHeader('KYB Modified Date', 'kyb_modified_at', true, (item) => {
        if (!item.kyb_modified_at) return '-';
        return renderFullDate(item.kyb_modified_at);
      }),
      listHeader(
        'Actions',
        'actions',
        false,
        (item) => (
          <Link to={getKybInfoRoute(item.id)}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
        ),
        undefined,
        undefined,
        'center'
      )
    ];
  };

  onSelectedChange = (status) => {
    const query = {...this.props.query};
    delete query.page;
    if (status) {
      query.filter = status.value;
    } else {
      delete query.filter;
    }
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  render() {
    const {isLoading, data, rowsPerPage, count} = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>KYB List | Elicon</title>
        </Helmet>
        <Page permission={'list_company_kyb'}>
          <Card>
            <ListHeader
              type="KYBList"
              title="Company KYB List"
              searchTitle="Search"
              selected={this.props.query.filter}
              onSelectedChange={this.onSelectedChange}
              actionHandler={() =>
                this.props.history.push(routes.createCompanyKYB)
              }
              rejectHandler={() => {
                alert('Reject Modal');
                console.error('Reject Modal');
              }}
            />
            <ListBodyWithLoading
              headers={this.headers()}
              sortField={this.state.sort}
              requestSort={this.requestSort}
              isLoading={isLoading}
              data={data}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
          </Card>
        </Page>
        <AddOrganizationDialog
          isOpen={this.state.addKybDialogOpen}
          toggle={() => this.toggleDialog('addOrganization')}
          reloadData={() => this.loadData()}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(KybList));
