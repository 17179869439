import React, { Fragment } from 'react'
import {
    listHeader,
    renderActivityStatus,
    renderCheckmark,
    renderFullDate,
    renderLink
} from '../../../../../Util/listView'
import More from '../../../../../UI/More/More'
import { Card, ListItemIcon, ListItemText, withStyles } from '@material-ui/core'
import { Done, Close, Link, Search } from '@material-ui/icons'
import ListBody from '../../../../../Util/ListView/ListBody/ListBody'
import WithLoading from '../../../../../Util/HOC/WithLoading'
import { linkAccountant } from '../../../../../../api/source'
import qs from "query-string";
import { getName } from 'country-list'
import LinkPointOfSaleDialog from '../../../../../PoS/Others/LinkPointOfSaleDialog'
import { getPosInfoRoute } from "../../../../../Util/routing";
import { getSafeDeep } from "../../../../../Util/state";
import { getCountryName } from "../../../../../Util/countryList";
import { approvalStates } from "../../../../../PoS/PoSList/PoSList";
import stores from "../../../../../../api/V3/stores";
import { createMessage } from "../../../../../Util/notification";
import { connect } from "react-redux";
import { hasPermission, mapRoleState } from "../../../../../Util/role-helpers";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { withRouter } from "react-router";
import { renderDoneClear } from '../../../../../Util/listView'
const ListBodyWithLoading = WithLoading(ListBody)

class PointsOfSale extends React.Component {

    state = {
        isLoading: false,
        rowsPerPage: 10,
        count: 0,
        isOpen: false,
        pos: {},
        data: []
    }

    getActions = (data) => {

        const StyledListItemText = withStyles({
            root: {
                fontSize: "16px!important"
            }
        })(ListItemText)

        const StyledIcon = (Icon) => {
            return withStyles({
                root: {
                    fontSize: "24px"
                }
            })(Icon)
        }

        const StyledListItemIcon = withStyles({
            root: {
                minWidth: 24,
                marginRight: "16px"
            }
        })(ListItemIcon)

        const StyledSearch = StyledIcon(Search)
        const StyledLink = StyledIcon(Link)

        const items = [{
            handler: () => this.props.push(getPosInfoRoute(data.id)),
            render: () => (<Fragment><StyledListItemIcon><StyledSearch /></StyledListItemIcon><StyledListItemText disableTypography primary="Details" /></Fragment>)
        }]

        if (hasPermission(this.props.user, 'company_add_source')) {
            items.push({
                handler: () => this.toggleLink(data),
                render: () => (<Fragment><StyledListItemIcon><StyledLink /></StyledListItemIcon><StyledListItemText disableTypography primary="Link" /></Fragment>)
            })
        }

        return {
            items
        }
    }

    toggleLink = (pos) => {
        this.setState(prev => ({
            isOpen: !prev.isOpen,
            pos
        }))
    }

    listHeaders = [
        listHeader('Name', 'full_name', true, (item) => renderLink(item.full_name, getPosInfoRoute(item.id))),
        listHeader('Brand', 'franchise__name', true, (item) => getSafeDeep(item, 'franchise.name', 'N\\A')),
        listHeader('Store Type', "source_type", false, (data) => { return renderDoneClear(!!data.source_type) }, undefined, undefined, 'center'),
        listHeader('City', "city", true),
        listHeader('Country', "country", true, (data) => {
            const country = data.country || getSafeDeep(data, 'company.0.country')
            if (country) {
                return getCountryName(country)
            }
            return ""
        }),
        listHeader('Payment Platform', "integrator__name", true, (data) => getSafeDeep(data, 'payment_platform', "")),
        listHeader('Created', "created", true, (data) => renderFullDate(data.created)),
        listHeader('Status', "approval_state", false, (data) => approvalStates[data.approval_state]),
        listHeader('Activity Status', "activity_status", false, (data) => renderActivityStatus(data.activity_status)),
        listHeader('Actions', 'actions', false, (data) => (<More {...this.getActions(data)} />), undefined, undefined, "center")
    ]


    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = { ...this.props.query };
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify({ ...prevProps.query, rows: prevState.rowsPerPage }) !==
            JSON.stringify({ ...this.props.query, rows: this.state.rowsPerPage })) {
            this.loadData()
        }
    }

    onSortChange = (field) => {
        const query = { ...this.props.query }
        query.sort = field
        this.props.push(`${this.props.location.pathname}?${qs.stringify(query)}`)
    }

    loadData = async () => {
        let { page, search, sort, pageSize, ...filter } = { ...this.props.query }
        filter.organization_id = this.props.organization.id
        this.setState({ isLoading: true })
        try {
            const response = await stores.list(this.state.rowsPerPage, (page || 0) * this.state.rowsPerPage, search, sort, filter)
            this.setState({
                count: response.data.count,
                data: response.data.results
            })
        } catch (e) {
            createMessage(getSafeDeep(e, "response.data.message", getSafeDeep(e, "response.data", e.message)), 'error')
        } finally {
            this.setState({ isLoading: false })
        }
    }


    render() {

        const { data, isLoading, count, rowsPerPage } = this.state

        return (
            <Fragment>
                <ListBodyWithLoading
                    padding="8px 16px"
                    headers={this.listHeaders}
                    isLoading={isLoading}
                    data={data}
                    count={count}
                    sortField={this.props.query.sort}
                    requestSort={this.onSortChange}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
                <LinkPointOfSaleDialog
                    source={this.state.pos}
                    toggle={this.toggleLink}
                    isOpen={this.state.isOpen} />
            </Fragment>
        )
    }
}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        location: state.router.location,
        user: state.user.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(PointsOfSale));
