import React from 'react';
import {
  computeIsRequired,
  getDataProp,
  getInputConfig,
  getInputData,
  getInputError
} from './util';
import {formContextWrapper} from '../FormContext';
import type {FormContextProps} from '../FormContext';
import type {ArrayContextProps} from '../ArrayContext';
import {arrayContextWrapper} from '../ArrayContext';

import CheckBox from '../../UI/CheckBox/CheckBox';

type SelectProps = {
  label: string,
  outterhtmlClass: string,
  allowedClear: boolean,
  arrayOptions: {
    displayFieldName: string,
    idFieldName: string,
    loadItemsOnOpen: boolean,
    $ref: string
  },
  scope: {
    $ref: string
  },
  context: FormContextProps,
  array?: ArrayContextProps
};

class Boolean extends React.Component<SelectProps> {
  state = {
    required: false,
    $ref: ''
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.scope.$ref !== prevState.$ref) {
      return {
        required: computeIsRequired(
          nextProps.context,
          nextProps.array,
          nextProps.scope.$ref
        ),
        $ref: nextProps.scope.$ref
      };
    }
    return prevState;
  }

  getValue = () => {
    const {
      context,
      array,
      scope: {$ref}
    } = this.props;
    const config = getInputConfig(context, array, $ref);
    const dataValue = getInputData(context, array, $ref);

    if (dataValue === undefined) return config.default;
    return dataValue;
  };

  shouldComponentUpdate(nextProps, nextState) {
    const context = this.props.context;
    const nextContext = nextProps.context;
    const $ref = this.props.scope.$ref;
    const next$ref = nextProps.scope.$ref;
    const array = this.props.array;
    const nextArray = nextProps.array;
    try {
      return (
        getInputData(context, array, $ref) !==
          getInputData(nextContext, nextArray, next$ref) ||
        getDataProp(context.data, this.props.arrayOptions.$ref) !==
          getDataProp(nextContext.data, nextProps.arrayOptions.$ref) ||
        getInputError(context, array, $ref) !==
          getInputError(nextContext, nextArray, next$ref) ||
        $ref !== next$ref
      );
    } catch (e) {
      return true;
    }
  }

  render() {
    const {
      outterhtmlClass,
      scope: {$ref},
      context,
      label,
      array,
      scope
    } = this.props;

    return (
      <CheckBox
        label={label}
        outterhtmlClass={outterhtmlClass}
        $ref={$ref}
        onClick={() => context.onInputChange($ref, !this.getValue())}
        getValue={this.getValue}
        disabled={scope.disabled}
      />
    );
  }
}

export default arrayContextWrapper(formContextWrapper(Boolean));
