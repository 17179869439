import React, {Fragment, useState} from 'react';
import qs from 'query-string';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import Page from '../UI/Page/Page';
import Card from '../UI/Card/Card';
import Chip from '../UI/Chip/Chip';
import More from '../UI/More/More';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';

import WithLoading from '../Util/HOC/WithLoading';
import ListHeader from '../Util/ListView/ListHeader/ListHeader';
import ListBody from '../Util/ListView/ListBody/ListBody';
import {listHeader, getStyledActions} from '../Util/listView';

import {Edit, Delete} from '@material-ui/icons';

import AddRoleDialog from './AddRoleDialog';
import EditRoleDialog from './EditRoleDialog';

import {notifyError} from '../Util/notification';

import roles from '../../api/V3/roles';
import {hasPermission} from '../Util/role-helpers';
import {Helmet} from 'react-helmet';

const ListBodyWithLoading = WithLoading(ListBody);

const DeleteDialog = (props) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Modal
      title="Are you sure?"
      toggle={props.toggle}
      isOpen={!!props.role}
      size="xs"
      isLoading={isLoading}
      cancelLabel="Close"
      submitButton={() => (
        <Button
          isLoading={isLoading}
          className="ml-3"
          onClick={() => {
            setLoading(true);
            roles
              .deleteRole(props.role.id)
              .then((res) => {
                setLoading(false);
                props.toggle();
                props.reloadData();
              })
              .catch((err) => {
                console.error(err);
                setLoading(false);
                notifyError(err.message);
                props.reloadData();
                props.toggle();
              });
          }}
          color="danger"
        >
          Delete
        </Button>
      )}
    >
      <p>Role {props.role && props.role.name} is going to be deleted</p>
    </Modal>
  );
};

const contained = (item, items, field) => {
  for (let i = 0; i < items.length; i++) {
    if (item[field] == items[i][field]) {
      return i;
    }
  }
  return -1;
};

const groupPermissions = (data) => {
  let permissions = [];
  data.forEach((item) => {
    const index = contained(item, permissions, 'group');
    if (index != -1) {
      permissions[index].actions.push(item);
    } else {
      permissions.push({group: item.group, actions: [item]});
    }
  });
  return permissions;
};

class Roles extends React.Component {
  state = {
    isLoading: false,
    addNew: false,
    addNewLoading: false,
    deleteRole: null,
    editRole: null,
    count: 0,
    rowsPerPage: 10,
    sort: '',
    editLoading: false,
    data: [],
    search: '',
    permissions: []
  };

  componentDidMount() {
    this.loadData();

    roles.getPermissions().then((res) => {
      this.setState({permissions: groupPermissions(res.data)});
    });
  }

  componentDidUpdate(oldProps, oldState) {
    const {query, auth} = this.props;
    const {query: oldQuery, auth: oldAuth} = oldProps;
    if (
      JSON.stringify(query) !== JSON.stringify(oldQuery) ||
      this.state.rowsPerPage != oldState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  editRole = ({form}) => {
    let permissions = [];
    form.permissions.forEach((item) => {
      permissions = permissions.concat(item.actions);
    });
    this.setState({editLoading: true});
    roles
      .editRole(form.id, {
        name: form.name,
        permissions
      })
      .then((res) => {
        this.setState({editLoading: false, editRole: null});
        this.loadData();
      })
      .catch((err) => {
        console.error(err);
        this.setState({editLoading: false, editRole: null});
        notifyError(err.message);
      });
  };

  loadData = async () => {
    // const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const query = this.props.query;
    const page = query.page || 0;
    const search = query.search;
    const sort = query.sort;
    this.setState({isLoading: true, search, sort});
    const {
      data: {count, results: data}
    } = await roles.getRoles(
      search,
      page * this.state.rowsPerPage,
      this.state.rowsPerPage,
      sort
    );
    this.setState({count, data, isLoading: false});
    if (query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
  };

  createNewRole = ({form}) => {
    let permissions = [];
    form.permissions.forEach((item) => {
      permissions = permissions.concat(item.actions);
    });
    this.setState({addNewLoading: true});
    roles
      .createNew({
        name: form.name,
        permissions
      })
      .then((res) => {
        this.setState({addNewLoading: false, addNew: null});
        this.loadData();
      })
      .catch((err) => {
        console.error(err);
        this.setState({addNewLoading: false, addNew: null});
        notifyError(err.message);
      });
  };

  renderPermissions = (data) => {
    return (
      <div className="d-flex">
        {data.permissions.slice(0, 3).map((item) => {
          return <Chip text={item.display_name} key={`${item.code}`} />;
        })}
        {data.permissions.length > 3 ? (
          <Chip
            color="#757575"
            text={`+ ${data.permissions.length - 3} More`}
          />
        ) : null}
        {data.permissions.length == 0 ? (
          <Chip color="#757575" text={`No Permissions`} />
        ) : null}
      </div>
    );
  };

  getStyledActions = (data) => {
    const items = [];
    if (hasPermission(this.props.user, 'change_role')) {
      items.push({
        handler: () =>
          this.setState({
            editRole: {...data, permissions: groupPermissions(data.permissions)}
          }),
        Icon: Edit,
        text: 'Edit'
      });
    }

    if (hasPermission(this.props.user, 'delete_role')) {
      items.push({
        handler: () => this.setState({deleteRole: data}),
        Icon: Delete,
        text: 'Delete'
      });
    }

    return getStyledActions(items);
  };

  listHeaders = [
    listHeader('Role', 'name', true, (data) => (
      <span style={{fontWeight: 'bold'}}>{data.name}</span>
    )),
    listHeader('Permissions', 'permisions', false, (data) =>
      this.renderPermissions(data)
    ),
    listHeader(
      'Actions',
      '',
      false,
      (data) => {
        return data.type == 'system' || data.type == 'merchant' ? null : (
          <More items={this.getStyledActions(data)} />
        );
      },
      undefined,
      undefined,
      'center'
    )
  ];

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    let currentItem =
      qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).page *
        this.state.rowsPerPage || 0 + 1;
    let newPage = Math.floor(currentItem / newRowsPerPage);
    this.setState({rowsPerPage: newRowsPerPage});
    const query = {...this.props.query};
    query.page = newPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  requestSort = (sort) => {
    this.setState({sort});
    const query = {...this.props.query};
    query.page = 0;
    query.sort = sort;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  requestSearch = (e) => {
    e.preventDefault();
    const query = {...this.props.query};
    query.page = 0;
    query.search = this.state.search;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Roles | Elicon</title>
        </Helmet>
        <Page title="Roles" permission={'view_all_roles'}>
          <Card>
            <ListHeader
              type="OnlySearch"
              title="Role List"
              actionLabel="Add Role"
              searchTitle="Search"
              permission="create_role"
              searchValue={this.state.search}
              onChange={(e) => this.setState({search: e.target.value})}
              onSubmit={this.requestSearch}
              actionHandler={() => this.setState({addNew: true})}
            />
            <ListBodyWithLoading
              sortField={this.state.sort}
              requestSort={this.requestSort}
              headers={this.listHeaders}
              isLoading={this.state.isLoading}
              data={this.state.data}
              count={this.state.count}
              rowsPerPage={this.state.rowsPerPage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
          </Card>
        </Page>
        <AddRoleDialog
          isLoading={this.state.addNewLoading}
          isOpen={this.state.addNew}
          permissions={this.state.permissions}
          toggle={() => this.setState({addNew: false})}
          onSubmit={this.createNewRole}
        />
        <EditRoleDialog
          isLoading={this.state.editLoading}
          permissions={this.state.permissions}
          role={this.state.editRole}
          toggle={() => this.setState({editRole: null})}
          onSubmit={this.editRole}
        />
        <DeleteDialog
          reloadData={this.loadData}
          role={this.state.deleteRole}
          toggle={() => this.setState({deleteRole: null})}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true}),
    user: state.user.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(Roles));
