import React from "react";
import { Form } from "reactstrap";

import Button from "../../../UI/Button/Button";
import Input from "../../../UI/Input/Input";

import "./styles.module.css";
import { PermissionGuard } from "../../role-helpers";

const OnlySearchListHeader = (props) => {
  return (
    <div>
      <div styleName="header" className="d-flex align-items-center">
        <h4>{props.title}</h4>
        <PermissionGuard code={props.permission}>
          {!props.noBtn && (
            <Button
              className="ml-auto mr-3"
              color="primary"
              onClick={props.actionHandler}
            >
              {props.actionLabel}
            </Button>
          )}
          {!props.noBtn && props.createLabel && (
            <Button color="primary" onClick={props.createHandler}>
              {props.createLabel}
            </Button>
          )}
        </PermissionGuard>
        {props.additionalComponent && props.additionalComponent()}
      </div>

        <Form onSubmit={props.onSubmit} styleName="search">
          <div className="d-flex align-items-end">
              {!props.noSearch && (
            <Input
              style={{ width: "256px" }}
              value={props.value}
              onChange={props.onChange}
              name="search"
              placeholder={props.searchTitle}
            />
              )}
            {props.subtitle && (
              <h6 className="ml-5">Default Tenant: {props.subtitle}</h6>
            )}
          </div>
        </Form>

    </div>
  );
};

export default OnlySearchListHeader;
