import React, {useEffect} from 'react';

import cx from 'classnames';

import './checkbox.module.css';

const CheckBox = (props) => {
  const onClick = props.disabled
    ? undefined
    : props.onChange
    ? props.onChange
    : props.onClick;
  return (
    <label
      className={cx(props.className, props.outterhtmlClass)}
      htmlFor={props.$ref ? props.$ref : props.name}
      styleName="checkbox"
    >
      <input
        id={props.$ref ? props.$ref : props.name}
        name={props.$ref ? props.$ref : props.name}
        disabled={props.disabled}
        type="checkbox"
        checked={props.getValue ? props.getValue() : props.checked}
        onChange={onClick}
      ></input>
      <span styleName="box"></span>
      {props.label}
    </label>
  );
};

export default CheckBox;
