import React from 'react';
import {withRouter} from 'react-router-dom';

import Menu from './Menu/Menu';

import Page from '../UI/Page/Page';

import './kyb.module.css';

import KYBDetails from './KYBDetails/KYBDetails';
import RepresentativeInfo from './RepresentativeInfo/RepresentativeInfo';
import Documents from './Documents/Documents';
import BeneficialOwners from './BeneficialOwners/BeneficialOwners';
import WithLoading from '../Util/HOC/WithLoading';
import companyKyb from '../../api/companyKyb';
import {getSafeDeep} from '../Util/state';
import qs from 'query-string';
import {bindActionCreators} from 'redux';
import {replace} from 'connected-react-router';
import {connect} from 'react-redux';
import * as _ from 'lodash';
import {Helmet} from 'react-helmet';

const PageWithLoading = WithLoading(Page);

class KYB extends React.Component {
  state = {
    links: [
      {
        icon: 'information',
        name: 'KYB details',
        render: KYBDetails,
        show: () => true,
        order: 0
      },
      {
        icon: 'profile',
        name: 'Representative info',
        render: RepresentativeInfo,
        show: () => true,
        order: 1
      },
      {
        icon: 'folder-2',
        permission: 'list_admin_company_profile_documents',
        name: 'Company documents',
        render: Documents,
        show: (data) => getSafeDeep(data, 'business_type') === 1,
        order: 2
      },
      {
        icon: 'users',
        permission: 'list_admin_company_profile_owners',
        name: 'Beneficial owners',
        render: BeneficialOwners,
        show: (data) => [0, 1].includes(getSafeDeep(data, 'business_type')),
        order: 3
      }
    ],
    isLoading: true,
    data: undefined
  };

  navigateTab = (tab) => {
    this.props.replace(
      `${this.props.location.pathname}?${qs.stringify({tab})}`
    );
  };

  componentDidMount() {
    if (this.props.location.state) {
      getSafeDeep(
        this.props.location.state,
        'kyb_data.beneficial_owners',
        []
      ).forEach((owner) => {
        owner.ownership_percentage *= 100;
      });
      this.props.location.state.kyb_data.company_documents = _.reverse(
        this.props.location.state.kyb_data.company_documents
      );
      this.setState({
        data: this.props.location.state,
        isLoading: false
      });
    } else {
      this.loadData();
    }
  }

  loadData = async () => {
    const {
      match: {
        params: {id}
      }
    } = this.props;
    const response = await companyKyb.get(id);
    getSafeDeep(response.data, 'kyb_data.beneficial_owners', []).forEach(
      (owner) => {
        owner.ownership_percentage *= 100;
      }
    );

    response.data.kyb_data.company_documents = _.reverse(
      response.data.kyb_data.company_documents
    );
    this.setState({
      data: response.data,
      isLoading: false
    });
  };

  dataUpdated = (newData) => {
    if (newData.kyb_data && newData.kyb_data.company_documents)
      newData.kyb_data.company_documents = _.reverse(
        newData.kyb_data.company_documents
      );
    this.setState({data: newData});
  };

  render() {
    const active = this.state.links[this.props.query.tab || 0];

    const {isLoading, data} = this.state;

    return (
      <PageWithLoading isLoading={isLoading} noCard title="KYB">
        <Helmet>
          <title>{`KYB Info for ${getSafeDeep(
            data,
            'full_name'
          )} | Elicon`}</title>
        </Helmet>
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <Menu
              active={active}
              company={data}
              navigateTab={this.navigateTab}
              links={this.state.links}
            />
          </div>
          <div className="col-xl-9 col-lg-8">
            <div styleName="panel">
              <div styleName="body">
                <h3 styleName="header" className="pb-5 pt-3">
                  {active.name}
                </h3>
                {data && (
                  <active.render
                    data={data}
                    dataUpdated={this.dataUpdated}
                    user={this.props.user}
                    reloadData={this.loadData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </PageWithLoading>
    );
  }
}
function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true}),
    user: state.user.data
  };
}

function mapActions(dispatch) {
  return bindActionCreators({replace}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(KYB));
