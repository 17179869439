import React, {Component, Fragment, PureComponent} from 'react';
import auth from './api/auth';
import 'bootstrap/dist/css/bootstrap.css';
import {ERR_NOT_SUPERUSER} from './api/errorCodes';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Redirect, Route, Switch, withRouter} from 'react-router';
import Login from './components/Login/Login';
import {bindActionCreators} from 'redux';
import {clearUser, getUser, getUserList} from './redux/user';
import {connect} from 'react-redux';
import {loadApiResources} from './components/Util/reduxUtil';
import {getCurrencyList} from './redux/currency';
import {getHwPosOptionsList} from './redux/hwPosOptions';
import {getDocumentTypeList} from './redux/documentType';
import {getBrandList} from './redux/brand';
import {getTransactionFeeList} from './redux/transactionFee';
import {getCategoryList} from './redux/category';
import {getPosProviderList} from './redux/posProvider';
import {getPlatformList} from './redux/platform';
import Header from './components/Header/Header';
import './App.css';
import {getTierList} from './redux/tier';
import {routes} from './components/Util/routing';
import UserList from './components/User/UserList';
import OrganizationList from './components/Organization/OrganizationList';
import NewOrganization from './components/Organization/NewOrganization/NewOrganization';
import BrandList from './components/Brand/BrandList';
import QRBundleList from './components/QRBundle/QRBundleList';
import QRCodeList from './components/QRCode/QRCodeList';
import KYCList from './components/KYC/KYCList';
import UserInfo from './components/User/UserInfo';
import {ELIPAY_LOCAL_STORAGE} from './config';
import {getExchangeFeeList} from './redux/exchangeFee';
import OrganizationMenu from './components/Organization/OrganizationMenu/OrganizationMenu';
import LoyaltyQuestionList from './components/Loyalty/LoyaltyQuestionList';
import LoyaltyQuestionInfo from './components/Loyalty/LoyaltyQuestionInfo';
import BroadcastNotifications from './components/Notification/BroadcastNotifications';
import {getGlobalLanguageList} from './redux/sourceLang';
import SideBar from './components/SideBar/SideBar';
import Footer from './components/Footer/Footer';
import BalanceList from './components/Balance/BalanceList';
import NewOfflinePoS from './components/PoS/NewOfflinePoS/NewOfflinePoS';
import NewOnlinePoS from './components/PoS/NewOnlinePoS/NewOnlinePoS';
import PoSList from './components/PoS/PoSList/PoSList';
import PoSMenu from './components/PoS/PoSMenu/PoSMenu';
import Transactions from './components/Transactions/Transactions';
import WalletsList from './components/Wallets/WalletsList';
import Referral from './components/Referrals/Referral';
import BillingList from './components/Billing/BillingList';
import Profile from './components/User/Profile/Profile';
import Roles from './components/Roles/Roles';
import KYB from './components/KYB/KYB';
import Dashboard from './components/Dashboard/Dashboard';
import {getLanguageList} from './redux/language';
import './flaticon/Flaticon.css';
import KybList from './components/KYB/List/KybList';
import KYBNew from './components/KYB/KYBNew';
import OTPRegistration from './components/Login/OTPRegistration';
import {getPosTypeList} from './redux/posType';
import Activities from './components/Referrals/Activities/Activities';
import {getCountryList} from './redux/countries';
import UserActivate from './components/Login/UserActivate';
import {getSafeDeep} from './components/Util/state';
import cx from 'classnames';
import ElipayUserList from './components/User/ElipayUserList';
import TransactionsExperimental from './components/Transactions/TransactionsExperimental';
import ReferralList from './components/Referrals/ReferralList';
import WithdrawalList from './components/Withdrawal/WithdrawalList';
import Onboarding from './components/Onboarding/Onboarding';
import POSProvidersBillingList from './components/POSProvidersBillingList/POSProvidersBillingList';
import TradeList from './components/Exchanges/ExchangeTrades/TradeList';
import LedgerList from './components/Exchanges/ExchangeLedgers/LedgerList';
import ExchangeBalanceList from './components/Exchanges/ExchangeBalances/BalanceList';
import HolisticPOSCompanies from './components/HolisticPOS/HolisticPOSCompanies/HolisticPOSCompanies';
import HolisticPOSCompanyDetails from './components/HolisticPOS/HolisticPOSCompanyDetails/HolisticPOSCompanyDetails';
import HolisticPOSStoreDetails from './components/HolisticPOS/HolisticPOSStoreDetails/HolisticPOSStoreDetails';

import HolisticPOSStores from './components/HolisticPOS/HolisticPOSStores/HolisticPOSStores';
import HolisticPOSLab4payCompanyDetails from './components/HolisticPOS/HolisticPOSLab4payCompanyDetails/HolisticPOSLab4payCompanyDetails';
import {clearAllCookies} from './utils/helperFunctions';
import {getPayfacList} from './redux/payfac';

export const navigationRoutes = [
  {
    path: '/',
    component: Dashboard
  },
  {
    path: routes.userList,
    component: UserList
  },
  {
    path: routes.kycList,
    component: KYCList
  },
  {
    path: routes.organizationList,
    component: OrganizationList
  },
  {
    path: routes.newOrganization,
    component: NewOrganization
  },
  {
    path: routes.posNewOffline,
    component: NewOfflinePoS
  },
  {
    path: routes.posNewOnline,
    component: NewOnlinePoS
  },
  {
    path: routes.posInfoPattern,
    component: PoSMenu
  },
  {
    path: routes.posList,
    component: PoSList
  },
  {
    path: routes.brandList,
    component: BrandList
  },
  {
    path: routes.QRBundleList,
    component: QRBundleList
  },
  {
    path: routes.QRCodeList,
    component: QRCodeList
  },
  {
    path: routes.userInfoPattern,
    component: UserInfo
  },
  {
    path: routes.organizationInfoPattern,
    component: OrganizationMenu
  },
  {
    path: routes.loyaltyQuestionList,
    component: LoyaltyQuestionList
  },
  {
    path: routes.loyaltyQuestionInfoPattern,
    component: LoyaltyQuestionInfo
  },
  {
    path: routes.broadcastNotifications,
    component: BroadcastNotifications
  },
  {
    path: routes.balanceList,
    component: BalanceList
  },
  {
    path: routes.walletsList,
    component: WalletsList
  },
  {
    path: routes.transactions,
    component: Transactions
  },
  {
    path: routes.referral,
    component: Referral
  },
  {
    path: routes.billing,
    component: BillingList
  },
  {
    path: routes.externalPOSProviders,
    component: POSProvidersBillingList
  },
  {
    path: routes.profile,
    component: Profile
  },
  {
    path: routes.roles,
    component: Roles
  },
  {
    path: routes.createCompanyKYB,
    component: KYBNew
  },
  {
    path: routes.viewCompanyKYB,
    component: KYB
  },
  {
    path: routes.listCompanyKYB,
    component: KybList
  },
  {
    path: routes.activities,
    component: Activities
  },
  {
    path: routes.elipayUserList,
    component: ElipayUserList
  },
  {
    path: routes.transactionsExperimental,
    component: TransactionsExperimental
  },
  {
    path: routes.refactorReferralList,
    component: ReferralList
  },
  {
    path: routes.merchantWithdrawal,
    component: WithdrawalList
  },
  {
    path: routes.onboarding,
    component: Onboarding
  },
  {
    path: routes.exchangeTradesKraken,
    component: TradeList
  },
  {
    path: routes.exchangeTradesBitstamp,
    component: TradeList
  },
  {
    path: routes.exchangeTradesTokensnet,
    component: TradeList
  },
  {
    path: routes.exchangeTradesBitcoincom,
    component: TradeList
  },
  {
    path: routes.exchangeTradesBlockchaincom,
    component: TradeList
  },
  {
    path: routes.exchangeTradesBinance,
    component: TradeList
  },
  {
    path: routes.exchangeTradesOkex,
    component: TradeList
  },
  {
    path: routes.exchangeTradesBitmart,
    component: TradeList
  },
  {
    path: routes.exchangeLedgersKraken,
    component: LedgerList
  },
  {
    path: routes.exchangeLedgersBitstamp,
    component: LedgerList
  },
  {
    path: routes.exchangeLedgersTokensnet,
    component: LedgerList
  },
  {
    path: routes.exchangeLedgersBitcoincom,
    component: LedgerList
  },
  {
    path: routes.exchangeLedgersBlockchaincom,
    component: LedgerList
  },
  {
    path: routes.exchangeLedgersBinance,
    component: LedgerList
  },
  {
    path: routes.exchangeLedgersOkex,
    component: LedgerList
  },
  {
    path: routes.exchangeLedgersBitmart,
    component: LedgerList
  },
  {
    path: routes.exchangeBalancesKraken,
    component: ExchangeBalanceList
  },
  {
    path: routes.exchangeBalancesBitstamp,
    component: ExchangeBalanceList
  },
  {
    path: routes.exchangeBalancesTokensnet,
    component: ExchangeBalanceList
  },
  {
    path: routes.exchangeBalancesBitcoincom,
    component: ExchangeBalanceList
  },
  {
    path: routes.exchangeBalancesBlockchaincom,
    component: ExchangeBalanceList
  },
  {
    path: routes.exchangeBalancesBinance,
    component: ExchangeBalanceList
  },
  {
    path: routes.exchangeBalancesOkex,
    component: ExchangeBalanceList
  },
  {
    path: routes.exchangeBalancesBitmart,
    component: ExchangeBalanceList
  },
  {
    path: routes.holisticPOSCompanies,
    component: HolisticPOSCompanies
  },
  {
    path: routes.holisticPOSCompanyDetails,
    component: HolisticPOSCompanyDetails
  },

  //TODO: remove this after lab4pay is implemented as separate tenant for pulsar
  {
    path: routes.holisticPOSLab4payCompanyDetails,
    component: HolisticPOSLab4payCompanyDetails
  },
  {
    path: routes.holisticPOSLab4payStores,
    component: HolisticPOSStores
  },
  {
    path: routes.holisticPOSLab4payStoreDetails,
    component: HolisticPOSStoreDetails
  }
  //TODO: end-remove
];

class RenderRoute extends PureComponent {
  render() {
    const Component = this.props.component;
    return <Component />;
  }
}

class App extends Component {
  state = {
    firstLoad: true,
    upperNav: false,
    lowerNav: false
  };

  async componentDidMount() {
    try {
      const res = await loadApiResources(this.props);
      if (!getSafeDeep(res, '0.value.0.data.restricted')) {
        await this.props.clearUser();
        clearAllCookies();
        this.props.replace('/login');
      }
      localStorage.setItem(`${ELIPAY_LOCAL_STORAGE}/LoggingIn`, false);
    } catch (e) {}
    this.setState({firstLoad: false});
  }

  setNav = (nav) => this.setState(nav);

  async componentDidUpdate(oldProps, oldState) {
    if (oldState.firstLoad && !this.state.firstLoad) {
      try {
        const spinner = document.getElementById('spinner-container');
        spinner.classList.add('done');
        setTimeout(() => {
          spinner.remove();
          document.getElementById('spinner-style').remove();
        }, 500);
      } catch (e) {
        //
      }
    }
    if (
      this.props.location.pathname === '/register-otp' ||
      this.props.location.pathname === '/login' ||
      this.props.location.pathname === '/activate/confirm' ||
      this.props.location.pathname === '/set-password'
    ) {
      return;
    }
    if (this.props.auth.error !== oldProps.auth.error) {
      if (this.props.auth.error === ERR_NOT_SUPERUSER) {
        await auth.logout();
        await this.props.clearUser();
        clearAllCookies();
        await this.props.setError(null);
        this.props.replace('/login');
      }
    }
  }

  render() {
    const {firstLoad} = this.state;

    return !firstLoad ? (
      <Fragment>
        {this.props.user && this.props.user.data && (
          <Header
            setNav={this.setNav}
            upperNav={this.state.upperNav}
            lowerNav={this.state.lowerNav}
          />
        )}
        <div className="d-flex flex-grow-1">
          {this.props.user && this.props.user.data && (
            <SideBar
              user={this.props.user}
              setNav={this.setNav}
              upperNav={this.state.upperNav}
              lowerNav={this.state.lowerNav}
            />
          )}
          <div className={cx('app-container', {expanded: this.state.upperNav})}>
            <ToastContainer
              position="top-left"
              autoClose={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
            />

            <Switch>
              <Route path="/register-otp" component={OTPRegistration} />
              <Route path="/activate/confirm" component={UserActivate} />
              <Route path="/set-password" component={UserActivate} />
              {!this.props.user.data || !this.props.user.data.restricted ? (
                <Fragment>
                  <Route exact path="/login" component={Login} />
                  <Redirect to="/login" />
                </Fragment>
              ) : null}
              {navigationRoutes.map((navItem, i) => {
                const TagName = navItem.component;
                return (
                  <Route
                    key={i}
                    exact
                    path={navItem.path}
                    render={(props) => (
                      <RenderRoute
                        user={this.props.user}
                        auth={this.props.auth}
                        component={TagName}
                      />
                    )}
                  />
                );
              })}
              <Redirect to="/" />
            </Switch>
            {/* </div> */}
            {this.props.user && this.props.user.data && (
              <Footer floating={this.state.lowerNav && !this.state.upperNav} />
            )}
          </div>
        </div>
      </Fragment>
    ) : (
      <p>loading</p>
    );
  }
}

function mapState(state) {
  return {
    user: state.user,
    auth: state.auth
  };
}

function mapActions(dispatch) {
  return bindActionCreators(
    {
      getUser,
      clearUser,
      getCurrencyList,
      getHwPosOptionsList,
      getDocumentTypeList,
      getBrandList,
      getTransactionFeeList,
      getCategoryList,
      getPosProviderList,
      getPlatformList,
      getTierList,
      getUserList,
      getExchangeFeeList,
      getGlobalLanguageList,
      getPosTypeList,
      getLanguageList,
      getCountryList,
      getPayfacList
    },
    dispatch
  );
}

export default withRouter(connect(mapState, mapActions)(App));
