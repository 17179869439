import React, { memo, useState } from 'react'

import { Form } from 'reactstrap'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Select from '../../../UI/Select/Select'

import './styles.module.css'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import qs from "query-string";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { getCountryData } from "../../countryList";
import { getSafeDeep } from "../../state";
import { PermissionGuard } from "../../role-helpers";
import DatePicker from "../../../UI/DatePicker/DatePicker";
import moment from "moment";

const approvalStates = [
    { value: 'approved', label: "Approved" },
    { value: 'pending', label: "Pending" },
    { value: 'rejected', label: "Rejected" },
    { value: 'terminated', label: "Terminated" }
]

const sourceTypes = [
    { value: 'offline', label: 'Offline' },
    { value: 'online', label: 'Online' }
]

const countryList = getCountryData()

const PosListHeader = props => {

    const query = props.query;

    const setFilter = (key, value) => {
        const newQuery = props.query
        if (value == null || value == "") {
            delete newQuery[key]
        } else {
            newQuery[key] = value
        }

        props.push(`?${qs.stringify(newQuery)}`)
    }

    const getFilter = (key) => query[key]
    const dataFormat = "YYYY-MM-DD";

    return (
        <div>
            <div styleName="header" className="d-flex align-items-center justify-content-end">
                <h4 className="mr-auto">{props.title}</h4>
                <PermissionGuard code="view_store_report">
                    <Button isLoading={props.exportLoading} color="primary" onClick={props.onExportClicked}>Export</Button>
                </PermissionGuard>
            </div>
            <Form onSubmit={props.onSubmit} className="d-flex" styleName="search">
                <div>
                    <label>&nbsp;</label>
                    <Input
                        value={props.search || ""}
                        className="mr-3"
                        style={{ width: "256px" }}
                        onChange={props.onChange}
                        name="search"
                        placeholder={props.searchTitle} />
                </div>
                <div style={{ width: "180px" }}>
                    <label>Store type</label>
                    <Select
                        value={sourceTypes.find(t => t.value == getFilter("type"))}
                        options={sourceTypes}
                        isClearable={true}
                        onChange={s => setFilter("type", getSafeDeep(s, 'value'))}
                        className="mr-3" />
                </div>
                <div style={{ width: "180px" }}>
                    <label>Country</label>
                    <Select
                        value={countryList.find(t => t.code == getFilter("country"))}
                        options={countryList}
                        getOptionValue={c => c.code}
                        getOptionLabel={c => c.name}
                        isClearable={true}
                        onChange={s => setFilter("country", getSafeDeep(s, 'code'))}
                        className="mr-3" />
                </div>
                <div style={{ width: "180px" }}>
                    <label>Status</label>
                    <Select
                        value={approvalStates.find(t => t.value == getFilter("approval_state"))}
                        options={approvalStates}
                        isClearable={true}
                        onChange={s => setFilter("approval_state", getSafeDeep(s, 'value'))}
                        className="mr-3" />
                </div>
                <div style={{ width: "180px" }}>
                    <label>Payment platform</label>
                    <Select
                        value={props.integrators.find(t => t.id == getFilter("payment_platform"))}
                        options={props.integrators}
                        getOptionValue={c => c.id}
                        isClearable={true}
                        getOptionLabel={c => c.name}
                        onChange={s => setFilter("payment_platform", getSafeDeep(s, 'id'))}
                        className="mr-3" />
                </div>
                <div style={{ width: "180px" }} className="mr-3">
                    <label>Date from</label>
                    <DatePicker format="DD/MM/YYYY"
                        clearable={true}
                        variant="dialog"
                        maxDate={getFilter("created_to")}
                        value={getFilter("created_from") || null}
                        onChange={date => setFilter('created_from', date ? moment(date).format(dataFormat) : null)} />
                </div>
                <div style={{ width: "180px" }}>
                    <label>Date to</label>
                    <DatePicker format="DD/MM/YYYY"
                        value={getFilter("created_to") || null}
                        minDate={getFilter("created_from")}
                        clearable={true}
                        variant="dialog"
                        onChange={date => setFilter('created_to', date ? moment(date).format(dataFormat) : null)} />
                </div>
            </Form>
        </div>
    )
}

function mapState(state) {
    return {
        integrators: [...(state.posProviders.data || []), ...(state.platforms.data || [])]
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(PosListHeader))
