import React from 'react';
import cx from 'classnames';
import './menu.module.css';
import {getSafeDeep} from '../../Util/state';
import {COMPANY_TYPES, KYB_STEPS} from '../List/KybList';
import {Link} from 'react-router-dom';

const Menu = (props) => {
  const {company, links} = props;

  return (
    <div styleName="menu">
      <div styleName="profile">
        <img
          styleName="picture"
          style={{backgroundImage: getSafeDeep(company, 'logo_url')}}
        />
        <span styleName="title">{getSafeDeep(company, 'full_name')}</span>
        <span styleName="status" className="d-block pt-0 pb-2">
          {COMPANY_TYPES[getSafeDeep(company, 'business_type')]}
        </span>
        <span styleName="status">
          {KYB_STEPS[getSafeDeep(company, 'kyb_status')]}
        </span>
        {getSafeDeep(company, 'kyb_approved_by') && (
          <span styleName="status" className="d-block pt-0 pb-2">
            Approved by:{' '}
            <Link
              to={`/user-management/users/${getSafeDeep(
                company,
                'kyb_approved_by'
              )}`}
            >
              {getSafeDeep(company, 'kyb_approved_by')}
            </Link>
          </span>
        )}
      </div>
      <div styleName="divider" />
      <div>
        {links
          .filter((link) => link.show(company))
          .map(
            (link, index) =>
              link && (
                <div
                  styleName={cx('link', {
                    active: link.icon == props.active.icon
                  })}
                  onClick={() => props.navigateTab(link.order)}
                  key={link.icon}
                >
                  <i className={`flaticon-${link.icon}`}></i>
                  <span>{link.name}</span>
                </div>
              )
          )}
      </div>
      <div styleName="divider" />
    </div>
  );
};

export default Menu;
