import React, {Fragment} from 'react';
import {computeIsRequired, getInputConfig, getInputData, getInputError, getSafe} from "./util";
// import {Button, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import Input from '../../UI/Input/Input'
import * as classnames from "classnames";
import {arrayContextWrapper} from "../ArrayContext";
import {formContextWrapper} from "../FormContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faImage, faPlus} from "@fortawesome/free-solid-svg-icons";
import {getBase64} from "../../Util/image";
import "./workinghours.module.css";
import source from "../../../api/source";
import {deepCopy} from "../../Util/state";
import Link from '../../UI/Link/Link'
import Time from '../../UI/Time/Time'

class ImageArray extends React.Component {


    state = {
        required: false,
        $ref: "",
        workingHours: []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scope.$ref !== prevState.$ref) {
            return {
                required: computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref),
                $ref: nextProps.scope.$ref
            }
        }
        return prevState;
    }

    shouldComponentUpdate(nextProps, nextState) {
        try{
            const context = this.props.context;
            const nextContext = nextProps.context;
            const $ref = this.props.scope.$ref;
            const next$ref = nextProps.scope.$ref;
            const array = this.props.array;
            const nextArray = nextProps.array;
            return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) ||
            getInputError(context, array, $ref) !== getInputError(nextContext, nextArray, next$ref) ||
            $ref !== next$ref 
        }catch(e){
            return false;
        }
    }


    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate() {
        this.loadData()
    }

    loadData = () => {
        const {context, array, scope: {$ref}} = this.props;
        let workingHours = getInputData(context, array, $ref);
        this.setState({ workingHours })
    }

    deleteTime = (index, inputHandler, $ref) => {
        // let workingHours = this.state.workingHours;
        const workingHours = [...getInputData(this.props.context, this.props.array, $ref)];
        workingHours.splice(index, 1)

        this.setState({workingHours}, async() => {
            await inputHandler($ref, this.state.workingHours);
            this.validateIntervals(workingHours);
        });

    };


    onChange = async (e, index, key, subIndex, inputHandler, $ref) => {
        const workingHours = [...this.state.workingHours];
        let time = workingHours[index][key];
        time = time.split(":");
        
        if(e == '+'){ 
            time[subIndex] = ( ( parseInt(time[subIndex]) + 1 ) % (subIndex == 0 ? 24 : 60) ) + "" 
        }else if(e == '-'){
            time[subIndex] = ( (parseInt(time[subIndex]) - 1 < 0 ) ? (subIndex == 0 ? 23 : 59) : ( parseInt(time[subIndex]) - 1 ) ) + ""
        }else{
            if (e.target.value.length > 2) {
                time[subIndex] = e.target.value.substring(0, 2);
            }else{
                time[subIndex] = e.target.value;
            }
        }
        time = time.join(":");
        workingHours[index][key] = time;
        this.setState({
            workingHours: workingHours,
        }, async() => {
            await inputHandler($ref, this.state.workingHours);
            this.validateIntervals(workingHours);
        });
    };

    ensureDigits = async (index, key, subIndex, inputHandler, $ref) => {
        const workingHours = [...this.state.workingHours];
        let time = workingHours[index][key];
        time = time.split(":");
        time[subIndex] = time[subIndex].length < 2 ? "0" + time[subIndex] : time[subIndex]
        time = time.join(":");
        workingHours[index][key] = time;
        this.setState({
            workingHours: workingHours,
        }, async() => {
            await inputHandler($ref, this.state.workingHours);
        });
    }

    validateIntervals(intervals) {

        let intervalsConverted = intervals.map(interval => {
            return {
                open_time: parseInt(interval.open_time.split(":")[0])*60 + parseInt(interval.open_time.split(":")[1]),
                close_time: parseInt(interval.close_time.split(":")[0])*60 + parseInt(interval.close_time.split(":")[1]),
            }
        });


        //0000 1439 540 1260
        let flag = false;
        for (let i = 0; i < intervalsConverted.length; i ++) {
            for (let j = i + 1; j < intervalsConverted.length; j ++) {
                const a = intervalsConverted[i];
                const b = intervalsConverted[j];
                if (Math.max(a.close_time, b.close_time) - Math.min(a.close_time, b.close_time) < (a.close_time - a.close_time) + (b.close_time - b.close_time)) {
                    flag = true;
                    break;
                }
            }
        }

        if (flag) this.props.context.setError(this.props.scope.$ref, "Overlapping intervals");
        else this.props.context.setError(this.props.scope.$ref, undefined);
    }

    appendTime = (inputHandler, $ref) =>{
        let workingHours = this.state.workingHours ? [...this.state.workingHours] : []
        workingHours.push({
            open_time:"07:00:00",
            close_time:"19:00:00"
        });
        this.setState({workingHours}, async () => {
            await inputHandler($ref, this.state.workingHours);
            this.validateIntervals(workingHours);
        });

    };

    renderInput(){
        const {context, scope: {$ref}, readOnly, allowedClear = true, array} = this.props;
        const {loading} = this.state;
        const config = getInputConfig(context, array, $ref);
        const value = getInputData(context, array, $ref);
        const error = getInputError(context, array, $ref);
        const inputHandler = array ? array.onInputChange : context.onInputChange;

        const opened = (workingHours, index) => {
            const { open_time, close_time } = workingHours
            return (
                <div className="d-inline-block">
                    <div styleName="container" className="d-flex align-items-center p-3 position-relative">
                        <a styleName="remove" onClick={() => this.deleteTime(index, inputHandler, $ref)}>
                            <i className="flaticon-plus"></i>
                        </a>
                        <div>
                            <div className="text-center">Opens at</div>
                            <div  className="d-flex align-items-center">
                                <Time onBlur={() => this.ensureDigits(index, 'open_time', 0, inputHandler, $ref)} onChange={(e) => this.onChange(e, index, "open_time", 0, inputHandler, $ref)} type="number" min="0" max="23" step="1" value={open_time.split(":")[0]} />
                                <span className="mx-1">:</span>
                                <Time onBlur={() => this.ensureDigits(index, 'open_time', 1, inputHandler, $ref)} onChange={(e) => this.onChange(e, index, "open_time", 1, inputHandler, $ref)} type="number" min="0" max="59" step="1" value={open_time.split(":")[1]} />
                            </div>
                        </div>
                        <div className="mx-3"> - </div>
                        <div>
                            <div className="text-center">Closes at</div>
                            <div className="d-flex align-items-center">
                                <Time onBlur={() => this.ensureDigits(index, 'close_time', 0, inputHandler, $ref)} onChange={(e) => this.onChange(e, index, "close_time", 0, inputHandler, $ref)} type="number" min="0" max="23" step="1" value={close_time.split(":")[0]} />
                                <span className="mx-1">:</span>
                                <Time onBlur={() => this.ensureDigits(index, 'close_time', 1, inputHandler, $ref)} onChange={(e) => this.onChange(e, index, "close_time", 1, inputHandler, $ref)} type="number" min="0" max="59" step="1" value={close_time.split(":")[1]} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="d-flex align-items-center">
                <div className="mr-3" styleName="partOfDayLeft">
                    { value && value.length ? opened(value[0], 0) : (
                        <div styleName="closedAllDay"> 
                            <p>Closed all day</p>
                        </div>
                    )}                    
                </div>
                <div className="ml-3" styleName="partOfDayRight">
                    {!value || value.length < 2 ?  
                    (!this.props.disabled ? <a styleName="addNew" onClick={() => this.appendTime(inputHandler, $ref) }>
                        <i className="flaticon-plus"></i>
                    </a> : null) : opened(value[1], 1)}
                </div>
            </div>
        )
    }

    setClosed = () => {
        const inputHandler = this.props.array ? this.props.array.onInputChange : this.props.context.onInputChange;

        this.setState({ workingHours: []}, async () => {
            await inputHandler(this.props.scope.$ref, this.state.workingHours)
        })
    }

    setOpened24 = () => {
        const inputHandler = this.props.array ? this.props.array.onInputChange : this.props.context.onInputChange;

        this.setState({ workingHours: [{ open_time: "00:00:00", close_time: "23:59:59" }]}, async () => {
            await inputHandler(this.props.scope.$ref, this.state.workingHours)
        })
    }

    applyToAll = async () => {
        const inputHandler = this.props.array ? this.props.array.onInputChange : this.props.context.onInputChange;

        let allPath = this.props.scope.$ref.split('/')
        allPath.pop()
        allPath = allPath.join('/')
        
        await inputHandler(allPath, [...new Array(7)].map(() => ([...this.state.workingHours])))
    }

    render() {

        const {context, scope: {$ref}, label, outterhtmlClass, array} = this.props;
        const error = getInputError(context, array, $ref);

        return (
            <div className={classnames(outterhtmlClass, 'mb-5')}>
                {!this.props.disabled && (
                    <>
                        <div className="d-flex">
                            <h6>{label}</h6>
                            <Link onClick={this.applyToAll} className="ml-auto">Apply to all days</Link>
                        </div>
                        <div className="d-flex">
                            <Link onClick={this.setOpened24} className="mr-4">Open all day</Link>
                            <Link onClick={this.setClosed}>Closed</Link>
                        </div>
                    </>
                )}
                <div>
                    {this.renderInput()}
                </div>
                {error && <span style={{ color: "red" }} className="d-block mt-4">{error}</span>}
            </div>
        );
    };

}

export default arrayContextWrapper(formContextWrapper(ImageArray));
