import React, {Fragment} from "react";
import PlacesAutocomplete, {geocodeByAddress, getLatLng } from "react-places-autocomplete";
import './googleSearch.css';
import Input from '../../UI/Input/Input'

export default class GoogleSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: ''
        };
    }

    handleChange = (address) => {
        this.setState({ address });
    };

    handleSelect = address => {
        let placeId = undefined;
        geocodeByAddress(address)
            .then((results) => {
                placeId = results[0].place_id;
                return getLatLng(results[0]);
            })
            .then((latLng) => {
                return this.props.handleChange( {placeId, location: latLng} )
            })
            .catch((error) => {
                console.error('Google map search error: ', error.message)
            });
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="mb-3 position-relative">
                        <label>Search location</label>
                        <Input
                            {...getInputProps({
                                placeholder: 'Search location by name, street or city',
                                className: 'location-search-input',
                            })}
                            disabled={this.props.disabled}
                        />
                        <div className="autocomplete-dropdown-container position-absolute pb-3">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, sugId) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <Fragment key={sugId}>
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}
