import React, {useState} from 'react';
import DynamicForm from '../../DynamicForm/DynamicForm';
import dataSchema from '../schema/newKybData.json';
import uiSchema from '../schema/newKybUi.json';
import companyKyb from '../../../api/companyKyb';
import {parseErrors} from '../../Util/form';
import {createMessage} from '../../Util/notification';
import {deepCopy, getSafeDeep, removeEmptyFields} from '../../Util/state';
import Select from '../../UI/Select/Select';
import {COMPANY_TYPES} from '../List/KybList';
import * as classnames from 'classnames';
import {FormFeedback} from 'reactstrap';
import {connect} from 'react-redux';
import qs from 'query-string';
import {bindActionCreators} from 'redux';
import {replace} from 'connected-react-router';
import {getKybInfoRoute} from '../../Util/routing';

const NewKybForm = ({replace, countries}) => {
  const [errors, setErrors] = useState({});
  const [company, setCompany] = useState();
  const [companyType, setCompanyType] = useState({
    value: 0,
    label: 'Sole Proprietor'
  });

  const onSubmit = async (formData) => {
    if (!company) {
      setErrors({
        company: 'Select a company'
      });
      return;
    }
    setErrors({});
    const newData = deepCopy(formData.form);
    console.log('new data 1', newData);
    if (getSafeDeep(newData, 'document.document_front_image') === '') {
      delete newData.document.document_front_image;
    }
    if (getSafeDeep(newData, 'document.document_back_image') === '') {
      delete newData.document.document_back_image;
    }
    if (newData.temporary_address) {
      const allBlank = Object.entries(newData.temporary_address).reduce(
        (allBlank, [key, value]) => {
          if (key == 'country') return allBlank;
          return allBlank && value === '';
        },
        true
      );
      if (allBlank) {
        delete newData.temporary_address;
      }
    }
    if (newData.permanent_address) {
      const allBlank = Object.entries(newData.permanent_address).reduce(
        (allBlank, [key, value]) => {
          if (key == 'country') return allBlank;
          return allBlank && value === '';
        },
        true
      );
      if (allBlank) {
        delete newData.permanent_address;
      }
    }
    if (newData.ownership_percentage) {
      newData.ownership_percentage = newData.ownership_percentage / 100;
    }
    try {
      newData.business_type = companyType.value;

      const submitData = removeEmptyFields(newData);
      if (newData.ownership_percentage === 0) {
        submitData.ownership_percentage = 0;
      }
      const response = await companyKyb.create(company.id, submitData);
      createMessage('KYB created successfully');
      replace({
        pathname: getKybInfoRoute(company.id),
        state: response.data
      });
    } catch (e) {
      setErrors(parseErrors(e));
    }
  };

  const getSearchCompanies = async (input) => {
    return (await companyKyb.list(0, input, undefined, 0, 10)).data.results;
  };

  const watchInput = (data) => {
    const country = countries.find(
      (c) => c.a2_iso_code == getSafeDeep(data, 'form.document.issuing_country')
    );
    data.required_fields = getSafeDeep(
      country,
      'configuration.required_fields.passport',
      getSafeDeep(country, 'configuration.required_fields.id', [])
    );
    return deepCopy(data);
  };

  return (
    <>
      <h5 style={{color: '#575962'}} className="d-flex align-items-center">
        <label className="flex-grow-1">Company information</label>
      </h5>
      <div className="horizontal-layout">
        <div>
          <label htmlFor="company">
            Company<sup className="text-danger">*</sup>
          </label>
          <Select
            loadOptions={getSearchCompanies}
            async
            value={company}
            onChange={setCompany}
            id="company"
            cacheOptions
            defaultOptions
            getOptionValue={(c) => c.id}
            getOptionLabel={(c) => c.full_name}
          />
          <FormFeedback
            valid={!getSafeDeep(errors, 'company')}
            className={classnames({
              'd-block': !!getSafeDeep(errors, 'company')
            })}
          >
            {getSafeDeep(errors, 'company')}
          </FormFeedback>
        </div>
        <div>
          <label htmlFor="business-type">
            Business Type<sup className="text-danger">*</sup>
          </label>
          <Select
            options={COMPANY_TYPES.map((type, i) => ({value: i, label: type}))}
            value={companyType}
            onChange={setCompanyType}
            id="business-type"
          />
        </div>
      </div>
      <DynamicForm
        submit
        dataSchema={dataSchema}
        uiSchema={uiSchema}
        onSubmit={onSubmit}
        errors={errors}
        inputChangeListener={watchInput}
        initData={{
          form: {},
          countries,
          document_types: [
            {value: 0, label: 'ID Card'},
            {value: 1, label: 'Passport'}
          ]
        }}
      />
    </>
  );
};

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true}),
    countries: state.countries.data || []
  };
}

function mapActions(dispatch) {
  return bindActionCreators({replace}, dispatch);
}

export default connect(mapState, mapActions)(NewKybForm);
