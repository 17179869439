import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ListBody from '../Util/ListView/ListBody/ListBody';
import WithLoading from '../Util/HOC/WithLoading';
import qs from 'query-string';
import {push} from 'connected-react-router';
import {listHeader} from '../Util/listView';
import ListHeader from '../Util/ListView/ListHeader/ListHeader';
import immutable from 'immutable';
import v3hash from '../../api/V3/hash';
import AddBundleDialog from './AddBundleDialog';
import Page from '../UI/Page/Page';
import moment from 'moment';

import {notifyError} from '../Util/notification';

import {Card} from '@material-ui/core';
import Button from '../UI/Button/Button';

import './QRBundles.module.css';
import {PermissionGuard} from '../Util/role-helpers';
import {Helmet} from 'react-helmet';

const ListBodyWithLoading = WithLoading(ListBody);

class QRBundleList extends React.Component {
  state = {
    isLoading: true,
    data: [],
    addBundleDialogOpen: false,
    rowsPerPage: 10,
    count: 0,
    polling: null
  };

  componentDidMount() {
    this.loadData();
    this.startPolling();
  }

  startPolling = () => {
    const polling = setInterval(() => {
      this.loadData();
    }, 10000);
    this.setState({polling});
  };

  componentWillUnmount() {
    clearInterval(this.state.polling);
  }

  componentDidUpdate(oldProps, prevState) {
    const {query, auth} = this.props;
    const {query: oldQuery, auth: oldAuth} = oldProps;
    if (
      !immutable.is(query, oldQuery) ||
      this.state.rowsPerPage != prevState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  renderStatus = (data) => {
    if (data.status == 'archived') {
      return <div styleName="archived">Archived</div>;
    }
    return <div styleName="ready">Ready</div>;
  };

  regenerate = async (data) => {
    try {
      await v3hash.regenerate(data.token);
      this.loadData();
    } catch (err) {
      notifyError(err.message);
    }
  };

  getUrl = async (data) => {
    try {
      const res = await v3hash.regenerate(data.token);
      if (res.data.status == 'ready') {
        window.open(res.data.url, '_blank');
      } else {
        notifyError(
          'Please wait few seconds and try again, bundle is being generated'
        );
      }
    } catch (err) {
      notifyError(err.message);
    }
  };

  renderAction = (data) => {
    if (data.status == 'archived') {
      return (
        <Button color="outline-primary" onClick={() => this.regenerate(data)}>
          Regenerate
        </Button>
      );
    }
    return (
      <Button color="outline-success" onClick={() => this.getUrl(data)}>
        Download
      </Button>
    );
  };

  bundleListHeaders = [
    listHeader('Token', 'token', false),
    listHeader('Created', '', false, (data) => moment(data.created).fromNow()),
    listHeader('Status', 'status', false, (data) => this.renderStatus(data)),
    listHeader('Action', '', false, (data) => (
      <PermissionGuard code="get_qr_bundle">
        {this.renderAction(data)}
      </PermissionGuard>
    ))
  ];

  toggleDialog = (dialogName) => {
    const fieldName = `${dialogName}DialogOpen`;
    this.setState({[fieldName]: !this.state[fieldName]});
  };

  loadData = async () => {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const page = query.page || 0;
    const {
      data: {count, results: data}
    } = await v3hash.listBundlesPaginated(
      page * this.state.rowsPerPage,
      this.state.rowsPerPage
    );
    this.setState({count, data, isLoading: false});
    if (query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    let currentItem =
      qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).page *
        this.state.rowsPerPage || 0 + 1;
    let newPage = Math.floor(currentItem / newRowsPerPage);
    this.setState({rowsPerPage: newRowsPerPage});
    const query = {...this.props.query};
    query.page = newPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  render() {
    const {isLoading, data, count, rowsPerPage} = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>QR Bundles | Elicon</title>
        </Helmet>
        <Page permission={'get_qr_bundle'}>
          <Card>
            <ListHeader
              type="QRCodeBundles"
              title="QR Code Bundles"
              actionHandler={() => this.toggleDialog('addBundle')}
              actionLabel="Generate QR Bundle"
            />
            <ListBodyWithLoading
              headers={this.bundleListHeaders}
              isLoading={isLoading}
              data={data}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
          </Card>
        </Page>
        <AddBundleDialog
          isOpen={this.state.addBundleDialogOpen}
          toggle={() => this.toggleDialog('addBundle')}
          reloadData={() => this.loadData()}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(QRBundleList));
