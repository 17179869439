import qs from 'query-string';
import React, {Fragment} from 'react';
import {push} from 'connected-react-router';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import Card from '../../UI/Card/Card';
import Page from '../../UI/Page/Page';
import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';
import WithLoading from '../../Util/HOC/WithLoading';
import {listHeader, renderLink} from '../../Util/listView';
import ListBody from '../../Util/ListView/ListBody/ListBody';
import ListHeader from '../../Util/ListView/ListHeader/ListHeader';
import {getSafeDeep} from '../../Util/state';
import holistic from '../../../api/V3/holistic';
import HolisticPOSAddNew from '../HolisticPOSAddNew';
import HolisticPOSDeleteModal from '../HolisticPOSDeleteModal';
import HolisticPOSEditModal from '../HolisticPOSEditModal';
import LinkExternalCompanyModal from '../LinkExternalCompanyModal';
import LinkGoCryptoCompanyModal from '../LinkGoCryptoCompanyModal';
import companyUiSchema from '../schemas/companyUI.json';
import companyDataSchema from '../schemas/companyData.json';
import merchantUiSchema from '../schemas/merchantUI.json';
import merchantDataSchema from '../schemas/merchantData.json';
import InfoForm from '../../InfoForm/InfoForm';
import {infoField, infoFieldCategory} from '../../Util/infoForm';
import {
  dictionaryToArrayOfObjects,
  openNewTab
} from '../../../utils/helperFunctions';
import auth from '../../../api/auth';
import {toast} from 'react-toastify';

const ListBodyWithLoading = WithLoading(ListBody);

class HolisticPOSCompanyDetails extends React.Component {
  state = {
    isLoading: true,
    count: 0,
    rowsPerPage: 10,
    sort: '',
    addNew: false,
    delete: null,
    companies: [],
    merchant: null,
    merchants: [],
    previewMerchant: false,
    previewMerchants: false,
    previewExternalCompany: false,
    previewGoCryptoCompany: false,
    about: {},
    paymentMethods: [],
    operatedBy: [],
    showLinkToGoCrypto: true
  };

  componentDidMount() {
    this.loadData().catch((error) =>
      console.error('loadData error: ', error.message)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: {id}
      }
    } = this.props;
    const {
      match: {
        params: {id: oldId}
      }
    } = prevProps;
    if (id !== oldId) {
      this.loadData();
    }

    const {query} = this.props;
    const {query: oldQuery} = prevProps;
    if (
      JSON.stringify(query) !== JSON.stringify(oldQuery) ||
      this.state.rowsPerPage !== prevState.rowsPerPage
    ) {
      this.loadData();
    }
  }

  logInToManger = async () => {
    const currentMerchant = this.state.merchant.find((item) => item.current);

    if (!currentMerchant) return;

    toast.info('Generating login url...', {
      position: 'top-right'
    });

    try {
      const {data: managerData} = await auth.loginUserToManager(
        currentMerchant.email
      );

      if (!managerData.url) {
        throw new Error('Something went wrong');
      }

      const urlParams = new URLSearchParams(managerData.url.split('?')[1]);
      const userId = urlParams.get('user');

      const {data: signatureData} = await auth.validateManagerUser(
        parseInt(userId),
        encodeURI(managerData.token)
      );

      if (!signatureData.signature) {
        throw new Error('Something went wrong');
      }

      toast.dismiss();
      toast.success('Login link generated.', {
        position: 'top-right'
      });

      setTimeout(() => {
        toast.dismiss();
      }, 3000);

      openNewTab(
        `${managerData.url}&token=${encodeURI(
          managerData.token
        )}&signature=${encodeURIComponent(signatureData.signature)}`
      );
    } catch (error) {
      toast.error(`Error logging into Manager: ${error.message}`, {
        position: 'top-right'
      });
      console.error('Error logging into Manager: ', error.message);
      setTimeout(() => {
        toast.dismiss();
      }, 10000);
    }
  };

  loadData = async () => {
    const {
      match: {
        params: {id}
      }
    } = this.props;
    let {page, search, sort, pageSize, ...filter} = {...this.props.query};

    this.setState({isLoading: true});
    try {
      const {data: about} = await holistic.getCompanyDetails(id);
      const response = await holistic.getHolisticTenants(
        id,
        search,
        (page || 0) * (pageSize || 10),
        pageSize || 10,
        sort,
        filter
      );

      const paymentMethodsRes = await holistic.getPaymentMethods();
      const operatedByRes = await holistic.getOperatedBy();

      this.setState({
        about,
        companies: response.data,
        count: getSafeDeep(response, 'data.length', 0),
        paymentMethods: paymentMethodsRes.data,
        operatedBy: dictionaryToArrayOfObjects(operatedByRes.data)
      });

      //get merchant
      const merchantResponse = await holistic.getCompanyMerchant(id);
      this.setState({
        merchant: merchantResponse.data,
        merchants: merchantResponse.data
      });
    } catch (e) {
      console.error('loadData requests error: ', e.message);
    } finally {
      this.setState({isLoading: false});
    }
  };

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    const query = {...this.props.query};
    let currentItem = query.page * query.pageSize || 0;
    query.page = Math.floor(currentItem / newRowsPerPage);
    query.pageSize = newRowsPerPage;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  requestSort = (sort) => {
    this.setState({sort});
    const query = {...this.props.query};
    query.page = 0;
    query.sort = sort;
    this.props.history.push(
      `${this.props.location.pathname}?${qs.stringify(query)}`
    );
  };

  getCompaniesListHeaders = () => {
    return [
      listHeader('Tenant', 'tenant', true, (item) =>
        getSafeDeep(item, 'tenant')
      ),
      listHeader('External ID', 'external_id', (item) =>
        getSafeDeep(item, 'external_id')
      ),
      //TODO: remove this after lab4pay is implemented as separate tenant for pulsar
      listHeader('Action', '', false, (item) => {
        return (
          (item.tenant &&
            item.tenant.toLowerCase() === 'lab4pay' &&
            renderLink(
              'Edit organization',
              `/holistic-pos/companies/${item.organization}/lab4pay/${item.organization}`
            )) ||
          null
        );
      })
      //TODO: end-remove
    ];
  };

  getOperatedBy = (operatedBy) => {
    switch (operatedBy) {
      case 0:
        return 'GoCrypto Classic';
      case 1:
        return 'Lab4Pay';
      case 2:
        return 'GLS';
      case 3:
        return 'Elly';
      case 4:
        return 'GoCrypto CH';
      case 5:
        return 'GoCrypto';
      case 6:
        return 'Elly SKN';
      case 7:
        return 'GoCrypto SV';
      case 8:
        return 'Done4You';
      default:
        return 'Not available';
    }
  };

  render() {
    const {isLoading, companies, count} = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Holistic POS Company Details | Elicon</title>
        </Helmet>
        <Page permission={'view_all_holistic_companies'}>
          <Card>
            <ListHeader
              noSearch={true}
              type="OnlySearch"
              title={this.state.about.full_name}
              subtitle={this.getOperatedBy(
                getSafeDeep(this.state.about, 'operated_by', undefined)
              )}
              searchTitle="Search"
              noBtn={true}
              actionHandler={() => this.setState({addNew: true})}
              actionLabel="Add New Store"
              additionalComponent={() => {
                return (
                  <>
                    <Button
                      className="ml-3"
                      color="primary"
                      onClick={() => {
                        this.setState({edit: true});
                      }}
                    >
                      Edit Company
                    </Button>
                    <Button
                      className="ml-3"
                      color="primary"
                      onClick={() => {
                        this.setState({addMerchant: true});
                      }}
                    >
                      Add Merchant
                    </Button>
                    {this.state.merchant != null && (
                      <Button
                        className="ml-3"
                        color="primary"
                        onClick={() => {
                          this.setState({previewMerchant: true});
                        }}
                      >
                        Current Merchant
                      </Button>
                    )}
                    <Button
                      className="ml-3"
                      color="primary"
                      onClick={() => {
                        this.setState({previewMerchants: true});
                      }}
                    >
                      Additional Merchants
                    </Button>
                    <Button
                      className="ml-3"
                      color="primary"
                      onClick={() => {
                        this.setState({previewExternalCompany: true});
                      }}
                    >
                      Link external company
                    </Button>
                    {this.state.merchant != null && (
                      <Button
                        className="ml-3"
                        color="primary"
                        onClick={() => this.logInToManger()}
                      >
                        Admin Login
                      </Button>
                    )}
                  </>
                );
              }}
            />
            <ListBodyWithLoading
              sortField={this.state.sort}
              requestSort={this.requestSort}
              padding="16px"
              headers={this.getCompaniesListHeaders()}
              isLoading={isLoading}
              data={companies}
              count={count}
              rowsPerPage={this.props.query.pageSize || 10}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
          </Card>
        </Page>
        <HolisticPOSAddNew
          isOpen={this.state.addNew}
          reloadData={() => this.loadData()}
          toggle={() => this.setState({addNew: false})}
          label={'Store'}
          onSubmit={(name) => {
            return holistic
              .createStore(name)
              .then((res) => res.data.id)
              .then((id) => {
                return holistic.organizationAddStore(this.state.about.id, id);
              });
          }}
        />
        <HolisticPOSDeleteModal
          isOpen={!!this.state.delete}
          reloadData={() => {
            this.loadData();
          }}
          toggle={() => this.setState({delete: null})}
          label={getSafeDeep(this.state.delete, 'full_name')}
          onSubmit={() => {
            return holistic.deleteStore(this.state.delete.id);
          }}
        />
        <HolisticPOSEditModal
          uiSchema={companyUiSchema}
          dataSchema={companyDataSchema}
          active={this.state.edit ? this.state.about : null}
          reloadData={() => {
            this.loadData();
          }}
          toggle={() => this.setState({edit: false})}
          label="Company"
          paymentMethods={this.state.paymentMethods}
          operatedBy={this.state.operatedBy.filter((operatedBy) =>
            this.state.companies
              .map(({tenant}) => tenant)
              .includes(operatedBy.name)
          )}
          onSubmit={(data) => {
            return holistic.updateCompany(this.state.about.id, data.form);
          }}
        />
        <HolisticPOSAddNew
          uiSchema={merchantUiSchema}
          dataSchema={merchantDataSchema}
          isOpen={this.state.addMerchant}
          reloadData={() => this.loadData()}
          toggle={() => this.setState({addMerchant: false})}
          label={'Merchant'}
          onSubmit={(data) => {
            return holistic.addMerchant(this.state.about.id, data.form);
          }}
        />
        {/* current merchant */}
        <Modal
          title="Current Merchant"
          isOpen={this.state.previewMerchant}
          toggle={() => this.setState({previewMerchant: false})}
          size="sm"
          submitButton={() => null}
          cancelLabel="Close"
        >
          {this.state.merchant &&
            this.state.merchants.map((merchant) =>
              getSafeDeep(merchant, 'current') ? (
                <InfoForm
                  fields={[
                    infoFieldCategory('Merchant details', [
                      infoField('first_name', 'First name'),
                      infoField('last_name', 'Last name'),
                      infoField('email', 'E-mail'),
                      infoField('id', 'ID'),
                      infoField('date_joined', 'Date Joined', 'date')
                    ])
                  ]}
                  data={merchant}
                />
              ) : null
            )}
        </Modal>
        {/* additional merchants */}
        <Modal
          title="Additional Merchants"
          isOpen={this.state.previewMerchants}
          toggle={() => this.setState({previewMerchants: false})}
          size="sm"
          submitButton={() => null}
          cancelLabel="Close"
        >
          {this.state.merchant &&
            this.state.merchants.map((merchant) =>
              getSafeDeep(merchant, 'current') ? null : (
                <InfoForm
                  fields={[
                    infoFieldCategory('Merchant details', [
                      infoField('first_name', 'First name'),
                      infoField('last_name', 'Last name'),
                      infoField('email', 'E-mail'),
                      infoField('id', 'ID'),
                      infoField('date_joined', 'Date Joined', 'date')
                    ])
                  ]}
                  data={merchant}
                />
              )
            )}
        </Modal>
        <LinkExternalCompanyModal
          isOpen={this.state.previewExternalCompany}
          operatedByList={this.state.operatedBy}
          reloadData={() => this.loadData()}
          toggle={() => this.setState({previewExternalCompany: false})}
          onSubmit={(externalCompanyId, tenant, defaultTenant) => {
            return holistic.linkExternalCompany(
              this.state.about.id,
              externalCompanyId,
              tenant,
              defaultTenant
            );
          }}
        />
        <LinkGoCryptoCompanyModal
          isOpen={this.state.previewGoCryptoCompany}
          reloadData={() => this.loadData()}
          toggle={() => this.setState({previewGoCryptoCompany: false})}
          selectedCompany={this.state.about.gocrypto_company}
          companyId={this.state.about.id}
          onSubmit={(id) => {
            return holistic.linkGoCryptoCompany(this.state.about.id, id);
          }}
        />
      </Fragment>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(
  connect(mapState, mapActions)(HolisticPOSCompanyDetails)
);
