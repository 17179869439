import React, {Fragment} from 'react';
import {
  computeIsRequired,
  getInputConfig,
  getInputData,
  getInputError,
  getSafe
} from './util';
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import * as classnames from 'classnames';
import {arrayContextWrapper} from '../ArrayContext';
import {formContextWrapper} from '../FormContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faImage, faPlus} from '@fortawesome/free-solid-svg-icons';
import {getBase64} from '../../Util/image';
import './base64Image.module.css';
import source from '../../../api/source';
import {deepCopy} from '../../Util/state';
import {getImageS3} from '../../../api/util';

class ImageArray extends React.Component {
  constructor(props) {
    super(props);

    const {
      context,
      array,
      scope: {$ref}
    } = this.props;
    let images = getInputData(context, array, $ref);

    this.state = {
      required: false,
      $ref: '',
      uploading: false,
      images: images ? images : []
    };

    this.uploadImageRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.scope.$ref !== prevState.$ref) {
      return {
        required: computeIsRequired(
          nextProps.context,
          nextProps.array,
          nextProps.scope.$ref
        ),
        $ref: nextProps.scope.$ref
      };
    }
    return prevState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
    const context = this.props.context;
    const nextContext = nextProps.context;
    const $ref = this.props.scope.$ref;
    const next$ref = nextProps.scope.$ref;
    const array = this.props.array;
    const nextArray = nextProps.array;
    //if(this.state.images != getInputData(context, array, $ref)) return true;
    return (
      getInputData(context, array, $ref) !==
        getInputData(nextContext, nextArray, next$ref) ||
      getInputError(context, array, $ref) !==
        getInputError(nextContext, nextArray, next$ref) ||
      $ref !== next$ref
    );
  }

  componentDidMount() {
    //this.uploadImageRef.current.click();
  }

  componentDidUpdate() {}

  onDelete = (index, inputHandler, $ref) => {
    let images = this.state.images;
    images.splice(index, 1);
    this.setState({images}, async () => {
      await inputHandler($ref, this.state.images);
    });
  };

  onChange = async (e, inputHandler, $ref, context) => {
    const files = Array.from(e.target.files);
    this.setState({uploading: true});
    const formData = new FormData();
    formData.append('image', files[0]);
    try {
      const response = await source.uploadImage(formData);
      const images = this.state.images;
      images.push({
        image: response.data.image,
        id: -1
      });
      this.setState(
        {
          images: images,
          uploading: false
        },
        async () => {
          await inputHandler($ref, this.state.images);
          context.refreshForm();
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  renderInput() {
    const {
      context,
      scope: {$ref},
      readOnly,
      allowedClear = true,
      array
    } = this.props;
    const {loading} = this.state;
    const config = getInputConfig(context, array, $ref);
    const value = getInputData(context, array, $ref);
    const error = getInputError(context, array, $ref);
    const inputHandler = array ? array.onInputChange : context.onInputChange;

    return (
      <div className="button d-flex align-items-center flex-wrap">
        {value &&
          value.length > 0 &&
          value.map((imageItem, index) => {
            return (
              <div
                key={index}
                styleName="image-thumb"
                className="mb-3 position-relative mr-3"
              >
                <img
                  styleName="image-thumb"
                  alt="image field input"
                  src={imageItem.image}
                />
                <div
                  styleName="cross"
                  className="position-absolute"
                  onClick={(e) => this.onDelete(index, inputHandler, $ref)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            );
          })}
        {loading ? (
          <div
            styleName="image-thumb upload-wrapper"
            className="position-relative"
          >
            <div
              styleName="image-thumb bordered"
              className="d-flex justify-content-center align-items-center"
            >
              <FontAwesomeIcon icon={faTimes} color="#bbbbbb" size="2x" />
            </div>
          </div>
        ) : (
          <div
            styleName="image-thumb upload-wrapper"
            className="mb-3 position-relative"
          >
            <Input
              ref={this.uploadImageRef}
              styleName="hidden-input"
              className=" position-absolute"
              type="file"
              id="single"
              onChange={(e) => this.onChange(e, inputHandler, $ref, context)}
            />
            <div
              styleName="image-thumb bordered"
              className="d-flex justify-content-center align-items-center"
            >
              <FontAwesomeIcon icon={faPlus} color="#bbbbbb" size="2x" />
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      context,
      scope: {$ref},
      label,
      outterhtmlClass,
      array
    } = this.props;
    const error = getInputError(context, array, $ref);
    return (
      <FormGroup className={classnames(outterhtmlClass, 'row')}>
        <Label className="col-3 align-items-center d-flex m-0" htmlFor={$ref}>
          {label}
          {this.state.required && <sup className="text-danger">*</sup>}
        </Label>
        <div className="col-9">
          <div className="buttons fadein">{this.renderInput()}</div>
        </div>
        <div className="col-9 offset-3">
          <FormFeedback
            valid={!error}
            className={classnames({'d-block': !!error})}
          >
            {error}
          </FormFeedback>
        </div>
      </FormGroup>
    );
  }
}

export default arrayContextWrapper(formContextWrapper(ImageArray));
