import React, {useEffect, useState} from 'react'
import { Form } from 'reactstrap'

import Input from '../../../UI/Input/Input'

import "./styles.module.css"
import Select from "../../../UI/Select/Select";
import * as qs from "qs";



const BalanceListHeader = props => {



    return (
        <div>
            <Form onSubmit={props.onSelect} styleName="search">
                <div styleName="select">
                    {props.renderWalletsSelect(props.onSelect)}
                </div>
            </Form>
        </div>
    )
}

export default BalanceListHeader