import React from 'react';
import * as qs from "qs";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Card} from "@material-ui/core";
import "./SumTotalCardDisplay.module.css"

class SumTotalCardDisplay extends React.Component {


    render() {


        return(
            // eslint-disable-next-line react/jsx-no-undef
            <div styleName="container">
                <div styleName="row">
                    <div styleName="information-set">
                        <div styleName="label">Amount</div>
                        <div styleName="value">{this.props.data.amount}</div>
                    </div>
                    <div styleName="information-set">
                        <div styleName="label">Transaction Fee</div>
                        <div styleName="value">{this.props.data.transaction_fee}</div>
                    </div>
                    <div styleName="information-set">
                        <div styleName="label">Exchange Fee</div>
                        <div styleName="value">{this.props.data.provision_fee}</div>
                    </div>
                    <div styleName="information-set">
                        <div styleName="label">Total</div>
                        <div styleName="value">{this.props.data.total}</div>
                    </div>
                </div>
            </div>
        )
    }

};



function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(SumTotalCardDisplay));
