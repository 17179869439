import WithLoading from '../Util/HOC/WithLoading';
import ListBody from '../Util/ListView/ListBody/ListBody';
import React, {Fragment} from 'react';
import {getSafeDeep} from '../Util/state';
import qs from 'query-string';
import {listHeader, renderCreatedAt, renderFullDate} from '../Util/listView';
import Page from '../UI/Page/Page';
import {Card} from '@material-ui/core';
import ListHeader from '../Util/ListView/ListHeader/ListHeader';
import TransactionExportCSVDialog from '../Transactions/TransactionExportCSVDialog';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import './BillingOverviewModal.module.css';
import SumTotalCardDisplay from '../Util/SumTotalCardDisplay/SumTotalCardDisplay';
import Modal from '../UI/Modal/Modal';
import billing from '../../api/V3/billing';
import {notifyError} from '../Util/notification';

const ListBodyWithLoading = WithLoading(ListBody);

class BillingOverviewModal extends React.Component {
  state = {
    data: [],
    count: 0,
    page: 0,
    search: '',
    rowsPerPage: 10,
    sort: '',
    sumData: {},
    isLoading: false,
    BillingOverviewOpen: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.isOpen != this.props.isOpen ||
      this.state.rowsPerPage != prevState.rowsPerPage ||
      this.state.page != prevState.page
    )
      this.loadData();
  }

  loadData = async () => {
    try {
      this.setState({isLoading: true});
      let {search, page, rowsPerPage, sort} = this.state;
      if (page > 0 && !this.state.data.length) {
        //page more than 0 and no data
        page = 0;
      }

      const {start_date, end_date} = this.props;
      const startDate = start_date.format('YYYY-MM-DD');
      const endDate = end_date.format('YYYY-MM-DD');

      const {
        data: {count, results: data}
      } = await billing.getBillingTransactions(
        startDate,
        endDate,
        search,
        page * rowsPerPage,
        rowsPerPage,
        sort
      );
      const sumData = await billing.getBillingTransactionsTotal(
        startDate,
        endDate
      );

      this.setState({
        sumData: sumData.data,
        data,
        count,
        isLoading: false
      });
    } catch (err) {
      notifyError(err.message);
      this.setState({isLoading: false});
    }
  };

  overviewHeaders = [
    listHeader('Created', 'created', true, (row) =>
      renderFullDate(row.created)
    ),
    listHeader(
      'Company',
      'store__organizations__full_name',
      true,
      (row) => row.company_name,
      undefined,
      undefined,
      'right'
    ),
    listHeader(
      'Store',
      'store__full_name',
      true,
      (row) => row.store_name,
      undefined,
      undefined,
      'left'
    ),
    listHeader(
      'Amount',
      'amount',
      true,
      undefined,
      undefined,
      'undefined',
      'left'
    ),
    listHeader(
      'Currency',
      'currency',
      true,
      undefined,
      undefined,
      'undefined',
      'right'
    ),
    listHeader(
      'Crypto Amount',
      'crypto_amount',
      true,
      undefined,
      undefined,
      'undefined',
      'right'
    ),
    listHeader(
      'Crypto Currency',
      'crypto_currency',
      true,
      undefined,
      undefined,
      'undefined',
      'right'
    ),
    listHeader(
      'Conversion Rate',
      'conversion_rate',
      true,
      undefined,
      undefined,
      'undefined',
      'right'
    ),
    listHeader(
      'Transaction Fee',
      'transaction_fee',
      true,
      undefined,
      undefined,
      'undefined',
      'right'
    ),
    listHeader(
      'Exchange Fee',
      'provision_fee',
      true,
      undefined,
      undefined,
      'undefined',
      'right'
    )
  ];

  onChangeRowsPerPage = (e) => {
    let newRowsPerPage = e.target.value;
    let currentItem = this.state.page * this.state.rowsPerPage || 0 + 1;
    let newPage = Math.floor(currentItem / newRowsPerPage);
    this.setState({rowsPerPage: newRowsPerPage, page: newPage}, () => {
      this.loadData();
    });
  };

  changePage = (e, page) => {
    this.setState({page});
  };

  requestSort = (sort) => {
    this.setState({sort, page: 0}, () => {
      this.loadData();
    });
  };

  requestSearch = (e) => {
    e.preventDefault();
    this.loadData();
  };

  onInputChange = (e) => {
    this.setState({search: e.target.value}, () => {
      clearTimeout(this.debounceTimer);
      if (this.state.search == '') {
        this.loadData();
      } else {
        this.debounceTimer = setTimeout(() => {
          this.loadData();
        }, 200);
      }
    });
  };

  render() {
    const {data, page, isLoading, count, rowsPerPage, sumData} = this.state;

    return (
      <Modal
        isLoading={isLoading}
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        submitButton={() => null}
        size="xl"
        title={`Billing Overview ( ${this.props.start_date.format(
          'DD.MM.YYYY'
        )} - ${this.props.end_date.format('DD.MM.YYYY')} )`}
      >
        <div styleName="sub-title">Sum Total (EUR)</div>
        <div styleName="">
          <SumTotalCardDisplay data={sumData} />
        </div>
        <div styleName="sub-title"> All transactions </div>
        <Card>
          <ListHeader
            type="BillingOverview"
            onChange={this.onInputChange}
            value={this.state.search}
            onSubmit={this.requestSearch}
          />
          <ListBodyWithLoading
            sortField={this.state.sort}
            requestSort={this.requestSort}
            headers={this.overviewHeaders}
            isLoading={isLoading}
            data={data}
            count={count}
            currentPage={page}
            onChangePage={this.changePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
          />
        </Card>
      </Modal>
    );
  }
}

function mapState(state) {
  return {
    query: qs.parse(state.router.location.search, {ignoreQueryPrefix: true})
  };
}

function mapActions(dispatch) {
  return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(BillingOverviewModal));
