import React from 'react'

import profileIcon from '../../user_eligma.svg'

import cx from 'classnames'

import "./menu.module.css";

export default props => {

    const { user, links } = props
    
    if(!user) return null;

    return (
        <div styleName="menu">
            <div styleName="profile">
                <img src={profileIcon} styleName="picture" />
                <span styleName="title">{`${user.first_name} ${user.last_name}`}</span>
                <span styleName="status">{user.email}</span>
            </div>
            <div styleName="divider"></div>
            <div>
                {links.map((link, index) => link&&(
                    <div styleName={cx("link", { "active": link.icon == props.active.icon })} 
                            onClick={() => props.navigateTab(index)} 
                            key={link.icon} >
                        <i className={`flaticon-${link.icon}`}></i>
                        <span>{link.name}</span>
                    </div>
                ))}
            </div>
            <div styleName="divider"></div>
        </div>
    )
}