import React from 'react'
import { Link } from 'react-router-dom'

import cx from 'classnames'

import './sideBarSub.module.css'
import { PermissionGuard } from "../../../Util/role-helpers";
import { Collapse } from "reactstrap";

class SideBarSub extends React.Component {

    state = {
        expanded: {}
    }

    componentDidMount() {
        const expanded = {};
        if (this.props.subLinks) {
            this.props.subLinks.forEach((item, index) => {
                expanded[index] = false;
            })
        }
        this.setState({ expanded })
    }

    clickHandler = (index) => {
        this.setState(prev => ({
            expanded: {
                ...prev.expanded,
                [index]: !prev.expanded[index]
            }
        }))
    }


    render() {
        return (
            this.props.subLinks.map((item, index) => (
                <PermissionGuard code={item.permission} key={item.label} atLeastOne={item.atLeastOne} allowedRoles={item.allowedRoles}>
                    <div styleName="subLinks">
                        {item.subLinks &&
                            (<a styleName="link" href="#" onClick={(e) => { e.preventDefault(); this.clickHandler(index) }} >
                                <span styleName="bullet">&bull;</span>
                                <span>{item.label}</span>
                                <small styleName={cx('chevron', { expanded: this.state.expanded[index] })}>&#10095;</small>
                            </a>)}
                        {!item.subLinks && this.props.subLinkMapping && this.props[this.props.subLinkMapping].map((sublinkItem, subLinkIndex) => (
                            (
                                <Link key={`${this.props.subLinkMapping}-${subLinkIndex}`} styleName="link" to={`${this.props.menuLink}?tier=${sublinkItem.id}`} >
                                    <span styleName="bullet">&bull;</span>
                                    <span>{sublinkItem.name}</span>
                                </Link>
                            )
                        ))}
                        {!item.subLinks &&
                            (<Link styleName="link" to={item.link} >
                                <span styleName="bullet">&bull;</span>
                                <span>{item.label}</span>
                            </Link>)}
                        {item.subLinks && this.props.show && (
                            <Collapse isOpen={this.state.expanded[index]} styleName="box">
                                <SideBarSub subLinks={item.subLinks}
                                    menuLink={item.link}
                                    subLinkMapping={item.subLinkMapping}
                                    tiers={this.props.tiers}
                                    show={this.props.show} />
                            </Collapse>
                        )}
                    </div>
                </PermissionGuard>
            ))
        )
    }
}

export default SideBarSub;
