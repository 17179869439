import qs from "query-string";
import React, { Fragment } from 'react';
import { push } from 'connected-react-router';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Card from '../../UI/Card/Card';
import Page from '../../UI/Page/Page';
import Button from '../../UI/Button/Button';
import WithLoading from "../../Util/HOC/WithLoading";
import { listHeader, renderFullDate, renderLinkButton } from "../../Util/listView";
import ListBody from "../../Util/ListView/ListBody/ListBody";
import ListHeader from "../../Util/ListView/ListHeader/ListHeader";
import { createMessage } from "../../Util/notification";
import { getSafeDeep } from "../../Util/state";
import holistic from "../../../api/V3/holistic";
import { parseErrors } from '../../Util/form';
import HolisticPOSAddNew from '../HolisticPOSAddNew';
import HolisticPOSDeleteModal from '../HolisticPOSDeleteModal';
import HolisticPOSEditModal from "../HolisticPOSEditModal";

const ListBodyWithLoading = WithLoading(ListBody);


class HolisticPOSStoreDetails extends React.Component {

    state = {
        isLoading: true,
        count: 0,
        rowsPerPage: 10,
        sort: '',
        addNew: false,
        delete: null,
        terminals: [],
        about: {}
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { match: { params: { id } } } = this.props;
        const { match: { params: { id: oldId } } } = prevProps;
        if (id !== oldId) {
            this.loadData()
        }
    }

    loadData = async () => {
        const { match: { params: { id } } } = this.props;
        this.setState({ isLoading: true });
        try {
            const { data: about } = await holistic.getStoreDetails(id)
            const { data: terminals } = await holistic.getStoreTerminals(id)

            this.setState({
                about,
                terminals
            });

        } catch (e) {
            parseErrors(e)
        } finally {
            this.setState({ isLoading: false })
        }
    };

    getCompaniesListHeaders = () => {

        return [
            listHeader('Terminal ID', "id", false),
            // listHeader('Created', "created", false, (data) => renderFullDate(data.created)),
            listHeader('Delete', '', false, (item) => { return <Button color="danger" isLoading={this.state.isLoading} onClick={() => { this.setState({ delete: item }) }}>Delete</Button> })
        ]
    }

    render() {
        const { isLoading, terminals, count } = this.state;
        return <Fragment>
            <Helmet>
                <title>Holistic POS Store Details | Elicon</title>
            </Helmet>
            <Page permission={'view_all_holistic_companies'}>
                <Card>
                    <ListHeader
                        type='OnlySearch'
                        noSearch
                        title={this.state.about.full_name}
                        searchTitle="Search"
                        actionHandler={() => this.setState({ addNew: true })}
                        actionLabel="Add New Terminal"
                        additionalComponent={() => {
                            return <Button className="ml-3" color="primary" onClick={() => { this.setState({ edit: true }) }}>Edit Store</Button>
                        }}
                    />
                    <ListBodyWithLoading
                        pagination
                        sortField={this.state.sort}
                        requestSort={this.requestSort}
                        padding="16px"
                        headers={this.getCompaniesListHeaders()}
                        isLoading={isLoading}
                        data={terminals}
                        count={count}
                        rowsPerPage={this.props.query.pageSize || 10}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                </Card>
            </Page>
            <HolisticPOSAddNew
                isOpen={this.state.addNew}
                reloadData={() => this.loadData()}
                toggle={() => this.setState({ addNew: false })}
                label={'Terminal'}
                fullLabel={'Terminal ID'}
                onSubmit={(value) => {
                    return holistic.createTerminal(value)
                        .then(res => res.data.id)
                        .then(id => {
                            return holistic.storeAddTerminal(this.state.about.id, id)
                        })
                }}
            />
            <HolisticPOSDeleteModal
                isOpen={!!this.state.delete}
                reloadData={() => { this.loadData() }}
                toggle={() => this.setState({ delete: null })}
                label={getSafeDeep(this.state.delete, 'id')}
                onSubmit={() => { return holistic.deleteTerminal(this.state.delete.id) }}
            />
            {/* edit store */}
            <HolisticPOSEditModal
                active={this.state.edit ? this.state.about : null}
                reloadData={() => { this.loadData() }}
                toggle={() => this.setState({ edit: false })}
                label="Store"
                onSubmit={(name) => { return holistic.updateStore(this.state.about.id, name) }}
            />
            {/* edit terminal */}
            {/* <HolisticPOSEditModal
                active={this.state.active}
                reloadData={() => { this.loadData() }}
                toggle={() => this.setState({ active: null })}
                label="Terminal"
                onSubmit={(name) => { return holistic.updateTerminal(this.state.active.id, name) }}
            /> */}
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(HolisticPOSStoreDetails));
