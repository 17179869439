import {fulfilled, fulfilledState, initState} from "./middleware";
import documentType from "../api/documentType";

export const GET_DOCUMENT_TYPE_LIST = "GET_DOCUMENT_TYPE_LIST";

export default (state = initState, action) => {
    switch (action.type){
        case fulfilled(GET_DOCUMENT_TYPE_LIST):
            return fulfilledState(state, action.payload.data);
        default:
            return state;
    }
}

export const getDocumentTypeList = () => ({
    type: GET_DOCUMENT_TYPE_LIST,
    payload: documentType.list()
});
