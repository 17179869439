import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SideNav from "../SideNav/SideNav";
import Button from '../UI/Button/Button'
import {Col, Row} from "reactstrap";
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import {push} from 'connected-react-router';
import user from "../../api/user";
import {listHeader, renderEditUser, renderLink, renderName, renderProfileName} from "../Util/listView";
import {getSafe, getSafeDeep} from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';
import AddUserDialog from "./AddUserDialog";
import EditUserDialog from "./EditUserDialog";
import {API_URL} from "../../config";
import { Card } from '@material-ui/core';
import Page from '../UI/Page/Page';
import More from "../UI/More/More";
import {Delete, Edit, Search, Send} from "@material-ui/icons";
import Chip from '../UI/Chip/Chip'
import { renderDoneClear, getStyledActions, renderLinkButton } from '../Util/listView'
import Modal from '../UI/Modal/Modal'
import UserDetailsDialog from './UserDetailsDialog'
import moment from 'moment'
import { createMessage, notifyError } from '../Util/notification'
import { useState } from 'react';
import {hasPermission, PermissionGuard} from "../Util/role-helpers";
import {Helmet} from "react-helmet";

const ListBodyWithLoading = WithLoading(ListBody);

const ActivationEmailDialog = props => {
    const [isLoading, setLoading] = useState(false)
    return(
        <Modal
            isLoading={isLoading}
            title="Are you sure?"
            toggle={props.toggle}
            isOpen={!!props.user}
            size="xs"
            onSubmit={() => {
                setLoading(true)
                user.resendActivationEmail(props.user.id)
                    .then(res => {
                        createMessage('Successfully submitted');
                        props.reloadData()
                        setLoading(false)
                        props.toggle()
                    })
                    .catch(e => {
                        notifyError(e.message)
                        setLoading(false)
                    })
            }}
            cancelLabel="Close"
            confirmLabel="Ok">
            <p>Activation email for user "{props.user && props.user.first_name}" is going to be resent</p>
        </Modal>
    )
}


const DeleteUserDialog = props => {
    const [isLoading, setLoading] = useState(false)

    return(
        <Modal
            title="Are you sure?"
            toggle={props.toggle}
            isOpen={!!props.user}
            size="xs"
            isLoading={isLoading}
            cancelLabel="Close"
            submitButton={() => <Button isLoading={isLoading} className="ml-3" onClick={() => {
                setLoading(true)
                user.delete(props.user.id)
                    .then(res => {
                        createMessage('Successfully submitted');
                        props.reloadData()
                        setLoading(false)
                        props.toggle()
                    })
                    .catch(e => {
                        notifyError(e.message)
                        setLoading(false)
                    })
            }} color="danger">Delete</Button>} >
            <p className="text-center">User "{props.user && props.user.first_name}" is going to be deleted</p>
        </Modal>
    )
}

class UserList extends React.Component {

    state = {
        isLoading: true,
        addUserDialog: false,
        editUser: null,
        userDetails: null,
        activationEmail: null,
        deleteUser: null,
        rowsPerPage: 10,
        count: 0,
        sort: '',
    };

    getStyledActions = (data) => {
        const items = [
            {
                handler: () => this.setState({ userDetails: data }),
                Icon: Search,
                text: 'Details'
            }
        ]

        if (hasPermission(this.props.user, 'change_user')) {
            items.push({
                handler: () => this.setState({ editUser: data }),
                Icon: Edit,
                text: 'Edit'
            })
        }

        if (hasPermission(this.props.user, 'resend_activation_mail')) {
            items.push({
                handler: () => this.setState({ activationEmail: data }),
                Icon: Send,
                text: 'Resend Activation Email'
            })
        }

        if (hasPermission(this.props.user, 'delete_user')) {
            items.push({
                handler: () => this.setState({ deleteUser: data }),
                Icon: Delete,
                text: 'Delete'
            })
        }

        return getStyledActions(items)
    }

    renderRoles = (data) => {
        return(
            <div className="d-flex">
                {data.roles.slice(0, 3).map(item => {
                    return (
                        <Chip text={item.name} key={`${item.id}`} /> 
                    )
                })}
                {data.roles.length > 3 ? <Chip color="#757575" text={`+ ${data.roles.length - 3} More`} /> : null}
                {data.roles.length == 0 ? <Chip color="#757575" text={`No Assigned Role`} /> : null}
            </div>
        )
    }
    

    userListHeaders = [
        // listHeader('Full Name', "name", true, (data) => this.setState({ userDetails: data })),
        listHeader('Full Name', "first_name", true, (data) => renderLinkButton(renderProfileName(data), () => this.setState({ userDetails: data }))),
        listHeader('Email', "email", true),
        listHeader('Roles', "groups", false, (data) => this.renderRoles(data)),
        listHeader('Last Login', "last_login", true, (data) => { return data.last_login ? moment(data.last_login).format('DD.MM.YYYY HH:mm:ss') : 'Never Logged In'}),
        listHeader('Is Login Blocked', "is_login_blocked", true, (data) => (renderDoneClear(data.is_login_blocked)), undefined, undefined, 'center'),
        listHeader('Activated', "registered_token", true, (data) => (renderDoneClear(data.registered_token == null)), undefined, undefined, 'center'),
        listHeader('Action', "", false, (data) => <More items={this.getStyledActions(data)} />, undefined, undefined, "center"),
    ];

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, oldState) {
        const {query, auth} = this.props;
        const {query: oldQuery, auth: oldAuth} = oldProps;
        if (JSON.stringify(query) !== JSON.stringify(oldQuery) || this.state.rowsPerPage != oldState.rowsPerPage){
            this.loadData();
        }
    }

    loadData = async () => {
        // const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        try{
            const query = this.props.query
            const page = query.page || 0;
            const sort = query.sort; 
            const search = query.search
            // const tier = query.tier;
            this.setState({isLoading: true, sort});
            const {data: {count, results: data}} = await user.listPaginated('user', search, (page) * this.state.rowsPerPage, this.state.rowsPerPage, sort);
            this.setState({count, data, isLoading: false});
            if(query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
        }catch(err){
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    };

    renderAdditionalComponents = () => {
        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const search = query.search;
        let exportUrl = API_URL + `admin/users/export`;
        if(search) exportUrl += `?q= + ${search}`;
        return (
            <PermissionGuard code="view_users_export">
                <Button className="ml-3" color="outline-primary" target="_blank" href={exportUrl}>Export users</Button>
            </PermissionGuard>
        )
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = {...this.props.query};
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    requestSort = (sort) => {
        this.setState({sort});
        const query = {...this.props.query};
        query.page = 0;
        query.sort = sort
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    render() {
        const {isLoading, data, rowsPerPage, count} = this.state;

        return <Fragment>
            <Helmet>
                <title>Users | Elicon</title>
            </Helmet>
            <Page permission={'view_all_users'}>
                <Card>
                    <ListHeader
                        type='AllUsers'
                        title = "User List"
                        searchTitle = "Filter"
                        permission="add_user"
                        actionHandler = { () => this.setState({ addUserDialog: true }) }
                        actionLabel = "Add user"
                        additionalComponent = {this.renderAdditionalComponents}
                    />
                    <ListBodyWithLoading
                        sortField={this.state.sort}
                        requestSort={this.requestSort}
                        headers={this.userListHeaders}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                </Card>
            </Page>
            <AddUserDialog
                            reloadData={this.loadData}
                            isOpen={this.state.addUserDialog}
                            toggle={() => this.setState({ addUserDialog: false })}/>
            <EditUserDialog
                            isLoading={this.state.editLoding}
                            reloadData={this.loadData}
                            data={this.state.editUser}
                            toggle={() => this.setState({ editUser: null })}/>
            <ActivationEmailDialog
                            reloadData={this.loadData}
                            user={this.state.activationEmail}
                            toggle={() => this.setState({ activationEmail: null })} />
            <DeleteUserDialog
                            reloadData={this.loadData}
                            user={this.state.deleteUser}
                            toggle={() => this.setState({ deleteUser: null })} />
            <UserDetailsDialog    
                            user={this.state.userDetails}
                            toggle={() => this.setState({ userDetails: null })} />
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        user: state.user.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(UserList));
