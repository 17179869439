import {apiClient} from "./util";

export default {
    // List brands with pagination
    listPaginated: (filter: string, q: string = "", offset: number = 0, limit: number = 10, sort: string = "id") =>{
        let params = {
            filter: filter,
            offset: offset,
            limit: limit,
            sort: sort
        };
        if(q !== "") params.q = q;
        return apiClient.get("/admin/franchises", {params: params});
    },

    // Get all unpaginated brands
    list: (q, limit: 10, offset: 0) => {
        return apiClient.get("/admin/franchises", {params: {q, limit, offset}});
    },

    // Create a brand
    create: (brand: Object) => {
        return apiClient.post("/admin/franchise/", brand);
    },

    // Delete a brand
    delete: (id: number) => {
        return apiClient.delete(`/admin/franchise/${id}`);
    },

    // Update a brand
    update: (brand: Object) => {
        return apiClient.put(`/admin/franchise/${brand.id}`, brand);
    },

    // Get a brand by ID
    get: (id: number) => {
        return apiClient.get(`/admin/franchise/${id}`);
    }
}
